import React from 'react';
import {Link} from "react-router-dom";

class BookSection extends React.Component {
  render() {
    return(
      <section className="book-section book-section-container">
  			<div className="container text-center">
  				<Link to="/search-car" className="book-button">Réserver un Monbolide</Link>
  			</div>
  		</section>
    )
  }
}

export default BookSection
