import moment from "moment";

export function isDateExpired (checkin) {
    const format = 'DD-MM-YYYY HH:mm';
  
    let date1 = moment(checkin, format);
    // Ajouter 7 jours à date1
    date1.add(7, 'days');
  
    const currentDate = moment();
  
    return currentDate.isAfter(date1);
  }


export function copyFiveElements (source) {
    let tableauDestination = []; // création du tableau destination vide

    for (let i = 0; i < 10; i++) {
        if (source[i]) { // vérifie si l'élément existe dans le tableau source
            source[i].selected = false;
            tableauDestination.push(source[i]); // ajoute l'élément au tableau destination
        } else {
            break; // sort de la boucle si le tableau source ne contient pas assez d'éléments
        }
    }
    // console.log(tableauDestination)
    return tableauDestination; // retourne le tableau destination rempli
}

// formatter l'affichage des distances

export default function formatDistance (number) {
    
    let finalNumber = number    
    if (number && number?.toFixed(2) < 1) {
      finalNumber = number * 1000
      finalNumber = Math.round(finalNumber)    
      return finalNumber.toString() + "m"
    }

    // finalNumber = Math.round(number)
    
    if(number !== undefined) {
        finalNumber = parseFloat(number?.toFixed(2));
        if(finalNumber === 0){
            return "très proche".toUpperCase()    
        }
        return finalNumber.toString() + "Km"
    }
    
    // console.log("finalNumber: " + finalNumber)
    
  
  }