import React from "react";

class BookcardSection extends React.Component {
  render() {
    return (
      <section className="book-card-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-img">
                  <img
                    src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/business/business1_vnsvon.png"
                    className="res-img"
                    alt="voitures"
                  />
                </div>
                <div className="card-text">
                  <h3 className="book-big-text mb-16">
                    Des voitures de ville élégantes pour les réunions d'affaires
                  </h3>
                  <p className="book-small-text">
                    Un moyen fiable de rendre visite aux clients et aux
                    consommateurs lorsque les transports publics ne suffisent
                    pas.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-img">
                  <img
                    src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/business/business2_jpejf8.png"
                    className="res-img"
                    alt="Voitures plus grandes"
                  />
                </div>
                <div className="card-text">
                  <h3 className="book-big-text mb-16">
                    Voitures plus grandes pour l'équipement ou les événements
                  </h3>
                  <p className="book-small-text">
                    Réservez des véhicules offrant de l'espace supplémentaire
                    pour vous aider à accomplir vos tâches.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-img">
                  <img
                    src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/business/business3_opz0kl.png"
                    className="res-img"
                    alt="Voitures pour les journées"
                  />
                </div>
                <div className="card-text">
                  <h3 className="book-big-text mb-16">
                    Voitures pour les journées hors site et la formation
                    d'équipes
                  </h3>
                  <p className="book-small-text">
                    Des voitures comportant jusqu'à 7 sièges pour permettre à
                    toute l'équipe de voyager facilement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BookcardSection;
