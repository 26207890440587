import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./About.css";
class About extends React.Component {
  render() {
    const pagesList = this.props.DashboardReducer.pagesList;
    const pagesListArray = [];
    if (pagesList !== undefined && pagesList.length > 0) {
      for (let i = 0; i < pagesList.length; i++) {
        pagesListArray.push(
          <li className="footer-list" key={i} style={{ padding: "3px" }}>
            <Link to={"/pages/" + pagesList[i].slug}>{pagesList[i].name}</Link>
          </li>
        );
      }
    }
    return (
      <section style={{ background: "rgb(0 74 125)" }} >
        <div className="container" >
          <div className="top">
            <div className="row">
              <div className="col-lg-6">
                <div className="about">
                  <div className="title">A propos de nous</div>
                  <p>
                  Monbolide est une entreprise ivoirienne qui souhaite rendre
                    la mobilité plus accessible, plus facile et plus responsable
                    grâce au partage. Avec Monbolide, la location d'un véhicule
                    en Côte d'Ivoire n'a jamais été aussi simple.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ display: pagesListArray < 0 ? "none" : "block" }}
              >
                <div className="title">Partenaires</div>
                {/* <ul style={{width:"650px",height:"100px",flexFlow:"column wrap",display:"flex",flexDirection:"column",listStyle:"none"}}>
                          {pagesListArray}
                        </ul> */}
                <div className="container">
                  <div className="row">
                    <div
                      className="partnerIcon"
                      style={{ maxWidth: "90px", marginRight: "40px" }}
                    >
                      <img
                        src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/partenaires/paydunya_iawh4y.png"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="partnerIcon" style={{ maxWidth: "90px" }}>
                      <img
                        src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/partenaires/stripe_enovaq.jpg"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section" className="py-4">
              <div className="title">Contacts</div>
              <div className="col-lg-10 col-md-12 col-sm-12">
                <div
                  className="row clearRow"
                  style={{ justifyContent: "space-around" }}
                >
                  <div className="py-2 contact-alignment office">
                    <div className="container-icon container-iconMargin">
                      <i className="fas fa-map-marker-alt iconPositionContact"></i>
                    </div>
                    <span>Bingerville Cité Palma lot 1</span>
                  </div>
                  <div className="py-2 contact-alignment tel">
                    <div className="container-icon container-iconMargin">
                      <i className="fas fa-phone iconPositionContact"></i>
                    </div>
                    <span>(+225)0700780830/(+225)2722209208</span>
                  </div>
                  <div className="py-2 contact-alignment emailContact ">
                    <div className="container-icon container-iconMargin">
                      <i className="fas fa-globe iconPositionContact"></i>
                    </div>
                    <span>support@monbolide.com</span>
                  </div>
                </div>
              </div>

              {/* <div className="title" >
                      <a href="" style={{ marginTop: '30px' }}>Contact </a>
                    </div>
                    <p>
                      Téléphone/Fixe:<strong> (+225) 0700780830 / (+225) 2722209208</strong>
                    </p>
                    <p>
                      Email:<strong> monbolide.civ@gmail.com</strong>
                    </p>
                    <p>
                      Siège social:<strong> Bingerville Cité Palma lot 1</strong>
                    </p> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const AboutComponent = connect(mapStateToProps, mapDispatchToProps)(About);

export default AboutComponent;
