import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import {AC_YOUR_CAR,AC_HANDLE_INSTANT_LOCATION} from "../../actions/carsaction";



class InstantBookingSection extends React.Component
{ 
    constructor(props){
      super(props)
      this.state = {}

      
    }
   
    componentDidMount(){
      this.props.ViewCar({id:localStorage.getItem('carId')});
    }


    instantLocation(e){
      e.preventDefault();
      let car = this.props.CarsReducers.carList;
     

      if(!car.snapshot){
        swal({
          title: "Voulez-vous activer la reservation instantané ?",
          text: "Les demandes de Location seront immédiatement accepté",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async (response) => {
          if (response) {
            await this.props.switchIntantLocation({notif:{snapshot:true}},car._id);
          }
        });
      }else{
        swal({
          title: "Voulez-vous desactiver la reservation instantané ?",
          text: "Les demandes de Location ne seront plus immédiatement accepté",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async (response) => {
          if (response) {
            await this.props.switchIntantLocation({notif:{snapshot:false}},car._id);
          }
        });
      }


     

    }
    render()
    {
     
    
        return(
            <div className="list-car-card px-4">	
              <div className="booking-img mb-5">
                <div className="booking-txt">		
                  <div className="row">
                    <div className="col-md-6">
                      <div>	
                        <h1 className="desc-title w-clr">Augmentez vos revenus avec la réservation instantanée</h1>
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div>	
                        <a 
                          href="#" 
                          onClick={(e)=>this.instantLocation(e)}
                          className="Woohoo-btn big-woohoo">
                         
                            {(this.props.CarsReducers.carList.snapshot) ? "Désactiver la réservation instantanée" : "Activer la réservation instantanée"}
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="instant-booking">	
                <h2 className="desc-heading size-21 mb-4">Les avantages de la réservation instantanée</h2>
                <div className="row">	
                  <div className="col-md-6 d-flex">
                    <div className="l-desc">	
                      <i className="fa fa-globe" />
                    </div>
                    <div className="r-desc">	
                      <h3 className="desc-heading">Plus d'argent</h3>
                      <p>Votre voiture est mise en évidence dans les recherches et devient plus facile à réserver, ce qui attire davantage de conducteurs.</p>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="l-desc">	
                      <i className="fa fa-globe" />
                    </div>
                    <div className="r-desc">	
                      <h3 className="desc-heading">Des profils de confiance</h3>
                      <p>Les conducteurs dont les avis ne sont pas assez bons ne pourront pas réserver votre voiture.</p>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="l-desc">	
                      <i className="fa fa-globe" />
                    </div>
                    <div className="r-desc">	
                      <h3 className="desc-heading">Moins d'efforts</h3>
                      <p>Vous ne recevez que les réservations qui ont déjà été confirmées et payées. Vous n'aurez donc plus à répondre aux demandes !</p>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="l-desc">	
                      <i className="fa fa-globe" />
                    </div>
                    <div className="r-desc">	
                      <h3 className="desc-heading">Les locations que vous choisissez</h3>
                      <p>Vous décidez à l'avance du type de réservations que vous recevrez : durée, heures d'arrivée et de départ et délai de préavis.</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="benefit-border" />
              <div className="py-4">	
                <h2 className="desc-heading size-21 mb-4">Comment cela fonctionne pour les conducteurs</h2>
                <div className="stepline">
                  <div className="stepline-item">	
                    <div className="icon-container pb-3">
                      <i className="fa fa-car" />	
                    </div>
                    <div className="stepline-content">
                      <div className="desc-heading mb-4">Recherche</div>	
                      <p>Votre véhicule est bien classée car la réservation instantanée offre aux conducteurs la meilleure expérience possible.</p>
                    </div>
                  </div>
                  <div className="stepline-item">	
                    <div className="icon-container pb-3">
                      <i className="fa fa-car" />	
                    </div>
                    <div className="stepline-content">
                      <div className="desc-heading mb-4">Inscription</div>	
                      <p>Le conducteur choisit votre véhicule - il ne doit pas en choisir d'autres.</p>
                    </div>
                  </div><div className="stepline-item">	
                    <div className="icon-container pb-3">
                      <i className="fa fa-car" />	
                    </div>
                    <div className="stepline-content">
                      <div className="desc-heading mb-4">Réservation instantanée</div>	
                      <p>Le conducteur écrit un message pour dire pourquoi il veut louer votre véhicule, puis il paie immédiatement.</p>
                    </div>
                  </div><div className="stepline-item">	
                    <div className="icon-container pb-3">
                      <i className="fa fa-car" />	
                    </div>
                    <div className="stepline-content">
                      <div className="desc-heading mb-4">Location confirmée</div>	
                      <p>La location est confirmée - vous recevez une notification et pouvez discuter avec le conducteur.</p>
                    </div>
                  </div>	
                </div>	
              </div>
            </div>
         

        );
    }
}


function mapStateToProps(state) {
  return {
    CarsReducers : state.CarsReducers
  };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ViewCar:AC_YOUR_CAR, switchIntantLocation:AC_HANDLE_INSTANT_LOCATION},dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(InstantBookingSection);