import React from 'react';
import {Redirect } from "react-router-dom";

class AddVehicle extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     previous     : false,
     next         : false,
   }
   this.validateForm=this.validateForm.bind(this);
   this.previous     = this.previous.bind(this);
  }
  validateForm() {
  this.setState({next : true})
  }

  previous(){
   this.setState({previous : true})
  }
  render() {
    const next     = this.state.next;
    const previous = this.state.previous;
    if(next){
      return <Redirect to="/schedule" />
    }
    if(previous){
      return <Redirect to="/billing-address" />
    }
    return(
      <><section className="vehicle-form-layout">
    		<div className="container">
    			<div className="vehicle-title">Ajouter votre véhicule</div>
    			<div className="row b-60">
    				<div className="col-md-9 mb-24">
    					<div className="vehicle-card">
    						<div className="vehicle-content">
    							<div className="vehicle-section">
    								<div className="vehicle-layout">
    									<div className="layout-wrapper">
    										<div className="booking-container">
    											<div className="powered">
          									<a href="#" className="brand-con">
          									<div className="brand-badge">
          										<div className="brand-powered">powered by</div>
          										<div className="brand-calendly">Calendly</div>
          									</div>
          									</a>
          								</div>
          								<div className="section-inner-padding">
          									<div className="section-info">
          										<img src="assets/images/mon-bolide-logo.png" className="mon-bolide-logo" alt=''/>
          										<h4 className="mon-bolide-name">Monbolide UK</h4>
          										<h1 className="mon-bolide-event">Monbolide Connect phone appointment</h1>
          									</div>
          									<div className="time-icon-container">
          										<div className="time-icon">
          											<span><i className="fa fa-clock-o" aria-hidden="true"></i>
          											</span>
          											15 min
          										</div>
          									</div>
          									<div className="vehicle-des">
          										<p>Our team will get back to you to answer all your questions and plan the install of the Monbolide Connect device at the time you have chosen.</p>
          									</div>
          								</div>
          								<div className="date-and-time">
          									<h2 className="date-and-time-title">Select a Date & Time</h2>
          									<div className="date-and-time-header">
          										<div className="date-and-time-month">July 2020</div>
          										<div>
          											<button className="left-nav-btn" type="button" disabled><span className="icon-left"></span></button>
          											<button className="right-nav-btn" type="button"><span className="icon-right"></span></button>
          										</div>
          									</div>
          									<div className="calendar-table-wrapper">
          										<table className="calendar-weeks">
          											<thead>
          												<tr>
          													<th>Mon</th>
          													<th>Tue</th>
          													<th>Wed</th>
          													<th>Thu</th>
          													<th>Fri</th>
          													<th>Sat</th>
          													<th>Sun</th>
          												</tr>
          											</thead>
          										</table>
          										<table className="calendar-table">
          											<tbody>
          												<tr>
          													<td>
          														<button className="calendar-day calendar-hidden" tabIndex="0" type="button" disabled=""><span>29</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-hidden" tabIndex="0" type="button" disabled=""><span>30</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>1</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>2</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>3</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>4</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>5</span></button>
          													</td>
          												</tr>
          												<tr>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>6</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>7</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>8</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>9</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>10</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>11</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>12</span></button>
          													</td>
          												</tr>
          												<tr>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>13</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>14</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>15</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>16</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>17</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>18</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>19</span></button>
          													</td>
          												</tr>
          												<tr>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>20</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-today-bookable" tabIndex="0" type="button"><span>21</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>22</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>23</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>24</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>25</span></button>
          													</td>
          													<td>
          														<button className="calendar-day" tabIndex="0" type="button" disabled=""><span>26</span></button>
          													</td>
          												</tr>
          												<tr>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>27</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>28</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>29</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>30</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-bookable" tabIndex="0" type="button"><span>31</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-hidden" tabIndex="0" type="button" disabled=""><span>1</span></button>
          													</td>
          													<td>
          														<button className="calendar-day calendar-hidden" tabIndex="0" type="button" disabled=""><span>2</span></button>
          													</td>
          												</tr>
          											</tbody>
          										</table>
          										<div className="calendar-live-region" role="status" aria-live="polite"></div>
          									</div>
          									<div className="live-time">
          										<button type="button" className="live-time-btn">India Standard Time (3:39pm)</button>
          									</div>
          								</div>
                          <div className="list-bottom">
                            <div className="btn-nav">
                            <button className="prev-button mr-2" onClick={this.previous}>
                              <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                              </span>Previous
                            </button>
                            <button className="Woohoo-btn" onClick={this.validateForm}>Confirm</button>
                            </div>
                          </div>
    										</div>
    									</div>
    								</div>
    						</div>
    					</div>
    				</div>
    				</div>
    				<div className="col-md-3 mb-8">
    					<div className="contact-info">
    						<div className="contact-title">Need help?</div>
    						<a href="#">Contact us</a>
    					</div>
    				</div>
            </div>
    		</div>
    	</section>


    	<section className="vehicle-form">
    		<div className="container">
    			<div className="vehicle-title">Add your vehicle</div>
    			<div className="row b-60">
    				<div className="col-md-9 mb-24">
    					<div className="vehicle-card">
    						<div className="vehicle-content">
    							<div className="vehicle-section">
    								<div className="vehicle-layout">
    									<div className="layout-wrapper">
    										<div className="booking-container">
    											<div className="powered">
          									<a href="#" className="brand-con">
          									<div className="brand-badge">
          										<div className="brand-powered">powered by</div>
          										<div className="brand-calendly">Calendly</div>
          									</div>
          									</a>
          								</div>
          								<div className="section-inner-padding">
          									<button className="back-button" type="button">
          										<span className="icon-arrow-left">
          											<i className="fa fa-arrow-left arrow-left"></i>
          										</span>
          									</button>
          									<div className="section-info">
          										<img src="assets/images/mon-bolide-logo.png" className="mon-bolide-logo" alt=''/>
          										<h4 className="mon-bolide-name">Monbolide UK</h4>
          										<h1 className="mon-bolide-event">Monbolide Connect phone appointment</h1>
          									</div>
          									<div className="form-wrapper">
          										<div className="form-wrapper-container">
          											<div className="form-time-icon">
          												<span className="form-time-icon-size">15min</span>
          												<div className="form-highlight">
          													<span className="icon-calendar">
          														<i className="fa fa-calendar-o">1:30pm - 1:45pm, Thursday, July 23, 2020</i>
          													</span>
          												</div>
          											</div>
          											<div className="standard-time">
          												<span className="icon-globe"><i className="fa fa-globe"> India Standard Time</i></span>
          											</div>
          										</div>
          									</div>
          									<div className="main-panel">
          										<div className="main-panel-wrapper">
          											<div className="details">
          												<h2>Enter Details</h2>
          												<form>
          												  <div className="form-group">
          												    <label htmlFor="">Name *</label>
          												    <input type="text" className="form-control"/>
          												  </div>
          												  <div className="form-group">
          												    <label htmlFor="">Email *</label>
          												    <input type="text" className="form-control"/>
          												  </div>
          												  <div className="guests-btn-container">
          													  <button className="guests-btn" type="button">
          													  	Add Guests
          													  </button>
          												   </div>

          												  <div className="form-group">
          												  	<div className="phone-wrapper">
          												  		<div className="phone-flag-container">
          												  			<div className="phone-flag-box">
          												  				<div className="flag-us"></div>
          												  			</div>
          												  		</div>
          												  	</div>
          												    <label htmlFor="">Phone Number *</label>
          												    <input type="text" className="form-control padding" placeholder=""/>
          												  </div>

          												  <div className="form-group">
          												  	<div className="phone-wrapper">
          												  		<div className="phone-flag-container">
          												  			<div className="phone-flag-box">
          												  				<div className="flag-in"></div>
          												  			</div>
          												  		</div>
          												  	</div>
          												    <label htmlFor="">Send text reminder to</label>
          												    <input type="text" className="form-control padding" placeholder=""/>
          												  </div>
          												</form>
          												<button className="event-btn" type="button">
          													Schedule Event
          												</button>
          											</div>
          										</div>
          									</div>
          								</div>
    										</div>
    									</div>
    								</div>
    						</div>
    					</div>
    				</div>
    				</div>
    				<div className="col-md-3 mb-8">
    					<div className="contact-info">
    						<div className="contact-title">Need help?</div>
    						<a href="#">Contact us</a>
    					</div>
    				</div>
            </div>
    		</div>
    	</section></>
    )
  }
}

export default AddVehicle;
