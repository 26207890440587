import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import { AC_ACCOUNT_SETTINGS } from "../../actions/accountaction";
import { Emailvalidate, Validpassword } from "../../common/validate.js";
import { Badge, Avatar } from "antd";

class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
      checkpassword: "",
      emailerror: false,
      passworderror: false,
      oldpassworderror: false,
      newpassworderror: false,
      confirmpassworderror: false,
      checkpassworderror: false,
      emailvalidate: false,
      passwordvalidate: false,
      confirmpasswordvalidate: false,
    };
  }
  changeEmail() {
    var email = this.state.email;
    var password = this.state.password;
    var type = "Email";
    if (email) {
      this.setState({ emailerror: false });
    } else {
      this.setState({ emailerror: true });
    }
    if (password) {
      this.setState({ passworderror: false });
    } else {
      this.setState({ passworderror: true });
    }
    if (email && password && !this.state.emailvalidate) {
      let settingId = localStorage.getItem("siteUser");
      const formData = {
        email: email,
        password: password,
        id: settingId,
        type: type,
      };
      this.props.AccountSettings(formData);
      this.setState({ email: "", password: "" });
    }
  }
  deleteAccount() {
    var password = this.state.checkpassword;
    var type = "Delete";
    if (password) {
      this.setState({ checkpassworderror: false });
    } else {
      this.setState({ checkpassworderror: true });
    }
    if (password) {
      let settingId = localStorage.getItem("siteUser");
      const formData = {
        password: password,
        id: settingId,
        type: type,
      };
      swal({
        title: "En êtes-vous sûr ?",
        text: "Votre compte sera définitivement supprimé !",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.props.AccountSettings(formData);
          this.setState({ checkpassword: "" });
        }
      });
    }
  }
  changePassword() {
    var password = this.state.oldpassword;
    var newpassword = this.state.newpassword;
    var confirmpassword = this.state.confirmpassword;
    var type = "Password";
    if (password) {
      this.setState({ oldpassworderror: false });
    } else {
      this.setState({ oldpassworderror: true });
    }
    if (newpassword) {
      this.setState({ newpassworderror: false });
    } else {
      this.setState({ newpassworderror: true });
    }
    if (confirmpassword) {
      this.setState({ confirmpassworderror: false });
    } else {
      this.setState({ confirmpassworderror: true });
    }
    if (
      password &&
      newpassword &&
      confirmpassword &&
      !this.state.passwordvalidate &&
      !this.state.confirmpasswordvalidate
    ) {
      let settingId = localStorage.getItem("siteUser");
      const formData = {
        password: password,
        newpassword: newpassword,
        id: settingId,
        type: type,
      };
      this.props.AccountSettings(formData);
      this.setState({ oldpassword: "", newpassword: "", confirmpassword: "" });
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    const error = name + "error";
    if (name === "email" && value) {
      if (!Emailvalidate(value)) {
        this.setState({ emailvalidate: true });
      } else {
        this.setState({ emailvalidate: false });
      }
    } else {
      this.setState({ emailvalidate: false });
    }
    if (name === "newpassword" && value) {
      if (!Validpassword(value)) {
        this.setState({ passwordvalidate: true });
      } else {
        this.setState({ passwordvalidate: false });
      }
    } else {
      this.setState({ passwordvalidate: false });
    }
    if (name === "confirmpassword" && value) {
      if (value !== this.state.newpassword) {
        this.setState({ confirmpasswordvalidate: true });
      } else {
        this.setState({ confirmpasswordvalidate: false });
      }
    } else {
      this.setState({ confirmpasswordvalidate: false });
    }
    if (value) {
      this.setState({ [name]: value, [error]: false });
    } else {
      this.setState({ [name]: value, [error]: true });
    }
  }
  render() {
    return (
      <div className="main-acc" data-aos="zoom-in">
        <div className="form-div">
          <fieldset className="form-field">
            <legend className="form-head">
              <i className="fas fa-user-edit"></i> Modifier votre email
            </legend>
            <div className="group-div">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                  <strong>Nouvelle adresse électronique</strong>
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Entrez un email"
                    value={this.state.email}
                    onChange={(e) => this.onChangeValue(e)}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  {this.state.emailerror ? (
                    <label style={{ color: "red" }}>
                      L'email est obligatoire
                    </label>
                  ) : (
                    ""
                  )}
                  {this.state.emailvalidate ? (
                    <label style={{ color: "red" }}>L'email est invalide</label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1"><strong>Mot de passe</strong></label>
                  <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={(e) => this.onChangeValue(e)}
                    placeholder="Entrez un mot de passe"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                  {this.state.passworderror ? (
                    <label className="text-danger">
                      Le mot de passe est requis
                    </label>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3">
                  {/*<i className="fa fa-info-circle circle-icon-color" />
                        <a href>Vous avez oublié votre mot de passe ?</a>*/}
                </div>
              </div>
              <button
                type="submit"
                className="account-btn text-white px-4 py-2"
                onClick={() => this.changeEmail()}
              >
                Enregistrer
              </button>
            </div>
          </fieldset>

          <fieldset className="form-field mt-5">
            <legend className="form-head">
              <i className="fas fa-key"></i> Changer le mot de passe
            </legend>
            <div className="group-div">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="oldpassword">
                  <strong>Ancien mot de passe</strong>
                  </label>
                  <input
                    type="password"
                    name="oldpassword"
                    value={this.state.oldpassword}
                    className="form-control"
                    onChange={(e) => this.onChangeValue(e)}
                    placeholder="Entrez l'ancien mot de passe"
                    id="oldpassword"
                    aria-describedby="emailHelp"
                  />
                  {this.state.oldpassworderror ? (
                    <label className="text-danger">
                      L'ancien mot de passe est requis
                    </label>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3"></div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="newpassword">
                  <strong>Nouveau mot de passe</strong>
                  </label>
                  <input
                    type="password"
                    name="newpassword"
                    value={this.state.newpassword}
                    className="form-control"
                    onChange={(e) => this.onChangeValue(e)}
                    placeholder="Entrez le nouveau mot de passe"
                    id="newpassword"
                    aria-describedby="emailHelp"
                  />
                  {this.state.newpassworderror ? (
                    <label style={{ color: "red" }}>
                      Un nouveau mot de passe est nécessaire
                    </label>
                  ) : (
                    ""
                  )}
                  {this.state.passwordvalidate ? (
                    <label style={{ color: "red" }}>
                      Le nouveau mot de passe n'est pas valide
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="confirmpassword"><strong>Confirmation</strong></label>
                  <input
                    type="password"
                    name="confirmpassword"
                    value={this.state.confirmpassword}
                    className="form-control"
                    onChange={(e) => this.onChangeValue(e)}
                    id="confirmpassword"
                    placeholder="Confirmer le mot de passe"
                    aria-describedby="emailHelp"
                  />
                  {this.state.confirmpassworderror ? (
                    <label style={{ color: "red" }}>
                      La confirmation du mot de passe est requise
                    </label>
                  ) : (
                    ""
                  )}
                  {this.state.confirmpasswordvalidate ? (
                    <label style={{ color: "red" }}>
                      Les mots de passe ne correspondent pas
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <button
                type="submit"
                onClick={() => this.changePassword()}
                className="account-btn text-white px-4 py-2"
              >
                Enregistrer
              </button>
            </div>
          </fieldset>

          <fieldset className="form-field mt-5">
            <legend className="form-head">
              <i className="fas fa-user-slash"></i> Supprimer mon compte
            </legend>
            <div className="group-div">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="checkpassword"><strong>Mot de passe</strong></label>
                  <input
                    type="password"
                    name="checkpassword"
                    value={this.state.checkpassword}
                    className="form-control"
                    onChange={(e) => this.onChangeValue(e)}
                    placeholder="Entrez votre mot de passe"
                    id="checkpassword"
                    aria-describedby="emailHelp"
                  />
                  {this.state.checkpassworderror ? (
                    <label className="text-danger">
                      Le mot de passe est requis
                    </label>
                  ) : (
                    ""
                  )}

                  <div className="mb-3"></div>
                  <button
                    type="submit"
                    onClick={() => this.deleteAccount()}
                    className="btn text-danger px-4 py-2"
                    style={{ border: "1px solid #dc3545" }}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ AccountSettings: AC_ACCOUNT_SETTINGS }, dispatch);
}

const AccountSettingsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings);

export default AccountSettingsComponent;
