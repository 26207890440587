import React from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

const RouteGuard = ({ component: Component, ...rest }) => {
    const isAuth = localStorage.getItem("siteUser") ? true : false;
    const history = useHistory();
    const location = useLocation();
    console.log(location.state)
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={`/sign-in?redirect=${location.pathname}${location.search}`} />
                )
            }
        />
    );
};

export default RouteGuard;