import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CarsDashboard from '../carsdashboard/carsdashboard';
import Request from './request';
import Rental from './rental';
import Cars from './cars';
import Payments from './payments';
import MyAccount from './myaccount';
import TabHeader from './tabheader';
import RentalAgreement from './rental-agreement';
import PaymentsuccessComponent from '../searchcar/paymentsuccess';



class Accounts extends React.Component
{
    render()
    {
      // const pathName = this.props.location.pathname;
      const pathName = window.location.pathname;

      // console.log(window.location.href)
        return(
          <Router>
           <TabHeader path = {pathName}/>
           <div className="pt-5 pb-5 main-bg">
            <div className="tab-content" style={{marginBottom: "100px"}} >
            <Switch>
            <Route exact path="/booking-request" component={Request}/>
            <Route exact path="/rental" component={Rental}/>
            <Route exact path="/hired-cars" component={Cars}/>
            <Route exact path="/car-profile" component={CarsDashboard}/>
            <Route exact path="/general-tab" component={CarsDashboard}/>
            <Route exact path="/instant-tab"component={CarsDashboard}/>
            <Route exact path="/mon-bolide-tab" component={CarsDashboard}/>
            <Route exact path="/description-tab"component={CarsDashboard}/>
            <Route exact path="/address-tab"component={CarsDashboard}/>
            <Route exact path="/photos-tab"component={CarsDashboard}/>
            <Route exact path="/calendar-tab"component={CarsDashboard}/>
            <Route exact path="/price-tab"component={CarsDashboard}/>
            <Route exact path="/conditions-tab"component={CarsDashboard}/>
            <Route exact path="/insurance-tab"component={CarsDashboard}/>
            <Route exact path="/deactivate-tab"component={CarsDashboard}/>
            <Route exact path="/payments" component={Payments}/>
            <Route exact path="/modify-profile" component={MyAccount}/>
            <Route exact path="/verify-profile"component={MyAccount}/>
            <Route exact path="/account-settings"component={MyAccount}/>
            <Route exact path="/notifications"component={MyAccount}/>
            <Route exact path="/get-credit"component={MyAccount}/>
            <Route exact path="/billing-settings"component={MyAccount}/>
            <Route exact path="/rental-agreement/:id"component={RentalAgreement}/>
            <Route exact path="/payment-success"component={PaymentsuccessComponent}/>

            </Switch>
            </div>
          </div>
          {/* <FindSection/>
          <Container/> */}
          </Router>
 );
}
}
export default Accounts;
