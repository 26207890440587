import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';

class CarFacilities extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			doors: '4',
			seats: '5',
			previous: false,
			next: false,
		}
		this.validateForm = this.validateForm.bind(this);
		this.previous = this.previous.bind(this);
	}
	componentDidMount() {
		if (localStorage.getItem('formId')) {
			this.props.viewDetails(localStorage.getItem('formId'));
		}
	}
	selectDoor(e) {
		var value = e.target.id;
		this.props.handleInputChange("doors", value, 'facilities')
		this.setState({ doors: value })
	}

	selectSeat(e) {
		var value = e.target.id;
		this.props.handleInputChange("seats", value, 'facilities')
		this.setState({ seats: value })
	}
	validateForm() {
		var doors = this.state.doors;
		var seats = this.state.seats;
		const facilitiesInfo = this.props.HostCarReducer.facilitiesInfo;
		if (facilitiesInfo) {
			if (facilitiesInfo.doors) {
				doors = facilitiesInfo.doors;
			}
		}
		if (facilitiesInfo) {
			if (facilitiesInfo.seats) {
				seats = facilitiesInfo.seats;
			}
		}
		const facilities = {
			seats: seats,
			doors: doors
		}
		const id = localStorage.getItem('formId');
		this.props.updateHostDetails(facilities, id, 'Facilities');
		this.setState({ next: true })
	}

	previous() {
		this.setState({ previous: true })
	}
	render() {
		const next = this.state.next;
		const previous = this.state.previous;
		if (next) {
			return <Redirect push={true} to="/car-features" />
		}
		if (previous) {
			return <Redirect to="/car-fuel-detail" />
		}
		var seats = this.state.seats;
		var doors = this.state.doors;
		const facilitiesInfo = this.props.HostCarReducer.facilitiesInfo;
		if (facilitiesInfo) {
			if (facilitiesInfo.doors) {
				doors = facilitiesInfo.doors;
			}
			if (facilitiesInfo.seats) {
				seats = facilitiesInfo.seats;
			}
		}
		return (
			<section>
				<div className="form-layout">
					<div className="container">
						<div className="row">
							<div className="col-md-6 offset-md-3">
								<div className="list-my-car">
									<div className="list-top">
										<div className="progress" style={{ height: "8px" }}>
											<div className="progress-bar" role="progressbar" style={{ width: "60%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<div className="list-car-card">
									<h4 className='card-header text-white text-center py-4 mb-2' style={{background:"#004AAD", textTransform:'uppercase', fontFamily: 'poppins'}}>Ajouter mon véhicule</h4>
										<div className="container">

											<div className="title-card">Ajouter plus de détails</div>
											<label htmlFor="" className="acc-label">Nombre de portières</label>
											<div className="badge-group">
												<div className="group" onClick={(e) => this.selectDoor(e)} >
													<span id="2" className="badg" style={{ backgroundColor: doors === "2" ? "#004AAD" : "white", color: doors === "2" ? "white" : "black" }}>2</span>
												</div>
												<div className="group" onClick={(e) => this.selectDoor(e)}>
													<span id="3" className="badg" style={{ backgroundColor: doors === "3" ? "#004AAD" : "white", color: doors === "3" ? "white" : "black" }}>3</span>
												</div>
												<div className="group" onClick={(e) => this.selectDoor(e)}>
													<span id="4" style={{ backgroundColor: doors === "4" ? "#004AAD" : "white", color: doors === "4" ? "white" : "black" }} className="badg">4</span>
												</div>
											</div>
											<div className="badge-group">
												<div className="group" onClick={(e) => this.selectDoor(e)}>
													<span id="5" className="badg" style={{ backgroundColor: doors === "5" ? "#004AAD" : "white", color: doors === "5" ? "white" : "black" }}>5</span>
												</div>
												<div className="group" onClick={(e) => this.selectDoor(e)}>
													<span id="6" className="badg" style={{ backgroundColor: doors === "6" ? "#004AAD" : "white", color: doors === "6" ? "white" : "black" }}>6</span>
												</div>
												<div className="group" onClick={(e) => this.selectDoor(e)}>
													<span id="7+" className="badg" style={{ backgroundColor: doors === "7+" ? "#004AAD" : "white", color: doors === "7+" ? "white" : "black" }}>7+</span>
												</div>
											</div>
											<label htmlFor="" className="acc-label mt-5">Nombre de sièges</label>
											<div className="badge-group">
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="2" className="badg" style={{ backgroundColor: seats === "2" ? "#004AAD" : "white", color: seats === "2" ? "white" : "black" }} >2</span>
												</div>
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="3" className="badg" style={{ backgroundColor: seats === "3" ? "#004AAD" : "white", color: seats === "3" ? "white" : "black" }} >3</span>
												</div>
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="4" className="badg" style={{ backgroundColor: seats === "4" ? "#004AAD" : "white", color: seats === "4" ? "white" : "black" }} >4</span>
												</div>
											</div>
											<div className="badge-group">
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="5" className="badg" style={{ backgroundColor: seats === "5" ? "#004AAD" : "white", color: seats === "5" ? "white" : "black" }} >5</span>
												</div>
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="6" className="badg" style={{ backgroundColor: seats === "6" ? "#004AAD" : "white", color: seats === "6" ? "white" : "black" }} >6</span>
												</div>
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="7" className="badg" style={{ backgroundColor: seats === "7" ? "#004AAD" : "white", color: seats === "7" ? "white" : "black" }} >7</span>
												</div>
											</div>
											<div className="badge-group">
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="8" className="badg" style={{ backgroundColor: seats === "8" ? "#004AAD" : "white", color: seats === "8" ? "white" : "black" }} >8</span>
												</div>
												<div className="group" onClick={(e) => this.selectSeat(e)}>
													<span id="9+" className="badg" style={{ backgroundColor: seats === "9+" ? "#004AAD" : "white", color: seats === "9+" ? "white" : "black" }} >9+</span>
												</div>
											</div>
											<div className="list-info mt-4 mb-2">Ces informations aident les conducteurs à trouver ce qui leur convient le mieux.</div>
										</div>

									</div>
									<div className="list-bottom">
										<div className="btn-nav">
											<button className="prev-button mr-2" onClick={this.previous}>
												<span className="cobalt-Icon">
													<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
														<path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
													</svg>
												</span>Précédent
											</button>
											<button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
function mapStateToProps(state) {
	return {
		HostCarReducer: state.HostCarReducer,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}

const CarFacilitiesComponent = connect(mapStateToProps, mapDispatchToProps)(CarFacilities);

export default CarFacilitiesComponent;
