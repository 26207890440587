import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_GET_MODEL, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS, AC_HOST_CAR } from '../../actions/hostcaraction';
import { Select } from 'antd';
// import Congratulation from '../confetti/Confetti';
const { Option } = Select;
// import  { URL } from '../../common/api'

class CarModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextpage: false,
      error: false,
    }
    this.validateForm = this.validateForm.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    } else {
      this.props.getModel({ id: "" })
    }
  }

  getCartypeIdByName = (list, key) => {
    let id_final = ''
    for (let i = 0; i < list.length; i++) {
      if (list[i].cartype === key) {
        id_final = list[i]._id
        break
      }
    }
    return id_final
  }

  getCartypeNameById = (list, key) => {    
    let name = ''
    for (let i = 0; i < list.length; i++) {      
      if (list[i]._id === key) {
        name = list[i].cartype
        break;
      }
    }    
    return name
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "make") {
      this.props.getModel({ id: value })
    }
    this.props.handleInputChange(name, value, 'carmodel')
  }

  handleChange = (value) => {
    const name = "type"
    this.setState({error: false})    
    const finalValue = this.getCartypeIdByName(this.props.DashboardReducer.cartypesList, value)    
    this.props.handleInputChange(name, finalValue, 'carmodel')    
  };

  validateForm() {
    const data = this.props.HostCarReducer.carModelInfo;    
    const modelList = this.props.HostCarReducer.modelList;
    var brandList = this.props.DashboardReducer.brandList;
    if (!data.model && modelList[0]) {
      data.model = modelList[0]._id;
    }
    if (!data.make && brandList[0]) {
      data.make = brandList[0]._id;
    }    
    if (!data.type || !data.type === undefined) {
      this.setState({ error: true })
    }
    if (this.state.error === false && data.type) {
      const id = localStorage.getItem('formId')
      // Dans le cas où on veut faire des mises à jour
      if (localStorage.getItem('formId')) {
        this.props.updateHostDetails(data, id, 'CarModel');
      } else {
        this.props.hostaCar(data, 'Carmodel');
      }
      this.setState({ nextpage: true })
    }

  }
  render() {
    const nextpage = this.state.nextpage;
    if (nextpage) {
      return <Redirect push={true} to="/parking-address" /> // Nouvelle navigation
    }
    let make = "";
    let model = "";
    let type = ""
    var brandList = this.props.DashboardReducer.brandList; // La liste de toutes les marques
    if (this.props.HostCarReducer.carModelInfo?.make && this.props.HostCarReducer.carModelInfo?.model && this.props.HostCarReducer.carModelInfo?.type && localStorage.getItem('formId')) {

      make = this.props.HostCarReducer.carModelInfo?.make;
      model = this.props.HostCarReducer.carModelInfo?.model;
      type = this.props.HostCarReducer.carModelInfo?.type;
      // console.log('Make: ' + make)
      // console.log('Model: ' + model)
      // console.log('Type: ' + type)
    }

    var brandOptions = [];
    for (let i = 0; i < brandList.length; i++) {
      brandOptions.push(
        <option key={i} value={brandList[i]._id} selected={make === brandList[i]._id}> {brandList[i].brandname}</option>
      )
    }
    const modelList = this.props.HostCarReducer.modelList;
    const MODELIST_LENGTH = modelList.length
    var modelArray = [];
    if (modelList) {
      for (let j = 0; j < MODELIST_LENGTH; j++) {
        modelArray.push(
          <option key={j} value={modelList[j]._id} selected={model === modelList[j]._id}>{modelList[j].name}</option>
        )
      }
    }
    const cartypesList = this.props.DashboardReducer.cartypesList
    const CARTYPE_LENGTH = cartypesList.length
    let cartypeArray = []
    if (cartypesList) {
      for (let index = 0; index < CARTYPE_LENGTH; index++) {
        cartypeArray.push(
          <Option key={cartypesList[index]._id} value={cartypesList[index].cartype} label={cartypesList[index].cartype}>
            <div className="demo-option-label-item">
              {/* <span role="img" aria-label={cartypesList[index].cartype}>
                <img width={30}
                height={30} src={URL.IMAGEURL_CARTYPE + cartypesList[index].image} alt="" />
              </span> */}
              {cartypesList[index].cartype}
            </div>
          </Option>
        )

      }
    }

    return (
      <section style={{ marginBottom: "6rem" }}>
        
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "10%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <h4 className='card-header text-white text-center py-4 mb-2' style={{ background: "#004AAD", textTransform: 'uppercase', fontFamily: 'poppins' }}>Ajouter mon véhicule</h4>
                    <div className="container">
                      {/* <h5>Ajouter mon véhicule</h5> */}
                      <div className="title-card">Confirmez le modèle de votre véhicule</div>
                    </div>

                    <div className="col-lg-12">
                      <label htmlFor="sel1" className="acc-label">Marque</label>
                      <select className="form-control" id="sel1" name="make" onChange={(e) => this.onChangeValue(e)}>
                        {brandOptions}
                      </select>
                    </div>
                    {modelArray?.length > 0 ? (<div className="col-lg-12">
                      <label htmlFor="sel2" className="acc-label mt-4">Modèle</label>
                      {/* {modelArray.length > 0 && */}
                        <select className="form-control" id="sel2" name="model" onChange={(e) => this.onChangeValue(e)}>
                          {modelArray}
                        </select>
                      
                      {/* {modelArray.length === 0 && <div className='alert alert-danger' style={{ fontSize: "12px" }}>Aucun modèle défini pour cette marque</div>} */}
                    </div> ) : <div></div>}
                    <div className="col-lg-12">
                      <label htmlFor="type" className="acc-label mt-4">Catégorie</label>                      
                      <Select                                              
                      // loading={true}
                      style={{
                        width: '100%',
                      }}
                      id="type"
                      name="type"
                      status={this.state.error ? 'error' : "" }                      
                      defaultValue={this.getCartypeNameById(cartypesList, type) ? this.getCartypeNameById(cartypesList, type) : null}
                      placeholder="Sélectionner une catégorie"
                      onChange={this.handleChange}
                      optionLabelProp="label"
                    >
                      {cartypeArray}
                     
                    </Select>  
            

                    
                                          
                    </div>
                    <div className="list-info mt-4">Ajouter votre véhicule ne prendra que quelques minutes ⏱️</div>
                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button to="" className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, hostaCar: AC_HOST_CAR, getModel: AC_GET_MODEL, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}
const CarModelComponent = connect(mapStateToProps, mapDispatchToProps)(CarModel);

export default CarModelComponent;
