import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";
import ChatContainer from "../components/Chats/ChatContainer";
import Contacts from "../components/Chats/Contacts";

// Ce fichier contient tout les component du chat ou de la messagerie
export default function Chat() {
    // const navigate = useNavigate();
  const socket = useRef();
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [contacts, setContacts] = useState([
    {
        username: "Jean-Fabrice Gnohi"
    },
    {
        username: "Brice Gnanago"
    }
  ]);

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
  };
//   const [currentChat, setCurrentChat] = useState(undefined);
//   const [currentUser, setCurrentUser] = useState(undefined);
    return <>
        <Container>
            <div className="wrapper">
                <Contacts contacts={contacts} changeChat={handleChatChange} />
                <ChatContainer currentChat={currentChat} socket={socket} />
            </div>
        </Container>
    </>

}
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #131324;
  .wrapper {
    height: 85vh;
    width: 85vw;
    background-color: #00000076;
    display: grid;
    grid-template-columns: 25% 75%;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
  }
`;