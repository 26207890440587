import React from "react";
class MonBolideSection extends React.Component {
  render() {
    return (
      <div className="list-car-card px-4">
        <div className="desc-flex">
          <div className="desc-txt">
            <h1 className="desc-title mb-3">
              Améliorez votre expérience Monbolide
            </h1>
            <p className="mb-4 p-height">
              Avec Monbolide Plus, les conducteurs peuvent réserver et accéder à
              votre voiture sans votre présence.{" "}
            </p>
            <div className="text-center">
              <a href="#" className="Woohoo-btn big-woohoo mb-2">
                Essayez gratuitement pendant 1 mois
              </a>
            </div>
            <p className="text-center text-muted">
              Puis 20 000 XOF par mois, sans engagement
            </p>
          </div>
          <div className="desc-shape" />
          <div className="desc-banner" />
        </div>
        <div className="benefits">
          <h2 className="desc-heading mb-4">Les avantages</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="benefit-item">
                <div className="m-benefit">
                  <i className="fa fa-clock-o" />
                </div>
                <div className="tiny-head">
                  Véhicule renforcée dans les recherches
                </div>
                <p className="benefit-p">
                  Votre voiture apparaît en tête des résultats de recherche et
                  vous gagnez 100 000 XOF de plus par mois en moyenne.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="benefit-item">
                <div className="m-benefit">
                  <i className="fa fa-clock-o" />
                </div>
                <div className="tiny-head">
                  Simplifier la gestion des locations
                </div>
                <p className="benefit-p">
                  Vous n'avez plus besoin d'accepter les demandes de location,
                  ni d'être présent aux arrivées et aux départs : votre voiture
                  devient 100 % en libre-service.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="benefit-item">
                <div className="m-benefit">
                  <i className="fa fa-clock-o" />
                </div>
                <div className="tiny-head">Sécurité maximale</div>
                <p className="benefit-p">
                  Seuls les conducteurs munis de documents d'identité vérifiés
                  peuvent louer votre voiture. Ils doivent procéder à une
                  inspection complète de votre voiture avant l'enregistrement.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <hr className="benefit-border" />
              <div className="decs-works">
                <div className="row">
                  <div className="col-md-6">
                    <h2 className="desc-heading mb-4">Comment fonctionne le Monbolide Plus ?
                    </h2>
                    <div className="benefit-item mb-4">
                      <div className="tiny-head">Installation sans problème</div>
                      <p className="benefit-p">Nous installons le boîtier Monbolide Plus dans votre voiture en moins de 2 heures, gratuitement. </p>
                    </div>
                    <div className="benefit-item mb-4">
                      <div className="tiny-head">Déverrouillage sécurisé</div>
                      <p className="benefit-p">Avec Monbolide Plus, votre voiture bénéficie d'un système d'immobilisation pour la protéger contre le vol.</p>
                    </div>
                    <div className="benefit-item">
                      <div className="tiny-head">Notre application guide les conducteurs depuis leur réservation jusqu'à la fin de leur voyage.</div>
                      <p className="benefit-p">Avec Monbolide Plus, votre voiture bénéficie d'un système d'immobilisation pour la protéger contre le vol.</p>
                    </div>
                  </div>
                  <div className="col-md-6" />
                </div>
              </div> */}
        <hr className="benefit-border" />
        <div className="benefits">
          <h2 className="desc-heading mb-4">Un système sécurisé</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="benefit-item">
                <div className="m-benefit">
                  <i className="fa fa-clock-o" />
                </div>
                <div className="tiny-head">Système antidémarrage</div>
                <p className="benefit-p">
                  Avec Monbolide Plus, votre voiture bénéficie d'un système
                  d'immobilisation pour la protéger contre le vol.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="benefit-item">
                <div className="m-benefit">
                  <i className="fa fa-clock-o" />
                </div>
                <div className="tiny-head">Inspection détaillée</div>
                <p className="benefit-p">
                  Chaque enregistrement et chaque départ s'effectuent via
                  l'application Monbolide.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="benefit-item">
                <div className="m-benefit">
                  <i className="fa fa-clock-o" />
                </div>
                <div className="tiny-head">Pilotes vérifiés</div>
                <p className="benefit-p">
                Monbolide contrôle les documents d'identité et le permis de
                  conduire de tous les conducteurs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MonBolideSection;
