import React from "react";
import { /*Link,*/ Redirect } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

class CheckinAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
    };
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  validateForm() {
    this.setState({ next: true });
  }

  previous() {
    this.setState({ previous: true });
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect to="/schedule" />;
    }
    if (previous) {
      return <Redirect to="/add-vehicle" />;
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "75%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <h5>Ajouter mon véhicule</h5>
                    <div className="title-card">
                      Où rencontrerez-vous les conducteurs pour l'enregistrement
                      ?
                    </div>
                    <label htmlFor="" className="acc-label">
                      Point de rencontre
                    </label>
                    <div className="input-group mb-4">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          @
                        </span>
                      </div>
                      <Autocomplete
                        className="form-control"
                        onPlaceSelected={(place) => {}}
                        types={["(regions)"]}
                        componentRestrictions={{ country: "civ" }}
                      />
                    </div>
                    <div className="list-info mb-5">
                      Cette adresse sera utilisée pour permettre aux conducteurs
                      de louer votre voiture.
                    </div>
                    <p className="list-para m-0">
                      Vous pouvez modifier ces informations ultérieurement
                    </p>
                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button
                        className="prev-button mr-2"
                        onClick={this.previous}
                      >
                        <span className="cobalt-Icon">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>
                        Précédent
                      </button>
                      <button
                        className="Woohoo-btn"
                        onClick={this.validateForm}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CheckinAddress;
