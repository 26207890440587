import React from 'react';

import BannerSection from './bannersection';
import MillionSection from './millionsection';
import ShareSection from './sharesection';
import ContentSection from './contentsection';
import GetAroundSection from './monbolidesection';
import CoverSection from './coversection';
import DiscoverSection from './discoversection';
import FrequentlySection from './frequentlysection';

class HireOutCar extends React.Component {
  render() {
    return(
    <div className="main-body">
      <BannerSection/>
      <MillionSection/>
      <ShareSection/>
      <ContentSection/>
      <GetAroundSection/>
      <CoverSection/>
      <DiscoverSection/>
      <FrequentlySection/>
    </div>
    )
  }
}

export default HireOutCar
