import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_ACCOUNT, AC_VERIFY_PROILE, AC_ADD_ATTACHMENT_TO_PROILE } from '../../actions/accountaction';
// import { Imagevalidation } from '../../common/validate';
import URL from "../../common/api";
// import TextField from '@mui/material/TextField';
import Dropzone from 'react-dropzone';
import './Verifyprofile.css'

class VerifyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = (files, error) => {
      this.setState({ file: files[0], filesShow: files })
    };
    this.onDropRejected = (fileRejections) => {
      this.setState({ licenceAccepted: false })
    }
    this.onDropAccepted = () => {
      this.setState({ licenceAccepted: true })
    }

    // Verso
    this.onDropVerso = (files, error) => {
      this.setState({ fileVerso: files[0], filesShowVerso: files })
    };
    this.onDropRejectedVerso = (fileRejections) => {
      this.setState({ licenceVersoAccepted: false })
    }
    this.onDropAcceptedVerso = (files) => {
      this.setState({ licenceVersoAccepted: true })
    }


    /* Updloader fichier pièce d'identité  */
    this.onDropIdentity = (files, error) => {
      this.setState({ fileIdentity: files[0], fileIdentityShow: files })
    };
    this.onDropRejectedIdentity = (fileRejections) => {
      this.setState({ fileIdentityAccepted: false })
    }
    this.onDropAcceptedIdentity = () => {
      this.setState({ fileIdentityAccepted: true })
    }

    // Verso
    this.onDropVersoIdentity = (files, error) => {
      this.setState({ fileIdentityVerso: files[0], fileIdentityShowVerso: files })
    };
    this.onDropRejectedVersoIdentity = (fileRejections) => {
      this.setState({ fileIdentityVersoAccepted: false })
    }
    this.onDropAcceptedVersoIdentity = (files) => {
      this.setState({ fileIdentityVersoAccepted: true })
    }

    /* Uploader fichier facture CIE ou SODECI  */
    this.onDropBilling = (files, error) => {

      this.setState({ fileBilling: files[0], fileBillingShow: files })
    };
    this.onDropRejectedBilling = (fileRejections) => {
      this.setState({ fileBillingAccepted: false })
    }
    this.onDropAcceptedBilling = () => {
      this.setState({ fileBillingAccepted: true })
    }


    /* Uploader fichier facture CIE ou SODECI  */
    this.onDropSelfie = (files, error) => {

      this.setState({ fileSelfie: files[0], fileSelfieShow: files })
    };
    this.onDropRejectedSelfie = (fileRejections) => {
      this.setState({ fileSelfieAccepted: false })
    }
    this.onDropAcceptedSelfie = () => {
      this.setState({ fileSelfieAccepted: true })
    }




    this.state = {
      file: null,
      filesShow: [],
      licenceAccepted: null,

      fileVerso: null,
      filesShowVerso: [],
      licenceVersoAccepted: null,

      fileIdentity: null,
      fileIdentityShow: [],
      fileIdentityAccepted: null,

      fileIdentityVerso: null,
      fileIdentityShowVerso: [],
      fileIdentityVersoAccepted: null,

      fileBilling: null,
      fileBillingShow: [],
      fileBillingAccepted: null,

      fileSelfie: null,
      fileSelfieShow: [],
      fileSelfieAccepted: null,

      imageLicenceError: false,
      imageBillingError: false,
      imageIdentityError: false,
      imageSelfieError: false,
    }
  }
  // componentWillUpdate() {

  // }


  componentDidMount() {
    const id = localStorage.getItem('siteUser');
    this.props.ViewAccount(id);
  }

  validateForm(event) {
    // Permis de conduire
    if (event.target.name === "licence") {
      var imageLicence = this.state.file;
      var imageVersoLicence = this.state.fileVerso
      // Si aucune image n'est soumise
      if (!imageLicence || !imageVersoLicence) {
        this.setState({ imageLicenceError: true })
      }
      // Une image a été soumise
      if (this.state.licenceAccepted && this.state.licenceVersoAccepted) {
        const id = localStorage.getItem('siteUser');
        const formDataRecto = new FormData();
        formDataRecto.append('image', imageLicence);
        formDataRecto.append('action', 'licence')
        formDataRecto.append('id', id);

        const formDataVerso = new FormData()
        formDataVerso.append('image', imageVersoLicence);
        formDataVerso.append('action', 'licenceVerso')
        formDataVerso.append('id', id);
        this.props.AddAttachmentToProfile(formDataRecto).then(() => {
          this.props.AddAttachmentToProfile(formDataVerso)
        });
      }
    }
    // Facture de CIE OU SOCEDI
    if (event.target.name === "billing") {
      var imageBilling = this.state.fileBilling;
      // Si aucune image n'est soumise
      if (!imageBilling) {
        this.setState({ imageBillingError: true })
      }
      // Une image a été soumise
      if (imageBilling) {
        const id = localStorage.getItem('siteUser');
        const formData = new FormData();
        formData.append('image', imageBilling);
        formData.append('action', 'billing')
        formData.append('id', id);


        this.props.AddAttachmentToProfile(formData);
      }
    }


    if (event.target.name === "identity") {
      var imageIdentity = this.state.fileIdentity;
      var imageIdentityVerso = this.state.fileIdentityVerso;

      // Si aucune image n'est soumise
      if (!imageIdentity || !imageIdentityVerso) {
        this.setState({ imageIdentityError: true })
      }
      // Une image a été soumise
      if (imageIdentity && imageIdentityVerso) {
        const id = localStorage.getItem('siteUser');
        const formData = new FormData();
        formData.append('image', imageIdentity);
        formData.append('action', 'identity')
        formData.append('id', id);
        // Verifier le profil
        const formDataVerso = new FormData();
        formDataVerso.append('image', imageIdentityVerso);
        formDataVerso.append('action', 'identityVerso')
        formDataVerso.append('id', id);
        // Verifier le profil
        this.props.AddAttachmentToProfile(formData).then(() => {
          this.props.AddAttachmentToProfile(formDataVerso)
        });
      }
    }
    if (event.target.name === "selfie") {
      var imageSelfie = this.state.fileSelfie;
      // Si aucune image n'est soumise
      if (!imageSelfie) {
        this.setState({ imageSelfieError: true })
      }
      // Une image a été soumise
      if (imageSelfie) {
        const id = localStorage.getItem('siteUser');
        const formData = new FormData();
        formData.append('image', imageSelfie);
        formData.append('action', 'selfie')
        formData.append('id', id);

        // Verifier le profil
        const user = this.props.AccountReducer.accountInfo
        formData.append('fullname', user.firstname + " " + user.lastname);
        formData.append('mobilenumber', user.mobilenumber);
        formData.append('email', user.email);
        this.props.verifyProfile(formData);
      }
    }


  }


  // onChangeValue(event) {
  //   // const name = event.target.name;
  //   // var value  = event.target.value;
  //   var file = event.target.files[0];
  //   const validate = Imagevalidation(file);
  //   if (event.target.name === "imageLicence") {
  //     this.setState({ imageLicence: file })
  //     if (validate) {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onloadend = function (e) {
  //         this.setState({ imageLicenceError: false, imgLicenceSrc: [reader.result], imageLicenceCheck: false })
  //       }.bind(this);
  //     }
  //     else {
  //       this.setState({ imageLicenceCheck: true, imgLicenceSrc: "", imageLicenceError: true })
  //     }
  //   }
  //   if (event.target.name === "billingImage") {
  //     this.setState({ imageBilling: file })
  //     if (validate) {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onloadend = function (e) {
  //         this.setState({ imageBillingError: false, imgBillingSrc: [reader.result], imageBillingCheck: false })
  //       }.bind(this);
  //     }
  //     else {
  //       this.setState({ imageBillingCheck: true, imgBillingSrc: "", imageBillingError: true })
  //     }
  //   }
  //   if (event.target.name === "imageIdentity") {
  //     this.setState({ imageIdentity: file })
  //     if (validate) {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onloadend = function (e) {
  //         this.setState({ imageIdentityError: false, imgIdentitySrc: [reader.result], imageIdentityCheck: false })
  //       }.bind(this);
  //     }
  //     else {
  //       this.setState({ imageIdentityCheck: true, imgIdentitySrc: "", imageIdentityError: true })
  //     }
  //   }
  //   if (event.target.name === "imageSelfie") {
  //     this.setState({ imageSelfie: file })
  //     if (validate) {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onloadend = function (e) {
  //         this.setState({ imageSelfieError: false, imgSelfieSrc: [reader.result], imageSelfieCheck: false })
  //       }.bind(this);
  //     }
  //     else {
  //       this.setState({ imageSelfieCheck: true, imgSelfieSrc: "", imageSelfieError: true })
  //     }
  //   }

  // }
  render() {
    const profileList = this.props.AccountReducer.accountInfo;
    var image = "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideasset/ProjectImage/images/default_rmfapd.jpg";
    var firstname = "";
    var lastname = "";
    if (profileList) {
      firstname = profileList.firstname;
      lastname = profileList.lastname;
      if (profileList.image) {
        image = URL.IMAGEURL + profileList.image;
      }
    }
    const files = this.state.filesShow.map(file => (
      <div key={file.path}>
        {file.path} - {file.size} ko
      </div>
    ));
    const filesVerso = this.state.filesShowVerso.map(file => (
      <div key={file.path}>
        {file.path} - {file.size} ko
      </div>
    ));

    const filesIdentity = this.state.fileIdentityShow.map(file => (
      <div key={file.path}>
        {file.path} - {file.size} ko
      </div>
    ));

    const filesVersoIdentity = this.state.fileIdentityShowVerso.map(file => (
      <div key={file.path}>
        {file.path} - {file.size} ko
      </div>
    ));

    const filesBilling = this.state.fileBillingShow.map(file => (
      <div key={file.path}>
        {file.path} - {file.size} ko
      </div>
    ));

    const filesSelfie = this.state.fileSelfieShow.map(file => (
      <div key={file.path}>
        {file.path} - {file.size} ko
      </div>
    ));
    return (
      <div className="main-acc" data-aos="zoom-in"
      >
        <div className="top-item">
          <div className="row">
            <div className="col-sm-6">
              <h2>Vérifier votre profil</h2>
              <p className="p-txt">Vérifiez votre statut de vérification.</p>
              <div className="verify">
                <div className="prf-div">
                  <div className="prf-img">
                    <img alt="Profile Utilisateur" src={image} />
                  </div>
                  <div className="prf-txt">
                    <h5>{firstname} {lastname}</h5>
                    {profileList.verification === 0 ? <p style={{ color: "salmon" }}>Votre profil n'est pas accepté, téléchargez à nouveau les détails.</p> :
                      profileList.verification === 1 ? <p style={{ color: "limegreen" }}>Votre profil est vérifié.</p> :
                        profileList.verification === 2 ? <p style={{ color: "#444" }}>Votre profil sera bientôt vérifié par notre équipe.</p> :
                          <p style={{ color: "salmon" }}>Votre profil n'est pas encore vérifié.</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              {profileList?.attachments?.licenseimage ? <img src={URL.IMAGEURL + profileList?.attachments?.licenseimage?.recto} width="150px" height="150px" alt="oops" /> : <div className="img-div" />}
            </div>

          </div>
          <fieldset className="form-field mt-5" style={{ display: profileList?.verification === 1 || profileList?.verification === 2 ? "none" : "block" }}>
            <legend className="form-head">Permis de conduire</legend>
            <div className='alert alert-secondary'>
              <ul>
                <li>Vous devez ici charger deux (02) images</li>
                <li>La première face du permis (Recto)</li>
                <li>La deuxième face du permis de conduire (Verso) </li>
                <li>Les formats autorisés pour chacune des deux (02) images sont: .png, .jpg et .jpeg</li>
                <li>La taille de chaque fichier ne doit pas dépasser <strong>1Mo</strong></li>
              </ul>
            </div>
            {this.state.imageLicenceError && <div className="container mt-2">
              <div className="alert alert-danger">
                Merci de charger les images des différentes faces de votre permis suivant les indications ci-dessus.
              </div>
            </div>}
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Dropzone multiple={false} maxFiles={1} maxSize={1000000} onDropAccepted={this.onDropAccepted} onDropRejected={this.onDropRejected} onDrop={this.onDrop} accept="image/png,image/jpeg,image/jpg" >

                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div className={`dropzone shadowUploadContainer ${(this.state.licenceAccepted === true || profileList.attachments?.licenseimage?.recto) && 'successUpload'} ${(this.state.licenceAccepted === false) && 'errorUpload'}  `} style={{ padding: 0 }} {...getRootProps()}>
                        <input {...getInputProps()} name="image" type="file" />
                        <span className={`icon-size ${(this.state.licenceAccepted !== null || profileList?.attachments?.licenseimage?.recto) && 'icon--white'}`}><i className="fas fa-camera-retro fa-xs"></i></span>
                        <div><strong>Recto</strong></div>
                      </div>
                      <aside>

                        <div className='file-description'>
                          {files}</div>
                      </aside>
                    </section>
                  )}
                </Dropzone>

              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">

                <Dropzone multiple={false} maxFiles={1} maxSize={1000000}  onDropAccepted={this.onDropAcceptedVerso} onDropRejected={this.onDropRejectedVerso} onDrop={this.onDropVerso} accept="image/png,image/jpeg,image/jpg" >

                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div className={`dropzone shadowUploadContainer ${(this.state.licenceVersoAccepted === true || profileList.attachments?.licenseimage?.verso) && 'successUpload'} ${(this.state.licenceVersoAccepted === false) && 'errorUpload'}  `} style={{ padding: 0 }} {...getRootProps()}>
                        <input {...getInputProps()} type="file" />
                        <span className={`icon-size ${(this.state.licenceVersoAccepted !== null || profileList.attachments?.licenseimage?.verso) && 'icon--white'}`}><i className="fas fa-camera-retro fa-xs"></i></span>
                        <div><strong>Verso</strong></div>
                      </div>
                      <aside>

                        <div className='file-description'>
                          {filesVerso}</div>
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>

            <div className='container' style={{ textAlign: 'center' }}>
              <button className="btn btn-bg px-4 py-2 mt-5" name="licence" onClick={(event) => this.validateForm(event)}>Soumettre</button>
            </div>
          </fieldset>
          <fieldset className="form-field mt-5" style={{ display: profileList.verification === 1 || profileList.verification === 2 ? "none" : "block" }}>
            <legend className="form-head">Pièce d'identité</legend>
            <div className='alert alert-secondary'>
              <ul>
                <li>Vous devez ici charger deux (02) images</li>
                <li>La première face de la pièce (Recto)</li>
                <li>La deuxième face de la pièce (Verso)</li>
                <li>Les formats autorisés pour chacune des deux (02) images sont: .png, .jpg et .jpeg</li>
                <li>La taille de chaque fichier ne doit pas dépasser <strong>1Mo</strong></li>
              </ul>
            </div>
            {this.state.imageIdentityError && <div className="container mt-2">
              <div className="alert alert-danger">
                Merci de charger les images des différentes faces de votre pièce d'identité suivant les indications ci-dessus.
              </div>
            </div>}
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Dropzone multiple={false} maxFiles={1} maxSize={1000000} onDropAccepted={this.onDropAcceptedIdentity} onDropRejected={this.onDropRejectedIdentity} onDrop={this.onDropIdentity} accept="image/png,image/jpeg,image/jpg" >

                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div className={`dropzone shadowUploadContainer ${(this.state.fileIdentityAccepted === true || profileList.attachments?.idimage?.recto) && 'successUpload'} ${(this.state.fileIdentityAccepted === false) && 'errorUpload'}  `} style={{ padding: 0 }} {...getRootProps()}>
                        <input {...getInputProps()} name="image" type="file" />
                        <span className={`icon-size ${(this.state.fileIdentityAccepted !== null || profileList?.attachments?.idimage?.recto) && 'icon--white'}`}><i className="fas fa-camera-retro fa-xs"></i></span>
                        <div><strong>Recto</strong></div>
                      </div>
                      <aside>

                        <div className='file-description'>
                          {filesIdentity}</div>
                      </aside>
                    </section>
                  )}
                </Dropzone>

              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">

                <Dropzone multiple={false} maxFiles={1} maxSize={1000000} onDropAccepted={this.onDropAcceptedVersoIdentity} onDropRejected={this.onDropRejectedVersoIdentity} onDrop={this.onDropVersoIdentity} accept="image/png,image/jpeg,image/jpg" >

                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div className={`dropzone shadowUploadContainer ${(this.state.fileIdentityVersoAccepted === true || profileList?.attachments?.idimage?.verso) && 'successUpload'} ${(this.state.fileIdentityVersoAccepted === false) && 'errorUpload'}  `} style={{ padding: 0 }} {...getRootProps()}>
                        <input {...getInputProps()} type="file" />
                        <span className={`icon-size ${(this.state.fileIdentityVersoAccepted !== null || profileList.attachments?.idimage?.verso) && 'icon--white'}`}><i className="fas fa-camera-retro fa-xs"></i></span>
                        <div><strong>Verso</strong></div>
                      </div>
                      <aside>

                        <div className='file-description'>
                          {filesVersoIdentity}</div>
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>

            <div className='container' style={{ textAlign: 'center' }}>
              <button className="btn btn-bg px-4 py-2 mt-5" name="identity" onClick={(event) => this.validateForm(event)}>Soumettre</button>
            </div>
          </fieldset>
          <fieldset className="form-field mt-5" style={{ display: profileList.verification === 1 || profileList.verification === 2 ? "none" : "block" }}>
            <legend className="form-head">Selfie</legend>
            <div className='alert alert-secondary'>
              <ul>
                <li>Vous devez ici charger une (01) image de vous</li>
                <li>Les formats autorisés pour cette image sont: .png, .jpg et .jpeg</li>
                <li>La taille de chaque fichier ne doit pas dépasser <strong>1Mo</strong></li>
              </ul>
            </div>
            {this.state.imageSelfieError && <div className="container mt-2">
              <div className="alert alert-danger">
                Merci de charger une photo de vous.
              </div>
            </div>}
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Dropzone multiple={false} maxFiles={1} maxSize={1000000} onDropAccepted={this.onDropAcceptedSelfie} onDropRejected={this.onDropRejectedSelfie} onDrop={this.onDropSelfie} accept="image/png,image/jpeg,image/jpg" >
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div className={`dropzone shadowUploadContainer ${(this.state.fileSelfieAccepted === true || profileList.attachments?.selfie) && 'successUpload'} ${(this.state.fileSelfieAccepted === false) && 'errorUpload'}  `} style={{ padding: 0 }} {...getRootProps()}>
                        <input {...getInputProps()} name="image" type="file" />
                        <span className={`icon-size ${(this.state.fileSelfieAccepted !== null || profileList?.attachments?.selfie) && 'icon--white'}`}><i className="fas fa-camera-retro fa-xs"></i></span>
                        <div className={`${(this.state.fileSelfieAccepted !== null || profileList?.attachments?.selfie) && 'text-white'}`}><strong>Selfie</strong></div>
                      </div>
                      <aside>

                        <div className='file-description'>
                          {filesSelfie}
                        </div>
                      </aside>
                    </section>
                  )}
                </Dropzone>

              </div>
            </div>

            <div className='container' style={{ textAlign: 'center' }}>
              <button className="btn btn-bg px-4 py-2 mt-5" name="selfie" onClick={(event) => this.validateForm(event)}>Soumettre</button>
            </div>
          </fieldset>
          <fieldset className="form-field mt-5" style={{ display: profileList.verification === 1 || profileList.verification === 2 ? "none" : "block" }}>
            <legend className="form-head">Facture (Eau ou electricité)</legend>
            <div className='alert alert-secondary'>
              <ul>
                <li>Vous devez ici charger une (01) image de votre facture CIE ou SOCEDI</li>
                <li>Les formats autorisés pour cette image sont: .png, .jpg et .jpeg</li>
                <li>La taille de chaque fichier ne doit pas dépasser <strong>1Mo</strong></li>
              </ul>
            </div>
            {this.state.imageBillingError && <div className="container mt-2">
              <div className="alert alert-danger">
                Merci de charger une image de votre facture suivant les indications ci-dessus.
              </div>
            </div>}
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Dropzone multiple={false} maxFiles={1} maxSize={1000000} onDropAccepted={this.onDropAcceptedBilling} onDropRejected={this.onDropRejectedBilling} onDrop={this.onDropBilling} accept="image/png,image/jpeg,image/jpg" >
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div className={`dropzone shadowUploadContainer ${(this.state.fileBillingAccepted === true || profileList?.attachments?.billingimage) && 'successUpload'} ${(this.state.fileBillingAccepted === false) && 'errorUpload'}  `} style={{ padding: 0 }} {...getRootProps()}>
                        <input {...getInputProps()} name="image" type="file" />
                        <span className={`icon-size ${(this.state.fileBillingAccepted !== null || profileList?.attachments?.billingimage) && 'icon--white'}`}><i className="fas fa-camera-retro fa-xs"></i></span>
                        <div><strong>Facture</strong></div>
                      </div>
                      <aside>

                        <div className='file-description'>
                          {filesBilling}
                        </div>
                      </aside>
                    </section>
                  )}
                </Dropzone>

              </div>
            </div>

            <div className='container' style={{ textAlign: 'center' }}>
              <button className="btn btn-bg px-4 py-2 mt-5" name="billing" onClick={(event) => this.validateForm(event)}>Soumettre</button>
            </div>
          </fieldset>
         
        </div>
        <div className="verify-item">
          <div className="v-card">
            <div className="v-img">
              <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/verify_pamxzc.png" alt="Illustration de la licence" />
            </div>
            <div className="v-list">
              <h3>Pourquoi vérifier votre profil ?</h3>
              <div className="d-flex">
                <span className="tick-icon">
                  <i className="fa fa-check-circle tick-icon-size" />
                </span>
                <p className="pl-2">Nécessaire pour accéder aux voitures en libre-service</p>
              </div>
              <div className="d-flex">
                <span className="tick-icon">
                  <i className="fa fa-check-circle tick-icon-size" />
                </span>
                <p className="pl-2">N'est requis qu'une seule fois, ce qui accélère le processus de réservation.</p>
              </div>
              <div className="d-flex">
                <span className="tick-icon">
                  <i className="fa fa-check-circle tick-icon-size" />
                </span>
                <p className="pl-2">Les vérifications de profil nous aident à sécuriser la plateforme, tant pour les locataires que pour les propriétaires.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewAccount: AC_VIEW_ACCOUNT, verifyProfile: AC_VERIFY_PROILE, AddAttachmentToProfile: AC_ADD_ATTACHMENT_TO_PROILE }, dispatch)
}
const VerifyProfileComponent = connect(mapStateToProps, mapDispatchToProps)(VerifyProfile);

export default VerifyProfileComponent;
