import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class QuestionSection extends React.Component {
  render() {
    const faqList = this.props.DashboardReducer.faqList;
    const faqListArray = [];
    if (faqList) {
      for (var i = 0; i < faqList.length; i++) {
        var select = "collapse" + i;
        faqListArray.push(
          <div className="card" key={i}>
            <div className="card-header" id={faqList[i]._id}>
              <a
                className="show_more"
                data-toggle="collapse"
                data-target={"#" + select}
                aria-expanded="false"
                aria-controls={select}
                href
              >
                <h3>{faqList[i].question}</h3>
              </a>
            </div>

            <div
              id={select}
              className="collapse"
              aria-labelledby={faqList[i]._id}
              data-parent="#accordion"
              style={{ backgroundColor: "white" }}
            >
              <div className="card-body">{faqList[i].answer}</div>
            </div>
          </div>
        );
      }
    }
    return (
      <section className="questions-section">
        <div className="container">
          <div className="questions-main-title">
            <h2>Questions fréquemment posées</h2>
          </div>
          <div id="accordion">{faqListArray}</div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
const QuestionSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionSection);

export default QuestionSectionComponent;
