import React from "react";
import {Redirect,Route} from "react-router-dom";



const RouteGuardSigned = ({component:Component,...rest})=>{
  
    const isAuth = localStorage.getItem("siteUser") ? true : false;
    // const history = useHistory();
    return(
    <Route 
        {...rest}
        render={(props)=> !isAuth ? <Component {...props} /> : <Redirect to={"/"}/>  }
    />
    )
}

export default RouteGuardSigned;