import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AC_VIEW_HOST_DETAILS,
  AC_HANDLE_INPUT_CHANGE,
  AC_UPDATE_HOST_DETAILS,
} from "../../actions/hostcaraction";

class ContactNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
      mobilenoerror: false,
    };
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("formId")) {
      this.props.viewDetails(localStorage.getItem("formId"));
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "mobilenumber") {
      this.setState({ mobilenoerror: false });
    }
    this.props.handleInputChange(name, value, "contactnumber");
  }
  validateForm() {
    const contactNumberInfo = this.props.HostCarReducer.contactNumberInfo;
    const countryList = this.props.DashboardReducer.countryList;
    var mobileno = "";
    var countrycode = "";
    if (countryList[0]) {
      countrycode = countryList[0].countrycode;
    }
    if (contactNumberInfo) {
      if (contactNumberInfo.mobilenumber) {
        mobileno = contactNumberInfo.mobilenumber;
      }
    }
    if (contactNumberInfo) {
      if (contactNumberInfo.countrycode) {
        countrycode = contactNumberInfo.countrycode;
      }
    }
    if (mobileno) {
      const id = localStorage.getItem("formId");
      const formData = { mobilenumber: mobileno, countrycode: countrycode };
      this.setState({ next: true });
      this.props.updateHostDetails(formData, id, "Contactnumber");
    } else {
      this.setState({ error: true, mobilenoerror: true });
    }
  }

  previous() {
    this.setState({ previous: true });
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect to="/customer-care" />;
    }
    if (previous) {
      return <Redirect to="/car-price" />;
    }
    const contactNumberInfo = this.props.HostCarReducer.contactNumberInfo;
    var countrycode = "";
    var mobilenumber = "";
    if (contactNumberInfo) {
      if (contactNumberInfo.countrycode) {
        countrycode = contactNumberInfo.countrycode;
      }
      if (contactNumberInfo.mobilenumber) {
        mobilenumber = contactNumberInfo.mobilenumber;
      }
    }
    const countryList = this.props.DashboardReducer.countryList;
    var countryArray = [];
    for (let i = 0; i < countryList.length; i++) {
      countryArray.push(
        <option
          key={i}
          value={countryList[i].countrycode}
          selected={countrycode === countryList[i].countrycode}
        >
          {countryList[i].countrycode}
        </option>
      );
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "50%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <h5>Ajouter mon véhicule</h5>
                    <div className="title-card">
                      Quel est votre numéro de téléphone ?
                    </div>
                    <label htmlFor="" className="acc-label">
                      Numéro de téléphone
                    </label>
                    <div className="container">
                      <div className="row">
                        <select
                          className="form-control col mr-1"
                          id="sel1"
                          name="countrycode"
                          onChange={(e) => this.onChangeValue(e)}
                        >
                          {countryArray}
                        </select>
                        <input
                          className="form-control col ml-1"
                          placeholder="Entrez le numéro de téléphone mobile"
                          id="sel1"
                          value={mobilenumber}
                          name="mobilenumber"
                          onChange={(e) => this.onChangeValue(e)}
                        />
                      </div>
                      {this.state.mobilenoerror ? (
                        <label style={{ color: "red" }}>
                          Votre numéro de téléphone est requis{" "}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="list-info mt-4 mb-2">
                      Suivez nos recommandations pour attirer les conducteurs et
                      maximiser vos revenus 💰.
                    </div>
                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button
                        className="prev-button mr-2"
                        onClick={this.previous}
                      >
                        <span className="cobalt-Icon">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>
                        Précédent
                      </button>
                      <button
                        className="Woohoo-btn"
                        onClick={this.validateForm}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    HostCarReducer: state.HostCarReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      viewDetails: AC_VIEW_HOST_DETAILS,
      handleInputChange: AC_HANDLE_INPUT_CHANGE,
      updateHostDetails: AC_UPDATE_HOST_DETAILS,
    },
    dispatch
  );
}

const ContactNumberComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactNumber);

export default ContactNumberComponent;
