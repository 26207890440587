import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ModifyProfile from './modifyprofile';
import VerifyProfile from './verifyprofile';
import AccountSettings from './accountsettings';
import Notifications from './notifications';
import GetCredit from './getcredit';
import MyAccountNavigation from './myaccountnavigation';
// import Accounts from './accounts';
import Logout from './logout';
import BillingSetting from "./billing-settings"

class MyAccount extends React.Component {
    render() {
        const pathName = this.props.location.pathname;
        return (
            <div>
                <Router>
                    <div className="container" style={{ marginTop: "-70px" }}>
                        <div className="col-lg-12 p-1">
                            <div className="main">
                                <div className="acc-div">
                                    <div className="row">
                                        <MyAccountNavigation path={pathName} />
                                        <div className="col-md-12 col-lg-8">
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <Switch>
                                                    <Route  exact path="/modify-profile" component={ModifyProfile} />
                                                    <Route exact path="/verify-profile" component={VerifyProfile} />
                                                    <Route exact path="/account-settings" component={AccountSettings} />
                                                    <Route exact path="/billing-settings" component={BillingSetting} />
                                                    <Route exact path="/notifications" component={Notifications} />
                                                    <Route exact path="/get-credit" component={GetCredit} />
                                                    <Route exact path="/logout" component={Logout} />
                                                    {/* <ModifyProfile /> */}
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Router>
            </div>

        );
    }
}
export default MyAccount;
