const initialState = {
  ConfigValue       : [],
}

function ConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_CONFIGURATION":
      return Object.assign({}, state, {
        ConfigValue        : action.payload.optionList,
      })
    default:
      return state
  }
}

export default ConfigurationReducer;
