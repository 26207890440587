import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_ACCOUNT, AC_EDIT_ACCOUNT, AC_HANDLE_INPUT_CHANGE } from '../../actions/accountaction';
import { Emailvalidate, Twittervalidate, PhoneValidate } from '../../common/validate.js';
// import URL from "../../common/api";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import { fr } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import './ModifyProfil.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dropzone from 'react-dropzone';
import Autocomplete from '@mui/material/Autocomplete';
import countries from './countries'
// import Previews from './ImgDropAndCrop'
registerLocale("fr", fr);
setDefaultLocale("fr");


class ModifyProfile extends React.Component {
	// Constructor
	constructor(props) {
		super(props);
		this.onDrop = (files) => {			
			this.setState({ file: files[0], filesShow:files, imagecheck: false })
		};

		this.state = {			
			filesShow: [],
			firstname: this.props.AccountReducer.accountInfo.firstname || '',
			lastname: this.props.AccountReducer.accountInfo.lastname || '',
			placeofbirth: this.props.AccountReducer.accountInfo.placeofbirth || '',
			licensenumber: this.props.AccountReducer.accountInfo.licensenumber || '',
			issuingcountry: this.props.AccountReducer.accountInfo.issuingcountry || '',
			contactemail: this.props.AccountReducer.accountInfo.contactemail || '',
			addressline1: this.props.AccountReducer.accountInfo.addressline1 || '',
			addressline2: this.props.AccountReducer.accountInfo.addressline2 || '',
			city: this.props.AccountReducer.accountInfo.city || '',
			postcode: this.props.AccountReducer.accountInfo.postcode || '',
			image: this.props.AccountReducer.accountInfo.image || '',
			code: this.props.AccountReducer.accountInfo.code || '',
			town: this.props.AccountReducer.accountInfo.town || '',
			addressline3: this.props.AccountReducer.accountInfo.addressline3 || '',
			aboutme: this.props.AccountReducer.accountInfo.aboutme || '',
			linkedin: this.props.AccountReducer.accountInfo.linkedin || '',
			twitter: this.props.AccountReducer.accountInfo.twitter || '',
			mobilenumber: this.props.AccountReducer.accountInfo.mobilenumber || '',
			validatedate: this.props.AccountReducer.accountInfo.validatedate || '',
			validatedateBrut: this.props.AccountReducer.accountInfo.validatedate ? this.getSelectedDate(this.props.AccountReducer.accountInfo.validatedate) : '',
			dateofbirth: this.props.AccountReducer.accountInfo.dateofbirth || '',
			dateofbirthBrut: this.props.AccountReducer.accountInfo.dateofbirth ? this.getSelectedDate(this.props.AccountReducer.accountInfo.dateofbirth) : '',
			// image: '/assets/images/photograph.png',
			firstnameError: false,
			lastnameError: false,
			mobilenumberError: false,
			contactemailError: false,
			contactemailcheck: false,
			twittercheck: false,
			imagecheck: false,
			imagevalue: false,
			mobilenumbercheck: false,
			imgSrc: 'assets/images/photograph.png'
		}
		// this.validateForm = this.validateForm.bind(this);
	}

	componentDidMount() {
		const id = localStorage.getItem('siteUser');				
		this.props.ViewAccount(id);		


	}



	getSelectedDate = (actaulDate) => {
		let arrayDate = actaulDate.split('-')
		let date = arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0]
		date = Date.parse(date)


		return date
	}

	handleChangeValideDate = (date) => {
		if (date) {
			const validDate = moment(date).format("DD-MM-YYYY")			
			this.setState({ validatedate: validDate, validatedateBrut: date });
		}
	}

	handleChangeBirthday = (date) => {
		if (date) {
			const validDate = moment(date).format("DD-MM-YYYY")			
			this.setState({ dateofbirth: validDate, dateofbirthBrut: date });
		}
	}
	//validate form
	validateForm = () => {
		// let imagecheck = this.state.imagecheck;
		const id = localStorage.getItem('siteUser')
		
		let data = {}		
		if (this.state.city) {
			data.city = this.state.city
		}
		if (this.state.town) {
			data.town = this.state.town
		}
		if (this.state.mobilenumber) {
			data.mobilenumber = this.state.mobilenumber
			this.setState({mobilenumberError: false})
		}else{

			this.setState({mobilenumberError: true})
		}
		
		if (this.state.addressline3) {
			data.addressline3 = this.state.addressline3
		}
		if (this.state.aboutme) {
			data.aboutme = this.state.aboutme
		}
		if (this.state.linkedin) {
			data.linkedin = this.state.linkedin
		}
		if (this.state.twitter) {
			data.twitter = this.state.twitter
		}
		if (this.state.validatedate) {
			data.validatedate = this.state.validatedate
		}
		if (this.state.dateofbirth) {
			data.dateofbirth = this.state.dateofbirth
		}

		if (this.state.placeofbirth) {
			data.placeofbirth = this.state.placeofbirth
		}
		if (this.state.licensenumber) {
			data.licensenumber = this.state.licensenumber
		}
		if (this.state.placeofbirth) {
			data.placeofbirth = this.state.placeofbirth
		}
		if (this.state.issuingcountry) {
			data.issuingcountry = this.state.issuingcountry
		}
		if (this.state.code) {
			data.code = this.state.code
		}
		if (this.state.addressline1) {
			data.addressline1 = this.state.addressline1
		}
		if (this.state.addressline2) {
			data.addressline2 = this.state.addressline2
		}
		// image
		// data = {}
		if (this.state.file) {
			data.image = this.state.file
			
		}
		// else {
		// 	data.image = ""
		// }

		if (this.state.firstname) {
			data.firstname = this.state.firstname
			this.setState({ firstnameError: false })
		}
		else {
			this.setState({ firstnameError: true })
		}
		if (this.state.lastname) {
			data.lastname = this.state.lastname
			this.setState({ lastnameError: false })
		}
		else {
			this.setState({ lastnameError: true })
		}

		// if (this.state.firstname && this.state.lastname && !imagecheck) {
		var formData = new FormData();
		for (let i in data) {

			formData.append(i, data[i]);

		}
		formData.append('id', id);		
		this.props.EditAccount(formData);		
	}
	// Onchange events
	onChangeValue(event) {
		const name = event.target.name;
		var value = event.target.value;
		const validemail = Emailvalidate(value);
		const validtwitter = Twittervalidate(value);
		const validmobilenumber = PhoneValidate(value);

		//number
		if (name === 'mobilenumber') {

			if (value) {
				if (validmobilenumber) {
					this.setState({ mobilenumber: value, mobilenumbercheck: false });
				}
				else {
					this.setState({ mobilenumber: value, mobilenumbercheck: true });
				}
			}
			else {
				this.setState({ mobilenumber: "", mobilenumbercheck: false });
			}
		}
	
		if (name === 'firstname') {
			if (value) {
				this.setState({ firstname: value, firstnameError: false })
			} else {
				this.setState({ firstname: value, firstnameError: true })
			}
		}
		if (name === 'lastname') {
			if (value) {
				this.setState({ lastname: value, lastnameError: false })
			} else {
				this.setState({ lastname: value, lastnameError: true })
			}
		}
		if (name === 'placeofbirth') {
			if (value) {
				this.setState({ placeofbirth: value })
			} else {
				this.setState({ placeofbirth: value })
			}
		}
		if (name === 'licensenumber') {
			if (value) {
				this.setState({ licensenumber: value })
			} else {
				this.setState({ licensenumber: value })
			}
		}
		if (name === 'code') {
			if (value) {
				this.setState({ code: value })
			} else {
				this.setState({ code: value })
			}
		}
		if (name === 'date') {
			if (value) {
				this.setState({ validatedate: value })
			} else {
				this.setState({ validatedate: value })
			}
		}
		if (name === 'issuingcountry') {
			if (value) {
				this.setState({ issuingcountry: value })
			} else {
				this.setState({ issuingcountry: value })
			}
		}
		if (name === 'contactemail') {
			if (value) {
				if (validemail) {

					this.setState({ contactemail: value, contactemailError: false, contactemailcheck: false });
				}
				else {
					this.setState({ contactemail: value, contactemailError: false, contactemailcheck: true });
				}
			}

			else {
				this.setState({ contactemail: value, contactemailError: true, contactemailcheck: false });
			}
		}
		if (name === 'addressline1') {
			if (value) {
				this.setState({ addressline1: value })
			} else {
				this.setState({ addressline1: value })
			}
		}
		if (name === 'addressline2') {
			if (value) {
				this.setState({ addressline2: value })
			} else {
				this.setState({ addressline2: value })
			}
		}
		if (name === 'postcode') {
			if (value) {
				this.setState({ postcode: value })
			} else {
				this.setState({ postcode: value })
			}
		}
		if (name === 'city') {
			if (value) {
				this.setState({ city: value })
			} else {
				this.setState({ city: value })
			}
		}
		if (name === 'town') {
			if (value) {
				this.setState({ town: value })
			} else {
				this.setState({ town: value })
			}
		}
		if (name === 'addressline3') {
			if (value) {
				this.setState({ addressline3: value })
			} else {
				this.setState({ addressline3: value })
			}
		}
		if (name === 'aboutme') {
			if (value) {
				this.setState({ aboutme: value })
			} else {
				this.setState({ aboutme: value })
			}
		}
		// if (name === 'dateofbirth') {
		// 	if (value) {
		// 		this.setState({ dateofbirth: value })
		// 	} else {
		// 		this.setState({ dateofbirth: value })
		// 	}
		// }
		if (name === 'validatedate') {
			if (value) {
				this.setState({ validatedate: value })
			} else {
				this.setState({ validatedate: value })
			}
		}
		if (name === 'linkedin') {
			if (value) {
				this.setState({ linkedin: value })
			} else {
				this.setState({ linkedin: value })
			}
		}
		if (name === 'twitter') {
			if (value) {
				if (validtwitter) {

					this.setState({ twitter: value, twittercheck: false });
				}
				else {
					this.setState({ twitter: value, twittercheck: true });
				}

			} else {
				this.setState({ twitter: value, twittercheck: false });
			}
		}
		this.props.HandleInputChange(name, value)
	}
	render() {
		// const files =  <li key={this.state.file.filename} >{this.state.file.filename} - {this.state.file.size} ko</li>		
		const files = this.state.filesShow.map(file => (
			<li key={file.path}>
			  {file.path} - {file.size} ko
			</li>
		  ));
		let issuingcountry = this.state.issuingcountry
		
		return (
			<>
				<div className="col-lg-12">
					<div className="tab-content" id="v-pills-tabContent">
						<div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
							<div className="main-acc">
								<div className="view">Certaines de ces informations apparaîtront sur votre page de profil publique.
									{/*<a href="#">Voir son profile.</a>*/}
								</div>

								<fieldset className="form-field">
									<legend className="form-head"><i className="far fa-file-image fa-lg"></i> Votre photo</legend>
									{/* <label htmlFor className="acc-label">Photo</label>
									<div className="photo-upload">
										<div className="custom-file" style={{ width: '100%' }}>
											<input type="file" name="image" className="form-control" style={{ display: "none" }} id="exampleInputFile" onChange={(e) => this.onChangeValue(e)} />
											<label className="custom-file-label" style={{}} htmlFor="exampleInputFile">Sélectionner une image</label>
										</div>
										{this.state.imagecheck ? <label style={{ color: 'red' }}>Le format de l'image n'est pas valide
										</label> : ""}
										{this.state.imagevalue ? <img src={this.state.imgSrc} style={{ height: "100px", width: "100px" }} alt="" /> : <img src={this.state.image} style={{ height: "100px", width: "100px" }} alt="" />}
									</div> */}
									<Dropzone multiple={false} onDrop={this.onDrop} >
										
										{({ getRootProps, getInputProps }) => (
											<section className="container">
												<div className='dropzone' {...getRootProps()}>
													<input {...getInputProps()} name="image" type="file" />
													<span className='icon-size'><i className="fas fa-camera-retro fa-lg"></i></span>
													<p>Déposer ou cliquer dans ce champs pour ajouter une image</p>
												</div>
												<aside>
													<h4>Prévisualisation</h4>
													 
													<ul>
													{files}</ul>
												</aside>
											</section>
										)}
									</Dropzone>

								</fieldset>
								<fieldset className="form-field mt-5">
									<legend className="form-head"><i className="fas fa-id-card fa-lg"></i> Informations personnelles</legend>
									<div className="group-div">
										<label htmlFor="firstname" className="acc-label">Prénom</label><span style={{ color: "red" }}>*</span>
										<input type="text" id="firstname" className="form-control" name="firstname" placeholder="Entrez le prénom" value={this.state.firstname} onChange={(e) => this.onChangeValue(e)} />
										{this.state.firstnameError ? <label className="text-danger">Le prénom est obligatoire
										</label> : ""}
										<br />
										<label htmlFor="lastname" className="acc-label mt-3">Nom</label><span className="text-danger">*</span>
										<input type="text" id="lastname" className="form-control" name="lastname" placeholder="Entrez le nom" value={this.state.lastname} onChange={(e) => this.onChangeValue(e)} />
										{this.state.lastnameError ? <label className="text-danger" >Le nom est obligatoire</label> : ""}<br />
										<label htmlFor="dateofbirth" className="acc-label mt-3">Date de naissance</label>
										<div className="row">
											<div className="col-lg-12">
												<DatePicker
													locale="fr"
													id="dateofbirth"
													className='form-control'
													dateFormat="dd-MM-yyyy"
													shouldCloseOnSelect
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													withPortal
													dropdownMode="select"
													selected={this.state.dateofbirthBrut}
													onChange={(date) => this.handleChangeBirthday(date)}
													placeholderText='Ex: 17-11-1995'

												/>

												{/* <input className="form-control" type="text" name="dateofbirth" placeholder="Ex: 17-11-1995" value={dateofbirth} onChange={(e) => this.onChangeValue(e)} /> */}

											</div>
										</div>

									</div>
								</fieldset>
								<fieldset className="form-field mt-5">
									<legend className="form-head"><i className="fas fa-car-alt fa-lg"></i> Permis de conduire</legend>
									<div className="group-div">
										<label htmlFor="" className="acc-label mt-3">Numéro de licence</label>
										<input type="text" className="form-control" placeholder="ex: MORGA657054SM9IJ" name="licensenumber" value={this.state.licensenumber} onChange={(e) => this.onChangeValue(e)} />
										<br />

										<label htmlFor="validatedate" className="acc-label mt-3">Date de début de validité</label>

										<div className="col px-0">
											<DatePicker
												id="validatedate"
												locale="fr"
												className='form-control'
												dateFormat="dd-MM-yyyy"
												shouldCloseOnSelect
												peekNextMonth
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												selected={this.state.validatedateBrut}
												onChange={(date) => this.handleChangeValideDate(date)}
												placeholderText='Ex: 17-11-1995'
												name="validatedate"
												withPortal
											/>
											{/* <input className="form-control" type="text" name="validatedate" placeholder="Entrez une date" value={validatedate} onChange={(e) => this.onChangeValue(e)} /> */}
										</div>
										<label htmlFor="country-select-demo" className="acc-label mt-3">Pays d'émission</label>
										{/* <input type="text" className="form-control" name="issuingcountry" placeholder="Entrez un pays" value={issuingcountry} onChange={(e) => this.onChangeValue(e)} /> */}

										<Autocomplete
											id="country-select-demo"
											fullWidth
											onChange={(event, newValue) => {
												if(newValue)
													this.setState({issuingcountry: newValue.label})
												else
													this.setState({issuingcountry: ""})
											}}
											defaultValue={countries.find(v => v.label === issuingcountry)}
											options={countries}
											autoHighlight
											getOptionLabel={(option) => option.label}
											renderOption={(props, option) => (
												<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
													<img
														loading="lazy"
														width="20"
														src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
														srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
														alt=""
													/>
													{option.label} ({option.code}) +{option.phone}
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													fullWidth
													{...params}
													label="Selectionner un pays"
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password', // disable autocomplete and autofill
													}}
												/>
											)}
										/>
										<br /></div>
								</fieldset>
								<fieldset className="form-field mt-5">
									<legend className="form-head"><i className="fas fa-address-book fa-lg"></i> Contact</legend>
									<div className="group-div">
										<label htmlFor="contactemail" className="acc-label">Email</label><span className="text-danger" >*</span>
										<input type="email" id="contactemail" disabled className="form-control" placeholder="example@example.com" name="contactemail" value={this.state.contactemail} onChange={(e) => this.onChangeValue(e)} />
										{this.state.contactemailError ? <label className="text-danger" >Une adresse mail  est obligatoire</label> : ""}
										{this.state.contactemailcheck ? <label className="text-danger" >L'adresse de contact n'est pas valide</label> : ""}
										<br /><label htmlFor="mobilenumber" className="acc-label mt-3">Les notifications par SMS seront envoyées au : </label><span className="text-danger" >*</span>
										<div className="container">
											<div className="row">												
												<div className="col-12">
													<input type="text" name="mobilenumber" disabled id="mobilenumber" className="form-control" placeholder="Ex: 0707070707" value={this.state.mobilenumber} onChange={(e) => this.onChangeValue(e)} />
													{this.state.mobilenumbercheck ? <label className="text-danger" >Le numéro de téléphone n'est pas valide
													</label> : ""}
												</div>
											</div>
										</div>
									</div>
								</fieldset>
								<fieldset className="form-field mt-5">
									<legend className="form-head"><i className="fas fa-street-view fa-lg"></i> Adresse</legend>
									<div className="group-div">
										<label htmlFor="addressline1" className="acc-label">Quartier</label>
										<input name="addressline1" id="addressline1" type="text" className="form-control" placeholder="Ex: Programme 4, Riviéra Palmeraie" value={this.state.addressline1} onChange={(e) => this.onChangeValue(e)} />
										<br /><label htmlFor="addressline2" className="acc-label mt-3">Complément d'adresse</label>
										<input name="addressline2" id="addressline2" type="text" placeholder="Ex: Ilot N°552" className="form-control" value={this.state.addressline2} onChange={(e) => this.onChangeValue(e)} />
										<br /><label htmlFor="city" className="acc-label mt-3">Commune et Ville</label>
										<div className="container">
											<div className="row">
												<div className="col-12 col-md-6 px-1">
													<input type="text" id="city" className="form-control" name="city" placeholder="Ex: Cocody" value={this.state.city} onChange={(e) => this.onChangeValue(e)} />
													<br />	</div>
												<div className="col-12 col-md-6">
													<input type="text" className="form-control" name="town" placeholder="Ex: Abidjan" value={this.state.town} onChange={(e) => this.onChangeValue(e)} />
													<br />	</div>
											</div>
										</div>										
									</div>
								</fieldset>
								<fieldset className="form-field mt-5">
									<legend className="form-head"><i className="fas fa-info fa-lg"></i> Informations Comlémentaires</legend>
									<div className="group-div">
										<label htmlFor="aboutme" className="acc-label mt-3">À propos de moi</label>
										<textarea name="aboutme" id="aboutme" cols="30" rows="3" className="form-control" value={this.state.aboutme} onChange={(e) => this.onChangeValue(e)}></textarea>
										<br />	<label htmlFor="linkedin" className="acc-label mt-4" name="linkedin">Linkedin</label>
										<input name="linkedin" id="linkedin" type="text" className="form-control" value={this.state.linkedin} placeholder="http://www.linkedin.com/in/exemple" onChange={(e) => this.onChangeValue(e)} />
										<br />	<label htmlFor="twitter" className="acc-label mt-3" name="twitter">Twitter</label>
										<input name="twitter" id="twitter"type="text" className="form-control" value={this.state.twitter} placeholder="https://twitter.com/exemple" onChange={(e) => this.onChangeValue(e)} />
										{this.state.twittercheck ? <label style={{ color: 'red' }}>Le compte twitter est invalide</label> : ""}
										<br />	<fieldset className="form-field mt-5">
											<input type="button" name="commit" value="Enregistrer" className="account-btn px-5 py-3" onClick={() => this.validateForm()} />

										</fieldset>
									</div>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}


function mapStateToProps(state) {
	return {
		AccountReducer: state.AccountReducer
	};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({ ViewAccount: AC_VIEW_ACCOUNT, EditAccount: AC_EDIT_ACCOUNT, HandleInputChange: AC_HANDLE_INPUT_CHANGE }, dispatch)
}
const ModifyProfileComponent = connect(mapStateToProps, mapDispatchToProps)(ModifyProfile);

export default ModifyProfileComponent;
