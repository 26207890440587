import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { AC_DASHBOARD, } from './../../actions/dashboardaction';
import { bindActionCreators } from 'redux';
// import {Redirect} from 'react-router-dom'
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      status: '',
    }
    this.Submit = this.Submit.bind(this);
  }
  Submit = () => {
    confirmAlert({
      title: 'Se déconnecter',
      message: 'Vous êtes sûr de vouloir vous déconnecter de votre compte ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            localStorage.clear()

            // this.props.Dashboard({ slug: 'rent-a-car', status: 2 });
            // localStorage.removeItem('siteUser') 
            // localStorage.removeItem('formId')
            this.props.Dashboard({ slug: 'rent-a-car', status: 2 })
            this.props.history.push({ pathname: '/' });
            window.location.reload();
          }
        },
        {
          label: 'Non',
          onClick: () => {
            this.props.history.push("/modify-profile");
          }
        }
      ]

    });
  };
  render() {
    return (
      <div>
        {this.Submit()}
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    Dashboard: AC_DASHBOARD,
  },
    dispatch)
}

const LogoutComponent = connect(mapStateToProps, mapDispatchToProps)(Logout);

export default LogoutComponent;

