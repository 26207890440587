import React from 'react';
// import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { AC_EDIT_ACCOUNT, AC_VIEW_ACCOUNT } from "../../actions/accountaction";
import { connect } from "react-redux"
import { bindActionCreators } from 'redux';

class BillingSettings extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      iban: '',
      ownercard: '',
      mobilemonney: '',
    }

    this.accounInfo = {}
    this.handleInputChange = this.handleInputChange.bind(this)
  }


  componentDidMount() {
    const id = localStorage.getItem('siteUser');
    this.props.ViewAccount(id);

    this.setState({
      iban: this.props.AccountReducer.accountInfo.iban,
      ownercard: this.props.AccountReducer.accountInfo.ownercard,
      mobilemoney: this.props.AccountReducer.accountInfo.mobilemoney
    })
  }



  handleInputChange = (e) => {

    const { name, value } = e.target;
    this.setState({ [name]: value });

  }

  handleBankInfoSubmit = (e) => {

    const userData = this.props.AccountReducer.accountInfo;
    const form = { ...this.state, firstname: userData.firstname, lastname: userData.lastname, id: userData._id }

    this.props.EditAccount(form)    

  }

  render() {

    return (

      <div className="main-acc" data-aos="zoom-in">

        <div className="billing--container">
          <div className="billing--section mt-2">
            <h5 className="car-listing-title"> Coordonnées bancaires</h5>

            <div className="billing--section-form-container">
              <div className="billing--section-form col-lg-6 px-0">
                <div className="">

                  {this.state.ownercard && <label >Titulaire du compte</label>}
                  <input

                    className="form-control mb-4"
                    placeholder="Titulaire du compte"
                    name="ownercard"
                    onChange={this.handleInputChange}
                  />

                  {this.state.iban && <label >IBAN</label>}
                  <input
                    className="form-control mb-1"
                    placeholder="IBAN"
                    name="iban"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="form--section-assurance col-lg-6">

                <div className="list-info">

                  Vous avez accepté de partager votre IBAN
                  avec nos assurance partenaires pour un remboursement
                  plus rapide en cas d'accident

                </div>
              </div>
            </div>
          </div>

          <div className="billing--section mt-2">
            <h5 className="car-listing-title">Mobile monney</h5>

            <div className="billing--section-form-container col-lg-12 px-0">
              <div className="billing--section-form">
                <div >

                  {this.state.mobilemoney && <label >Numéro mobile money</label>}

                  <input
                    className="form-control mb-4"
                    placeholder="Numéro Mobile Monney"
                    name="mobilemoney"
                    value={this.state.mobilemoney}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="form--section-assurance">

                {/* <div className="list-info">

                          Vous avez accepté de partager votre IBAN
                          avec nos assurance partenaires pour un remboursement
                          plus rapide en cas d'accident
                      
                      </div> */}
              </div>
            </div>
          </div>
          <div className="container">
            <button
              onClick={this.handleBankInfoSubmit}
              type="button" name="commit" className="account-btn mt-4 text-white">Mettre à jour</button>
          </div>

        </div>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewAccount: AC_VIEW_ACCOUNT, EditAccount: AC_EDIT_ACCOUNT }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingSettings)