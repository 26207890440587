import React from "react";
import { Collapse } from 'antd';

class Policy extends React.Component {
    // constructor(props) {
    //   super(props);
    //   this.state = {
    //     navExpanded: false,
    //   };
    // }
    render() {
        const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

        const { Panel } = Collapse;

        function callback(key) {
            console.log(key);
        }
        return (
            <section id="policy" className="pb-5 mb-5" >
                <h1 className="py-5 px-2 text-center text-white" style={{ textTransform: "uppercase", backgroundColor: "#004aad", fontFamily: 'poppins' }}>Politique de confidentialité</h1>

                <div className="col-lg-8">
                    <div className="card" style={{ marginBottom: "100px" }}>
                        <div className="card-body">
                            {/* <h3 className="lead" style={{ textTransform: "uppercase" }}>A votre attention</h3>
                            La société « MONBOLIDE SARL, au capital de 1 000 000 FCFA,
                            immatriculé au Registre de Commerce et de Crédit Mobilier sous le
                            n°CI-ABJ-03-2021-B13-07627, Domiciliée : « Bingerville Cité Palma
                            Ilot 1 lot 1 » Téléphone : (+225) 0700720830 Email :
                            monbolide.civ@gmail.com met à disposition une Plateforme
                            destinée à faciliter la mise en relation entre les Propriétaires de
                            véhicule dit loueur et les Locataires. */}

                            <div className="elements">
                                <h4></h4>
                                <Collapse defaultActiveKey={['1']} onChange={callback}>
                                    <Panel header="Données Personnelles collecté et à quels
moments" key="1">
                                        <p>Les Données Personnelles comprennent toutes informations :</p>
                                        <ul className="pl-3">
                                            <li>
                                                Se rapportant à une personne physique identifiée ou identifiable
                                                (par ex. votre nom, votre adresse, etc.) ;
                                            </li>
                                            <li>Nous permettant de vous identifier directement ou indirectement,
                                                notamment par référence à un numéro d’identifiant (par ex. votre
                                                adresse IP)</li>
                                        </ul>
                                        <p>Les « Données Personnelles » incluent aussi bien les données que
                                            vous fournissez à Monbolide. Nous n’utilisons pas de cookies pour
                                            récolter vos données personnelles.</p>
                                        <p>Vos Données Personnelles seront collectées et traitées par
                                            Monbolide dans les cas suivants : </p>



                                        <ol className="pl-1">
                                            <li><h6>Lorsque vous créez un Compte sur notre Plateforme</h6></li>

                                            Lorsque vous créerez un Compte sur notre Plateforme, Nous vous
                                            demanderons alors de nous fournir les Données Personnelles
                                            suivantes pour la création de votre Compte (les « Données du
                                            Compte ») :
                                            <ul className="pl-2">
                                                <li>Nom Complet</li>
                                                <li>Date de naissance</li>
                                                <li>Informations relatives à votre permis de conduire</li>
                                                <li>Adresse électronique ;</li>
                                                <li>Mot de passe ;</li>
                                                <li>Numéro de téléphone ; </li>
                                                <li>Adresse postale ;</li>
                                            </ul>
                                            <li><h6>Lorsque vous louez votre Véhicule</h6></li>
                                            En plus des autres Données Personnelles que nous avons déjà pu
                                            collecter, lorsque vous souhaitez louer votre Véhicule, vous devez
                                            nous fournir les Données Personnelles suivantes :
                                            <ul className="pl-2">
                                                <li>Type et marque du Véhicule;</li>
                                                <li>Numéro de la plaque d’immatriculation;</li>
                                                <li>Adresse du lieu de stationnement </li>
                                                <li>Pays et année de la première immatriculation ;</li>
                                                <li>Date du dernier contrôle technique ; </li>
                                                <li> Informations détaillées concernant le Véhicule (par ex. nombre
                                                    de sièges et de portes, accessoires, capacité du réservoir,
                                                    kilométrage, type de carburant). </li>
                                                <li> Coordonnées bancaires : nom du titulaire du compte et numéro
                                                    de compte bancaire. </li>
                                            </ul>
                                            <p>Conformément à l’obligation de vigilance imposé à Stripe par le
                                                code financier et monétaire, Monbolide vérifiera votre identité
                                                lorsque les revenus que vous avez cumulés suite à la Location de votre Véhicule sont supérieurs à 655.000FCFA</p>
                                            <li>
                                                <h6>Lorsque vous louez un Véhicule </h6>
                                            </li>
                                            <p>En plus des Données Personnelles que nous avons déjà pu
                                                collecter, lorsque vous souhaitez louer un Véhicule, vous devez
                                                nous fournir les Données Personnelles suivantes : </p>
                                            <code>Pour réserver un Véhicule: </code> Lorsque vous réservez un Véhicule, vous devez nous fournir, Nom et prénom du titulaire de la carte, Données figurant sur la carte de paiement. Une fois collectées,
                                            ces données sont immédiatement envoyées à notre prestataire de
                                            services de paiement Stripe ou Paydunya, étant précisé que
                                            Monbolide ne conservera pas l’intégralité des données figurant sur
                                            la carte de paiement (uniquement les premiers et derniers chiffres).
                                            <br />
                                            <code>Pour vérifier votre identité: </code> Étant donné que, pour ces Locations, Monbolide est tenue de
                                            vérifier l’identité du Locataire, vous devez nous fournir, en plus des
                                            autres Données Personnelles que vous avez déjà pu nous fournir,
                                            les informations suivantes collectées via notre Application (les «
                                            Données de Vérification ») : une photo (recto verso) de votre permis
                                            de conduire ; Une photo de votre carte d’identité ; Un selfie ; Une facture de logement.
                                            <p>Le Propriétaire est tenu de vérifier l’identité du Locataire et de
                                                prendre des photos de ses documents d’identité (par ex. son permis
                                                de conduire). </p>
                                            <p>Monbolide demandera au Propriétaire de lui transmettre ces photos
                                                dans le seul but de vérifier l’identité du Locataire pour les besoins
                                                de son Assurance ou lorsque le Locataire n’est pas solvable. </p>
                                            <p>Dans le cas où Monbolide demande la transmission de ces photos,
                                                Monbolide et le Propriétaire seront tous deux responsables du
                                                traitement des Données Personnelles du Locataire. Par
                                                conséquent, les Locataires pourront exercer les droits attachés à
                                                leurs Données Personnelles auprès du Propriétaire et/ou de
                                                Monbolide. </p>
                                        </ol>
                                    </Panel>
                                    <Panel header="Traitement de vos Données Personnelles et
bases légales." key="2">
                                        <p>Nous traitons vos Données Personnelles pour les finalités et sur les
                                            bases légales suivantes : </p>

                                        <ul className="pl-3">
                                            <li>Gestion des Locations (paiement, Contrats de Location, etc.);</li>
                                            <li>Gestion des pénalités et des frais ; </li>
                                            <li>Gestion des sinistres (évaluation des dommages, etc.) ; </li>
                                            <li>Gestion des impayés ; </li>
                                            <li>Communication entre les Utilisateurs ; </li>
                                            <li>Inscription sur la Plateforme ; </li>
                                            <li>Vérification de votre identité;</li>
                                            <li>Publication d’une Annonce pour louer un Véhicule sur la
                                                Plateforme ; </li>
                                            <li>Marketing et communication (par courrier électronique, au moyen
                                                de notifications sur votre smartphone);</li>
                                        </ul>
                                        <p>
                                            Nous nous réservons le droit de conserver et d’utiliser
                                            ultérieurement les données de l’application et les données du site a
                                            toute fin qui va dans le sens de la protection et de la pérennité de la
                                            plateforme Monbolide.
                                        </p>

                                        <ul className="pl-3">
                                            <li> Répondre aux demandes officielles des autorités publiques ou
                                                judiciaires habilitées à le faire (par ex. demande d’un administrateur
                                                financier) ;</li>
                                            <li> Respecter la réglementation bancaire et financière (par ex. mise
                                                en place de mesures de sécurité, vérification de l’identité) afin de
                                                prévenir les abus et les fraudes. </li>
                                        </ul>
                                    </Panel>
                                    <Panel header="Temps de conservation de vos Données
Personnelles" key="3">
                                        <p>Nous ne conservons vos Données Personnelles dans notre base
                                            active (c’est-à-dire la base dans laquelle vos Données Personnelles
                                            sont accessibles dans l’environnement direct de travail des services
                                            en charge de ce traitement) que pendant la durée nécessaire à la
                                            réalisation de la finalité pour laquelle elles ont été collectées. </p>
                                        <p>Nous conserverons vos données personnelles pendant 5 ans après
                                            vos dernières actions en lien avec notre plateforme.
                                            <br />
                                            À l’expiration de ces périodes, toutes vos Données Personnelles
                                            seront automatiquement transférées dans notre base d’archives
                                            (autrement dit, vos Données Personnelles ne pourront être
                                            consultées que de manière ponctuelle par les employés de
                                            Monbolide qui y sont spécifiquement autorisés, dans certains cas
                                            particuliers). Vos Données Personnelles seront conservées dans
                                            notre base d’archives pendant les durées qui sevront.
                                        </p>
                                        <p>Pendant la durée nécessaire pour respecter les délais de
                                            prescription applicable et auxquelles nous sommes soumis dans le
                                            cadre de notre activité : </p>
                                        <ul className="pl-3">
                                            <li>13 mois après votre dernier paiement par carte ; </li>
                                            <li>5 ans après le premier défaut de paiement, après la date de votre
                                                dernier paiement </li>
                                            <li>6 ans après votre dernière Location ou après le dernier événement survenu pendant l’une de vos Locations (par ex.
                                                dommages à un Véhicule, infraction, pénalité, etc.)</li>
                                        </ul>
                                        <p>Pour des raisons de sécurité afin de protéger les intérêts de nos
                                            Utilisateurs et ceux de Monbolide : </p>
                                        <ul className="pl-3">
                                            <li>1 an après la création de votre Compte (si vous n’avez effectué
                                                aucune Location) ; </li>
                                            <li>2 ans après la date à laquelle l’accès à votre Compte a été
                                                temporairement restreint ; </li>
                                            <li>10 ans après la date à laquelle l’accès à votre Compte a été
                                                définitivement restreint. Si votre Compte tombe dans plusieurs
                                                catégories, nous choisirons la durée la plus longue. Une fois les
                                                périodes indiquées ci-dessus écoulées, nous effacerons
                                                définitivement vos Données Personnelles.</li>
                                        </ul>
                                    </Panel>
                                    <Panel header="Mesures de sécurité" key="4">
                                        <p>L’accès à votre Compte n’est possible qu’après avoir saisi votre mot
                                            de passe personnel directement sur la Plateforme. Avant leur
                                            stockage, les mots de passe sont hachés à l’aide de pratiques et
                                            d’algorithmes de pointe. Vous devez préserver en tout temps la
                                            confidentialité de vos identifiants et mot de passe mais également
                                            fermer la fenêtre de votre navigateur une fois que vous cessez
                                            d’utiliser la Plateforme, en particulier si vous partagez votre
                                            ordinateur et/ou votre appareil avec d’autres personnes. </p>

                                        <p>Nous mettons en œuvre des mesures techniques et
                                            organisationnelles appropriées pour protéger notre service et
                                            d’autres systèmes contre la perte, la destruction, la modification ou
                                            la diffusion de vos Données Personnelles par des personnes non
                                            autorisées ou contre tout accès non autorisé à celles-ci. Le transfert
                                            de données entre la Plateforme et le serveur est chiffré via le
                                            protocole SSL. </p>
                                        <p>
                                            Les Données de Paiement du Locataire et du Propriétaire seront
                                            traitées et conservées par nos prestataires de services de paiement
                                            (Stripe, Paydunya) dans le respect des mesures de sécurité les plus
                                            strictes applicables dans le secteur du commerce électronique. En
                                            particulier, les transactions s’effectueront au moyen d’un processus
                                            sécurisé et chiffré et une partie du numéro de la carte bancaire
                                            utilisée sera masquée lorsqu’elle apparaît à l’écran. Nous ne
                                            conservons pas l’intégralité des données figurant sur votre carte
                                            bancaire, mais uniquement les premiers et derniers chiffres.
                                        </p>
                                    </Panel>
                                    <Panel header="Transfère Données Personnelles" key="5">

                                        <p>Nous nous assurons de la protection de vos données personnelles
                                            avant tout transfert conformément à la législation et à nos
                                            conditions général d’utilisation</p>
                                        <p>
                                            En ce qui concerne les Données Personnelles auxquelles vous
                                            pouvez avoir accès (par ex. l’identité du Propriétaire ou du
                                            Locataire, la photo de sa carte d’identité et de son permis de
                                            conduire, etc.), vous vous engagez à ne pas divulguer de Données Personnelles d’un Utilisateur à des tiers qui n’ont pas qualité pour
                                            les recevoir.
                                        </p>
                                        <p>
                                            Pour le bon fonctionnement de notre service, tous nos Utilisateurs
                                            auront accès aux Données du Véhicule (à l’exception du numéro de
                                            la plaque d’immatriculation si celui-ci ne figure pas sur la photo de
                                            l’annonce) et aux Données Personnelles suivantes des
                                            Propriétaires et des Locataires (les « Données Publiques ») :
                                        </p>
                                        <ul className="pl-3">
                                            <li>Nom complet ou raison sociale de la société du Propriétaire ; </li>
                                            <li>Photo de profil (le cas échéant);</li>
                                            <li> Nombre de Locations et d’évaluations par d’autres Utilisateurs (le
                                                cas échéant) ; </li>
                                            <li>Biographie (le cas échéant) ; </li>
                                            <li> Lien Twitter (le cas échéant) ;</li>
                                            <li>Lien LinkedIn (le cas échéant) ;</li>
                                        </ul>

                                        <p>En plus des Données Publiques, le Propriétaire aura accès aux
                                            Données Personnelles du Locataire indiquées ci-dessous afin de
                                            pouvoir vérifier son identité au début de la Location (pour les
                                            Contrats de Location sur supports papier et numérique) et/ou dans
                                            le cadre de toute réclamation à la fin de la Location (pour
                                            l’ensemble des Locations) : </p>
                                        <ul className="pl-3">
                                            <li>Nom complet ;</li>
                                            <li>Date et lieu de naissance (vérification visuelle de la carte d’identité du Locataire au début de la Location)</li>
                                            <li>Données figurant sur le permis de conduire (par ex. numéro de
                                                permis, Date de première délivrance et pays de délivrance);</li>
                                            <li>Numéro de téléphone ;</li>
                                            <li>Adresse postale ; </li>
                                            <li>Données de paiement du Locataire.</li>
                                        </ul>
                                        <p>(Toutes ces données sont indiquées dans le Contrat de Location).
                                            Pour le bon fonctionnement de notre service, en plus des Données
                                            Publiques, le Locataire aura accès aux Données Personnelles
                                            suivantes du Propriétaire du Véhicule loué:</p>
                                        <ul className="pl-3">
                                            <li>Numéro de téléphone du Propriétaire</li>
                                            <li>Type et marque du Véhicule, numéro d’immatriculation et type de
                                                carburant (ces données sont indiquées dans le Contrat de
                                                Location). </li>
                                        </ul>
                                        <p>Nos prestataires de services n’ont accès qu’aux Données
                                            Personnelles vous concernant qui sont nécessaires pour fournir les
                                            services techniques indiqués ci-dessus. </p>
                                        <p>En cas d’incident (par ex. dommages, panne, etc.) pendant la
                                            Location, les Données Personnelles suivantes pourront leur être
                                            communiquées : les Données du Locataire, y compris ses Données
                                            de Paiement ; les Données du Propriétaire, y compris ses Données
                                            de Paiement ; les Données du Véhicule ; les documents relatifs aux
                                            dommages subis, tels que des photos, des devis, des rapports. En
                                            cas d’accident avec un tiers, notre Assurance pourra envoyer
                                            certaines des Données Personnelles indiquées ci-dessus à
                                            l’assurance du tiers concerné. </p>

                                    </Panel>
                                    <Panel header="Vos droits" key="6">
                                        <div >

                                        </div>
                                        <p>Vous pouvez exercer les droits suivants attachés à vos Données
                                            Personnelles : </p>

                                        <ul className="pl-3">
                                            <li>Droit d’accès</li>
                                            <li>Droit de rectification</li>
                                            <li>Droit à l’effacement</li>
                                            <li>Droit à la limitation du traitement</li>
                                            <li>Droit a la portabilité des données </li>
                                            <li>Droit d’opposition</li>
                                        </ul>
                                        {/* <p>{text}</p> */}
                                        <p>
                                            Pour l’exercer l’un de ces droits vous pouvez nous adressé un
                                            courrier électronique sur notre adresse mail
                                            monbolide.civ@gmail.com
                                        </p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        )
    }

}


export default Policy;