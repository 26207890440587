import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "./Tabheader.css";
import { connect } from "react-redux";
import moment from "moment";
import { Badge } from "antd";
import { AC_VIEW_CARS } from "../../actions/accountaction";
import { bindActionCreators } from "redux";

class TabHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requesttoggle: true,
      rentaltoggle: false,
      carstoggle: false,
      paymentstoggle: false,
      newaccounttoggle: false,
      account: false,
      settings: false,
      ask: 0,
      rental: 0,
    };
  }
  // componentWillUnmount() {
  //     this.unlisten();
  // }
  componentDidMount() {
    this.props.ViewCars({ id: localStorage.getItem("siteUser"), status: 4 });
    this.count()
    this.countRequest()
    const path = this.props.path.slice(1);
    // console.log("Tabheader" + path)
    var name = path + "toggle";
    if (
      path === "hired-cars" ||
      (path === path) === "general-tab" ||
      path === "car-profile" ||
      path === "instant-tab" ||
      path === "mon-bolide-tab" ||
      path === "address-tab" ||
      path === "calendar-tab" ||
      path === "conditions-tab" ||
      path === "deactivate-tab" ||
      path === "description-tab" ||
      path === "insurance-tab" ||
      path === "photos-tab" ||
      path === "price-tab"
    ) {
      name = "carstoggle";
    }
    if (path === "booking-request") {
      name = "requesttoggle";
    }
    if (
      path === "modify-profile" ||
      path === "verify-profile" ||
      path === "account-settings" ||
      path === "notifications" ||
      path === "get-credit"
    ) {
      name = "newaccounttoggle";
    }
    // if($(window).width() >=768){
    // 	$(".iconScrollRight").hide();
    // 	$(".iconScrollLeft").hide();
    // }
    $(window).on("resize", function () {
      if ($(this).width() >= 768) {
        $(".iconScrollRight").hide();
        $(".iconScrollLeft").hide();
      } else {
        $(".iconScrollRight").show();
        $(".iconScrollLeft").show();
      }
    });

    $(window).on("scroll", function () {
      // let height = $(window).scrollTop();
      if ($(window).scrollTop() !== 0 && $(window).width() <= 768) {
        $(".iconScrollRight").hide();
        $(".iconScrollLeft").hide();
        // alert($(window).scrollTop())
      } else if ($(window).scrollTop() === 0 && $(window).width() <= 768) {
        $(".iconScrollRight").show();
        $(".iconScrollLeft").show();
        // alert($(window).scrollTop())
      } else if ($(window).scrollTop() === 1 && $(window).width() <= 768) {
        $(".iconScrollRight").show();
        $(".iconScrollLeft").show();
        // alert('3')
      }
    });

    $(".nav").on("scroll", function () {
      let val = $(this).scrollLeft();

      if (
        $(this).scrollLeft() + $(this).innerWidth() >=
        $(this)[0].scrollWidth
      ) {
        $(".iconScrollRight").hide();
      } else {
        if ($(window).width() <= 768) {
          $(".iconScrollRight").show();
        }
      }
      if (val === 0) {
        $(".iconScrollLeft").hide();
      } else {
        if ($(window).width() <= 768) {
          $(".iconScrollLeft").show();
        }
      }
    });
    $(".iconScrollRight").on("click", function () {
      if ($(window).width() <= 768) {
        $(".nav").animate({ scrollLeft: "+=150" }, 500);
      }
    });

    $(".iconScrollLeft").on("click", function () {
      if ($(window).width() <= 768) {
        $(".nav").animate({ scrollLeft: "-=150" }, 200);
      }
    });
  }

  toggle(event) {
    const name = event.target.name;
    if (name === "request") {
      this.setState({
        requesttoggle: true,
        rentaltoggle: false,
        carstoggle: false,
        paymentstoggle: false,
        newaccounttoggle: false,
        settings: false,
      });
    }
    if (name === "rental") {
      this.setState({
        requesttoggle: false,
        rentaltoggle: true,
        carstoggle: false,
        paymentstoggle: false,
        newaccounttoggle: false,
        settings: false,
      });
    }
    if (name === "cars") {
      this.setState({
        requesttoggle: false,
        rentaltoggle: false,
        carstoggle: true,
        paymentstoggle: false,
        newaccounttoggle: false,
        settings: false,
      });
    }
    if (name === "payments") {
      this.setState({
        requesttoggle: false,
        rentaltoggle: false,
        carstoggle: false,
        paymentstoggle: true,
        newaccounttoggle: false,
        settings: false,
      });
    }
    if (name === "settings") {
      this.setState({
        requesttoggle: false,
        rentaltoggle: false,
        carstoggle: false,
        paymentstoggle: false,
        newaccounttoggle: false,
        settings: true,
      });
    }
    if (name === "newaccount") {
      this.setState({
        requesttoggle: false,
        rentaltoggle: false,
        carstoggle: false,
        paymentstoggle: false,
        newaccounttoggle: true,
        settings: false,
      });
    }
  }

  getNbHourBeforeTrips = (checkin) => {
    // Recupération de la date de début
    let currentDate = new Date();
    currentDate = moment(currentDate, "DD-MM-YYYY HH:mm");
    let checkInFormatted = moment(checkin, "DD-MM-YYYY HH:mm");
    let hours = checkInFormatted.diff(currentDate, "hours");
    return hours;
  };



  count() {

    let tripsList = this.props.AccountReducer.tripsList;
    let countAsk = 0;
    let countRental = 0;

    for (let i in tripsList) {
      if (
        tripsList[i]?.customerId === localStorage.getItem('siteUser') &&
        this.getNbHourBeforeTrips(tripsList[i]?.checkin) >= 0 && (tripsList[i]?.tripStatus === 3)
      ) {
        // Demande acceptée en cours de paiement          
        countRental++;
      }
    }
    return countRental;
  }

  countRequest() {

    let tripsList = this.props.AccountReducer.tripsListRequest;
    let countAsk = 0;
    // let countRental = 0;

    for (let i in tripsList) {
      if (
        tripsList[i]?.customerId !== localStorage.getItem('siteUser') &&
        this.getNbHourBeforeTrips(tripsList[i]?.checkin) >= 0 && tripsList[i]?.tripStatus === 1
      ) {
        // Demande acceptée en cours de paiement
        countAsk++;
      }
    }
    // console.log("Total des demandes en cours " + countAsk)
    return countAsk;
  }

  render() {
    let carlist = this.props.AccountReducer.carsList;
    // console.log("nombre de véhicule", this.props.AccountReducer)
    return (
      <div>
        <section className="tab-sec">
          <div className="tab-header">
            <div className="container p-0" onClick={(e) => this.toggle(e)}>
              {/* <ul className="nav nav-pills mt-1" id="pills-tab" role="tablist" style={{ background: 'rgb(0, 74, 173, .96)', boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", zIndex: 9999 }} > */}
              <ul
                className="nav nav-pills mt-1"
                id="pills-tab"
                role="tablist"
                style={{
                  background: "white",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: "5px",
                  zIndex: 99,
                }}
              >
                <i className="fas fa-arrow-left iconScrollLeft fa-xs colorLink"></i>
                <i className="fas fa-arrow-right iconScrollRight fa-xs colorLink"></i>
                {carlist?.length > 0 && (
                  <li className="nav-item">
                    <Link
                      title={"Vos demandes en attentes"}
                      to="/booking-request"
                      name="request"
                      className="nav-link tap colorLink"
                      id="pills-home-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected={this.state.requesttoggle ? "true" : "false"}
                    >
                      Demandes
                      <Badge
                        count={this.countRequest()}
                        title={"Vos demandes en attentes"}
                        offset={[0, -20]}
                      ></Badge>
                    </Link>
                  </li>)}
                <li className="nav-item">
                  <Link
                    title={"Vos locations en attentes"}
                    to="/rental"
                    name="rental"
                    className="nav-link tap colorLink"
                    id="pills-profile-tab"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected={this.state.rentaltoggle ? "true" : "false"}
                  >
                    Vos locations
                    <Badge
                      count={this.count()}
                      title={"Vos locations en attentes"}
                      offset={[0, -20]}
                    ></Badge>
                  </Link>
                </li>
                {carlist?.length > 0 && (
                <li className="nav-item">
                  <Link
                    to="/hired-cars"
                    name="cars"
                    className="nav-link tap colorLink"
                    id="pills-contact-tab"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected={this.state.carstoggle ? "true" : "false"}
                  >
                    Vos véhicules
                    <Badge
                      count={0}
                      title={"Votre profile n'est pas verifier"}
                      offset={[0, -20]}
                      dot={true}
                    ></Badge>
                    <i className="fas fa-cars"></i>
                  </Link>
                </li>)}
                <li className="nav-item">
                  <Link
                    to="/payments"
                    name="payments"
                    className="nav-link tap colorLink"
                    id="pills-contact-tab"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected={this.state.paymentstoggle ? "true" : "false"}
                  >
                    Vos paiements
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    title={"Vos demandes en attentes"}
                    to="/account-settings"
                    name="settings"
                    className="nav-link tap colorLink"
                    id="pills-home-tab"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected={this.state.settings ? "true" : "false"}
                  >
                    Paramètres
                    <Badge
                      // count={this.count()[0]}
                      title={"Vos demandes en attentes"}
                      offset={[0, -20]}
                    ></Badge>
                  </Link>
                </li>

                {/* <li className="nav-item">
									<Link to="/modify-profile" name="newaccount" className="nav-link tap" id="pills-contact-tab" role="tab" aria-controls="pills-contact" aria-selected={this.state.newaccounttoggle ? "true" : "false"}>Votre compte</Link>
								</li> */}
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewCars: AC_VIEW_CARS,
    },
    dispatch
  );
}
const TabHeaderComponent = connect(mapStateToProps, mapDispatchToProps)(TabHeader);
export default TabHeaderComponent;
