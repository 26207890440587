import React from "react";
import { Link } from "react-router-dom";

class ListCar extends React.Component{
    render(){
        return(
          <section className="list-car py-5">
	      	<div className="container-fluid">
	      		<div className="row">
	      			<div className="col-lg-6">
						<div className="container">
							<h2 className="heading mt-5">Gagner de l'argent <br /> grâce  à  votre véhicule </h2>
							<p className="para max">Gagnez jusqu'à 500 000 FCFA en louant votre véhicule.</p><p>Vous pouvez tracker votre véhicule via le service proposé par notre partenaire<span className="insure"> ONI</span></p>
							<Link to="/hire-out-car" className="add-car-btn mb-5 mt-5"><i className="fas fa-garage-car"></i>Ajouter un véhicule</Link>
						</div>
	      				
	      			</div>
	      			<div className="col-lg-6">
	      				<div className="owner-img">
	      					<img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/owner_fswwly.jpg" alt=""/>
	      				</div>
	      			</div>
	      		</div>
	      	</div>
	      </section>
        )
    }
}

export default ListCar;
