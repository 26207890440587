/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Redirect } from "react-router-dom";
import { GoogleMap, Marker } from "@react-google-maps/api";
// import ReactStars from "react-rating-stars-component";
// import moment from "moment";
import URL from "../../common/api";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import {
  AC_SEARCH_CAR,
  AC_PRICE_FILTER,
  AC_MODEL_CAR,
  AC_ADD_TRIP,
} from "../../actions/searchcarsaction";
import { getFinalPrice, getTravelDayFromCarDelails } from "../../utils/priceReducer";

class SearchCarDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carId: "",
      car: {},
      reviewList: [],
      rating: [],
      redirect: false,
      backLink: "/search-car",
    };
  }



  componentDidMount() {
    const d = localStorage.getItem('checkIn');
    const f = localStorage.getItem('checkOut');
    // if(d == null || f == null) {
    //   alert("Impossible faire faire les estimations");
    // }
    let carId = window.location.href.split("/")[4];

    this.props.modelCar({ id: carId });
  }

  closemodels() {
    this.setState({
      ...this.state,
      redirect: true,
    });
  }

  estimation = () => {
    const destination = localStorage.getItem('destination');
    const d = localStorage.getItem('checkIn');
    const f = localStorage.getItem('checkOut');
    const car = this.props?.SearchCarsReducer?.modelcarList[0];
  
    // Vérifier si les données de la voiture sont disponibles
    if (!car || !car.price) {
      return 0; // Ou gérer l'erreur de manière plus appropriée
    }
  
    let price = destination ? (car.price.secondPrice || car.price.price) : car.price.price;
  
    const day = getTravelDayFromCarDelails(d, f);
  
    // Vérifier si 'day' est valide. Si non, retourner le prix simple par jour
    if (isNaN(day) || day <= 0) {
      return price; 
    } else {
      price = getFinalPrice(price, day);
      return price * day;
    }
  };
  
 
  preparation(times) {
    if (times === 30) {
      return "30 minutes";
    } else if (times === 60) {
      return "1 heure";
    } else if (times === 120) {
      return "2 heures";
    } else if (times === 180) {
      return "3 heures";
    } else if (times === 360) {
      return "6 heures";
    } else if (times === 720) {
      return "12 heures";
    } else if (times === 1440) {
      return "1 jour";
    } else if (times === 2880) {
      return "2 jours";
    } else if (times === 4320) {
      return "3 jours";
    } else if (times === 10080) {
      return "7 jours";
    }
  }

  render() {
    
    if (this.state.redirect) {
      return <Redirect to={`${this.state.backLink}`} />;
    }

    let car = {};
    let center = {}
    let carRequire = {};
    let otherimages = null;
    let withDriver = false;
    let mobilityArea = false;
    let require = false;    
    if (
      this.props?.SearchCarsReducer &&
      this.props?.SearchCarsReducer?.modelcarList
    ) {
      console.log("car", this.props?.SearchCarsReducer?.modelcarList)
      car = this.props?.SearchCarsReducer?.modelcarList[0];
      if (car?.requirements) {
        withDriver = car?.requirements?.withDriver;
        mobilityArea = car?.requirements?.mobilityArea;
        carRequire = car?.requirements;
        require = true;
      }
      center = {
        lat: car?.parking?.location?.lat,
        lng: car?.parking?.location?.lon,
      };
      //console.log('withDriver::',withDriver);

      otherimages = car?.otherimage.map((image) => (
        <div key={image}>
          <img
            className="d-block w-100"
            style={{ height: 350 + "px", objectFit: "cover" }}
            src={URL.IMAGEURL + image}
            alt="Intérieure"
          />
        </div>
      ));
    }
    
    getTravelDayFromCarDelails(localStorage.getItem("checkIn"), localStorage.getItem("checkOut"))
    // console.log('Le nombre de jour de location est : ' + nbDayTrip)
    const reviewList = this.props.SearchCarsReducer?.reviewList;
    const rating = this.props.SearchCarsReducer?.rating;
    // const destination = localStorage.getItem('destination')
    // console.log(localStorage.getItem("checkOut"))
    
    
    return (
      <div className="address-full">
        <div className="main-body">
          <div className="modal-body">
            <div className="modal-section">
              <div className="container mt">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="carousselZone">
                      <Carousel
                        showThumbs={false}
                        emulateTouch={true}
                        swipeable={true}
                        infiniteLoop={true}
                        showStatus={false}
                      >
                        {car?.carimage?.mainimage !== "" && (
                          <div>
                            <img
                              className="d-block w-100"
                              style={{ height: 350 + "px", objectFit: "cover" }}
                              src={URL.IMAGEURL + car?.carimage?.mainimage}
                              alt="Principale"
                            />
                          </div>
                        )}

                        {car?.carimage?.sideimage !== "" && (
                          <div>
                            <img
                              className="d-block w-100"
                              style={{ height: 350 + "px", objectFit: "cover" }}
                              src={URL.IMAGEURL + car?.carimage?.sideimage}
                              alt="De profil"
                            />
                          </div>
                        )}

                        {car?.carimage?.interiorimage !== "" && (
                          <div>
                            <img
                              className="d-block w-100"
                              style={{ height: 350 + "px", objectFit: "cover" }}
                              src={URL.IMAGEURL + car?.carimage?.interiorimage + ".jpg"}
                              alt="Intérieure"
                            />
                          </div>
                        )}

                        {/* {car?.carimage?.extraimage !== "" ? (
                          <div>
                            <img
                              className="d-block w-100"
                              style={{ height: 350 + "px", objectFit: "cover" }}
                              src={URL.IMAGEURL + car?.carimage?.extraimage}
                              alt="Extérieur"
                            />
                          </div>
                        ) : (
                          ""
                        )} */}

                        {/* {car?.carimage?.extraimage !== "" && (
                          <div>
                            <img
                              className="d-block w-100"
                              style={{ height: 350 + "px", objectFit: "cover" }}
                              src={URL.IMAGEURL + car?.carimage?.extraimage}
                              alt="Extérieur"
                            />
                          </div>
                        )} */}

                        {car?.carimage?.backimage !== "" && (
                          <div>
                            <img
                              className="d-block w-100"
                              style={{ height: 350 + "px", objectFit: "cover" }}
                              src={URL.IMAGEURL + car?.carimage?.backimage}
                              alt="Arrière"
                            />
                          </div>
                        )}

                        {otherimages}
                      </Carousel>
                    </div>

                    <div className="carInfo">
                      <div className="car-header">
                        <h1 className="car-name">
                          {car?.carmodel?.make?.brandname}{" "}
                          {car?.carmodel?.model?.name} -{" "}
                          {car?.carmodel?.model?.description}
                        </h1>
                        <div className="car-details">
                          <div className="car-text">
                            <div className="car-icon">
                              {/* <i className="fas fa-map-marker"></i> */}
                              {/* <i className="fa fa-map-marker map-icon-size"></i> */}
                              <i className="fas fa-map-marker-alt fa-lg"></i>
                            </div>
                            <span className="car-attri">
                              {/*0.99
                            <span  className="dot"></span>*/}
                              {car?.number?.year}
                              <span className="dot"></span>
                              {car?.facilities?.seats} sièges
                            </span>
                          </div>
                          {rating ? (
                            <div className="peugeot-car-star">
                              {new Array(rating).fill(0).map((item, index) => (
                                <span className="car-count" key={index}>
                                  <i className="fa fa-star star-icon-size"></i>
                                </span>
                              ))}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {car?.snapshot && (
                          <div className="car-high">
                            <div className="car-group">
                              <div className="car-group-item">
                                <div className="car-pill">
                                  <span className="car-pill-icon">
                                    <span className="car-icon">
                                      <i className="fa fa-bolt bolt-icon-size"></i>
                                    </span>
                                  </span>
                                  Réservation instantanée
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="connect mb">
                      <div className="connect-section">
                        <div className="open-section">
                          <div className="open-section-icon">
                            <div className="open-icon">
                              <i className="fa fa-car car-icon-size"></i>
                            </div>
                          </div>
                          <div className="car-content">
                            <div className="big-text">Monbolide Plus</div>
                            <div className="small-text">
                              C'est une voiture en libre-service !
                            </div>
                          </div>
                          <a href="#" className="flex">
                            <div className="flex-icon">
                              <i className="fa fa-exclamation-circle info-icon-size"></i>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div> */}
                    <div className="location mb">
                      <div className="location-section">
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <div className="location-text">
                              Lieu de récupération et de dépot
                            </div>
                            <div className="location-address">
                              <div className="location-icon">
                                <i className="fas fa-map-marker-alt fa-lg"></i>
                              </div>
                              <div className="location-address-content">
                                <div className="location-address-text">
                                  Proche de
                                </div>
                                <div className="location-address-body">
                                  <div className="body-text">
                                    {car?.parking?.formatted_address}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="location-explan">
                              <div className="location-note">
                                La voiture sera garée à proximité. Vous
                                obtiendrez l'emplacement exact après la
                                réservation.
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <div className="car-map">
                              <GoogleMap
                                mapContainerStyle={{
                                  width: "100%",
                                  height: "300px",
                                  borderRadius: "10px",
                                }}
                                center={center}
                                zoom={40}
                              >
                                <Marker
                                  animation={window.google.maps.Animation.DROP}
                                  // icon={location.icon}
                                  // key={index}
                                  position={center}
                                  // onClick={() => setSelected(location)}
                                  title={
                                    car?.carmodel?.make?.brandname +
                                    " " +
                                    car?.carmodel?.model?.name
                                  }
                                />
                                {/* Child components, such as markers, info windows, etc. */}
                                <></>
                              </GoogleMap>
                              {/* </LoadScript> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mileage mb">
                      <div className="mileage-section">
                        <div className="mileage-text">Kilométrage inclus</div>
                        <div className="mileage-cell">
                          <div className="mileage-cell-left">
                            <div className="cell-icon">
                              {/* <i className="fa fa-map-marker map-icon-size-3"></i> */}
                              <i className="fas fa-map-marker-alt fa-lg"></i>
                            </div>
                          </div>
                          <div className="mileage-main">
                            <div className="mileage-title">
                              <div className="mileage-big-text">
                                {car?.mileage?.mileage}
                              </div>
                            </div>
                            {/* <div className="mileage-small-text">
                            Prix: {car?.price?.price} XOF/mi
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="owner mb">
                      <div className="owner-section">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="owner-text">Propriétaire</div>                            
                            <a
                              href=""
                              className="car-owner"
                              alt="Ajouter une voiture"
                            >
                              <div className="car-owner-img">
                                {car?.userId?.image ? <img
                                  src={URL.IMAGEURL + car?.userId?.image}
                                  alt="Utilisateur"
                                />: <img
                                src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/images/owner_j4ymyy.png"
                                alt="Utilisateur"
                              />}
                                
                              </div>
                              <div>
                                <div className="owner-big-text">
                                  <span>
                                    <span className="link">
                                      {car?.userId?.firstname}{" "}
                                      {car?.userId?.lastname}
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  <div className="peugeot-car-star">
                                    {/* {ratingArray} */}
                                    <div className="rating-count">
                                      <div className="rating-value">
                                        {reviewList && reviewList.length > 0
                                          ? reviewList.length
                                          : "Aucun"}
                                      </div>
                                      <div className="rating-label"> avis</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          {require  ? 
                            <div className="col-md-8 shadow rounded-3 bg-white">
                              <div className="owner-text">
                                Exigences du propriétaire
                              </div>
                              <div className="">
                                <div>
                                  {(carRequire?.hireMinDay ||
                                    carRequire?.hireMaxDay) ? (
                                      <div className="d-flex my-4">
                                        <div className="option-icon">
                                          <i className="fa fa-hand-o-right text-danger option-icon-size"></i>
                                        </div>
                                        <strong>Durée de location:&nbsp;</strong>
                                        <div className="min">
                                          {carRequire?.hireMinDay && <span>&nbsp;{carRequire?.hireMinDay} </span>} à
                                        </div>
                                        <div className="max">
                                          {carRequire?.hireMaxDay && <span>&nbsp;{carRequire?.hireMaxDay} jours </span>}
                                        </div>
                                      </div>
                                    ):<div>{""}</div>}
                                  {carRequire?.preparationTimeCar ? (
                                    <div className="d-flex my-4">
                                      <div className="option-icon">
                                        <i className="fa fa-hand-o-right text-danger option-icon-size"></i>
                                      </div>
                                      <strong>
                                        Temps de préparation avant livraison :
                                      </strong>
                                      <div className="mx-4">
                                        <div className="prepareTimes">
                                          <span>
                                            {this.preparation(
                                              carRequire?.preparationTimeCar
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ): <></>}
                                  <div className="d-flex my-4">
                                    <div className="option-icon">
                                      <i className="fa fa-hand-o-right text-danger option-icon-size"></i>
                                    </div>
                                    <strong>
                                      Zone de mobilité du véhicule:
                                    </strong>
                                    <div className="mx-4">
                                      <div className="mobilityArea">
                                        <span>{mobilityArea
                                          ? "Je suis flexible sur le sujet"
                                          : "Dans ma ville de résidence uniquement"}</span> 
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex my-4">
                                    <div className="option-icon">
                                      <i className="fa fa-hand-o-right text-danger option-icon-size"></i>
                                    </div>
                                    <strong>
                                      Type de location :
                                    </strong>
                                    <div className="mx-4">
                                      <div className="withDriver">
                                        <span>{withDriver
                                          ? "avec chauffeur"
                                          : "avec ou sans chauffeur"}</span>
                                      </div>
                                    </div>
                                  </div>
                                  {carRequire?.hireRequire && <div className="d-flex my-4">
                                    <blockquote style={{ fontStyle: 'italic', borderLeft: "5px solid rgb(243 244 246)", padding: "1em 1em 1em 2em", margin: "1em 0" }}>
                                      {carRequire?.hireRequire}
                                    </blockquote>
                                  </div>}
                                </div>
                              </div>
                            </div>: <div>{""}</div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="features-option">
                      <div className="features-wrapper my-2">
                        <div className="features-card">
                          <div className="features-section">
                            <div className="features-header">
                              Caractéristiques techniques
                            </div>
                            <div className="car-features">
                              <div className="car-features-group">
                                <div className="features-mb">
                                  <div className="features-text">Moteur</div>
                                  <p className="features-body">
                                    {car?.fuel?.fuel}
                                  </p>
                                </div>
                                <div>
                                  <div className="features-text">
                                    Mileomètre
                                  </div>
                                  <p className="features-body">
                                    {car?.mileage?.mileage}
                                  </p>
                                </div>
                              </div>
                              <div className="car-features-group">
                                <div className="features-mb">
                                  <div className="features-text">
                                    Boite de vitesse
                                  </div>
                                  <p className="features-body">
                                    {car?.fuel?.type}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {car?.feature ? (
                      <div className="options-wrapper my-2">
                        <div className="features-card rental-button-space">
                          <div className="features-section">
                            <div className="features-header">
                              Options et accessoires
                            </div>
                            <div className="car-option">
                              <div className="car-group">
                                {car?.feature &&
                                  Object.keys(car?.feature).map(
                                    (item, index) => (
                                      <div className="show-option" key={index}>
                                        <div className="option-icon">
                                          <i className="fa fa-star option-icon-size"></i>
                                        </div>
                                        <div className="option-content">
                                          {item}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>) : <></>}
                    </div>

                    <div
                      className="review mb"
                      style={{ display: reviewList.length ? "block" : "none" }}
                    >
                      <div className="review-section">
                        <div className="review-header">Avis</div>
                        <div className="text-mute">
                          <div className="inline-block">
                            <div className="rentals-count">
                              <div className="review-value">
                                {reviewList?.length}
                              </div>
                              <div className="review-label"> locations</div>
                            </div>
                          </div>
                          -
                          <div className="inline-block">
                            <div>
                              <div className="rate-container">
                                <div className="peugeot-car-star">
                                  {/* {ratingArray} */}
                                  <div className="ratings-count-value">
                                    {reviewList?.length}
                                  </div>
                                  <div className="ratings-count-label">
                                    {" "}
                                    avis
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="infinite-page">
                          {reviewList.map((item, index) => (
                            <div className="rental-review" key={index}>
                              <div className="review-img">
                                <img
                                  src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/images/owner_j4ymyy.png"
                                  alt="review-img"
                                />
                              </div>
                              <div className="rental-review-content">
                                <div className="rental-review-info">
                                  <div className="big-text">
                                    {item?.customerId?.firstname +
                                      " " +
                                      item?.customerId?.lastname}
                                  </div>
                                </div>
                                <div className="rental-review-text">
                                  <p>{item?.review}</p>
                                </div>
                                <div className="peugeot-car-star">
                                  {/* {ratingArray} */}
                                  <div className="bottom-margin"></div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          className="rental-button"
                          style={{ display: "none" }}
                        >
                          <div className="rental-button-item">
                            <button type="button" className="rental-btn">
                              En savoir plus...
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 text-center">
                    <div className="book">
                      <span className="value">{this.estimation() + " FCFA"}</span>
                      {/* <span className="mi">
                      {car?.mileage?.mileage} inclus, puis{" "}
                      {car?.price?.price} XOF/mi
                    </span> */}
                      <br />
                      <Link
                        to={"/book-car/" + car?._id}
                        type="button"
                        className="book-btn mt-2"
                        style={{ backgroundColor: "#004AAD" }}
                      >
                        <span className="book-btn-icon">
                          <span className="book-icon">
                            <i className="fa fa-bolt bolt-icon-size text-white"></i>
                          </span>
                        </span>
                        <span className="text-white">Louer</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="close-btn close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.closemodels()}
              ></div>
            </div>
          </div>
        </div>
        <div className="rental-button-space"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    SearchCarsReducer: state.SearchCarsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchCar: AC_SEARCH_CAR,
      priceFilter: AC_PRICE_FILTER,
      modelCar: AC_MODEL_CAR,
      addTrip: AC_ADD_TRIP,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCarDetail);
