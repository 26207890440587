import Geocode from "react-geocode";
// import React, { Component } from 'react';
// Récupérer une ville depuis une adresse formatée
export function getCityFromPlace(place) {
    let city = ""
    const length = place?.address_components?.length ? place.address_components.length : -1
    if (length > 0) {
        for (let i = 0; i < place.address_components.length; i++) {
            for (let j = 0; j < place.address_components[i].types.length; j++) {
                // eslint-disable-next-line default-case
                switch (place.address_components[i].types[j]) {
                    case "locality":
                        city = place.address_components[i].long_name;
                        break;
                }
            }
        }
    } else {
        return city
    }

    return city;
}




export function geoLocation() {
    // Geocode.setApiKey("AIzaSyDhufU1t7jo40lgxjBvlUyn9d5aWkejp-A");
    // Geocode.setLanguage("fr");
    navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position.coords)     

        if (position) {
            // this.setState({lat: position.coords.latitude, long: position.coords.longitude})
            // localStorage.setItem("currentLat", position.coords.latitude)
            // localStorage.setItem("currentLong", position.coords.longitude)
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    // console.log(response.results[0])
                    // console.log(response.results)
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            // eslint-disable-next-line default-case
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    // console.log(city, state, country);
                    // ville/pays/lat/log
                    localStorage.setItem('userAdress', address)
                    localStorage.setItem('userCity', city)
                },
                (error) => {
                    console.error(error);
                }
            );
        }



    })

};


export function getFormattedAdress(place) {    
    let city = ""
    let state = ""    
    var addressName = ""
    for (let i = 0; i < place.address_components.length; i++) {
        for (let j = 0; j < place.address_components[i].types.length; j++) {
            // eslint-disable-next-line default-case

            // console.log(place.address_components[i].types[j])

            // eslint-disable-next-line default-case
            switch (place.address_components[i].types[j]) {
                case "locality":
                    city = place.address_components[i].long_name || "";
                    break;
                case "sublocality_level_1":
                    state = place.address_components[i].long_name || "";
                    break;
                // case "country":
                //     country = place.address_components[i].long_name || "";
                //     break;
            }
            // console.log()            
        }

    }
    addressName = place.name
    if(state) {
        addressName = addressName + ", " + state
    }
    if(city) {
        addressName = addressName + ", " + city
    }
    return addressName
}