import { toast } from 'react-toastify';

export function Success(message) {
  toast.success(message,{
    position: "top-right",
    icon: true,
    autoClose:5000,    
    // style: { backgroundColor: "#fff", color: '#1E1E1E' },
  });
}

export function Error(message) {
  toast.error(message,{position: "top-right"})
}

// Au Charngement d'une page, Pour une action en cours
// export function Loader() {
//   return (
//     <div id="loader" className='' style={{ height:'100vh', width:'100%', backgroundColor: 'lightblue', color: 'white',position: 'fixed', top: 0, left:0, zIndex: 9999999999999}}>Chargement en cours...</div>
//   )
// }