import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Avatar } from "antd";

class MyAccountNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modifyprofiletoggle: false,
      verifyprofiletoggle: false,
      accountsettingstoggle: false,
      notificationstoggle: false,
      getcredittoggle: false,
      bellingsettingtoggle: false,
    };
  }
  componentDidMount() {
    const path = this.props.path.slice(1);
    var name = path + "toggle";
    if (path === "get-credit") {
      name = "getcredittoggle";
    }
    if (path === "verify-profile") {
      name = "verifyprofiletoggle";
    }
    if (path === "account-settings") {
      name = "accountsettingstoggle";
    }
    if (path === "modify-profile") {
      name = "modifyprofiletoggle";
    }

    if (path === "billing-settings") {
      name = "billingSettings";
    }
    // console.log(path)
    this.setState({ [name]: true });
  }
  toggle(event) {
    const name = event.target.name;
    // console.log('Event.. toggle...')
    if (name === "modifyprofile") {
      this.setState({
        bellingsettingtoggle: false,
        modifyprofiletoggle: true,
        verifyprofiletoggle: false,
        accountsettingstoggle: false,
        notificationstoggle: false,
        getcredittoggle: false,
      });
    }
    if (name === "verifyprofile") {
      this.setState({
        bellingsettingtoggle: false,
        verifyprofiletoggle: true,
        modifyprofiletoggle: false,
        accountsettingstoggle: false,
        notificationstoggle: false,
        getcredittoggle: false,
      });
    }
    if (name === "accountsettings") {
      this.setState({
        bellingsettingtoggle: false,
        accountsettingstoggle: true,
        modifyprofiletoggle: false,
        verifyprofiletoggle: false,
        notificationstoggle: false,
        getcredittoggle: false,
      });
    }
    if (name === "notifications") {
      this.setState({
        bellingsettingtoggle: false,
        notificationstoggle: true,
        modifyprofiletoggle: false,
        verifyprofiletoggle: false,
        accountsettingstoggle: false,
        getcredittoggle: false,
      });
    }
    if (name === "getcredit") {
      this.setState({
        bellingsettingtoggle: false,
        getcredittoggle: true,
        modifyprofiletoggle: false,
        verifyprofiletoggle: false,
        accountsettingstoggle: false,
        notificationstoggle: false,
      });
    }

    if (name === "billingSettings") {
      this.setState({
        bellingsettingtoggle: true,
        getcredittoggle: false,
        modifyprofiletoggle: false,
        verifyprofiletoggle: false,
        accountsettingstoggle: false,
        notificationstoggle: false,
      });
    }
  }

  render() {
    const userInfo = this.props?.AccountReducer?.accountInfo;
    const userVerified = userInfo?.verification;
    let pay = false;

    if (
      userInfo?.ownercard === undefined ||
      userInfo?.iban === undefined ||
      userInfo?.mobilemoney === undefined
    ) {
      pay = false;
    } else pay = true;
    return (
      <div className="col-lg-4 p-0">
        <div
          className="nav flex-column nav-pills px-2 py-2"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
          onClick={(e) => this.toggle(e)}
          style={{
            backgroundColor: "white",
            zIndex: 999999,
            borderRadius: "15px",
            boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
          }}
        >
          {/* <Link to="/modify-profile" name="modifyprofile" className={this.state.modifyprofiletoggle?"nav-link active":"nav-link"} id="v-pills-home-tab"  role="tab" aria-controls="v-pills-home" aria-selected="true">Modification du profile</Link> */}
          <Link
            to="/verify-profile"
            name="verifyprofile"
            className={
              this.state.verifyprofiletoggle ? "nav-link active" : "nav-link"
            }
            id="v-pills-profile-tab"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            <i className="fas fa-id-badge mr-2"></i> Vérification du profile
            <Badge
              count={userVerified ? 0 : 1}
              title={"Votre profile n'est pas verifier"}
              offset={[5, -13]}
              dot={true}
            ></Badge>
          </Link>
          <Link
            to="/account-settings"
            name="accountsettings"
            className={
              this.state.accountsettingstoggle ? "nav-link active" : "nav-link"
            }
            id="v-pills-messages-tab"
            role="tab"
            aria-controls="v-pills-messages"
            aria-selected="false"
          >
            <i className="fas fa-cogs mr-2"></i> Paramétrage du compte
          </Link>
          <Link
            to="/billing-settings"
            name="billingSettings"
            className={
              this.state.bellingsettingtoggle ? "nav-link active" : "nav-link"
            }
            id="v-pills-settings-tab"
            role="tab"
            aria-controls="v-pills-settings"
            aria-selected="false"
          >
            <i className="fas fa-money-bill-alt mr-2"></i> Paramétrage de
            paiement{" "}
            <Badge
              count={pay ? 0 : 1}
              title={"Vos paramètre de payement ne sont pas complète"}
              offset={[5, -13]}
              dot={true}
            ></Badge>
          </Link>
          <Link
            to="/notifications"
            name="notifications"
            className={
              this.state.notificationstoggle ? "nav-link active" : "nav-link"
            }
            id="v-pills-settings-tab"
            role="tab"
            aria-controls="v-pills-settings"
            aria-selected="false"
          >
            <i className="fas fa-bell-slash mr-2"></i>Notifications{" "}
          </Link>
          <Link
            to="/logout"
            name="logout"
            className="nav-link"
            id="v-pills-settings-tab"
            role="tab"
            aria-controls="v-pills-settings"
            aria-selected="false"
          >
            <i className="fas fa-sign-out-alt mr-2"></i>Déconnexion
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    AccountReducer: state.AccountReducer,
  };
}
const MyAccountNavigationComponent =
  connect(mapStateToProps)(MyAccountNavigation);
export default MyAccountNavigationComponent;
