import React from "react";
import {
  AC_EDIT_CAR_REQUIREMENT,
  AC_YOUR_CAR,
  AC_HANDLE_CAR_REQUIRE,
} from "../../actions/carsaction";
import { AC_VIEW_TRIPS, AC_VIEW_CAR_TRIPS } from "../../actions/accountaction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, InputNumber } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

class ConditionSection extends React.Component {
  constructor(props) {
    super(props);
    //console.log( this.props.CarsReducers)
    this.state = {
      //date de location min
      hireMinDay:
        this.props?.CarsReducers?.carList?.requirements?.hireMinDay || null,
      //date de location max
      hireMaxDay:
        this.props?.CarsReducers?.carList?.requirements?.hireMaxDay || null,
      //temps de preparation apres location du véhicule
      preparationTimeCar:
        this.props?.CarsReducers?.carList?.requirements
          ?.preparationTimeCar || null,
      //zone de mobilité du véhicule après location
      mobilityArea:
        this.props?.CarsReducers?.carList?.requirements?.mobilityArea ||
        false,
      //voiture louer avec chauffeur only
      withDriver:
        this.props?.CarsReducers?.carList?.requirements?.withDriver || false,
      //
      hireRequire:
        this.props?.CarsReducers?.carList?.requirements?.hireRequire || null,

      //error
      errorMin: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.ViewCar({ id: localStorage.getItem("carId") });
    this.props.ViewCarTrips({ carId: localStorage.getItem("carId") });
  }


  handleSubmit = (e) => {
    const form = {
      id: localStorage.getItem("carId"),
      "requirements.hireMinDay": this.state.hireMinDay,
      "requirements.hireMaxDay": this.state.hireMaxDay,
      "requirements.preparationTimeCar": this.state.preparationTimeCar,
      "requirements.mobilityArea": this.state.mobilityArea,
      "requirements.withDriver": this.state.withDriver,
      "requirements.hireRequire": this.state.hireRequire,
    };
    if (this.state.hireMaxDay !== 0 && this.state.hireMinDay > this.state.hireMaxDay && this.state.hireMaxDay !== null ) {
      this.setState({ errorMin: true });
    } else {
      this.setState({ errorMin: false });
      this.props.EditRequireCar(form).then(() =>  this.props.ViewCar({ id: localStorage.getItem("carId")}));
    }
  };

  error() {
    if (this.state.hireMaxDay !== 0 && this.state.hireMinDay > this.state.hireMaxDay && this.state.hireMaxDay !== null) {
      this.setState({ errorMin: true });
    } else {
      this.setState({ errorMin: false });
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  render() {
    //console.log(this.state.s);
    // console.log('dirver', this.state.withDriver);
    return (
      <div className="condition list-car-card px-3 py-3">
        <h2 className="sub-title mb-16">
          Rappel des conditions déjà requises par Monbolide
        </h2>
        <div className="content-block">
          <li>
            Pour votre <strong>véhicule de catégorie économique</strong>: 21 ans
            et 2 ans de conduite minimum.
          </li>
          <li>
            Les véhicules ne peuvent être conduits que dans les pays suivants :
            Côte d'Ivoire.
          </li>
        </div>
        <h2 className="sub-title mb-16">
          Définissez les conditions de location de votre véhicule
        </h2>
        <div className="mt-20">
          <form className="condition-form">
            <div className="mb-35">
              <div className="row align-items-center">
                <div className="col-lg-4 " style={{ paddingLeft: "2.5rem" }}>
                  <div className="form-title">Durée de la location <span style={{	color: "rgb(156 163 175)"}}>(jour)</span></div>
                </div>
                <div className="col-lg-4">
                  <div className="form-slider">
                    <div className="row align-items-center justify-content-start">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <InputNumber                          
                            name={"hireMinDay"}
                            title={"Temps de location minimum"}
                            className="form-control"
                            min={0}
                            max={30}
                            required
                            style={{fontSize: "14px"}}
                            defaultValue={this.state.hireMinDay}
                            placeholder="Au moins"
                            onChange={(e) => {
                              this.setState({ hireMinDay: e });
                            }}
                            onBlur={(e) => {
                              //console.log(this.state.hireMinDay);
                              this.error();
                              if (this.state.hireMinDay >= 2) {
                                this.setState({ sMin: true });
                              } else {
                                this.setState({ sMin: false });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <InputNumber
                            //type="number"
                            name={"hireMaxDay"}
                            title={"Temps de location maximum"}
                            className="form-control"
                            required
                            min={0}
                            max={30}
                            placeholder="Au plus"
                            defaultValue={this.state.hireMaxDay}
                            style={{fontSize: "14px"}}
                            onChange={(e) => {
                              this.setState({ hireMaxDay: e });
                            }}
                            onBlur={(e) => {
                              //console.log(this.state.hireMaxDay);
                              this.error();
                              if (this.state.hireMaxDay >= 2) {
                                this.setState({ sMax: true });
                              } else {
                                this.setState({ sMax: false });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {this.state.errorMin ? (
                      <label className="text-danger">
                        Le nombre de jours minimum ne doit pas être pas
                        supérieur à la date max
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-line">
                    Votre voiture peut être louer pendant combien de jours au
                    minimum et au maximum sachant que vous ne devez pas excéder 30
                    jours ?
                  </div>
                </div>
              </div>
            </div>
          </form>
          <form className="condition-form">
            <div className="mb-35">
              <div className="row align-items-center">
                <div className="col-lg-4 " style={{ paddingLeft: "2.5rem" }}>
                  <div className="form-title">Temps de Préparation</div>
                </div>
                <div className="col-lg-4">
                  <div className="form-slider">
                    <div className="form-group">
                      <select
                        id="inputState"
                        className="form-control"
                        name="preparationTimeCar"
                        defaultValue={this.state.preparationTimeCar}
                        onChange={this.handleChange}
                      >
                        <option>Demandes de dernière minute acceptées</option>
                        <option value={30}>30 minutes </option>.
                        <option value={60}>1 heure </option>
                        <option value={120}>2 heures </option>
                        <option value={180}>3 heures </option>
                        <option value={360}>6 heures </option>
                        <option value={720}>12 heures </option>
                        <option value={1440}>1 jour </option>
                        <option value={2880}>2 jours </option>
                        <option value={4320}>3 jours </option>
                        <option value={10080}>7 jours </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-line">
                    Nous recommandons d'accepter les demandes de dernière
                    minute, mais vous êtes libre de préciser que vous avez
                    besoin d'un préavis.
                  </div>
                </div>
              </div>
            </div>
          </form>
          <form className="condition-form">
            <div className="mb-35">
              <div className="row align-items-center">
                <div className="col-lg-4 " style={{ paddingLeft: "2.5rem" }}>
                  <div className="form-title">Zone Mobilité du véhicule</div>
                </div>
                <div className="col-lg-4">
                  <div className="form-slider">
                    <div className="d-flex align-items-center">
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked = {this.state.mobilityArea}
                        onChange={(e) => {
                          //console.log(e);
                          this.setState({ mobilityArea: e });
                        }}                                 
                      />
                      <div className="form-check-label" style={{color: "rgb(75 85 99)"}}>
                        {this.state.mobilityArea
                          ? "Mon véhicule  sort  de ma ville de résidence"
                          : "Mon véhicule ne sort pas de ma ville de résidence"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-line">
                    Si vous voulez que votre véhicule ne sorte pas de votre
                    ville de résidence alors cocher la case ou laisser la case
                    tel quel pour que votre véhicule puis aller n'import où en
                    en Côte d'Ivoire.
                  </div>
                </div>
              </div>
            </div>
          </form>
          <form className="condition-form">
            <div className="mb-35">
              <div className="row align-items-center">
                <div className="col-lg-4 " style={{ paddingLeft: "2.5rem" }}>
                  <div className="form-title">
                    Mise à disposition de chauffeur
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-slider">
                    <div className="d-flex align-items-center">
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked = {this.state.withDriver}
                        onChange={(e) => {
                          this.setState({ withDriver: e });
                        }}                        
                      />
                      <div className="form-check-label">
                        {this.state.withDriver
                          ? "Avec chauffeur"
                          : "avec ou sans chauffeur"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-line">
                    Dites-nous vous mettez votre véhicule à disposition avec
                    chauffeur ou vous êtes flexible sur le sujet.
                  </div>
                </div>
              </div>
            </div>
          </form>
          <form className="condition-form">
            <div className="mb-35">
              <div className="row align-items-center">
                <div className="col-lg-4 " style={{ paddingLeft: "2.5rem" }}>
                  <div className="form-title">Exigences particulières</div>
                </div>
                <div className="col-lg-4">
                  <div className="form-slider">
                    <div className="form-group">
                      <textarea
                        name="hireRequire"
                        className="form-control"
                        rows="3"
                        onChange={this.handleChange}
                        placeholder="Ex: Le client devrait avoir 21 ans au moins "
                      >
                        {this.state.hireRequire}
                      </textarea>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-line">
                    Êtes-vous facile à vivre ou obsessionnel de la propreté ?
                    Acceptez-vous les animaux de compagnie ?
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="submit">
            {!this.state.errorMin ? (
              <button
                type="submit"
                className="btn btn-save-requirements col-lg-6 col-sm-12 py-3"
                onClick={this.handleSubmit}
                style={{borderRadius: "12px", border: "none",fontWeight:'bold',letterSpacing: "2px", textTransform: "uppercase",  background:"rgb(0, 74, 173)"}}
              >
                Enregistrer
              </button>
            ) : (
              <div className="text-danger">Veuillez Corriger l'Erreur</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ViewTrips: AC_VIEW_TRIPS,
    ViewCarTrips: AC_VIEW_CAR_TRIPS,
    CarsReducers: state.CarsReducers,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      /*AddRequireCar: AC_ADD_REQUIRE_CAR */
      ViewCar: AC_YOUR_CAR,
      EditRequireCar: AC_EDIT_CAR_REQUIREMENT,
      HandleRequireCar: AC_HANDLE_CAR_REQUIRE,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ConditionSection);
