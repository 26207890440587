import React, { useState, useEffect } from "react";
import { Rate } from 'antd';
import 'antd/dist/reset.css';


const UserComment = (props) => {
    return (
        <div className="flex-container">
            <div className="row">
              <div className="col-12">
                <div className="flex-box">
                  <div className="card-img">
                    <img
                      src={props.profil}
                      alt={props.alt}
                    />
                  </div>                                  
                  <div className="card-body text-center">
                    <h6 className="card-title review-name text-white">{props.name}</h6>
                    <Rate disabled defaultValue={props.rating}  />
                    <div className="card-text text-white" style={{fontSize: "1.5rem"}}>
                        <cite>``{props.description}``</cite><br /> 
                        
                    </div>

                   
                  </div>
                </div>
              </div>

           
            </div>
          </div>
    )
}

export default UserComment;