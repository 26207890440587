import React from "react";
import 'antd/dist/reset.css';
import PropTypes from "prop-types";
import { Modal,Collapse, Tabs} from 'antd';


export default class BeginModal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    const { Panel } = Collapse;
    const { TabPane } = Tabs;
    return (
      <>
        <Modal 
        title="Pour	commencer" visible={this.props.showBegin}
        centered={true}
        width={1000}
        footer={null}
        onCancel={this.onClose} >
            <div class="modalText">
                <Collapse bordered={false}>
                    <Panel header="Guide louer une voiture sur mon bolide" key="1">
                        <div>   
                            <p >
                                Il existe deux types de location sur mon bolide :
                            </p>
                            <p >
                                Les locations avec contact direct du propriétaire : rencontrez le
                                propriétaire de la voiture. Il devra vérifier vos documents et il vous
                                donnera les clés du véhicule.
                            </p>
                            <p >
                                Les locations sans contact du propriétaire : le véhicule vous sera remis
                                par un agent de mon bolide. Il s’occupera de vérifier vos documents et de
                                vous remettre les clés du véhicule.
                            </p>
                            <p >
                                Pour démarrer, vous devrez créer votre compte MONBOLIDE : c'est simple et
                                rapide. Nous aurons besoin d'informations comme votre nom, votre numéro de
                                permis ou sa date d'obtention.
                            </p>
                            <p >
                                Une fois votre compte créé, louez votre véhicule en quelques étapes :
                            </p>
                            <ol class="p-4">
                                <li>
                                    <p  align="justify">
                                        <strong>Cherchez une voiture : </strong>
                                        Choisissez les voitures autour de vous, à une adresse en
                                        particulier ou aux alentours d'une gare. Vous pouvez également
                                        filtrer les voitures par type de véhicule ou selon leurs
                                        équipements.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        <strong>Choisissez une voiture : </strong>
                                        Si vous choisissez une voiture avec la réservation instantanée
                                        votre demande sera acceptée automatiquement. Dans le cas contraire,
                                        envoyez une demande au propriétaire
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        <strong>Vérifiez votre profil </strong>
                                        (si nécessaire) : Pour certaines locations vous devrez vérifier
                            votre profil avant de prendre la voiture. Vous <a href="/profile" target="_blank">
                <u>trouverez plus de détails sur la vérification du profil ici</u></a>.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        <strong>Récupérez la voiture : </strong>
                                        S'il s'agit d'une location MONBOLIDE sans contact, retrouver
                                        l’agence la plus proche pour récupérer le véhicule. S'il s'agit
                                        d'une location MONBOLIDE avec contact du propriétaire, retrouvez le
                                        propriétaire pour qu'il vous remette les clés.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        <strong>Rendez la voiture : </strong>
                                        Ramenez la voiture à l'endroit prévu avec le même niveau de
                                        carburant qu'en début de location.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        <strong>Évaluez la location et la voiture : </strong>
                                        Laissez une évaluation publique sur votre location et sur la
                                        voiture ou bien un commentaire privé pour le propriétaire.
                                    </p>
                                </li>
                                </ol>
                                <p  align="left">
                                    Une fois la location confirmée et payée, voici les étapes à suivre
                                    pour le bon déroulement de la location :
                                </p>
                                    <ul class="p-4">
                                        <li>
                                            <p  align="left">
                                                Arrivez à l'heure au RDV.
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Présentez spontanément tous les documents nécessaires au
                                                propriétaire (<em>ou a l’agent MONBOLIDE) </em>: l'original
                                                de votre permis de conduire, ainsi que le moyen de paiement
                                                à votre nom. Si vous avez un permis étranger, des documents
                                                supplémentaires peuvent être nécessaires.
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                            Complétez le contrat de location avec le propriétaire et                    <strong>signez-le.</strong>
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Si vous êtes fumeurs ou que vous souhaitez transporter un
                                                animal, assurez-vous d’avoir l’accord du propriétaire -
                                                sinon, des frais seraient appliqués.
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                N'oubliez pas de faire l'appoint de carburant à la fin,
                                                avant de restituer le véhicule au propriétaire pour le
                                                rendre avec le même niveau de jauge qu’au début de la
                                                location.
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Restituez le véhicule dans le même état de propreté que
                                                vous l’avez reçu - et si vous l’avez un peu sali,
                                                nettoyez-le avant de le rendre au propriétaire.
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Complétez à nouveau le contrat de location à la restitution
                                                du véhicule, procédez aux ajustements kilométriques et de
                                                carburant avec le propriétaire si nécessaire, et signez le
                                                contrat.
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Evaluez la location pour partager votre expérience et aider
                                                les prochains locataires à choisir un véhicule sur
                                                MONBOLIDE.
                                            </p>
                                        </li>
                                    </ul>
                        </div>
                    </Panel>
                    <Panel header="Ajouter mes informations personnelles" key="2">
                        <div>
                            <p >
                                Sur MONBOLIDE, il est essentiel que les informations personnelles que vous
                                renseignez soient exactes : elles sont utiles à la génération du contrat de
                                location. Votre compte est personnel, vous ne pouvez pas le prêter à un
                                proche souhaitant effectuer une location : il doit alors se créer un compte
                                dédié.
                            </p>
                            <p >
                                Au début de la location, les informations renseignées sur le contrat seront
                                comparées par le propriétaire à celles sur votre permis, ainsi que sur
                                votre carte de crédit : en cas de non conformité, il sera obligé de refuser
                                d'effectuer la location, conformément à nos conditions d'assurance.
                            </p>
                            <p >
                                Si votre profil est vérifié et que vous effectuez une modification, nos
                                équipes devront procéder à nouveau à la validation de votre profil - ce
                                temps de traitement est à prendre en compte dans vos locations à venir.
                            </p>
                            <p >
                                Lorsque vous réservez une voiture, faites attention à bien fournir des
                                informations correctes sur votre permis
                            </p>
                        </div>
                    </Panel>
                    <Panel header="Vérifier si je peux louer sur mon bolide" key="3">
                        <div>
                            <p >
                                Pour louer une voiture sur Mon bolide vous devez respecter certaines
                                conditions :
                            </p>
                            <ul class="p-4">
                                <li>
                                    <p  align="left">
                                        Avoir un permis de conduire valide pour la cote d’ivoire et qu’il
                                        s’agit du permis original. Pour les copies et les permis étranger,
                                        vous devrez présenter des document supplémentaire (carte
                                        d’identité, passeport et numéro de téléphone identifié a votre nom
                                        ou preuve de résidence sur le territoire)
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Respectez les conditions d’âge et d’ancienneté de permis de la
                                        voiture que vous souhaitez louer
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Pourvoir payer grâce au moyens de payement mobile ; par CB ou en se
                                        déplaçant directement à l’agence
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Rester dans la zone définie pour la location
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Etre présent pour l’état des lieux de début et de fin de location
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Faire vérifier votre profil
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </Panel>
                    <Panel header="Comprendre le prix d’une location" key="4">
                        <div >
                            <p>
                                Le prix de la location pourra se faire en heure en jours et en mois. Le
                                prix de la location inclut :
                            </p>
                            <ul class="p-4">
                                <li>
                                    <p  align="left">
                                        Le prix que le propriétaire a choisi pour sa voiture par zone
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Les frais de MONBOLIDE : ces frais serviront au bon fonctionnement
                                        de votre plateforme et aidera à améliorer votre parcours clients
                                    </p>
                                </li>
                            </ul>
                            <p >
                                Le prix total de la location dépendra de plusieurs facteurs tels que la
                                durée de votre location et les options que vous aurez choisi.
                            </p>
                            <p >
                                Le prix de votre location ne prendra pas en compte :
                            </p>
                            <ul class="p-4">
                                <li>
                                    <p  align="left">
                                        Le carburant : si vous rendez la voiture avec moins de carburant
                                        pour pourrez être pénaliser par le propriétaire. Vous serez donc
                                        dans l’obligation de de lui rembourser la somme correspondante aux
                                        carburants manquant
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        La zone définie : si vous ne respectez pas défini par le
                                        propriétaire, il sera en droit de vous demandez une contre parie et
                                        vous aurez l’obligation de vous y soumettre
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        L’assurance et l’assistance : cette couverture est optionnelle et
                                        donc vous aurez le choix de vous faire couvrir par notre assurance
                                        et de bénéficier de notre assistance totale pendant votre trajet.
                                    </p>
                                </li>
                            </ul>
                            <p >
                                Par contre sachez que toute inscriptions et toutes les autres options
                                proposées sont gratuite
                            </p>
                        </div>
                    </Panel>
                    <Panel header="Vérifier mon profil" key="4">
                        <div >
                            <p >
                                Pour toutes les locations sur MONBOLIDE vous devriez vérifier votre profil
                                pour plus de sécurité avant de récupérer le véhicule.
                            </p>
                            <ol>
                            </ol>
                            <ul class="m-4 p-4">
                                <li>
                                    <p  align="left">
                            Vérifiez que vos            <strong>informations personnelles sont correctes</strong>. Votre
                                        date de naissance, numéro de permis, date d'obtention, et toutes
                                        les informations de votre profil doivent correspondre aux
                                        informations indiquées sur votre pièce d'identité et votre permis.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Télécharger votre carte d’identité (recto verso) ou votre passeport
                                        (les 2 pages : photo et signature).
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Télécharger votre permis de conduire (recto verso ou trois volets
                                        pour les permis roses).
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Nous envoyer un selfie.
                                    </p>
                                </li>
                            </ul>
                            <p >
                                Nous acceptons que les documents originaux pour les carte d’identité et
                                passeport. La vérification des profils se fait aussi rapidement que
                                possible. Mais cette vérification peut aller jusqu'à 24 heures
                            </p>
                            <p >
                                Une fois que votre compte est vérifié vous recevez un email et une
                                notification de confirmation.
                            </p>
                            <p >
                                Si vos documents ne sont pas en règle nous pourrons vous demander des
                                justificatifs supplémentaires
                            </p>
                            <p >
                                Afin de faciliter la vérification de votre profil assurez-vous que :
                            </p>
                            <ol>
                            </ol>
                            <ul class="m-4 p-4">
                                <li>
                                    <p  align="left">
                                        Votre nom et prénom renseigné sur MONBOLIDE est conforme à celui
                                        apparaissant sur votre permis et votre pièce d'identité.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Vous avez signé votre permis.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Vous avez envoyé la photo de votre permis dans son intégralité,
                                        recto-verso, ou avec les 3 volets visibles pour le cas d'un permis
                                        rose.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Le selfie que vous avez envoyé est bien net avec votre visage au
                                        centre.
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Les photos envoyées sont lisibles et nettes.
                                    </p>
                                </li>
                            </ul>
                            <p >
                                Si votre location va démarrer et que votre profil n'a pas été vérifié, vous
                                pouvez nous contacter.
                            </p>
                        </div>
                    </Panel>
                    <Panel header="Faire le plein" key="5">
                        <div >
                            <p >
                                Le coût du carburant n'est pas inclus dans le prix de la location. En
                            principe, vous devriez rendre le véhicule avec    <strong>le même niveau de carburant </strong>qu'au début de la location.
                            </p>
                            <p >
                                S'il y a une différence de niveau de carburant entre le début et la fin de
                                la location, le propriétaire pourra procéder à un ajustement.
                            </p>
                            <p >
                                Toutefois, vous pouvez rendre le véhicule sans faire le plein. Dans ce cas,
                                vous paierez un service de remise à niveau du réservoir de 300francs par
                                litre manquant en plus du prix du litre pour vos locations sur MONBOLIDE
                                bénéficiant des ajustements automatiques de carburant.
                            </p>
                            <p >
                                Vous pouvez également passez un accord a l’amiable avec le propriétaire si
                                vous souhaiter payer direct le manque au propriétaire
                            </p>
                        </div>
                    </Panel>
                    <Panel header="Règle de propreté" key="6">
                        <div >
                            <p  align="justify">
                                Vous devez rendre le véhicule dans le même état que vous l'avez reçu au
                                début de la location.
                            </p>
                            <p  align="justify">
                                En cas d’utilisation normale, vous n’êtes pas obligé de nettoyer le
                                véhicule. Cependant, un nettoyage rapide est conseillé : c’est important
                                aux yeux des propriétaires qui en tiennent compte dans leurs évaluations.
                            </p>
                            <p >
                                Si le véhicule est très sale à l'extérieur (boue, saleté sur la carrosserie
                                due à la conduite sur des routes non goudronnées, etc.) ou à l'intérieur
                                (taches de boue sur les tapis, sable, miettes, etc.), vous devez le
                                nettoyer. Si le véhicule est rendu excessivement sale, vous devrez régler
                                au propriétaire des frais pour le nettoyage de l'intérieur et de
                                l'extérieur. Ces frais sont les suivants :
                            </p>
                            <ol>
                            </ol>
                            <ul class="m-4 p-4">
                                <li>
                                    <p  align="left">
                                        Intérieur : 2000francs
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Extérieur : 2000francs
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Odeur de cigarette : 1000francs
                                    </p>
                                </li>
                            </ul>
                            <p >
                                Pour les salissures nécessitant l’intervention d’un professionnel (taches
                                sur les sièges, saleté intense à l’intérieur, etc.), vous devrez payer les
                                frais de nettoyage correspondants.
                            </p>
                        </div>
                    </Panel>
                    <Panel header="Problème d’accès et de connexion" key="7">
                        <div >
                            <p>
                                Si vous ne parvenez pas à vous connecter, il existe trois possibilités :
                            </p>
                            <Tabs defaultActiveKey="1" >
                                <TabPane tab="Mot de passe est incorrect" key="1">
                                    <p>
                                        <p>-- 1. cliquez sur Se connecter</p>
                                        <p>---- 2. puis “mot de passe oublié”</p>
                                        <p>-------- 3. Vous recevrez un email pour réinitialiser
                                        votre mot de passe</p>
                                    </p>
                                    <p >
                                        Afin de garantir la sécurité de votre compte MONBOLIDE nous vous demandons
                                        de respecter 5 critères de complexité.
                                    </p>
                                    <p >
                                        Votre mot de passe doit :
                                    </p>
                                    
                                    <ul class="p-4">
                                        <li>
                                            <p  align="left">
                                                Faire au moins 8 caractères
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Contenir au moins une majuscule
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Contenir au moins une minuscule
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Contenir au moins un chiffre
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                Ne pas être un mot de passe courant, comme “Azerty123”, “P@$$w0rd”,
                                                … Nous vous recommandons également de choisir un mot de passe
                                                unique, que vous ne réutilisez pas sur d’autres sites. Un bon mot de passe doit être difficile
                                                à trouver, mais facile à retenir. Le générateur de mot de passe de la CNIL
                                                peut vous aider si vous manquez d’inspiration.
                                            </p>
                                        </li>
                                    </ul>
                                    <p  align="left">
                                        Si vous ne recevez pas l'email de réinitialisation du mot de passe :
                                    </p>
                                    <ul class="p-4">
                                        <li>
                                        Patientez quelques minutes
                                        </li>
                                        <li>
                                        Vérifiez la section courrier indésirable (ou spam) de votre boîte
                                                mail
                                        </li>
                                    </ul>
                                    <p  align="justify">
                                        <em>Si vous recevez l'email mais que le lien reçu ne fonctionne pas :</em>
                                        vous avez dû faire plusieurs demandes d'envoi de mail. Patientez quelques
                                        minutes sans refaire de demande, et cliquer sur le lien présent dans le
                                        dernier mail que vous avez reçu.
                                    </p>
                                    <p  align="justify">
                                        Si vous êtes toujours bloqué malgré ces indications, vous pouvez nous
                                        contacter.
                                    </p>
                                </TabPane>
                                <TabPane tab="Mauvaise adresse mail" key="2">
                                    <ul class="p-4">
                                        <li>
                                            <p  align="left">
                                                Vous essayez de vous connecter avec la mauvaise adresse mail : vous
                                                avez peut-être déjà un compte chez nous avec lequel vous vous étiez
                                                connecté au cours de la réservation - notamment le compte lié à
                                                votre adresse Facebook. Vérifiez vos différentes adresses mails
                                                pour retrouver avec laquelle vous connecter sur MONBOLIDE.
                                            </p>
                                        </li>
                                        <li>
                                            <p  align="left">
                                                L’accès à votre compte est temporairement suspendu suite à de
                                                nombreuses tentatives de connections échouées. Lorsque nous
                                                constatons de nombreuses tentatives échouées de
                                            </p>
                                        </li>
                                    </ul>
                                </TabPane>
                                <TabPane tab="Compte est temporairement suspendu" key="3">
                                    <p >
                                        connexion à votre compte, l’accès au compte est temporairement suspendu
                                        pour prévenir tout risque de piratage. Vous en êtes notifié par email.
                                        L’accès à votre compte est automatiquement débloqué au bout de quelques
                                        heures. Si vous avez oublié votre mot de passe ou souhaitez vous connecter
                                        à votre compte avant la fin de ce délai, vous devez réinitialiser votre mot
                                        de passe.
                                    </p>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Panel>
                    <Panel header="Vérifier mon numéro de téléphone" key="8">
                        <div >
                            <h6 >
                                L’INTERET DE FAIRE VERIFIER VOTRE NUMERO
                            </h6>
                            <p >
                                La vérification de votre numéro de téléphone est très importante. Cela
                                permettra :
                            </p>
                            <ul class="p-4">
                                <li>
                                    <p  align="left">
                                        D’éviter les fraudes sur une location
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        De vérifier votre identité
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        De permettre aux agents MONBOLIDE et aux membres de la plateforme
                                        de se contacter sans problème
                                    </p>
                                </li>
                            </ul>
                            <h6 >
                                METHODE DE VERIFICATION DE MON NUMERO DE TELEPHONE
                            </h6>
                            <p >
                                Lorsque vous renseignerez vos informations, nous vous demanderons de
                                vérifier votre numéro de téléphone à ce moment :
                            </p>
                            <ul class="p-4">
                                <li>
                                    <p  align="left">
                                        Renseignez votre numéro de téléphone
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Vous recevrez instantanément un SMS contenant un code
                                    </p>
                                </li>
                                <li>
                                    <p  align="left">
                                        Rentrez ce code sur la page de vérification MONBOLIDE
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </Modal>

      </>
    
    )
  }
}

BeginModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showBegin: PropTypes.bool.isRequired
};