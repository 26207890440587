import axios from "axios";
import URL from "../common/api";
import { Success, Error } from "../common/swal";
const VIEW_ACCOUNT = "VIEW_ACCOUNT";
const VIEW_CUSTOMER = "VIEW_CUSTOMER";
const VIEW_OWNER_INFO = "VIEW_OWNER_INFO";
const EDIT_ACCOUNT = "EDIT_ACCOUNT";
const UPDATE_ACCOUNT_DATA = "UPDATE_ACCOUNT_DATA";
const ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS";
const NOTIFICATION_SETTINGS = "NOTIFICATION_SETTINGS";
const ACCOUNT_CREATION = "ACCOUNT_CREATION";
const AUTHORIZATION = "AUTHORIZATION";
const UPDATE_NOTIFICATION_DATA = "UPDATE_NOTIFICATION_DATA";
const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
const VIEW_TRIPS = "VIEW_TRIPS";
const VIEW_TRIPS_REQUEST = "VIEW_TRIPS_REQUEST";
const VIEW_CARS_TRIPS = "VIEW_CARS_TRIPS";
const VIEW_CARS = "VIEW_CARS";
const ADD_REVIEW = "ADD_REVIEW";
// const COUNT_TRIPS = "COUNT_TRIPS"

// const SEND_CONTRACT = 'SEND_CONTRACT'
// const SET_CAR_STATUS = 'SET_CAR_STATUS';

// const FACEBOOK_AUTH = 'FACEBOOK_AUTH';
// const GOOGLE_AUTH = 'GOOGLE_AUTH';

const logginMessage = (name) => {
  return `Content de vous revoir ${name}`;
};

// Envoyer le contrat aux deux protagonistes
export function AC_SEND_CONTRACT(formData) {
  return function () {
    return axios
      .post(URL.API.SendContract, formData)
      .then((data) => {
        if (data.status === 1) {
          Success(data.message);

          // dispatch({ type: Trip, payload: data });
        } else {
          Error(data.message);
        }
      })
      .catch((error) => {
        Error("Une erreur s'est produite");
      });
  };
}
// Inscription facebook
export function AC_FACEBOOK_AUTH(fbCallback) {
  return function (dispatch) {
    return axios.post(URL.API.FacebookAuth, fbCallback).then(({ data }) => {
      if (data.status) {
        Success(logginMessage(data?.data?.firstname));
        dispatch({ type: AUTHORIZATION, payload: data });
        localStorage.setItem("siteUser", data.data._id);
        let redirectTo = "";
        // window.location='/';
        if (localStorage.getItem("redirect")) {
          redirectTo = localStorage.getItem("redirect");
          localStorage.removeItem("redirect");
        } else {
          redirectTo = window.location.href.split("redirect")[1] || "/";
        }
        window.location.href = window.location.origin + redirectTo;
      } else {
        Error(data.message);
      }
    });
  };
}

// Inscription via google
export function AC_GOOGLE_AUTH(googleCallback) {
  return function (dispatch) {
    return axios.post(URL.API.GoogleAuth, googleCallback).then(({ data }) => {
      if (data.status) {
        Success(logginMessage(data?.data?.firstname));
        dispatch({ type: AUTHORIZATION, payload: data });
        localStorage.setItem("siteUser", data.data._id);
        let redirecTo = window.location.href.split("redirect")[1] || "/";
        window.location.href = window.location.origin + redirecTo;
      } else {
        Error(data.message);
      }
    });
  };
}

export function AC_VIEW_OWNER_INFO(formData) {
  const id = { id: formData };
  return function (dispatch) {
    return axios.post(URL.API.ViewOwnerAccount, id).then(({ data }) => {
      if (data.status) {
        dispatch({ type: VIEW_OWNER_INFO, payload: data });
      } else {
        localStorage.clear();
        window.location.href = window.location.origin + "/";
      }
    });
  };
}

export function AC_VIEW_CUSTOMER(formData) {
  const id = { id: formData };
  return function (dispatch) {
    return axios.post(URL.API.ViewCustomer, id).then(({ data }) => {
      if (data.status) {
        dispatch({ type: VIEW_CUSTOMER, payload: data });
      } else {
        localStorage.clear();
        window.location.href = window.location.origin + "/";
      }
    });
  };
}

export function AC_VIEW_ACCOUNT(formData) {
  const id = { id: formData };
  return function (dispatch) {
    return axios.post(URL.API.ViewAccount, id).then(({ data }) => {
      if (data.status) {
        dispatch({ type: VIEW_ACCOUNT, payload: data });
      }
    });
  };
}
// export function AC_COUNT_TRIPS(formData) {
//   const id = { id: formData };
//   return function (dispatch) {
//     return axios.post(URL.API.ViewAccount, id).then(({ data }) => {
//       if (data.status) {
//         dispatch({ type: VIEW_ACCOUNT, payload: data });
//       }
//     });
//   };
// }

export function AC_VIEW_TRIPS(formData) {
  return function (dispatch) {
    return axios.post(URL.API.ViewTrips, formData).then(({ data }) => {
      // console.log('Affichage data viewTrips')
      // console.log(data)
      dispatch({ type: VIEW_TRIPS, payload: data });
    });
  };
}
export function AC_VIEW_TRIPS_REQUEST(formData) {
  return function (dispatch) {
    return axios.post(URL.API.ViewTrips, formData).then(({ data }) => {
      // console.log('Affichage data viewTrips')
      // console.log(data)
      dispatch({ type: VIEW_TRIPS_REQUEST, payload: data });
    });
  };
}
// Recupérer la liste de toutes les locations sur un véhicule donnée
export function AC_VIEW_CAR_TRIPS(formData) {
  return function (dispatch) {
    return axios.post(URL.API.ViewCarTrips, formData).then(({ data }) => {
      dispatch({ type: VIEW_CARS_TRIPS, payload: data });
    });
  };
}

export function AC_UPDATE_CAR_STATUS(formData) {
  return function (dispatch) {
    return axios
      .post(URL.API.UpdateCarStatus, formData)
      .then(({ data }) => {
        if (data.status === 1) {
          // Success(data.message);
          dispatch({ type: VIEW_TRIPS, payload: data });
        } else {
          Error(data.message);
        }
      })
      .catch(() => {
        Error("Une erreur s'est produite");
      });
  };
}

export function AC_ADD_REVIEW(formData) {
  return function (dispatch) {
    return axios.post(URL.API.AddReview, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        dispatch({ type: ADD_REVIEW, payload: data });
        // window.location.reload();
      } else {
        Error(data.message);
      }
    });
  };
}

export function AC_VIEW_CARS(formData) {
  return function (dispatch) {
    return axios.post(URL.API.ViewCars, formData).then(({ data }) => {
      dispatch({ type: VIEW_CARS, payload: data });
    });
  };
}
export function AC_DELETE_CARS(formData) {
  return function (dispatch) {
    return axios.post(URL.API.DeleteCars, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        dispatch({ type: VIEW_CARS, payload: data });
      } else {
        Error(data.message);
      }
    });
  };
}

export function AC_TOGGLE_ACTIVE_CAR(formData) {
  return function (dispatch) {
    return axios.post(URL.API.ToggleActiveCar, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        dispatch({ type: VIEW_CARS, payload: data });
      } else {
        Error(data.message);
      }
    });
  };
}

export function AC_ACCOUNT_SETTINGS(formData) {
  return function (dispatch) {
    return axios.post(URL.API.AccountSettings, formData).then(({ data }) => {
      if (data.status === 2) {
        localStorage.removeItem("siteUser");
        window.location.href = window.location.origin + "/";
      }
      if (data.status) {
        Success(data.message);
      } else {
        Error(data.message);
      }
      dispatch({ type: ACCOUNT_SETTINGS, payload: data });
    });
  };
}

export function AC_EDIT_ACCOUNT(formData) {
  return function (dispatch) {
    return axios.post(URL.API.EditAccount, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
      } else {
        Error(data.message);
      }
      dispatch({ type: EDIT_ACCOUNT, payload: data });
    });
  };
}

export function AC_ADD_ATTACHMENT_TO_PROILE(formData) {
  return function (dispatch) {
    return axios
      .post(URL.API.AddAttachmentToProfile, formData)
      .then(({ data }) => {
        if (data.status) {
          Success(data.message);
          dispatch({ type: VIEW_ACCOUNT, payload: data });
        } else {
          Error(data.message);
        }
      });
  };
}

export function AC_VERIFY_PROILE(formData) {
  return function (dispatch) {
    return axios.post(URL.API.VerifyProfile, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        dispatch({ type: VIEW_ACCOUNT, payload: data });
      } else {
        Error(data.message);
      }
    });
  };
}
export function AC_EDIT_NOTIFICATION(notifier, id) {
  return function (dispatch) {
    return axios
      .post(URL.API.EditNotification, notifier, {
        headers: { authorization: id },
      })
      .then(({ data }) => {
        if (data.status) {
          Success(data.message);
        } else {
          Error(data.message);
        }
        dispatch({ type: EDIT_NOTIFICATION, payload: data });
      });
  };
}

// OtpSubmitCode

export function AC_OTP_VERIFICATION(formData) {
  // Obtenir les infos de l'utilisateur à peine inscrit pour la
  return function (dispatch) {
    const phonenumber = localStorage.getItem('number');
    return axios.post(URL.API.OtpVerification, { phonenumber: phonenumber, code: formData.code, token: formData.token })
      .then(data => {
        if (data.data.valid) {
          return axios.post(URL.API.ActiveAccount, { userTempId: localStorage.getItem('userTempId') })
            .then(result => {
              Success(result.message);
              localStorage.removeItem('number');
              localStorage.removeItem('userTempId');

              // Pas de redirection immédiate ici
              // On enchaîne avec la connexion automatique après l'activation
              const email = localStorage.getItem('email');
              const password = localStorage.getItem('password');

              if (email && password) {
                // Appeler la fonction de connexion après la vérification OTP et l'activation
                dispatch(AC_AUTHORIZATION({ email, password }));
              } else {
                Error("Données utilisateur manquantes dans le localStorage.");
              }
            })
            .catch(e => {
              Error("Une erreur s'est produite lors de l'activation du compte");
            });
        } else {
          Error(data.message);
        }
      })
      .catch(e => {
        if (e.response.status === 404 || e.response.status === 400) {
          Error("Code non valide!");
        } else if (e.response.status === 429) {
          Error("Merci de réessayer 10 minutes plus tard.");
        } else {
          Error("Erreur lors de la vérification OTP.");
        }
      });
  };
}

// Enregistrer l'utilisateur temporaire
export function AC_ADD_USER_TEMP(formData) {
  return function () {
    return axios.post(URL.API.AddUserTemp, formData).then(({ data }) => {
      if (data.status) {
        // dispatch({type: AC_ADD_USER_TEMP, payload: data })
        localStorage.setItem('userTempId', data.data._id);
        localStorage.setItem('email', formData.email); // Sauvegarde l'email
        localStorage.setItem('password', formData.password); // Sauvegarde le mot de passe
        const query = {
          phonenumber: data.data.mobilenumber,
          channel: "sms",
          countryPhone: formData.countryPhone,
          token: data.token
        };

        // Send code to user
        return axios
          .post(
            URL.API.SendOtpVerification, { phonenumber: query.phonenumber, channel: query.channel, token: query.token }
          )
          .then((result) => {
            if (result.data.status === "pending") {
              localStorage.setItem("number", query.phonenumber);
              // localStorage.setItem(
              //   "fullName",
              //   data.data.firstname + " " + data.data.lastname
              // );
              // localStorage.setItem("email", data.data.email);
              window.location.href =
                window.location.origin + "/auth/otp-validation";
            }
          })
          .catch((e) => {            
            Error("Ce numéro n'est pas valide");
          });

      } else {
        Error(data.message)
      }
    })
      .catch((e) => {
        Error("Une erreur s'est produite");
      })
  }
}

// La création d'un nouveau compte
export function AC_ACCOUNT_CREATION(formData) {
  return function (dispatch) {
    return axios.post(URL.API.AccountCreation, formData).then(({ data }) => {
      if (data.status) {
        // Success(data.message)
        dispatch({ type: ACCOUNT_CREATION, payload: data });
        localStorage.setItem("idRegister", data.data._id);
        // si l'identifiant de l'id existe alors envoyer l'sms de vérification
        const query = {
          phonenumber: data.data.mobilenumber,
          channel: "sms",
          countryPhone: formData.countryPhone,
        };
        return axios
          .get(
            `${URL.API.SendOtpVerification}?phonenumber=${query.phonenumber}&channel=${query.channel}`
          )
          .then((result) => {
            if (result.data.status === "pending") {
              localStorage.setItem("number", query.phonenumber);
              localStorage.setItem(
                "fullName",
                data.data.firstname + " " + data.data.lastname
              );
              localStorage.setItem("email", data.data.email);
              window.location.href =
                window.location.origin + "/auth/otp-validation";
            }
          })
          .catch((e) => {
            Error("Ce numéro n'est pas valide");
          });
        // window.location='/auth/otp-validation';
      } else {
        Error(data.message);
      }
    });
  };
}


export function AC_SEND_OTP_CODE(formData) {
  return function () {
    const query = {
      phonenumber: localStorage.getItem('number'),
      channel: 'sms'
    }
    return axios.post(URL.API.SendOtpVerification, { phonenumber: query.phonenumber, channel: query.channel, token: formData.token }).then((result) => {
      // console.log(result)
      if (result.data.status === 'pending') {
        Success('Code OTP envoyé.')
      }

    }).catch((e) => {      
      if (e.response.status === "429" || e.response.status === 429)
        Error('Patienter 10 minutes avant une nouvelle tentative')
      else
        Error('Numéro n\'est pas valide')
    })
  }
}


// L'autorisation de connexion
export function AC_AUTHORIZATION(formData) {
  return function (dispatch) {
    return axios.post(URL.API.Authorization, formData).then(({ data }) => {
      if (data.status) {
        // Affiche un message de succès de connexion
        Success(logginMessage(data?.data?.firstname));

        // Dispatch pour mettre à jour l'état d'autorisation dans Redux
        dispatch({ type: AUTHORIZATION, payload: data });

        // Stocke l'ID utilisateur dans le localStorage pour maintenir la session
        localStorage.setItem("siteUser", data.data._id);

        let redirectTo = "";

        // Vérifiez si la redirection est stockée dans le localStorage (ex. tentative de booking avant la connexion)
        if (localStorage.getItem("redirect")) {
          redirectTo = localStorage.getItem("redirect");
          localStorage.removeItem("redirect");  // Retire la redirection une fois utilisée
        } else {
          // Sinon, essaye de récupérer un paramètre "redirect" dans l'URL
          const urlParams = new URLSearchParams(window.location.search);
          redirectTo = urlParams.get('redirect') || "/";  // Redirection par défaut vers la page d'accueil
        }
        
        // Rediriger l'utilisateur vers la page désirée (ou la page par défaut "/")
        window.location.href = window.location.origin + redirectTo;
      } else {
        // Affiche le message d'erreur en cas d'échec
        Error(data.message);
      }
    }).catch(err => {
      // Gestion des erreurs d'autorisation (requête échouée)
      console.error("Authorization error: ", err);
      Error("An error occurred during authorization.");
    });
  };
}

// Mot de passe oublié
export function AC_FORGOT_PASSWORD(formData) {
  return function () {
    return axios.post(URL.API.ForgotPassword, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        setTimeout(function () {
          window.location.href = window.location.origin + "/sign-in";
        }, 6000);
      } else {
        Error(data.message);
      }
    });
  };
}

// Changer de mot de passe
export function AC_CHANGE_PASSWORD(formData) {
  return function (dispatch) {
    return axios.post(URL.API.ChangePassword, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        //setTimeout(function(){ window.location='/sign-in'; },6000)
        dispatch({ type: AUTHORIZATION, payload: data });
        // localStorage.setItem("siteUser", data.data._id);
        window.location.href = window.location.origin + "/sign-in";
      } else {
        Error(data.message);
      }
    });
  };
}

export function AC_NOTIFICATION_SETTINGS() {
  return function (dispatch) {
    return axios.get(URL.API.NotificationSettings).then(({ data }) => {
      dispatch({ type: NOTIFICATION_SETTINGS, payload: data });
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name, value) {
  return function (dispatch) {
    dispatch({ type: UPDATE_ACCOUNT_DATA, name: name, value: value });
  };
}

export function AC_HANDLE_NOTIFICATION_CHANGE(name, value, id) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_NOTIFICATION_DATA,
      name: name,
      value: value,
      id: id,
    });
  };
}
