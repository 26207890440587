import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "react-google-autocomplete";
import { Redirect /*Link*/ } from "react-router-dom";
import { /*useTranslation,*/ withTranslation } from "react-i18next";
import { AC_SEARCH_CAR } from "../../actions/searchcarsaction";
import moment from "moment";
import { fr } from "date-fns/locale";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import MobileDetect from 'mobile-detect'
import "react-datepicker/dist/react-datepicker.css";
import "./BannerSearch.css";
import { getCityFromPlace } from "../../utils/geoLocation";
// import {geoLocation} from './../../utils/geoLocation'
// registerLocale("fr", fr); // register it with the name you want
registerLocale("fr", fr);
setDefaultLocale("fr");
let initCheckin = new Date();

var momentObj = moment(initCheckin);
var momentString = momentObj.format('DD-MM-YYYY HH:mm');
let initCheckout = moment(momentString, "DD-MM-YYYY HH:mm").add(7, 'days').toDate();
localStorage.getItem('checkIn', new Date());
class BannerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      addresserr: false,
      route: false,
      place: "",
      checkIn: new Date(),
      checkOut: initCheckout,
      brutCheckin: new Date(),
      brutCheckout: initCheckout,
      loading: true,
    };
  }
  componentDidMount() {
    localStorage.setItem('checkIn', new Date());
    localStorage.setItem('checkOut', this.addDaysToDate(7));
    this.setState({brutCheckout: this.addDaysToDate(7)})  
  }

  // Cette fonction équipe la valeur de la date de début pour définir l'intervalle maximum de 21 jours
  maxDateController = (checkin, nbDays) => {
    let finalDate = moment(checkin, "DD-MM-YYYY HH:mm").add(nbDays, 'days').toDate();
    return finalDate;
  }

  addDaysToDate = (nbDays) => {
    let momentObj = moment(new Date());
    let momentString = momentObj.format('DD-MM-YYYY HH:mm');
    let finalDate = moment(momentString, "DD-MM-YYYY HH:mm").add(nbDays, 'days').toDate();
    return finalDate;
  }

  onSetPlace(place) {
    // console.log(event.target.value)
    // this.setState({ place: event.target.value });
    // this.setState({ adress: event.target.value });    

    this.setState({ placedEvent: place });    
    const defaultLocation = "Abidjan, Côte d'Ivoire/5.3599517/-4.0082563";

    // const address = place.formatted_address ? place.geometry.location : null;
    const setplace = place.formatted_address
      ? place.formatted_address +
      "/" +
      place.geometry.location.lat() +
      "/" +
      place.geometry.location.lng()
      : defaultLocation;
    localStorage.setItem("place", setplace);
    
    if(place) {      
      localStorage.setItem("userCity", getCityFromPlace(place));
      this.setState({ address: place.formatted_address, addresserr: false });
    }
      
    else {
      localStorage.setItem("userCity", 'Abidjan');
      this.setState({ addresserr: true });
    }
      
    localStorage.removeItem("time");
    // this.setState({ address: "Abidjan, Côte d'Ivoire", addresserr: false });
  }


  onChangeValue(event) {    
    const address = event.target.value
    this.setState({address: address.formatted_address})
  }

  handleChangeCheckin = (date) => {      
    localStorage.setItem('checkIn', date);
    this.setState({
      brutCheckin: date,
      checkIn: moment(date).format("DD-MM-YYYY HH:mm"),
    });    
  };

  handleChangeCheckout = (date) => {    
    localStorage.setItem('checkOut', date);  
    this.setState({
      brutCheckout: date,
      checkOut: moment(date).format("DD-MM-YYYY HH:mm"),
    });
  };
  // Controller of max Date

  // convertir les this.state.brutCheckIn en Date 
  // La date de fin doit être éloigné d'une semaine 
  // La différence entre les deux dates doit être au maximum de 4 semaines

  nextLink(e) {
    e.preventDefault();  
    if (this.state.address || this.state.place) {
      // this.props.searchCar(this.state.address);
      localStorage.setItem("checkIn", this.state.brutCheckin);
      localStorage.setItem("checkOut", this.state.brutCheckout);
      this.setState({ route: true });
    } else {
      this.setState({ addresserr: true });
    }
  }
  render() {
    let type = MobileDetect._impl.detectOS(window.navigator.userAgent)
    // let position = 
    localStorage.setItem(
      "period",
      localStorage.getItem("periodStart") +
      "%" +
      localStorage.getItem("periodend")
    );
    const route = this.state.route;
    const { t } = this.props;
    if (route) {
      return <Redirect push to="/search-car" />;
    }
    return (
      <section className="banner-search h-full" style={{paddingTop: "7rem", position: "relative"}}>
        
        <div className="container text-center" style={{zIndex: 2}}>
          <div className="header-txt" style={{ width: "100%" }}>
            <h2 data-aos="zoom-in" data-aos-duration="3000">
              {t("title")}
              {" "}
              
            </h2>
            <p style={{ fontWeight: "bolder", color: "white" }}>{t("subtitle")}</p>
          </div>
          <form className="form--container">
            <div className="row search--container">
              <div className="col-lg-6 col-md-12 mb-3">
                <span style={{position:"absolute", top: "10px", left: "42px", fontWeight: "bold"}}>  Localisation</span>                
                <Autocomplete                  
                  style={{ border: "none", borderRadius: "39px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  className="form-control pt-5"
                  placeholder={t("search_placeholder")}                  
                  onChange={(event) => this.onChangeValue(event)}
                  value={this.state.address}
                  onPlaceSelected={(place) => {
                    this.onSetPlace(place);
                  }}

                  types={['geocode', 'establishment']}
                  componentRestrictions={{ country: "civ" }}                
                />
              </div>
              <div className="mb-3 fullWidth" style={{position: "relative"}}>                
                <DatePicker                
                  locale="fr"
                  className="form-control pt-5"
                  style={{ border: "none", borderRadius: "39px" }}
                  dateFormat="dd-MMM-yyyy HH:mm"
                  timeInputLabel="Heure:"
                  selected={this.state.brutCheckin}
                  onChange={(date) => this.handleChangeCheckin(date)}
                  showTimeInput
                  showTimeSelect
                  closeOnScroll={(type !== 'iOS' && type !=='AndroidOs')}
                  minDate={new Date()}
                  timeCaption="Temps"
                  placeholderText="Date de debut"
                />
                <span className="datePickerlabel">Début location</span> 
              </div>
              <div className="mb-3 fullWidth" style={{position:"relative"}}>
                <DatePicker
                  locale="fr"
                  timeCaption="Temps"
                  className="form-control pt-5"
                  dateFormat="dd-MMM-yyyy HH:mm"
                  timeInputLabel="Heure:"
                  selected={this.state.brutCheckout}
                  onChange={(date) => this.handleChangeCheckout(date)}
                  showTimeInput
                  closeOnScroll={(type !== 'iOS' && type !=='AndroidOs')}
                  style={{ border: "none", borderRadius: "39px" }}
                  showTimeSelect
                  maxDate={this.maxDateController(this.state.brutCheckin,21)}
                  minDate={
                    this.state.brutCheckin
                      ? this.state.brutCheckin
                      : new Date() + 1
                  }
                  // maxDate={
                  //   this.state.brutCheckin
                  //     ? this.state.brutCheckin
                  //     : new Date() + 1
                  // }
                  placeholderText="Date de fin"

                />
                <span className="datePickerlabel">Fin location</span> 
              </div>

              <div className="col-lg-4 col-12 col-md-6 mb-3 px-3">
                <button
                  className="btn btn-default py-4"
                  type="submit"
                  style={{ fontWeight: 600, borderRadius: "39px", color: "white" }}
                  onClick={(e) => this.nextLink(e)}
                >
                  {t("search")}
                </button>
              </div>
            </div>
            {this.state.addresserr ? (
              <div className="alert alert-info">Une adresse est requise</div>
            ) : (
              ""
            )}
          </form>
          <div className="banner-img" style={{ marginTop: 0, width: "100%", height: "200px", zIndex: -100 }}>
          </div>
        </div>
        <div className="backdrop"></div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    SearchCarsReducer: state.SearchCarsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ searchCar: AC_SEARCH_CAR }, dispatch);
}

const BannerSearchComponent = withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(BannerSearch)
);

export default BannerSearchComponent;
