import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { AC_DASHBOARD } from "../../actions/dashboardaction";

import BeginModal from "./modal/Begin";
import RentalDetailsModal from "./modal/RentalDetails";
import InsuranceModal from "./modal/Insurance";
import QuestionModal from "./modal/Question";
import PaymentModal from "./modal/Payment";
import OtherQuestionModal from "./modal/OtherQuestion";
import ThoughtModal from "./modal/Thought";

class TabSection extends React.Component {
  componentDidMount() {
    this.props.viewQuestions();
  }

  state = {
    showBegin: false,
    showRental: false,
    showQuestion: false,
    showOtherQuestion: false,
    showPayment: false,
    showThought: false,
    showInsurance: false
  };

  showBeginModal = e => {
    this.setState({
      showBegin: !this.state.showBegin
    });
  };

  showRentalModal = e => {
    this.setState({
      showRental: !this.state.showRental
    });
  };
  
  showInsuranceModal = e => {
    this.setState({
      showInsurance: !this.state.showInsurance
    });
  };
  
  showQuestionModal = e => {
    this.setState({
      showQuestion: !this.state.showQuestion
    });
  };
  
  showPaymentModal = e => {
    this.setState({
      showPayment: !this.state.showPayment
    });
  };
  
  showOtherQuestionModal = e => {
    this.setState({
      showOtherQuestion: !this.state.showOtherQuestion
    });
  };
  
  showThoughtModal = e => {
    this.setState({
      showThought: !this.state.showThought
    });
  };



  render() {
    let faqList = this.props.DashboardReducer.faqList;
    let driverfaqArray = [];
    let ownerfaqArray = [];
    if (faqList !== undefined && faqList.length > 0) {
      for (let i = 0; i < faqList.length; i++) {
        if (faqList[i].faqtype === 1) {
          driverfaqArray.push(
            <ul key={i}>
              <li>
                <Link to={"/faq-description/" + faqList[i]._id}>
                  <span>{faqList[i].question}</span>
                </Link>
              </li>
            </ul>
          );
        } else {
          ownerfaqArray.push(
            <ul key={i}>
              <li>
                <Link to={"/faq-description/" + faqList[i]._id}>
                  <span>{faqList[i].question}</span>
                </Link>
              </li>
            </ul>
          );
        }
      }
    }
    return (
      <section className="tab-section">
        <div className="tab-bar">
          <nav>
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                AIDES POUR LES LOCATAIRES
              </a>
              <a
                className="nav-item nav-link"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                AIDES POUR LES PROPRIÉTAIRES
              </a>
            </div>
          </nav>
        </div>

        <div className="layer">
          <div className="container">
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="left-tab-text">
                  <h2 className="tab-h2">
                    Articles les plus utiles pour les locataires
                  </h2>
                  {ownerfaqArray}
                  <h2 className="tab-h2-2">
                    Rubriques d'aides pour les locataires
                  </h2>
                  <div className="row">

                    <div className="col-lg-4 col-md-4 col-sm-6 mt-4">
                      <button onClick={e => {
                              this.showBeginModal();
                        }} className="btn btn-outline-primary customBtn">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-flag sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Pour commencer</div>
                      </button>
                      {this.state.showBegin && <BeginModal onClose={this.showBeginModal} showBegin={this.state.showBegin} />}
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mt-4">
                      <button onClick={e => {
                              this.showRentalModal();
                        }} className="btn btn-outline-primary customBtn">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-key sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Détails de la location</div>
                        {this.state.showRental && <RentalDetailsModal onClose={this.showRentalModal} showRental={this.state.showRental} />}
                      </button>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mt-4">
                      <button onClick={e => {
                              this.showInsuranceModal();
                        }} className="btn btn-outline-primary customBtn">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-rebel sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Assurance et assistance</div>
                      </button>
                      {this.state.showInsurance && <InsuranceModal onClose={this.showInsuranceModal} showInsurance={this.state.showInsurance} />}
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mt-4">
                       <button onClick={e => {
                              /* this.showQuestionModal(); */
                        }} className="btn btn-outline-primary customBtn">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-frown-o sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">
                          Questions embarrassantes
                        </div>
                      </button>
                      {this.state.showQuestion && <QuestionModal onClose={this.showQuestionModal} showQuestion={this.state.showQuestion} />}
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mt-4">
                    <button onClick={e => {
                              this.showPaymentModal();
                        }} className="btn btn-outline-primary customBtn">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-credit-card sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Paiement</div>
                      </button>
                      {this.state.showPayment && <PaymentModal onClose={this.showPaymentModal} showPayment={this.state.showPayment} />}
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mt-4">
                       <button onClick={e => {
                              this.showOtherQuestionModal();
                        }} className="btn btn-outline-primary customBtn">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-info-circle sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Autres questions</div>
                      </button>
                      {this.state.showOtherQuestion && <OtherQuestionModal onClose={this.showOtherQuestionModal} showOtherQuestion={this.state.showOtherQuestion} />}
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mt-4">
                       <button onClick={e => {
                              /* this.showThoughtModal(); */
                        }} className="btn btn-outline-primary customBtn">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-star sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Avis</div>
                      </button>
                      {this.state.showThought && <ThoughtModal onClose={this.showThoughtModal} showThought={this.state.showThought} />}
                    </div>

                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="right-tab-text">
                  <h2 className="tab-h2">
                    Articles les plus utiles pour les propriétaires
                  </h2>
                  {/*   {ownerfaqArray} */}
                  <h2 className="tab-h2-2">
                    Rubriques d'aide pour les propriétaires
                  </h2>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-flag sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">General</div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-rebel sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Assurance et assistance</div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-lock sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Tracking du véhicule</div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-pencil sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">
                          Enregistrement d'un véhicule
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-tag sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Prix de location</div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-envelope sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">
                          Gestion des demandes de location
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-key sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Gestion d'une location</div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-exclamation-triangle"></i>
                          </div>
                        </div>
                        <div className="icon-name">Problèmes</div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-key sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name text-left">
                          Paiements, impôts et revenus
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-briefcase sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name text-left">
                        Monbolide pour les professionnels
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-info-circle sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Autres questions</div>
                      </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <a href="" className="icon-a">
                        <div className="icon">
                          <div className="sub-icon">
                            <i className="fa fa-star sub-icon-size"></i>
                          </div>
                        </div>
                        <div className="icon-name">Avis</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewQuestions: AC_DASHBOARD }, dispatch);
}

const TabSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TabSection);

export default TabSectionComponent;
