import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AC_DASHBOARD } from "../../actions/dashboardaction";

class FrequentlySection extends React.Component {
  render() {
    let faqList = this.props.DashboardReducer.faqList;
    let faqListArray = [];
    if (faqList !== undefined && faqList.length > 0) {
      for (let i = 0; i < faqList.length; i++) {
        if (i === 5) {
          break;
        }
        faqListArray.push(
          <ul className="frequently-ul" key={i}>
            <li className="frequently-li"><a href="">{faqList[i].question}</a></li>
          </ul>
        );
      }
    }
    return (
      <section className="frequently-section">
        <div className="container">
          <div className="frequently-text">
            <h2 className="frequently-h2">Questions fréquemment posées</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {faqListArray}
              <div className="text-left">
                <Link to="questions">
                  <button type="button" className="view-btn">
                    Voir toutes les questions
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="questions-text">
                <h2 className="questions-h2">Vous avez une question ?</h2>
              </div>
              <p>Nous pouvons vous aider!</p>
              <p>
                Jetez un coup d'œil à notre{" "}
                <Link to="questions">centre d'aide aux propriétaires</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewQuestions: AC_DASHBOARD }, dispatch);
}

const FrequentlySectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(FrequentlySection);

export default FrequentlySectionComponent;
