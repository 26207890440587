import React from 'react';

import { AC_YOUR_CAR } from '../../actions/carsaction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_EDIT_CARDETAILS, AC_HANDLE_INPUT_CHANGE } from "../../actions/carsaction"
import { Success, Error } from "../../common/swal";

class PriceSection extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      price: 0,
      secondPrice: 0
    }

    this.handleChangePrice = this.handleChangePrice.bind(this)
  }

  componentDidMount() {


    if (this.props.CarsReducers.carList.price) {
      this.setState({
        price: this.props.CarsReducers.carList.price.price,
        secondPrice: this.props.CarsReducers.carList.price.secondPrice
      })
    }
  }

  reducePrice() {

    if (this.state.price > 0) {
      this.setState((state, props) => {
        return { ...state, price: this.state.price - 5000 }
      })
    }
  }

  upPrice() {
    this.setState((state, props) => {
      return { ...state, price: this.state.price + 5000 }
    })
  }

  reduceSecondPrice() {

    if (this.state.secondPrice > 0) {
      this.setState((state, props) => {
        return { ...state, secondPrice: this.state.secondPrice - 5000 }
      })
    }
  }

  upSecondPrice() {
    this.setState((state, props) => {
      return { ...state, secondPrice: this.state.secondPrice + 5000 }
    })
  }


  handleChangePrice(e) {
    e.preventDefault();

    // this.props.handleInputChange(name,value,'ParkingAddress')
    let price = {}
    price["price"] = this.state.price;
    price["secondPrice"] = this.state.secondPrice;
    const carId = localStorage.getItem('carId');
    if (carId) {
      this.props.EditCardetails({ price: price }, carId);
    } else {
      Error("Impossible de mettre à jour cette information")
    }
  }

  render() {
    return (
      <div className="price list-car-card px-3 py-3">
        <div className="price-text">
          <p className="mb-32">Pour tout savoir sur le mode de calcul des prix de location, lisez notre rubrique <a href="#">article du centre d'aide</a></p>
        </div>
        <hr />
        <h2 className="sub-title mb-16">Options de prix journaliers</h2>
        {/* <div className="price-container">
          <div className="icon-checkbox">
            <div className="box-1">
              <div className="arrow-icon-1">
                <i className="fa fa-long-arrow-right arrow-icon-size" />
              </div>
              <div className="box-1-title">
                Prix fixe
                <div className="box-1-text">
                  Vous fixez un prix quotidien qui s'applique à tous les jours.
                </div>
              </div>
            </div>
          </div>
          <div className="icon-checkbox">
            <div className="box-2">
              <div className="arrow-icon-2">
                <i className="fa fa-line-chart chart-icon-size" />
              </div>
              <div className="box-2-title">
                Prix variable
                <div className="badge badge-color">Recommandé</div>
                <div className="box-2-text">
                  Vous pouvez fixer un prix différent pour chaque jour afin d'augmenter vos gains jusqu'à 30 %.
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-6">
            <div className="price-cont p-4">
              <h2 className="sub-title-price text-center mb-16">Prix quotidien</h2>
              {/* range */}
              <div className="price-action" style={{display: 'flex', justifyContent:'center'}}>
                <button type="button" className="cal-btn" onClick={() => this.reducePrice()}>-</button>
                <div className="price-wrapper">
                  <span className="price-value">{this.state.price}</span>
                  <span className="price-unit">/jour</span>
                </div>
                <button type="button" className="cal-btn" onClick={() => this.upPrice()}>+</button>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="price-cont p-4">
              <h2 className="sub-title-price text-center mb-16">Prix Hors de la ville</h2>
              {/* range */}
              <div className="price-action" style={{display: 'flex', justifyContent:'center'}}>
                <button type="button" className="cal-btn" onClick={() => this.reduceSecondPrice()}>-</button>
                <div className="price-wrapper">
                  <span className="price-value">{this.state.secondPrice}</span>
                  <span className="price-unit">/jour</span>
                </div>
                <button type="button" className="cal-btn" onClick={() => this.upSecondPrice()}>+</button>
              </div>

            </div>
          </div>
        </div>


        {/* range */}
        <p className="price-mt-20">À ce prix, vous êtes <span className="color">très probable</span> pour louer votre véhicule</p>
        <hr className="divider" />
        {/* <a className="price-card" href>
          <div className="price-card-text">
            <div className="left">
              <i className="fa fa-history history-icon-size" />
            </div>
            <div className="main">
              <div className="main-title">
                Prix pour d'autres durées
              </div>
              <div className="main-content">
                De 1 heure à 1 mois
              </div>
            </div>
            <div className="right">
              <i className="fa fa-chevron-right right-icon-size" />
            </div>
          </div>
        </a> */}
        <div className="note">
          <span>Les prix comprennent les frais de service de 25 % de Monbolide. Les conducteurs paient l'assurance et une partie des frais de service séparément.</span>
        </div>
        <div className="submit text-center">
          <input type="submit" name="update" defaultValue="Mettre à jour" className="update-btn" onClick={this.handleChangePrice} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    CarsReducers: state.CarsReducers
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ViewCar: AC_YOUR_CAR,
    EditCardetails: AC_EDIT_CARDETAILS,
    handleInputChange: AC_HANDLE_INPUT_CHANGE
  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(PriceSection);
