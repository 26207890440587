import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';
import { AC_GET_CONFIGURATION } from '../../actions/configurationaction';

class FuelDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
      type: 'manual',
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    this.props.ConfigList({ slug: "fuel" });
    if (localStorage.getItem("formId")) {
      this.props.viewDetails(localStorage.getItem("formId"));
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.props.handleInputChange(name, value, 'fuel')
  }
  validateForm() {
    const id = localStorage.getItem("formId");
    const fuelInfo = this.props.HostCarReducer.fuelInfo;
    var type = this.state.type;
    var fuel = "";
    const options = this.props.ConfigurationReducer.ConfigValue;
    if (options?.length && options[0]) {
      fuel = options[0].name;
    }
    if (fuelInfo) {
      if (fuelInfo.fuel) {
        fuel = fuelInfo.fuel;
      }
      if (fuelInfo.type) {
        type = fuelInfo.type;
      }
    }
    const data = {
      fuel: fuel,
      type: type,
    };
    if (fuel && type) {
      this.props.updateHostDetails(data, id, "Fuel");
      this.setState({ next: true });
    }
  }

  previous() {
    this.setState({ previous: true });
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect to="/car-facilities" />
    }
    if (previous) {
      return <Redirect to="/car-registration-detail" />;
    }
    var type = this.state.type;
    var fuel = "";
    const fuelInfo = this.props.HostCarReducer.fuelInfo;
    if (fuelInfo) {
      if (fuelInfo.fuel) {
        fuel = fuelInfo.fuel;
      }
      if (fuelInfo.type) {
        type = fuelInfo.type;
      }
    }
    const options = this.props.ConfigurationReducer.ConfigValue;
    // console.log(options)
    var fuelOptions = [];
    if (options) {
      for (let i = 0; i < options.length; i++) {
        fuelOptions.push(
          <option key={i} value={options[i].name}>
            {options[i].name}
          </option>
        );
      }
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                  <h4 className='card-header text-white text-center py-4 mb-2' style={{background:"#004AAD", textTransform:'uppercase', fontFamily: 'poppins'}}>Ajouter mon véhicule</h4>
                    <div className="container">
                      {/* <h5>Ajouter mon véhicule</h5> */}
                      <div className="title-card">Ajouter plus de détails</div>
                      <label htmlFor="" className="acc-label">Carburant</label>
                      <select className="form-control" id="sel1" name="fuel" value={fuel} onChange={(e) => this.onChangeValue(e)}>
                        {fuelOptions}
                      </select>
                      <label htmlFor="" className="acc-label mt-4">
                        Type de transmission
                      </label>
                      <div>
                        <input
                          type="radio"
                          id="other"
                          name="type"
                          value="manual"
                          checked={type === "manual"}
                          onChange={(e) => this.onChangeValue(e)}
                        />
                        <label htmlFor="other" className="pl-2">
                          Manuelle
                        </label>
                      </div>
                      <div><input type="radio" id="other2" name="type" value="automatic" checked={type === 'automatic'} onChange={(e) => this.onChangeValue(e)} /><label htmlFor="other2" className="pl-2">Automatique</label>
                      </div>
                    </div>
                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button
                        className="prev-button mr-2"
                        onClick={this.previous}
                      >
                        <span className="cobalt-Icon">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>
                        Précédent
                      </button>
                      <button
                        className="Woohoo-btn"
                        onClick={this.validateForm}
                      >
                        Confirmer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigurationReducer: state.ConfigurationReducer,
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ConfigList: AC_GET_CONFIGURATION,
      viewDetails: AC_VIEW_HOST_DETAILS,
      handleInputChange: AC_HANDLE_INPUT_CHANGE,
      updateHostDetails: AC_UPDATE_HOST_DETAILS,
    },
    dispatch
  );
}
const FuelDetailComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(FuelDetail);

export default FuelDetailComponent;
