import React from "react";
import {
  /*BrowserRouter as Router, Switch,*/ Route, Link,
} from "react-router-dom";
import AddressSection from "./addresssection";
import CalendarSection from "./calendarsection";
import CarsDashboardNavigation from "./cardashboardnavigation";
import ConditionSection from "./conditionsection";
import DeactivateSection from "./deactivatesection";
import DescriptionSection from "./descriptionsection";
import GeneralSection from "./generalsection";
import MonBolideSection from "./monbolidesection";
import InstantBookingSection from "./instantbookingsection";
import InsuranceSection from "./insurancesection";
import PhotoSection from "./photosection";
import PriceSection from "./pricesection";
// import Sheet from 'react-modal-sheet';
// import { Badge } from "antd";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//   import { faCoffee } from '@fortawesome/free-solid-svg-icons'

class CarsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDashboardCardButton: false,
      isOpen: false
    };
  }

  toggleShowDashboardCardButton = () => {    
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  count() {
    let require = {};
    if (this.props.CarsReducers.carList.requirements) {
      require = this.props?.CarsReducers?.carList?.requirements[0];
    }
    //console.log(require);
    let count = 0;
    for (let i in require) {
      if (
        require[i] === undefined ||
        require[i] === "" ||
        require[i] === null
      ) {
        count++;
      }
    }
    //console.log("count", count);
    return count;
  }
  render() {
    const pathName = this.props.location.pathname;
    // const showDashboardCardButton = this.state.showDashboardCardButton;
    const isOpen = this.state.isOpen;

    return (
      <div className="bg-container" style={{ marginTop: "-70px" }}>
        <div className="container">
          
          {/*<div className="title">
                <a href className="title-big-text">Abarth 500<span className="badge badge-size">1</span></a>
              </div>*/}
          {/* {showDashboardCardButton && 
             <div className="backdrop-dashboard"></div> } */}
          {/* <div onClick={this.toggleShowDashboardCardButton} style={{ position: "relative", flexDirection: "row-reverse", display: "flex", zIndex: 1 }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 512 512">
              <path d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z" />
            </svg>
          </div> */}

          <div className="row">
            <CarsDashboardNavigation path={pathName} />
            <div className="col-lg-9">
              <div className="right-section">
                <div className="tab-content" id="v-pills-tabContent">
                  <Route exact path="/general-tab">
                    <GeneralSection />
                  </Route>
                  <Route exact path="/instant-tab">
                    <InstantBookingSection />
                  </Route>
                  <Route exact path="/mon-bolide-tab">
                    <MonBolideSection />
                  </Route>
                  <Route exact path="/description-tab">
                    <DescriptionSection />
                  </Route>
                  <Route exact path="/address-tab">
                    <AddressSection />
                  </Route>
                  <Route exact path="/photos-tab">
                    <PhotoSection />
                  </Route>
                  <Route exact path="/calendar-tab">
                    <CalendarSection />
                  </Route>
                  <Route exact path="/price-tab">
                    <PriceSection />
                  </Route>
                  <Route exact path="/conditions-tab">
                    <ConditionSection />
                  </Route>
                  <Route exact path="/insurance-tab">
                    <InsuranceSection />
                  </Route>
                  <Route exact path="/deactivate-tab">
                    <DeactivateSection />
                  </Route>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CarsDashboard;
