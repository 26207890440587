import React from "react";
// import React from 'react';
// import { Table, Tag, Space } from 'antd';
// const columns = [
//   {
//     title: 'Nom & Prénon',
//     dataIndex: 'full_name',
//     key: 'full_name',
//     render: (text) => <a>{text}</a>,
//   },
//   {
//     title: 'Methode',
//     dataIndex: 'method',
//     key: 'method',
//   },
//   {
//     title: 'Montant',
//     dataIndex: 'amount',
//     key: 'amount',
//   },
//   {
//     title: 'Date',
//     key: 'dates',
//     dataIndex: 'dates',
//     render: (_, { dates }) => (
//       <>
//         {dates.map((date) => {
//           let color = date.length > 5 ? 'geekblue' : 'green';

//           if (date === 'loser') {
//             color = 'volcano';
//           }

//           return (
//             <Tag color={color} key={date}>
//               {date.toUpperCase()}
//             </Tag>
//           );
//         })}
//       </>
//     ),
//   },
//   {
//     title: 'Action',
//     key: 'action',
//     render: (_, record) => (
//       <Space size="middle">
//         <a>Invite {record.name}</a>
//         <a>Delete</a>
//       </Space>
//     ),
//   },
// ];
// const data = [
//   {
//     key: '1',
//     full_name: 'John Brown',
//     method: 32,
//     amount: 'New York No. 1 Lake Park',
//     dates: ['nice', 'developer'],
//   },
//   {
//     key: '2',
//     full_name: 'Jim Green',
//     method: 42,
//     amount: 'London No. 1 Lake Park',
//     dates: ['loser'],
//   },
//   {
//     key: '3',
//     full_name: 'Joe Black',
//     method: 32,
//     amount: 'Sidney No. 1 Lake Park',
//     dates: ['cool', 'teacher'],
//   },
// ];

class Payments extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="col-lg-12">
          <div className="main-sec py-3">
            <div className="car-sec">
              <img
                src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/cards_y6iams.png"
                className="car-img"
                style={{ width: "60%" }}
                alt=""
              />
              <h2>Vous n'avez pas encore de transactions sur Monbolide.</h2>
            </div>
            {/* <Table columns={columns} dataSource={data} /> */}
          </div>
          
        </div>
      </div>
    );
  }
}
export default Payments;
