import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import API from "../../common/api";
import { AC_YOUR_CAR } from "../../actions/carsaction";

class GeneralSection extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("carId")){
      this.props.ViewCar({ id: localStorage.getItem("carId") });
    }
  }
  render() {
    const carList = this.props.CarsReducers.carList;
    var name = "";
    var address = "";
    var logo = "";
    if (carList.carmodel) {
      name =
        carList.carmodel.make.brandname + " " + carList.carmodel.model.name;
      // logo = API.LIVEURL + carList.carmodel.make.logo;
    }
    if (carList?.carimage) {
      logo = API.IMAGEURL + carList?.carimage?.mainimage;
    }else {
      logo = "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/voiture-monbolide_akyfyn.png";
    }
    if (carList.parking) {
      address = carList.parking.formatted_address;
    }
    const reviews = this.props.CarsReducers.reviews;
    var totalreviews = 0;
    var rentals = 0;
    if (reviews.reviews) {
      totalreviews = reviews.reviews;
    }
    if (reviews.rentals) {
      rentals = reviews.rentals.length;
    }
    return (
      <div className="list-car-card border-0">
        <div className="dashboard-header">
          <div className="row">
            <div className="col-lg-5">
              <div className="dash-img">
                <img src={logo} alt="" style={{ height: "150px" }} />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="dash-content">
                <div className="car-title">{name}</div>
                <div>{address}</div>
                <hr className="seperator" />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="rent">{`${rentals === 0 ? "Aucune location" : rentals  + "locations"}`}</div>
                </div>
                <div className="col-lg-6">
                  <div className="rent">{`${totalreviews === 0 ? "Aucun avis" : totalreviews  + "avis"}`}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div  className="row">
                      <div  className="col-lg-7 offset-lg-5">
                          <div  className="rental-reviews">
                              <div  className="row">
                                  <div  className="col-lg-6">
                                        <div  className="rent">
                                          0 rentals
                                        </div>
                                    </div>
                                    <div  className="col-lg-6">
                                          <div  className="rent">
                                            0 reviews
                                          </div>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div> */}
        </div>
        {carList.status === 2 ? (
          <div className="view">
            <h6>Inscription du véhicule en cours</h6>
            <p className="m-0">
              Notre équipe mettra bientôt votre annonce en ligne. Vous recevrez
              un courriel de confirmation lorsque cela aura été fait.
            </p>
          </div>
        ) : (
          ""
        )}
        {carList.status === 1 ? (
          <div className="view">
            <h6>Inscription du véhicule terminée</h6>
            <p className="m-0">
              Notre équipe a vérifié votre inscription et l'a mise en ligne.
            </p>
          </div>
        ) : (
          ""
        )}
        <div className="listing-card">
          <div className="listing-title">
            <h2>Prêt à être loué !</h2>
            <div className="listing-percentage">
              <div className="list-p">100%</div>
              <div className="list-status">Achèvement de l'inscription</div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "100%" }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
            <div className="row">
              {/*<div className="col-lg-6">
                      <div className="flex-list">
                        <div className="calendar-i">
                          <i className="fa fa-calendar">	</i>
                        </div>
                        <div className="list-content">
                          <h3>check your calendar</h3>
                          <p className="m-0">Indicate when your car is available</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="flex-list">
                        <div className="calendar-i">
                          <i className="fa fa-calendar">	</i>
                        </div>
                        <div className="list-content">
                          <h3>check your calendar</h3>
                          <p className="m-0">Indicate when your car is available</p>
                        </div>
                      </div>
                    </div>*/}
              <div className="col-lg-6">
                <div className="flex-list">
                  <div className="calendar calendar-bg">
                    <i
                      className="fa fa-check-circle tick-clr mr-3"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="list-content">
                    <p className="m-0">Détails de base Terminé</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="flex-list">
                  <div className="calendar calendar-bg">
                    <i
                      className="fa fa-check-circle tick-clr mr-3"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="list-content">
                    <p className="m-0">Photos ajoutées</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="listing-card p-0">
          <div className="service-titl">
            <h2>Un service fiable</h2>
          </div>
          <div className="listing-title">
            <div className="row">
              <div className="col-lg-6">
                <div className="flex-list">
                  <div className="calendar calendar-bg">
                    <i
                      className="fa fa-check-circle tick-icon-size1 mr-3"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="list-content">
                    <h3 className="head-clr">Tracking véhicule</h3>
                    <p className="m-0">
                      Possibilité de profiter de notre service de tracking du
                      véhicule.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="flex-list">
                  <div className="calendar calendar-bg">
                    <i
                      className="fa fa-check-circle tick-icon-size1 mr-3"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="list-content">
                    <h3 className="head-clr">Des conducteurs fiables</h3>
                    <p className="m-0">
                      Sélectionnés par notre équipe et examinés par d'autres
                      propriétaires de voitures.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="flex-list">
                  <div className="calendar calendar-bg">
                    <i
                      className="fa fa-check-circle tick-icon-size1 mr-3"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="list-content">
                    <h3 className="head-clr">Paiements sécurisés en ligne</h3>
                    <p className="m-0">
                      Recevez le paiement sur votre compte bancaire 5 jours
                      ouvrables après le règlement.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="flex-list border-0 pl-5">
                  <div className="prev-button">En savoir plus</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    CarsReducers: state.CarsReducers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewCar: AC_YOUR_CAR }, dispatch);
}

const GeneralSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSection);

export default GeneralSectionComponent;
