import React, { useState, useEffect } from "react";
// import moment from "moment";

import {
  AC_PAYDUNYA_PAYMENT_TOKEN,
  AC_STRIPE_PAY_DATA_RESET,
} from "../../actions/searchcarsaction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../searchcar/CheckoutForm";

var paydunya = require("paydunya");

const prodToken = {
  privateKey: "live_private_acYXwGhVKOUaYoYtjjctzBJ2lDo",
  publickKey: "live_public_Gh7Wd7Cec5M1AoflXsjdFre6sph",
  masterKey: "jpYV0lgC-xiOa-Mkca-MvvZ-VUeRfbaE4DPX",
  token: "v3q54nUBxS7MvNFV12kq",
};

// const testToken = {
//     privateKey: "test_private_RNHKKk3ysVwbSMepJmmPrDwBuag",
//     publickKey: "test_public_IjjNNtDzNK9yilBnNGmkQxldV0F",
//     masterKey: "jpYV0lgC-xiOa-Mkca-MvvZ-VUeRfbaE4DPX",
//     token: "ryoOt6PVm7IqzhGEVRzg",
//     mode: 'test'
// }

// Setup paydunya API keys.
var setup = new paydunya.Setup({
  masterKey: prodToken.masterKey,
  privateKey: prodToken.privateKey,
  publicKey: prodToken.publickKey,
  token: prodToken.token,
  mode: prodToken.mode,
  // mode: "test", // Optionnel. Utilisez cette option pour les paiements tests.
});

// var setup = new paydunya.Setup({
//     masterKey   : prodToken.masterKey,
//     privateKey  : prodToken.privateKey,
//     publicKey   : prodToken.publickKey,
//     token       : prodToken.token,
//     // mode        : prodTokwen.mode
//     // mode: "test", // Optionnel. Utilisez cette option pour les paiements tests.
// });

// Checkout Store Configuration
// var store = new paydunya.Store({
//     name: "MONBOLIDE", // Seul le nom est requis
//     tagline: "Plateforme de location de véhicule",
//     phoneNumber: "2250700780830",
//     postalAddress: "Bingerville Cité Palma lot 1",
//     websiteURL: "https://monbolide.herokuapp.com/",
//     logoURL: "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/MB111-Plan_de_travail_1_octwxu.png",
//     returnURL: "https://monbolide.herokuapp.com/payment-success",
//     cancelURL: 'https://monbolide.herokuapp.com/rental'
// });

var store = new paydunya.Store({
  name: "MONBOLIDE", // Seul le nom est requis
  tagline: "Plateforme de location de véhicule",
  phoneNumber: "2250700780830",
  postalAddress: "Bingerville Cité Palma lot 1",
  websiteURL: "https://www.monbolide.com/",
  logoURL:
    "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/MB111-Plan_de_travail_1_octwxu.png",
  returnURL: "https://www.monbolide.com/payment-success",
  cancelURL: "https://www.monbolide.com/rental",
});

// const estimatedPrice = (trips) => {
//     const price = trips.carId?.price?.price
//     const { checkin: dateStartRange, checkout: dateEndRange } = trips;

//     let startDate = moment(dateStartRange, "YYYY-MM-DD HH:mm");

//     var endDate = moment(dateEndRange, "YYYY-MM-DD HH:mm");

//     let minutes = endDate.diff(startDate, "minutes");
//     let hours = minutes / 60;
//     hours = Math.round((hours + Number.EPSILON) * 100) / 100;

//     // return hours * price
//     return price
// }

const PaymentMethod = ({
  trips,
  setPaydunyaToken,
  AccountReducer,
  stripeDataReset,
}) => {
  // const testKey = "pk_test_51JEBCILKF83CRjpMMOew5WrrGNycNua28qc5R11uad2LCpzE8EuI6uTNXVRRC8jjCa6kZbLSfKBOKhNxDcuaMGDH00gnis33q9";
  const prodKey =
    "pk_live_51JEBCILKF83CRjpMqR2q2gIcnt3Kcz7BdKKJy8Ni2azSZGq8ZLpomNd4uKT4v6Q7h8LIzWIxmFcxTl1vD07ZR2d700f3EdL4eB";

  // const totalEstimedPrice = estimatedPrice(trips)
  const accountInfo = AccountReducer.accountInfo;
  const [scaling, setScaling] = useState(true);
  const stripePromise = loadStripe(prodKey);
  const userId = localStorage.getItem("siteUser");

  const [formData] = useState({
    customerId: userId,
    ownerId: trips.ownerId,
    carId: trips?.carId?._id,
    tripStatus: 2,
    checkin: trips.checkin,
    checkout: trips.checkout,
  });

  useEffect(() => {
    // stripeDataReset({})
    // return true
  }, []);

  const booking = () => {
    // const userId = localStorage.getItem("siteUser");
    if (trips) {
      var invoice = new paydunya.CheckoutInvoice(setup, store);

      invoice.addItem(
        trips?.carId?.carmodel?.make?.brandname +
          " " +
          trips?.carId?.carmodel?.model?.name,
        1,
        trips.price,
        trips.price
      );

      invoice.addCustomData("tripsID", trips?._id);
      invoice.addCustomData("email", trips?.customerId.email);
      // invoice.addCustomData("")
      invoice.totalAmount = trips.price;
      invoice
        .create()
        .then(function () {
          setPaydunyaToken(invoice.token);
          window.location.href = invoice.url;
        })
        .catch(function () {});
    }
  };

  return (
    <div id="payment-action--container">
      <div className="titlePayementCard bg-danger">
        <i className="fas fa-money-bill-wave"></i> Payer maintenant{" "}
        {trips.price} F
      </div>
      <div id="payment-btn--container">
        <div>
          <button
            title="Payer via mobile money"
            className="btn btn-outline-primary btn-action mr-2"
            onClick={(e) => booking()}
          >
            <i className="fas fa-mobile-alt fas-lg"></i>
            &nbsp; Paiement Mobile
          </button>
        </div>
        <div>
          <button
            title="Payer par carte de credit"
            type="button"
            className={`btn btn-action text-white  ${
              scaling ? "btn-primary growingIconPayment" : "btn-outline-primary"
            }`}
            onClick={() => {
              setScaling(!scaling);
            }}
          >
            <i className="fas fa-credit-card"></i> Carte de crédit
          </button>
        </div>
      </div>

      {scaling && (
        <div id="strip-payment--container">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              accountInfo={accountInfo}
              amount={trips.price}
              checkin={trips.checkin}
              checkout={trips.checkout}
              formData={formData}
              tripsID={trips._id}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    //   SearchCarsReducer: state.SearchCarsReducer,
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPaydunyaToken: AC_PAYDUNYA_PAYMENT_TOKEN,
      stripeDataReset: AC_STRIPE_PAY_DATA_RESET,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
