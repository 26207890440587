const initialState = {
  carList: [],
  tripsCar: [],
  modelList: [],
  reviews: {},
  numberInfo: {},
  fuelInfo: {},
  carmodelInfo: {},
  featureInfo: {},
  parkingInfo: {},
  priceInfo: {},
  makeid: "",
  modelid: "",
};

function CarsReducers(state = initialState, action) {
  switch (action.type) {
    case "LIST_CAR_MODEL":
      return Object.assign({}, state, {
        modelList: action.payload,
        modelid: (action.payload.length >0) ? action.payload[0]?._id : "",
      });
    case "VIEW_YOUR_CAR":
      return Object.assign({}, state, {
        carList: action.payload,
        reviews: action.reviews,
        numberInfo: action.payload.number,
        fuelInfo: action.payload.fuel,
        featureInfo: action.payload.feature,
        parkingInfo: action.payload.parking,
        priceInfo: action.payload.price,
        carmodelInfo: action.payload.carmodel,
        modelList: action.model,
        makeid: action.payload?.carmodel?.make?._id,
        modelid: action.payload?.carmodel?.model?._id,
      });
    case "UPDATE_CAR_DETAILS":
      if (action.module === "Number") {
        return Object.assign({}, state, {
          numberInfo: {
            ...state.numberInfo,
            [action.name]: action.value,
          },
        });
      }
      if (action.module === "Price") {
        return Object.assign({}, state, {
          priceInfo: {
            ...state.priceInfo,
            [action.name]: action.value,
          },
        });
      }
      if (action.module === "Fuel") {
        return Object.assign({}, state, {
          fuelInfo: {
            ...state.fuelInfo,
            [action.name]: action.value,
          },
        });
      }
      if (action.module === "Make") {
        return Object.assign({}, state, {
          carmodelInfo: {
            ...state.carmodelInfo,
            [action.name]: action.value,
          },
          makeid: action.value,
        });
      }
      if (action.module === "Model") {
        return Object.assign({}, state, {
          carmodelInfo: {
            ...state.carmodelInfo,
            [action.name]: action.value,
          },
          modelid: action.value,
        });
      }
      if (action.module === "Feature") {
        return Object.assign({}, state, {
          featureInfo: {
            ...state.featureInfo,
            [action.name]: action.value,
          },
        });
      }
      if (action.module === "ParkingAddress") {        
        return Object.assign({}, state, {
          parkingInfo: {
            formatted_address: action.value?.formatted_address,
            location: {
              lat: action.value?.location?.lat(),
              lng: action.value?.location?.lng()
            }
          }
        });
      }
      break;
    case "EDIT_CARDETAILS":
      return Object.assign({}, state, {
        carList: action.payload,
        numberInfo: action.payload.number,
        fuelInfo: action.payload.fuel,
        featureInfo: action.payload.feature,
        carmodelInfo: action.payload.carmodel,
        modelList: action.model,
        makeid: action.payload.carmodel?.make,
        modelid: action.payload.carmodel?.model,
      });

    case "ADD_CARIMAGE":
      return Object.assign({}, state, {
        carList: action.payload,
      });

    case "ADD_UNAVAILIBILITY":
      return Object.assign({}, state, {
        carList: action.payload.data,
      });

    case "DELETE_UNAVAILIBILITY":
      return Object.assign({}, state, {
        carList: action.payload.data,
      });

    case "INSTANT_LOCATION":
      return Object.assign({}, state, {
        carList: action.payload,
        numberInfo: action.payload?.number,
        fuelInfo: action.payload?.fuel,
        featureInfo: action.payload?.feature,
        carmodelInfo: action.payload?.carmodel,
        modelList: action.model,
        makeid: action.payload?.carmodel?.make,
        modelid: action.payload?.carmodel?.model,
        snapshot: action.payload.snapshot === true ? false : true,
      });

    case "UPDATE_REQUIRE_CAR":
      return editCarRequire(action, state);

    default:
      return state;
  }

  function editCarRequire(action, state) {
    if (action.name === "hireDayMin") {
      return Object.assign({}, state, {
        carList: {
          ...state.carList,
          hireDayMin: action.value,
        },
      });
    }

    if (action.name === "hireDayMax") {
      return Object.assign({}, state, {
        carList: {
          ...state.carList,
          hireDayMax: action.value,
        },
      });
    }

    if (action.name === "preparationTimeCar") {
      return Object.assign({}, state, {
        carList: {
          ...state.carList,
          preparationTimeCar: action.value,
        },
      });
    }

    if (action.name === "mobilityArea") {
      return Object.assign({}, state, {
        carList: {
          ...state.carList,
          mobilityArea: action.value,
        },
      });
    }

    if (action.name === "withDriver") {
      return Object.assign({}, state, {
        carList: {
          ...state.carList,
          withDriver: action.value,
        },
      });
    }

    if (action.name === "hireRequire") {
      return Object.assign({}, state, {
        carList: {
          ...state.carList,
          hireRequire: action.value,
        },
      });
    }
  }
}
export default CarsReducers;
