import styled from "@emotion/styled";

const Row = styled.div`
  max-width: 475px;
  margin: 30px auto;  
  position: relative;

`;

export default Row;
