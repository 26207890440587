import axios from "axios";
import { Success } from "../common/swal";
import { toast } from "react-toastify";

const SEARCHCARLIST = "SEARCHCARLIST";
const PRICERANGELIST = "PRICERANGELIST";
const MODELCARLIST = "MODELCARLIST";
const START_LOADING = "START_LOADING";
const STRIPE_PAY = "STRIPE_PAY";
const STRIPE_PAY_LOADING = "STRIPE_PAY_LOADING";
const STRIPE_PAY_DATA_RESET = "STRIPE_PAY_DATA_RESET";

const PAYDUNYA_PAYMENT_TOKEN = "PAYDUNYA_PAYMENT_TOKEN";
const PAYDUNYA_PAYMENT_TOKEN_RESET = "PAYDUNYA_PAYMENT_TOKEN_RESET";
const END_LOADING = "END_LOADING";

export function AC_PAYDUNYA_PAYMENT_TOKEN(token) {
  return function (dispatch) {
    dispatch({ type: PAYDUNYA_PAYMENT_TOKEN, payload: token });
  };
}

export function AC_PAYDUNYA_SEND_PAYMENT_TOKEN(token) {
  return function (dispatch) {
    dispatch({ type: START_LOADING, payload: true });

    return axios.post(URL.API.paydunyaPayTokenSend, token).then(({ data }) => {
      if (data.success) {
        toast.success("Paiement effectué ", { position: "top-center" });
      }
      dispatch({ type: PAYDUNYA_PAYMENT_TOKEN_RESET });
    });
  };
}

export function AC_STRIPE_PAY_DATA_RESET(){
  return function (dispatch) {
    dispatch({ type: STRIPE_PAY_DATA_RESET });
  }
}

export function  AC_STRIPE_PAY (paymentData)  {
  return function async (dispatch)  {
    dispatch({ type: STRIPE_PAY_LOADING, payload: true });

    return axios.post(URL.API.stripePay, paymentData).then(({ data }) => {
      // if (data.success) {
      //   toast.success("Paiement effectué ", { position: "top-center" });
      // }
      // if(data.requires_action){
      //   // const { paymentIntent, error } = await stripe.confirmCardPayment(
      //   //   res.data.clientSecret
      //   // );
      // }
      dispatch({ type: STRIPE_PAY, payload: data });
      return data;
    }).catch(e => {
      toast.error("Un erreur s'est produite", { position: "top-center" })
     
    })
  };
}

export function AC_SEARCH_CAR(formData) {
  return function (dispatch) {
    return axios.post(URL.API.searchCar, formData).then(({ data }) => {
      dispatch({
        type: SEARCHCARLIST,
        payload: data.carlist,
        pricerange: data.price,
      });
    });
  };
}

export function AC_PRICE_FILTER(formData) {
  return function (dispatch) {
    return axios.post(URL.API.priceFilter, formData).then(({ data }) => {
      dispatch({ type: PRICERANGELIST, payload: data.data });
    });
  };
}

export function AC_MODEL_CAR(formData) {
  return function (dispatch) {
    return axios.post(URL.API.modelCar, formData).then(({ data }) => {
      dispatch({ type: MODELCARLIST, payload: data.data });
    });
  };
}

export function AC_ADD_TRIP(formData) {
  return function (dispatch) {
    dispatch({ type: START_LOADING });

    return axios.post(URL.API.AddTrips, formData).then(({ data }) => {
      if (data.message) {
        Success(data.message);
        return data;
      }
      dispatch({ type: END_LOADING });
    });
  };
}
