import axios from 'axios';
import URL from "../common/api";
const LIST_CAR_MODEL      = 'LIST_CAR_MODEL' ;
// const HOST_CAR            = 'VIEW_HOST_DETAILS' ;
const VIEW_HOST_DETAILS   = 'VIEW_HOST_DETAILS' ;
const HANDLE_INPUT_CHANGE = 'HANDLE_INPUT_CHANGE' ;
const UPDATE_HOST_DETAILS = 'UPDATE_HOST_DETAILS' ;



export function AC_GET_MODEL(formData) {
  return function(dispatch) {
    return axios.post(  URL.API.getCarModel,formData)
      .then(({ data }) => {
        dispatch({type: LIST_CAR_MODEL, payload:data.data});
    });
  };
}
const userId = localStorage.getItem('siteUser')

export function AC_HOST_CAR(formData,module) {
  return function(dispatch) {
    return axios.post( URL.API.HostCar,formData, { headers : {authorization:userId } })
      .then(({ data }) => {
        // console.log(data.status)
          if(data.status){
          localStorage.setItem('formId',data.data._id);               
        }
    });
  };
}

export function AC_VIEW_HOST_DETAILS(id) {
  return function(dispatch) {
    return axios.post(
      URL.API.ViewHostDetails,
      {},
      { headers : { authorization:id } },
      )
      .then(({ data }) => {
        if(data.status){
          if(data.status === 1){
            dispatch({type: VIEW_HOST_DETAILS, payload:data.data, model:data.model});
          } else {
            dispatch({type: VIEW_HOST_DETAILS, model:data.model});
            localStorage.removeItem('formId');
          }
        }else{
          localStorage.removeItem('formId');
        }
    });
  };
}

export function AC_UPDATE_HOST_DETAILS(formData,id,module) {
  return function(dispatch) {
    return axios.post(
      URL.API.UpdateHostDetails,
      formData,
      { headers : { module:module,authorization:id } },
      )
      .then(({ data }) => {
        if(data.status !== 2){
          if(data.data){
          dispatch({type: UPDATE_HOST_DETAILS, payload:data.data, module:module});
          }
        }
        if(data.status === 2){
          localStorage.removeItem('formId');
        }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value,module) {
  return function(dispatch) {
    dispatch({type: HANDLE_INPUT_CHANGE,name:name,value:value,module:module})
  };
}
