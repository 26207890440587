import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AC_PAYDUNYA_SEND_PAYMENT_TOKEN } from "../../actions/searchcarsaction";
// import Loading from "../../common/loading";
// import { makeStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom"

// const useStyles = makeStyles({
//   root: {
//     minWidth: 275,
//     textAlign: "center !important",
//   },
//   bullet: {
//     display: "inline-block",
//     margin: "0 2px",
//     transform: "scale(0.8)",
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
// });

const Paymentsuccess = (props) => {
  // const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;

  useEffect(() => {
    const { paydunyaToken } = props.SearchCarsReducer;
    if (paydunyaToken !== "") {
      props.sendPaydunyaToken({ token: paydunyaToken });
    }
  }, []);

  // const { isLoading } = props.SearchCarsReducer;

  return (
    <div  className="bg-container mt-5">
      <div  className="container">
        {/* {isLoading ? <Loading /> : <div>PAIEMENT SUCCESS !!!</div>} */}
        <div  className="wrapperAlert">

          <div  className="contentAlert">

            <div  className="topHalf">

              <p><svg viewBox="0 0 512 512" width="100" title="check-circle">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                </svg></p>
              <h1>Félicitation !</h1>

            <ul  className="bg-bubbles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            </div>

            <div  className="bottomHalf">

              <p>Votre paiment a été effectué!,Veuiller consulter vos mails pour consulter votre reçu de paiment...</p>

              <Link
                    to="rental"
                    type="button"
                    className="alertMO btn btn-primary"
                  >
                    Mon compte
                  </Link>

            </div>

          </div>        

        </div>
        {/* <Card className={classes.root}>
          <CardContent>
            
            <Typography variant="h3" component="h2" className={classes.title}>
            Veuillez verifier votre messagerie pour une eventuel facture !
            </Typography><Typography variant="h3" component="h2">
              PAIEMENT SUCCES
            </Typography>
          </CardContent>
         
        </Card> */}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    SearchCarsReducer: state.SearchCarsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendPaydunyaToken: AC_PAYDUNYA_SEND_PAYMENT_TOKEN,
    },
    dispatch
  );
}

const PaymentsuccessComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Paymentsuccess);

export default PaymentsuccessComponent;
