import React from 'react';
// import { BrowserRouter as Router} from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_FAQ_DESCRIPTION } from '../../actions/faqaction';

class FaqDescription extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.viewFaq({id:id});
  }
  render() {
    const faqInfo      = this.props.FaqReducer.FaqInfo;
    if(faqInfo){
      var question = faqInfo.question;
      var answer   = faqInfo.answer;
    }
    return(
      <section className="layer-section faq-description">
        <div className="container">
        <h1>{question}</h1>
        <p>{answer}</p>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    FaqReducer :state.FaqReducer
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({viewFaq:AC_VIEW_FAQ_DESCRIPTION},dispatch);
}

const FaqDescriptionComponent = connect(mapStateToProps, mapDispatchToProps)(FaqDescription);

export default FaqDescriptionComponent;
