import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	AC_VIEW_HOST_DETAILS,
	AC_HANDLE_INPUT_CHANGE,
	AC_UPDATE_HOST_DETAILS,
} from "../../actions/hostcaraction";

class CarPrice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			previous: false,
			next: false,
			price: 5000,
			secondPrice: 5000,
			range: 5000,
			secondRange: 5000
		}
		this.validateForm = this.validateForm.bind(this);
		this.previous = this.previous.bind(this);
		this.add = this.add.bind(this);
		this.minus = this.minus.bind(this);
	}
	componentDidMount() {
		if (localStorage.getItem('formId')) {
			this.props.viewDetails(localStorage.getItem('formId'));
		}
	}
	validateForm() {
		let price = this.state.price;
		let secondPrice = this.state.secondPrice;
		const id = localStorage.getItem('formId');
		const priceInfo = this.props.HostCarReducer.priceInfo;
		if (priceInfo) {
			if (priceInfo.price) {
				price = priceInfo.price;
			}
			if (priceInfo.secondPrice) {
				secondPrice = priceInfo.secondPrice;
			}
		}
		const data = {
			price: price,
			secondPrice: secondPrice
		}
		this.props.updateHostDetails(data, id, 'Price');
		this.setState({ next: true })
	}
	add = (e) => {
		let price = this.state.price;
		let secondPrice = this.state.secondPrice;		
		if(e.target.name === "insideAdd"){
			this.props.handleInputChange("price", price + this.state.range, 'carprice')
			this.setState({ price: price + this.state.range });
		}

		if(e.target.name === 'outsideAdd'){
			this.props.handleInputChange("secondPrice", secondPrice + this.state.secondRange, 'carprice')
			this.setState({ secondPrice: secondPrice + this.state.secondRange });
		}
		
	}
	minus = (e) => {		
		let price = this.state.price;
		let secondPrice = this.state.secondPrice;
		// var price = this.state.price;
		if (e.target.name === "insideMinus") {
			// diminuer le prix dans la ville 			
			if (price > 1) {
				this.props.handleInputChange("price", price - this.state.range, 'carprice')
				this.setState({ price: price - this.state.range });
			}
		}
		if (e.target.name === "outsideMinus") {
			// diminuer le prix hors de la ville 
			if (secondPrice > 1) {
				this.props.handleInputChange("secondPrice", secondPrice - this.state.secondRange, 'carprice')
				this.setState({ secondPrice: secondPrice - this.state.secondRange });
			}
		}



		// if (price > 1) {
		// 	this.props.handleInputChange("price", price - this.state.range, 'carprice')
		// 	this.setState({ price: price - this.state.range });
		// }
	}
	previous() {
		this.setState({ previous: true })
	}
	render() {
		const next = this.state.next;
		const previous = this.state.previous;
		if (next) {
			return <Redirect push={true} to="/customer-care" />
		}
		if (previous) {
			return <Redirect to="/car-features" />
		}
		let price = this.state.price;
		let secondPrice = this.state.secondPrice;
		const priceInfo = this.props.HostCarReducer.priceInfo;
		if (priceInfo) {
			if (priceInfo.price) {
				price = priceInfo.price;
				// this.setState({price:priceInfo.price})
				// this.state.price = ;
			}
			if (priceInfo.secondPrice) {
				secondPrice = priceInfo.secondPrice;
			}
		}
		return (
			<section>
				<div className="form-layout">
					<div className="container">
						<div className="row">
							<div className="col-md-6 offset-md-3">
								<div className="list-my-car">
									<div className="list-top">
										<div className="progress" style={{ height: "8px" }}>
											<div className="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<div className="list-car-card">
										<h4 className='card-header text-white text-center py-4 mb-2' style={{ background: "#004AAD", textTransform: 'uppercase', fontFamily: 'poppins' }}>Ajouter mon véhicule</h4>
										<div className="container">
											{/* <div className="d-flex justify-content-between">
												<h5>Ajouter mon véhicule</h5>
												<div className="prev-button">info</div>
											</div> */}
											<div className="title-card"><i className="fas fa-money-bill-alt"></i> Prix de référence</div>
											<div className="container-price">
												<div className="title-price">Dans la ville de stationnement</div>
												<div className="price-flex mb-4">

													<button type="button" name="insideMinus" onClick={(e) => this.minus(e)} className="cal-btn mr-2">-</button>
													<div className="price-wrapper">
														<span className="price-value">{price} FCFA</span>
														<span className="price-unit">/ jour</span>
													</div>
													<button type="button" name="insideAdd" onClick={(e) => this.add(e)} className="cal-btn ml-2">+</button>
												</div>
											</div>

											<div className="container-price">
												<div className="title-price">Hors de la ville de stationnement</div>
												<div className="price-flex mb-4">
													<button type="button" name="outsideMinus" onClick={(e) => this.minus(e)} className="cal-btn mr-2">-</button>
													<div className="price-wrapper">
														<span className="price-value">{secondPrice} FCFA</span>
														<span className="price-unit">/ jour</span>
													</div>
													<button type="button" name="outsideAdd" onClick={(e) => this.add(e)} className="cal-btn ml-2">+</button>
												</div>
											</div>


											<div className="price-desc">À ce prix, vous êtes <span>très probable</span> pour louer un <span>Véhicule</span>.</div>
											<div className="list-info mt-4 mb-2">Suivez nos recommandations pour attirer les conducteurs et maximiser vos revenus 💰.</div>
										</div>

									</div>
									<div className="list-bottom">
										<div className="btn-nav">
											<button className="prev-button mr-2" onClick={this.previous}>
												<span className="cobalt-Icon">
													<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
														<path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
													</svg>
												</span>Précédent
											</button>
											<button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
function mapStateToProps(state) {
	return {
		HostCarReducer: state.HostCarReducer,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			viewDetails: AC_VIEW_HOST_DETAILS,
			handleInputChange: AC_HANDLE_INPUT_CHANGE,
			updateHostDetails: AC_UPDATE_HOST_DETAILS,
		},
		dispatch
	);
}

const CarPriceComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(CarPrice);

export default CarPriceComponent;
