import React from "react";
import {
  Emailvalidate,
  Validpassword,
  PhoneValidate,
  CodeValidate,
} from "../../common/validate";
import {
  AC_AUTHORIZATION,
  // AC_ACCOUNT_CREATION,
  AC_FACEBOOK_AUTH,
  AC_GOOGLE_AUTH,
  AC_ADD_USER_TEMP,
} from "../../actions/accountaction";
import { validateEmail } from "../../utils/validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReCAPTCHA from "react-google-recaptcha";

// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import GoogleLogin from "react-google-login";
import "./AuthenticationStyle.css";
import countries from "../accounts/countries";

// require('dotenv').config()
const recaptchaRef = React.createRef();
// console.log(process.env.RECAPTCHA_SITEKEY)
class Authorization extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formValidated: false,
      signup: true,
      code: "",
      signin: false,
      signupEmail: false,
      firstname: "",
      lastname: "",
      mobilenumber: "",
      email: "",
      loading: false,
      password: "",
      repassword: "",
      loginemail: "",
      loginpassword: "",
      vehicle1: false,
      vehicle2: true,
      firstnameError: false,
      lastnameError: false,
      mobilenumberError: false,
      emailError: false,
      passwordError: false,
      repasswordError: false,
      codeError: true,
      validemailError: false,
      validpasswordError: false,
      vehicle1Error: false,
      country: { code: "CI", label: "Cote d'Ivoire", phone: "225" },
      countryCode: "",
    };
    this.signupValidateForm = this.signupValidateForm.bind(this);

  }

  onchangeValue(e) {
    const name = e.target.name;
    const value = e.target.value;
    const check = e.target.checked;
    const validemailcheck = Emailvalidate(value);
    const validpasswordcheck = Validpassword(value);
    const validphonenumberCheck = PhoneValidate(value);
    const validCodeCheck = CodeValidate(value);

    if (name === "code") {
      if (value) {
        if (validCodeCheck) {
          this.setState({
            code: value,
            validCodeCheck: true,
            codeError: false,
          });
        } else {
          this.setState({
            code: value,
            validCodeCheck: false,
            codeError: true,
          });
        }
      } else {
        this.setState({ code: value, validCodeCheck: false, codeError: true });
      }
    }
    if (name === "repassword") {
      if (value === this.state.password)
        this.setState({ repassword: value, repasswordError: false });
      else this.setState({ repassword: value, repasswordError: true });
    }
    if (name === "firstname") {
      if (value) {
        this.setState({ firstname: value, firstnameError: false });
      } else {
        this.setState({ firstname: value, firstnameError: true });
      }
    }
    if (name === "lastname") {
      if (value) {
        this.setState({ lastname: value, lastnameError: false });
      } else {
        this.setState({ lastname: value, lastnameError: true });
      }
    }

    if (name === "mobilenumber") {
      if (value) {
        if (validphonenumberCheck) {
          this.setState({
            mobilenumber: value,
            mobilenumberError: false,
          });
        } else {
          this.setState({
            mobilenumber: value,
            mobilenumberError: true,
          });
        }
      } else {
        this.setState({
          mobilenumber: value,
          mobilenumberError: true,
        });
      }
    }

    if (name === "email") {
      if (value) {
        if (validemailcheck) {
          this.setState({
            email: value,
            emailError: false,
            validemailError: false,
          });
        } else {
          this.setState({
            email: value,
            emailError: false,
            validemailError: true,
          });
        }
      } else {
        this.setState({
          email: value,
          emailError: true,
          validemailError: false,
        });
      }
    }

    if (name === "password") {
      if (value) {
        if (validpasswordcheck) {
          this.setState({
            password: value,
            passwordError: false,
            validpasswordError: false,
          });
        } else {
          this.setState({
            password: value,
            passwordError: false,
            validpasswordError: true,
          });
        }
      } else {
        this.setState({
          password: value,
          passwordError: true,
          validpasswordError: false,
        });
      }
    }
    if (name === "vehicle1") {
      if (value) {
        this.setState({ vehicle1: check, vehicle1Error: false });
      } else {
        this.setState({ vehicle1: check, vehicle1Error: true });
      }
    }
    if (name === "vehicle2") {
      this.setState({ vehicle2: check });
    }
  }

  registerUserInfo = (e) => {
    e.preventDefault();
  };

  async signupValidateForm(e) {
    e.preventDefault();
  
    const {
      firstname,
      lastname,
      mobilenumber,
      email,
      password,
      repassword,
      vehicle1,
      country
    } = this.state;
  
    const countryPhone = country.phone;
  
    // Validation des champs
    const isValid = this.validateFormFields();
  
    if (isValid) {
      const token = await recaptchaRef.current.executeAsync();
      this.setState({ loading: true });
  
      const formData = {
        firstname: firstname,
        lastname: lastname,
        mobilenumber: countryPhone + mobilenumber,
        email: email.toLowerCase().trim(),
        password: password,
        countryPhone: countryPhone,
        token: token
      };
  
      // Enregistrer l'utilisateur temporaire
      this.props.AddUserTemp(formData).then((response) => {
        if (response.success) {

          localStorage.setItem('email', formData.email);
          localStorage.setItem('password', formData.password);

          // Si l'inscription est réussie, connecter automatiquement l'utilisateur
          const authData = {
            email: formData.email,  // Utiliser l'email inscrit
            password: formData.password  // Utiliser le même mot de passe
          };
  
          this.props.SignIn(authData)  // Connexion automatique après inscription
            .then(() => {
              this.setState({
                loading: false,
                password: "",
                repassword: "",
                firstname: "",
                lastname: "",
                email: "",
                mobilenumber: "",
                vehicle1: false,
                vehicle2: false,
                next: true
              });
            })
            .catch(err => {
              console.error("SignIn error: ", err);
              Error("An error occurred during the login process.");
            });
        } else {
          Error(response.message);
        }
      }).catch(err => {
        console.error("Registration error: ", err);
        Error("An error occurred during registration.");
      }).finally(() => {
        this.setState({ loading: false });
        recaptchaRef.current.reset();  // Réinitialiser le token de reCAPTCHA
      });
    }
  }
  
  validateFormFields() {
    const {
      firstname,
      lastname,
      mobilenumber,
      email,
      password,
      repassword,
      vehicle1
    } = this.state;
  
    let formValid = true;
  
    // Valider chaque champ et mettre à jour les états d'erreur en conséquence
    if (!firstname) {
      this.setState({ firstnameError: true });
      formValid = false;
    } else {
      this.setState({ firstnameError: false });
    }
  
    if (!lastname) {
      this.setState({ lastnameError: true });
      formValid = false;
    } else {
      this.setState({ lastnameError: false });
    }
  
    if (!mobilenumber) {
      this.setState({ mobilenumberError: true });
      formValid = false;
    } else {
      this.setState({ mobilenumberError: false });
    }
  
    if (!email || !validateEmail(email)) {
      this.setState({ emailError: true });
      formValid = false;
    } else {
      this.setState({ emailError: false });
    }
  
    if (password !== repassword) {
      this.setState({
        repasswordError: true,
        loading: false
      });
      Error("Les mots de passe ne correspondent pas.");
      return;
    }
  
    if (!vehicle1) {
      this.setState({ vehicle1Error: true });
      formValid = false;
    } else {
      this.setState({ vehicle1Error: false });
    }
  
    return formValid;
  }
  

  navigate(e) {
    const path = e.target.id;
    const name = e.target.name;
    if (path === "pills-home-tab") {
      this.setState({ signin: true, signup: false });
    }
    if (path === "pills-profile-tab") {
      this.setState({ signin: false, signup: true });
    }
    if (name === "signin") {
      this.setState({ signin: true, signup: false });
    }
    if (name === "signup") {
      this.setState({ signin: false, signup: true });
    }
    if (name === "emailSignup") {
      this.setState({ signupEmail: false });
    }
  }
  render() {
    return (
      <div className="main-div">
        <div className="row mx-0 ">
          <div className="col-lg-6  p-0 m-0">
            <div className="main-layout">
              <div
                className="tab-content px-3 py-3"
                id="pills-tabContent"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className={
                    this.state.signup
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="create-acc">
                    <div className="signupTitle py-3 mb-3">Créer un compte</div>
                    {/* <div className="">
                      <div className="alert alert-info">Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule, 1 nombre, un caractère spécial: @ # $ % ^ & + = . _</div>
                    </div> */}
                    {this.state.signupEmail ? (
                      <button
                        onClick={(e) => this.navigate(e)}
                        name="emailSignup"
                        className="social-log mb-4"
                      >
                        <svg
                          className="Icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            fill="none"
                            fillRule="evenodd"
                            stroke="none"
                            strokeWidth="1"
                          >
                            <path
                              d="M21.9975216,6.42182031 C21.9975216,5.65086847 21.3536298,5 20.5593491,5 L3.43817244,5 C2.64270429,5 2,5.63657064 2,6.42182031 L2,6.69125369 L11.9987612,12 L21.9975216,6.69125369 L21.9975216,6.42182031 Z M22,17.57979 C22,18.3650397 21.3572161,19.0016103 20.5616493,19.0016103 L3.43835068,19.0016103 C2.64397153,19.0016103 2,18.3507418 2,17.57979 L2,8.73999023 L12,14 L22,8.73999023 L22,17.57979 Z"
                              fill="#b4bbc6"
                            ></path>
                          </g>
                        </svg>
                        S'inscrire via son adresse mail
                      </button>
                    ) : (
                      <form action="#" onSubmit={this.signupValidateForm}>
                        <input
                          type="Text"
                          placeholder="Prénom"
                          name="firstname"
                          className="form-control form-pad px-3 py-4"
                          value={this.state.firstname}
                          onChange={(e) => this.onchangeValue(e)}
                        />
                        {this.state.firstnameError ? (
                          <label className="text-danger fs-error">
                            Le prénom est obligatoire
                          </label>
                        ) : (
                          ""
                        )}
                        <input
                          type="text"
                          placeholder="Nom"
                          name="lastname"
                          className="form-control form-pad mt-3 px-3 py-4"
                          value={this.state.lastname}
                          onChange={(e) => this.onchangeValue(e)}
                        />
                        {this.state.lastnameError ? (
                          <label className="text-danger fs-error">
                            Le nom est obligatoire
                          </label>
                        ) : (
                          ""
                        )}
                        <div
                          className="mt-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <Autocomplete
                            id="country-select-demo"
                            // inputValue={countries.find(country => country.phone === "225").phone}
                            // defaultValue={countries.find(
                            //   (country) => country.code === "CI"
                            // )}
                            onChange={(event, newValue) => {
                              this.setState({ country: newValue });
                            }}
                            // readOnly
                            // clearIcon={false}
                            sx={{
                              width: 100,
                              padding: 0,
                              marginRight: 0.3,
                              border: 0,
                              flex: 3,
                              fontSize: 8,
                            }}
                            options={countries}
                            getOptionLabel={(option) => "+" + option.phone}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{
                                  "& > img": {
                                    mr: 2,
                                    flexShrink: 0,
                                    border: "none",
                                  },
                                }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                {option.label}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Prefixe"
                                //disabled
                                inputProps={{
                                  style: {
                                    padding: 4,
                                  },
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                          <input
                            type="text"
                            placeholder="Ex: 0778239374"
                            name="mobilenumber"
                            // className="form-control form-pad mt-3 px-3 py-4"
                            className="form-control form-pad px-3 py-4"
                            value={this.state.mobilenumber}
                            style={{ flex: 3 }}
                            onChange={(e) => this.onchangeValue(e)}
                          />
                        </div>
                        {this.state.mobilenumberError ? (
                          <label className="text-danger fs-error">
                            Le numéro de téléphone doit contenir 10 chiffres
                            exactement
                          </label>
                        ) : (
                          ""
                        )}

                        <input
                          type="email"
                          placeholder="Ex: username@example.com"
                          name="email"
                          className="form-control form-pad mt-3 px-3 py-4"
                          value={this.state.email}
                          onChange={(e) => this.onchangeValue(e)}
                        />
                        {this.state.emailError ? (
                          <label className="text-danger fs-error">
                            L'email est obligatoire
                          </label>
                        ) : (
                          ""
                        )}
                        {this.state.validemailError ? (
                          <label className="text-danger fs-error">
                            L'email n'est pas valide
                          </label>
                        ) : (
                          ""
                        )}
                        <input
                          type="password"
                          placeholder="Mot de passe"
                          name="password"
                          className="form-control form-pad mt-3 px-3 py-4"
                          value={this.state.password}
                          onChange={(e) => this.onchangeValue(e)}
                        />

                        {this.state.passwordError ? (
                          <label className="text-danger fs-error">
                            Le mot de passe est obligatoire
                          </label>
                        ) : (
                          ""
                        )}
                        {this.state.validpasswordError ? (
                          <label className="alert alert-danger text-danger fs-error mt-1">
                            Le mot de passe doit contenir au moins 8 caractères,
                            une lettre majuscule, 1 nombre, un caractère
                            spécial: @ # $ % ^ & + = . _
                          </label>
                        ) : (
                          ""
                        )}
                        <input
                          type="password"
                          placeholder="Confirmer le mot de passe"
                          name="repassword"
                          className="form-control form-pad mt-3 px-3 py-4"
                          value={this.state.repassword}
                          onChange={(e) => this.onchangeValue(e)}
                        />
                        {this.state.repasswordError ? (
                          <label className="text-danger fs-error">
                            Les deux mots de passe ne correspondent pas
                          </label>
                        ) : (
                          ""
                        )}
                        {this.state.validRepasswordError ? (
                          <label className="text-danger fs-error">
                            Veuillez renseigner un mot de passe robuste
                          </label>
                        ) : (
                          ""
                        )}

                        <div className="pt-2 fs-condition">
                          <input
                            type="checkbox"
                            className="mr-1"
                            name="vehicle1"
                            value={this.state.vehicle1}
                            checked={this.state.vehicle1}
                            onChange={(e) => this.onchangeValue(e)}
                          />{" "}
                          <label className="cobalt-CheckmarkField__Label">
                            Je suis d'accord avec{" "}
                            <a target="_blank" href="/privacy">
                              la politique de confidentialité
                            </a>
                          </label>
                        </div>
                        {this.state.vehicle1Error ? (
                          <label className="text-danger fs-error">
                            Ce champ est obligatoire
                          </label>
                        ) : (
                          ""
                        )}
                        <div className="pt-3 fs-condition">
                          <input
                            type="checkbox"
                            className="mr-1"
                            name="vehicle2"
                            value={this.state.vehicle2}
                            checked={this.state.vehicle2}
                            onChange={(e) => this.onchangeValue(e)}
                          />{" "}
                          <span className="cobalt-CheckmarkField__Label">
                            Je souhaite m'abonner à la newsletter et les offres
                            spéciales de Monbolide.
                          </span>
                        </div>
                        
                        <div className="mt-2 pt-4 fs-condition">
                          En vous inscrivant, vous acceptez nos {" "}
                          <a target="_blank" href="#">
                            conditions de services
                          </a>
                          .
                        </div>
                        <input
                          type="submit"
                          value={
                            this.state.loading ? "En cours..." : "Confirmer"
                          }
                          onClick={this.signupValidateForm}
                          className="form-control sign-btn"
                          disabled={this.state.loading ? true : false}
                        />
                        
                      </form>
                    )}
                    <div className="or">ou</div>
                    
                    <div className="text-center">
                      Déja membre ?{" "}
                      <Link
                        to="/sign-in"
                        name="signin"
                        onClick={(e) => this.navigate(e)}
                      >
                        Se connecter
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6  p-0 m-0">
            <div className="signup-img">
              {/* <img src="assets/images/signup_banner.jpg" alt="" /> */}
              
            </div>
            <ReCAPTCHA
                        className="recaptchaClass"
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey="6Ld3nmklAAAAANIX-BuKyGhpGaKs0x1SLPzu994j"
                                                    
                        />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // SignUp: AC_ACCOUNT_CREATION,
      AddUserTemp: AC_ADD_USER_TEMP,
      SignIn: AC_AUTHORIZATION,
      FacebookAuth: AC_FACEBOOK_AUTH,
      GoogleAuth: AC_GOOGLE_AUTH,
    },
    dispatch
  );
}

const AuthorizationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorization);

export default AuthorizationComponent;
