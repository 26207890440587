import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AC_YOUR_CAR,
  AC_HANDLE_INPUT_CHANGE,
  AC_EDIT_CARDETAILS,
  AC_GET_MODEL,
} from "../../actions/carsaction";
import { AC_GET_CONFIGURATION } from "../../actions/configurationaction";
import Autocomplete from "react-google-autocomplete";

class DescriptionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresserror: false,
      place: null,
    };
  }
  componentDidMount() {
    this.props.ViewCar({ id: localStorage.getItem("carId") });
    this.props.ConfigList({ slug: "fuel" });
  }
  validateForm() {
    const id = localStorage.getItem("carId");
    // console.log(this.props.CarsReducers)
    const country = (this.props.CarsReducers?.numberInfo?.country) ? this.props.CarsReducers?.numberInfo?.country : null;
    const year = (this.props.CarsReducers?.numberInfo?.year) ? this.props.CarsReducers?.numberInfo?.year : null;
    const number = this.props.CarsReducers?.numberInfo?.number ? this.props.CarsReducers?.numberInfo?.number : null;
    const fuel = this.props.CarsReducers?.fuelInfo?.fuel ? this.props.CarsReducers?.fuelInfo?.fuel : null;
    const type = this.props.CarsReducers?.fuelInfo?.type ? this.props.CarsReducers?.fuelInfo?.type : null;
    const make = this.props.CarsReducers?.makeid ? this.props.CarsReducers?.makeid : null;
    const model = this.props.CarsReducers.modelid ? this.props.CarsReducers?.modelid : null;
    const featureInfo = Object.assign({}, this.props.CarsReducers?.featureInfo ? this.props.CarsReducers?.featureInfo : {});
    // console.log()
    const featureinfoArraykeys = Object.keys(featureInfo);
    const featureinfoArrayvalues = Object.values(featureInfo);
    // console.log(featureinfoArrayvalues)
    let newfeatureInfo = [];
    for (let p = 0; p < featureinfoArraykeys.length; p++) {
      if (featureinfoArrayvalues[p] === true) {
        newfeatureInfo.push(featureinfoArraykeys[p]);
      }
    }
    let carFeatureDetails = {};
    for (let k = 0; k < newfeatureInfo.length; k++) {
      carFeatureDetails[newfeatureInfo[k]] = true;
    }
    const carDetails = {
      carNumberDetails: {
        country: country,
        number: number,
        year: year,
      },
      carFuelDetails: {
        fuel: fuel,
        type: type,
      },
      carFeatureDetails,
      carModelDetails: {
        make: make,
        model: model,
      },

    };
    // afficher les détails du véhicule
    // console.log("-----------Informations sur les détails du véhicules-----------");
    // console.log(carDetails);
    // console.log("-----------Informations sur les détails du véhicules-----------");
    this.props.EditCardetails(carDetails, id);
    
  }

  // onChangeValue(event) {
  //   const name = "location";
  //   var value = "";
  //   if (event.target) {
  //     value = {
  //       formatted_address: event.target.value,
  //     };
  //   } else {
  //     value = {
  //       formatted_address: event.formatted_address,
  //       location: event.geometry.location,
  //     };
  //   }

  //   this.props.handleInputChange(name, value, "ParkingAddress");
  // }
  onSelectParking(place) {
    // On met à jour la location localisation du véhicule à la sélection d'une adresse
    // récupérer l'adresse du véhicule
    // console.log(place)
    const id = localStorage.getItem("carId");
    console.log(place)
    const parking = {
      formatted_address: place.formatted_address,
      location: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    }

    let carDetails = {};
    carDetails["parking"] = parking;
    console.log(carDetails)
    // this.props.EditCardetails(carDetails, id);
  }


  onChangeValue(event) {
    let name = event.target.name;
    let value = event.target.value;
    const id = event.target.id;
    console.log()

    if (name === "country") {
      this.props.handleInputChange(name, value, "Number");
    }
    else if (name === "year") {
      this.props.handleInputChange(name, value, "Number");
    }
    else if(name === "number"){
      this.props.handleInputChange(name, value, "Number");
    }
    else if (name === "fuel") {
      this.props.handleInputChange(name, value, "Fuel");
    }
    else if (name === "make") {
      this.props.handleInputChange(name, value, "Make");
      this.props.getModel({ id: value });
    }
    else if (name === "model") {
      this.props.handleInputChange(name, value, "Model");
    }
    else if (id === "feature") {
      value = event.target.checked;
      this.props.handleInputChange(name, value, "Feature");
    } else if (name === "parking") {
      // console.log("Parking State: " + event)
      // value["formatted_address"] = event.formatted_address;
      // value["location"]["lat"] = event.geometry.location.lat();
      // value["location"]["lon"] = event.geometry.location.lng();        


      // console.log("-------------location-------------")
      // console.log(value)
      // console.log("-------------location-------------")

      this.props.handleInputChange(name, value, "ParkingAddress");
    }
  }

  render() {
    const carList = this.props.CarsReducers.carList;
    const numberInfo = this.props.CarsReducers.numberInfo;
    const fuelInfo = this.props.CarsReducers.fuelInfo;
    // const carmodelInfo = this.props.CarsReducers.carmodelInfo;
    const parkingInfo = this.props.CarsReducers.parkingInfo;
    if (parkingInfo) {
      var parkingAddress = parkingInfo.formatted_address;
      // this.setState({place: parkingAddress})

    }
    var brandList = this.props.DashboardReducer.brandList;
    var makeid = this.props.CarsReducers.makeid || "DEFAULT";
    var modelid = this.props.CarsReducers.modelid || "DEFAULT";
    let number = numberInfo.number
    var brandOptions = [];
    if (carList.carmodel) {
      // var make = carmodelInfo.make.brandname;
      // var model = carmodelInfo.model.name;
      var year = numberInfo?.year || "DEFAULT";
      var country = numberInfo?.country || "DEFAULT";
      var fuel = fuelInfo?.fuel || "DEFAULT";      
    }

    // console.log('-----------Parking-----------')
    // console.log(parkingAddress)
    // console.log('-----------Parking-----------')

    brandOptions.push(
      <option
        key={-1}
        value={"DEFAULT"}
        // selected={makeid === brandList[i]._id}
        disabled
      >
        Sélectionner la marque du véhicule
      </option>
    )
    for (var i = 0; i < brandList.length; i++) {
      brandOptions.push(
        <option
          key={i}
          value={brandList[i]._id}
        // selected={makeid === brandList[i]._id}
        >
          {brandList[i].brandname}
        </option>
      );
    }
    const featuresList = this.props.DashboardReducer.featuresList;
    var featuresListArray = [];
    var featureInfo = Object.assign({}, this.props.CarsReducers.featureInfo);
    if (this.props.DashboardReducer.featuresList.length) {
      for (let i = 0; i < featuresList.length; i++) {
        featuresListArray.push(
          <div className="desc-check mb-2" key={i}>
            <input
              type="checkbox"
              checked={featureInfo[featuresList[i].name]}
              className="mr-2"
              id="feature"
              name={featuresList[i].name}
              onChange={(e) => this.onChangeValue(e)}
            />
            {featuresList[i].name}
          </div>
        );
      }
    }

    var options = this.props.ConfigurationReducer.ConfigValue;

    var fuelOptions = [];
    if (options) {
      fuelOptions.push(
        <option
          key={-1}
          value={"DEFAULT"}
          // selected={false}
          // defaultValue={false}            
          disabled
        >
          Choisir une catégorie d'énergie
        </option>
      )
      for (let i = 0; i < options.length; i++) {
        fuelOptions.push(
          <option
            key={i}
            value={options[i].name}
          // selected={fuel === options[i].name}
          >
            {options[i].name}
          </option>
        );
      }

    }

    const countryList = this.props.DashboardReducer.countryList;
    let countryArray = [];
    countryArray.push(
      <option
        key={100}
        value={"DEFAULT"}
        disabled
      // selected={country === countryList[i].countryname}
      >
        Sélectionner le pays d'immatriculation du véhicule
      </option>
    );

    for (let i = 0; i < countryList.length; i++) {
      countryArray.push(
        <option
          key={i}
          value={countryList[i].countryname}
        // selected={country === countryList[i].countryname}
        >
          {countryList[i].countryname}
        </option>
      );
    }
    var yearArray = [];
    yearArray.push(
      <option key={-1} value={"DEFAULT"} disabled>
        Selectionner l'année de première mise en circulation du véhicule
      </option>
    )

    for (let i = 2021; i > 2010; i--) {

      yearArray.push(
        <option key={i} value={i}
        // selected={year === i}
        >
          {i}
        </option>
      );
    }

    const modelList = this.props.CarsReducers.modelList;
    var modelArray = [];
    modelArray.push(<option
      key={-1}
      value={"DEFAULT"}
      disabled
    // selected={modelid === modelList[j]._id}
    >
      Sélectionner le modèle du véhicule
    </option>
    )
    if (modelList) {
      for (let j = 0; j < modelList.length; j++) {
        modelArray.push(
          <option
            key={j}
            value={modelList[j]._id}
          // selected={modelid === modelList[j]._id}
          >
            {modelList[j].name}
          </option>
        );
      }
    }
    return (
      <div className="list-car-card px-3 py-3">
        <h1 className="desc-title mb-3">Votre véhicule</h1>
        <div className="desc-form">
          <div className="desc-group mb-4">
            <div className="desc-label">
              <label htmlFor="make">Marque</label>
            </div>
            <div className="desc-select">
              <select
                name="make"
                id="make"
                value={makeid}
                onChange={(e) => this.onChangeValue(e)}
                className="form-control"
              >
                {brandOptions}
              </select>
            </div>
          </div>
          <div className="desc-group mb-4 mt-4">
            <div className="desc-label">
              <label htmlFor="model">Modèle</label>
            </div>
            <div className="desc-select">
              <select
                name="model"
                id="model"
                onChange={(e) => this.onChangeValue(e)}
                className="form-control"
                value={modelid}
              >
                {modelArray}
              </select>
            </div>
          </div>
          <hr className="hr-seperator" />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4>Immatriculation</h4>
            <div className="desc-group mb-4 mt-4">
              <div className="desc-label">
                <label htmlFor="country">Pays d'immatriculation</label>
              </div>
              <div className="desc-select">
                <select
                  name="country"
                  id="country"
                  onChange={(e) => this.onChangeValue(e)}
                  className="form-control"
                  value={country}
                // defaultValue={"DEFAULT"}
                >
                  {countryArray}
                </select>
              </div>
            </div>
          </div>
          <div className="desc-group mb-4 mt-4">
            <div className="desc-label">
              <label htmlFor="number">Numéro d'immatriculation</label>
            </div>
            <div className="desc-select">
              <input type="text" value={number} name={"number"} className="input-number" onChange={(e) => this.onChangeValue(e)} />            
            </div>
          </div>
          <div className="desc-group mb-4 mt-4">
            <div className="desc-label">
              <label htmlFor="year">Année de la première immatriculation</label>
            </div>
            <div className="desc-select">
              <select
                name="year"
                id="year"
                onChange={(e) => this.onChangeValue(e)}
                className="form-control"
                // defaultValue={year}
                value={year}
              >
                {yearArray}
              </select>
            </div>
          </div>
          <hr className="hr-seperator" />
          <div className="desc-group mb-4">
            <div className="desc-label">
              <label htmlFor="featureList">Caractéristiques</label>
            </div>
            <div className="desc-select" id="featureList">
              {featuresListArray}
            </div>
          </div>
          <div className="desc-group mb-4">
            <div className="desc-label">
              <label htmlFor="fuel">Energie</label>
            </div>
            <div className="desc-select">
              <select
                name="fuel"
                id="fuel"
                value={fuel}
                onChange={(e) => this.onChangeValue(e)}
                className="form-control"
              >
                {fuelOptions}
              </select>
            </div>
          </div>                                           
          <hr className="hr-seperator" />
          <div className="desc-group mb-4 mt-4">
            <div className="desc-label"></div>
            <div className="desc-select">
              <div className="Woohoo-btn d-block text-white"
                onClick={(e) => this.validateForm(e)}>
                Mettre à jour
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    CarsReducers: state.CarsReducers,
    HostDetailsReducer: state.HostDetailsReducer,
    DashboardReducer: state.DashboardReducer,
    ConfigurationReducer: state.ConfigurationReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ConfigList: AC_GET_CONFIGURATION,
      getModel: AC_GET_MODEL,
      ViewCar: AC_YOUR_CAR,
      handleInputChange: AC_HANDLE_INPUT_CHANGE,
      EditCardetails: AC_EDIT_CARDETAILS,
    },
    dispatch
  );
}

const DescriptionSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DescriptionSection);

export default DescriptionSectionComponent;
