import React, { createRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "aos/dist/aos.css";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_DASHBOARD, } from './actions/dashboardaction';
import DownloadApp from './components/DownloadApp'
import MobileDetect from 'mobile-detect'
import { io } from "socket.io-client";
import API from "./common/api";
import 'antd/dist/reset.css';

// import {geoLocation} from "./utils/geoLocation";

import { AC_VIEW_ACCOUNT, AC_VIEW_TRIPS, AC_VIEW_TRIPS_REQUEST } from './actions/accountaction';
// Account Component
import Accounts from './components/accounts/accounts';
// import Logout from './components/accounts/logout';

// Authorization Component
import Signin from './components/authorization/signin';
import OtpValidation from './components/authorization/otp-validation';
import Signup from './components/authorization/signup';
import ForgotPassword from './components/authorization/forgotpassword';
import ChangePassword from './components/authorization/changepassword';
// import Messaging from './components/Chats/messaging';
import Chat from './pages/Chat.jsx';

// SearchCar Components
import Searchcar from './components/searchcar/searchcar';
import BookCar from './components/searchcar/bookcar';
import Paymentsuccess from './components/searchcar/paymentsuccess';


//Question Components
import Question from './components/questions/question';
import FaqDescription from './components/questions/faqdescription';

//Dashboard Components
import Dashboard from './components/dashboard/dashboard';

//Layouts Components
import Header from './components/layouts/header';
import Footer from './components/layouts/footer';

//HireOutCar Components
import HireOutCar from './components/hireoutcar/hireoutcar';

//Pages Components
import Pages from './components/pages/content';

//Business Components
import Business from './components/business/business';

//Profile Components
import Profile from './components/profile/profile';

// Hostacar Component
import CarModel from './components/hostacar/carmodel';
import ParkingAddress from './components/hostacar/parkingaddress';
import Mileage from './components/hostacar/mileage';
import RegistrationDetail from './components/hostacar/registrationdetail';
import FuelDetail from './components/hostacar/fueldetail';
import CarFacilities from './components/hostacar/carfacilities';
import ParkingLocation from './components/hostacar/parkinglocation';
import CarFeatures from './components/hostacar/carfeatures';
import CarPrice from './components/hostacar/carprice';
import ContactNumber from './components/hostacar/contactnumber';
import CustomerCare from './components/hostacar/customercare';
import DateofBirth from './components/hostacar/dateofbirth';
import BillingAddress from './components/hostacar/billingaddress';
import CheckinAddress from './components/hostacar/checkinaddress';
import AddVehicle from './components/hostacar/addvehicle';
import Schedule from './components/hostacar/schedule';
import ReadyToRent from './components/hostacar/readytorent';
import AddCarImage from './components/hostacar/addcarimage';
// import RentalAgreement from './components/accounts/rental-agreement';
import SearchCarDetail from './components/searchcar/searchcar-detail';
import AOS from "aos";


// ROUTE PROTECTION
import RouteGuard from './middlewares/auth';
import RouteGuardSigned from './middlewares/RedirectWhenSigned';
import Policy from "./components/Policy"


const isAuth = localStorage.getItem('siteUser') || false;

// Geocode.setLocationType("ROOFTOP");
class App extends React.Component {
  constructor(props) {
    super(props);   
    this.socket = createRef() 
    this.state = {      
      type: MobileDetect._impl.detectOS(window.navigator.userAgent),
      showDownloadButton: true,
      lat: null,
      long: null
    }
  }
  componentDidMount() {
    // geoLocation()
    // console.log("Position:" + position)
    
    AOS.init({ once: true });    
    // this.props.Dashboard({slug:'rent-a-car', status : 2});
    this.props.Dashboard({ slug: 'rent-a-car', status: 2 });
    window.addEventListener("resize", this.closeDownloadButton);
    

    if (isAuth) {
      this.socket.current = io(API.LOCAL_WEBSOCKET_URL)
      this.props.ViewAccount(isAuth);
      // rental
      this.props.ViewTrips({
        request: 0,
        id: localStorage.getItem("siteUser"),
        status: 5,
      })

      // request
      this.props.ViewTripsRequest({
        request: 1,
        id: localStorage.getItem("siteUser"),
        status: 5,
      })
      // this.socket.current = io(API.LOCAL_WEBSOCKET_URL)
      this.socket.current.emit("add-user", localStorage.getItem("siteUser"));
    }    
  }

  getClientPosition = () => {   
    
    
    
  }
  closeDownloadButton = () => {    
    let type = MobileDetect._impl.detectOS(window.navigator.userAgent)
    if(type === "iOS" || type=== "AndroidOs"){
      this.setState({showDownloadButton: true})
    }else{
      this.setState({showDownloadButton: false})
    }
    
  }

  
  render() {
    let url = "" 
    let flag = false
    let text = ''
    let type = this.state.type
    if(this.state.type === 'iOS') {
        url = "https://apps.apple.com/fr/app/monbolide/id1625891237" ;
        text = "Ouvrir l'application mobile"
        flag = true
    }
    else if (this.state.type === 'AndroidOS') {
        url = "https://play.google.com/store/apps/details?id=com.monbolide"
        text = "Ouvrir l'application mobile"
        flag = true
    }
        
    return (
      <div className="main-div"  style={{position: "relative", paddingBottom: "50px", minHeight: "100vh"}}>
        <Router>
        {this.state.showDownloadButton && <DownloadApp isMobile={flag} type={type} url={url} text={text} />}
          <Header />          
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/auth/otp-validation" component={OtpValidation} />
            <Route exact path="/book-car/:carId" component={BookCar} />
            <Route exact path="/search-car" component={Searchcar} />
            <Route exact path="/search-car/:id" component={SearchCarDetail} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <RouteGuardSigned exact path="/sign-in" component={Signin} />
            <RouteGuard exact path="/messaging" component={Chat} />
            <RouteGuardSigned exact path="/sign-up" component={Signup} />
            <RouteGuard exact path="/hire-out-car" component={HireOutCar} />
            <RouteGuard exact path="/questions" component={Question} />
            <RouteGuard exact path="/pages/:slug" component={Pages} />
            <RouteGuard exact path="/business" component={Business} />
            <Route exact path="/change-password/:token" component={ChangePassword} />
            <RouteGuard exact path="/logout" component={Accounts} />
            <RouteGuard exact path="/booking-request" component={Accounts} />
            <RouteGuard exact path="/rental" component={Accounts} />
            <RouteGuard exact path="/payments" component={Accounts} />
            <RouteGuard exact path="/policy" component={Policy} />
            <RouteGuard exact path="/hired-cars" component={Accounts} />
            <RouteGuard exact path="/modify-profile" component={Accounts} />
            <RouteGuard exact path="/verify-profile" component={Accounts} />
            <RouteGuard exact path="/account-settings" component={Accounts} />
            <RouteGuard exact path="/billing-settings" component={Accounts} />
            <RouteGuard exact path="/notifications" component={Accounts} />
            <RouteGuard exact path="/get-credit" component={Accounts} />
            <RouteGuard exact path="/car-profile" component={Accounts} />
            <RouteGuard exact path="/general-tab/" component={Accounts} />
            <RouteGuard exact path="/instant-tab" component={Accounts} />
            <RouteGuard exact path="/mon-bolide-tab" component={Accounts} />
            <RouteGuard exact path="/description-tab" component={Accounts} />
            <RouteGuard exact path="/address-tab" component={Accounts} />
            <RouteGuard exact path="/photos-tab" component={Accounts} />
            <RouteGuard exact path="/calendar-tab" component={Accounts} />
            <RouteGuard exact path="/price-tab" component={Accounts} />
            <RouteGuard exact path="/conditions-tab" component={Accounts} />
            <RouteGuard exact path="/insurance-tab" component={Accounts} />
            <RouteGuard exact path="/deactivate-tab" component={Accounts} />
            <RouteGuard exact path="/profile" component={Profile} />
            <RouteGuard exact path="/car-model" component={CarModel} />
            <RouteGuard exact path="/parking-address" component={ParkingAddress} />
            <RouteGuard exact path="/mileage" component={Mileage} />
            <RouteGuard exact path="/car-registration-detail" component={RegistrationDetail} />
            <RouteGuard exact path="/car-fuel-detail" component={FuelDetail} />
            <RouteGuard exact path="/car-facilities" component={CarFacilities} />
            <RouteGuard exact path="/parking-location" component={ParkingLocation} />
            <RouteGuard exact path="/car-features" component={CarFeatures} />
            <RouteGuard exact path="/car-price" component={CarPrice} />
            <RouteGuard exact path="/contact-number" component={ContactNumber} />
            <RouteGuard exact path="/customer-care" component={CustomerCare} />
            <RouteGuard exact path="/date-of-birth" component={DateofBirth} />
            <RouteGuard exact path="/billing-address" component={BillingAddress} />
            <RouteGuard exact path="/check-in-address" component={CheckinAddress} />
            <RouteGuard exact path="/add-vehicle" component={AddVehicle} />
            <RouteGuard exact path="/schedule" component={Schedule} />
            <RouteGuard exact path="/ready-to-rent" component={ReadyToRent} />
            <RouteGuard exact path="/add-car-image" component={AddCarImage} />
            <RouteGuard exact path="/faq-description/:id" component={FaqDescription} />
            <RouteGuard exact path="/payment-success" component={Paymentsuccess} />
            <RouteGuard exact path="/rental-agreement/:id" component={Accounts} />
          </Switch>          
          {window.location.pathname !== "/search-car" && <Footer />}
          <ToastContainer />
        </Router>
      </div>
    );
  }

}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    Dashboard: AC_DASHBOARD,
    // Dashboards:AC_DASHBOARDS,
    ViewAccount: AC_VIEW_ACCOUNT,
    ViewTrips: AC_VIEW_TRIPS,
    ViewTripsRequest: AC_VIEW_TRIPS_REQUEST,
  },
    dispatch)
}

const AppComponent = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppComponent;
