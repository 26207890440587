
const initialState = {
  countryList       : [],
  faqList           : [],
  configurationList : [],
  featuresList      : [],
  cartypesList      : [],
  brandList         : [],
  settings          : [],
  languageList      : [],
  pagesList         : [],
  rentList          : [],
  lastCars: []
}
function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "DASHBOARD":      
      return Object.assign({}, state, {
        brandList          : action.payload.results[0].data,
        countryList        : action.payload.results[1],
        rentList           : action.payload.results[2],
        faqList            : action.payload.results[3],
        pagesList          : action.payload.results[4],
        settings           : action.payload.results[5],
        configurationList  : action.payload.results[6],
        languageList       : action.payload.results[7],
        featuresList       : action.payload.results[8],
        cartypesList       : action.payload.results[9],
        lastCars           : action.payload.results[10]
      })
    default:
      return state
  }
}

export default DashboardReducer;
