import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "react-google-autocomplete";
import {
  AC_YOUR_CAR,
  AC_HANDLE_INPUT_CHANGE,
  AC_EDIT_CARDETAILS,
} from "../../actions/carsaction";

class AddressSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresserror: false,
    };
  }
  componentDidMount() {
    this.props.ViewCar({ id: localStorage.getItem("carId") });
  }
  onChangeValue(event) {
    const name = "location";
    var value = "";
    if (event.target) {
      value = {
        formatted_address: event.target.value,
      };
    } else {
      value = {
        formatted_address: event.formatted_address,
        location: event.geometry.location,
      };
    }

    this.props.handleInputChange(name, value, "ParkingAddress");
  }
  validateForm() {
    const id = localStorage.getItem("carId");
    const parkingInfo = this.props.CarsReducers.parkingInfo;
    // console.log("--------------------------------ParkingInfo--------------------------------")
    // console.log(parkingInfo);
    // console.log("--------------------------------ParkingInfo--------------------------------")

    const carDetails = {
      parking: parkingInfo,
    };
    if (parkingInfo.location) {
      this.setState({ addresserror: false });
      this.props.EditCardetails(carDetails, id);
    } else {
      this.setState({ addresserror: true });
    }
    // console.log("--------------------------------CarDétail --------------------------------")
    // console.log(carDetails);
    // console.log("--------------------------------CarDétail--------------------------------")
    // this.props.EditCardetails(carDetails, id);
  }

  render() {
    const parkingInfo = this.props.CarsReducers.parkingInfo;
    if (parkingInfo) {
      var ParkingAddress = parkingInfo.formatted_address;
      
    }

    // console.log(parkingInfo)


    
    
    return (
      <div className="list-car-card px-3 py-3">
        <h2 className="desc-heading size-21 mb-4">Adresse</h2>
        <div className="row">
          <div className="col-sm-8">
            <label htmlFor="parking" className="acc-label">
              Adresse de stationnement du véhicule
            </label>
            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  @
                </span>
              </div>
              <Autocomplete
                style={{ width: "70%" }}
                className="form-control"
                id="parking"
                autoComplete="off"
                onPlaceSelected={(place) => {
                  this.onChangeValue(place);
                }}
                onChange={(e) => this.onChangeValue(e)}
                value={ParkingAddress}
                types={["(regions)"]}
                componentRestrictions={{ country: "civ" }}
              />
              {this.state.addresserror ? (
                <label style={{ color: "red" }}>
                  Une adresse valide est requise
                </label>
              ) : (
                ""
              )}
            </div>
            {/*<div className="row">
                    <div className="col-sm-6">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6090197104118!2d80.23803621430574!3d13.060540790797594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52666708a7c545%3A0x67925d184da96515!2s16%2C+Deivanayagam+St%2C+Nungambakkam%2C+Chennai%2C+Tamil+Nadu+600034!5e0!3m2!1sen!2sin!4v1493814888300" width={250} height={200} frameBorder={0} style={{margin: 'auto'}} allowFullScreen />
                    </div>
                    <div className="col-sm-6">
                      Full address:
                      <div className="w-600">London Bridge</div>
                      <div className="w-600">SE1 9RA London</div>
                      <div className="w-600">United Kingdom</div>
                      <p className="mt-3">If this address is incorrect, please enter it again.</p>
                    </div>
                  </div>*/}
            <div className="row mt-5">
              <div className="col-sm-8">
                <h2 className="desc-heading size-21 mb-2">Livraison</h2>
                <p>
                  Vous devez activer votre véhicule avant de pouvoir choisir les
                  lieux de livraison.
                </p>
                <div className="text-left">
                  <span
                    className="Woohoo-btn text-white"
                    onClick={(e) => this.validateForm(e)}
                  >
                    Mettre à jour
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="text-muted">
              ex : Cité Angré Les Manguiers, Abidjan, Côte d'Ivoire
              <br />
              <br />
              Plus vous êtes précis, mieux c'est pour les conducteurs.
              <br />
              <br />
              Si vous ne pouvez pas donner une adresse plus complète, donnez un
              lieu connu facile de trouver.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    CarsReducers: state.CarsReducers,
    HostDetailsReducer: state.HostDetailsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewCar: AC_YOUR_CAR,
      handleInputChange: AC_HANDLE_INPUT_CHANGE,
      EditCardetails: AC_EDIT_CARDETAILS,
    },
    dispatch
  );
}

const AddressSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressSection);

export default AddressSectionComponent;
