import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AC_YOUR_CAR,
  AC_HANDLE_INPUT_CHANGE,
  AC_EDIT_CARDETAILS,
} from "../../actions/carsaction";

class InsuranceSection extends React.Component {
  componentDidMount() {
    this.props.ViewCars({ id: localStorage.getItem("carId") });
  }
  validateForm() {
    const id = localStorage.getItem("carId");
    const country = this.props.CarsReducers.numberInfo.country;
    const year = this.props.CarsReducers.numberInfo.year;
    const number = this.props.CarsReducers.numberInfo.number;
    const fuel = this.props.CarsReducers.fuelInfo.fuel;
    const type = this.props.CarsReducers.fuelInfo.type;
    const make = this.props.CarsReducers.makeid;
    const model = this.props.CarsReducers.modelid;
    const featureInfo = Object.assign({}, this.props.CarsReducers.featureInfo);
    const featureinfoArraykeys = Object.keys(featureInfo);
    const featureinfoArrayvalues = Object.values(featureInfo);
    var newfeatureInfo = [];
    for (let p = 0; p < featureinfoArraykeys.length; p++) {
      if (featureinfoArrayvalues[p] === true) {
        newfeatureInfo.push(featureinfoArraykeys[p]);
      }
    }
    var carFeatureDetails = {};
    for (let k = 0; k < newfeatureInfo.length; k++) {
      carFeatureDetails[newfeatureInfo[k]] = true;
    }
    const carDetails = {
      carNumberDetails: {
        country: country,
        number: number,
        year: year,
      },
      carFuelDetails: {
        fuel: fuel,
        type: type,
      },
      carFeatureDetails,
      carModelDetails: {
        make: make,
        model: model,
      },
    };
    this.props.EditCardetails(carDetails, id);
  }

  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.props.handleInputChange(name, value, "Number");
  }
  render() {
    const carList = this.props.CarsReducers.carList;
    const numberInfo = this.props.CarsReducers.numberInfo;
    if (carList.carmodel) {
      var number = numberInfo?.number;
    }
    var regdate = [];
    for (let i = 1; i < 32; i++) {
      regdate.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    var regyear = [];
    for (let i = 2020; i > 1998; i--) {
      regyear.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    var motdate = [];
    for (let i = 1; i < 32; i++) {
      motdate.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    var motyear = [];
    for (let i = 2020; i > 1998; i--) {
      motyear.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return (
      <div className="quality p-5">
        <h2 className="title mb-16">Détails pour assurer votre véhicule</h2>
        <p>
          Pour chaque location, vous devez générer{" "}
          <b>un contrat de location fourni par Monbolide</b> (sur papier ou par
          téléphone portable) <b>pour que votre véhicule soit assuré</b>. Les
          détails suivants sont nécessaires pour générer l'accord:
        </p>
        <form className="quality-form">
          <div className="mb-35">
            <div className="row">
              <div className="col-lg-4 text-right">
                <label className="form-title">Plaque d'immatriculation </label>
              </div>
              <div className="col-lg-5">
                <div className="form-input">
                  <input
                    type="text"
                    value={number}
                    name="number"
                    onChange={(e) => this.onChangeValue(e)}
                    className="input-text"
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-line">
                  Vous ne devez changer la plaque d'immatriculation que si vous
                  avez fait une erreur. Découvrez comment changer le véhicule
                  fourni pour une location <a href="#"> ici</a>.
                </div>
              </div>
            </div>
          </div>
        </form>
        <form className="quality-form">
          <div className="mb-35">
            <div className="row">
              <div className="col-lg-4 text-right">
                <label className="form-title">Première inscription </label>
              </div>
              <div className="col-lg-5">
                <div className="form-input">
                  <select name className={"mr-2 mb-2"}>
                    {regdate}
                  </select>
                  <select name className>
                    <option value />
                    <option value={1} selected="selected">
                      Janvier
                    </option>
                    <option value={2}>Février</option>
                    <option value={3}>Mars</option>
                    <option value={4}>Avril</option>
                    <option value={5}>Mai</option>
                    <option value={6}>Juin</option>
                    <option value={7}>Juillet</option>
                    <option value={8}>Août</option>
                    <option value={9}>Septembre</option>
                    <option value={10}>Octobre</option>
                    <option value={11}>Novembre</option>
                    <option value={12}>Décembre</option>
                  </select>
                  <select name className>
                    {regyear}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-line"></div>
              </div>
            </div>
          </div>
        </form>
        <form className="quality-form">
          <div className="mb-35">
            <div className="row">
              <div className="col-lg-4 text-right">
                <label className="form-title">
                  Prochain contrôle technique{" "}
                </label>
              </div>
              <div className="col-lg-5">
                <div className="form-input">
                  <select name className={"mr-2 mb-2"}>
                    {motdate}
                  </select>
                  <select name className>
                    <option value />
                    <option value={1} selected="selected">
                      Janvier
                    </option>
                    <option value={2}>Février</option>
                    <option value={3}>Mars</option>
                    <option value={4}>Avril</option>
                    <option value={5}>Mai</option>
                    <option value={6}>Juin</option>
                    <option value={7}>Juillet</option>
                    <option value={8}>Août</option>
                    <option value={9}>Septembre</option>
                    <option value={10}>Octobre</option>
                    <option value={11}>Novembre</option>
                    <option value={12}>Décembre</option>
                  </select>
                  <select name>{motyear}</select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-line"></div>
              </div>
            </div>
          </div>
        </form>
        <div className="submit-btn">
          <button
            type="submit"
            name="Mettre à jour"
            defaultValue="Update"
            className="update-btn"
            onClick={(e) => this.validateForm(e)}
          >
            Mettre à jour
          </button>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    CarsReducers: state.CarsReducers,
    HostDetailsReducer: state.HostDetailsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewCars: AC_YOUR_CAR,
      handleInputChange: AC_HANDLE_INPUT_CHANGE,
      EditCardetails: AC_EDIT_CARDETAILS,
    },
    dispatch
  );
}

const InsuranceSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceSection);

export default InsuranceSectionComponent;
