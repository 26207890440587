import React from "react";
import { BrowserRouter as Router,  Link } from "react-router-dom";
// import CarsDashboard from "./carsdashboard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AC_YOUR_CAR } from "../../actions/carsaction";
import { Badge } from "antd";
// import { Sidebar } from './sidebar/Sidebar.jsx'

class CarsDashboardNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generaltoggle: false,
      instanttoggle: false,
      descriptiontoggle: false,
      monbolidetoggle: false,
      addresstoggle: false,
      photostoggle: false,
      calendartoggle: false,
      pricetoggle: false,
      conditionstoggle: false,
      insurancetoggle: false,
      deactivatetoggle: false,
    };
  }
  componentDidMount() {
    if (localStorage.getItem("carId"))
      this.props.ViewCar({ id: localStorage.getItem("carId") });

    const path = this.props.path.slice(1);
    var name = "generaltoggle";
    if (path === "instant-tab") {
      name = "instanttoggle";
    }
    if (path === "mon-bolide-tab") {
      name = "monbolidetoggle";
    }
    if (path === "address-tab") {
      name = "addresstoggle";
    }
    if (path === "calendar-tab") {
      name = "calendartoggle";
    }
    if (path === "conditions-tab") {
      name = "conditionstoggle";
    }
    if (path === "deactivate-tab") {
      name = "deactivatetoggle";
    }
    if (path === "description-tab") {
      name = "descriptiontoggle";
    }
    if (path === "insurance-tab") {
      name = "insurancetoggle";
    }
    if (path === "photos-tab") {
      name = "photostoggle";
    }
    if (path === "price-tab") {
      name = "pricetoggle";
    }
    this.setState({ [name]: true });
  }
  toggle(event) {
    const name = event.target.name;
    if (name === "General-tab") {
      this.setState({
        generaltoggle: true,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "Instant-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: true,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "monbolide-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: true,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "description-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: true,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "Address-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: true,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "Photos-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: true,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "Calendar-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: true,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "Price-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: true,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "conditions-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: true,
        insurancetoggle: false,
        deactivatetoggle: false,
      });
    }
    if (name === "quality-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: true,
        deactivatetoggle: false,
      });
    }
    if (name === "Deactivate-tab") {
      this.setState({
        generaltoggle: false,
        instanttoggle: false,
        descriptiontoggle: false,
        monbolidetoggle: false,
        addresstoggle: false,
        photostoggle: false,
        calendartoggle: false,
        pricetoggle: false,
        conditionstoggle: false,
        insurancetoggle: false,
        deactivatetoggle: true,
        isOpen: false
      });
    }
  }

  count() {
    let require = {};
    if (this.props.CarsReducers.carList.requirements) {
      require = this.props?.CarsReducers?.carList?.requirements[0];
    }
    //console.log(require);
    let count = 0;
    for (let i in require) {
      if (
        require[i] === undefined ||
        require[i] === "" ||
        require[i] === null
      ) {
        count++;
      }
    }
    //console.log("count", count);
    return count;
  }

  render() {
    
    return (
      <>
        
        <nav className="navbar navbar-expand-lg navbar-light hide-navbar">
          {/* <Link to="/general-tab" className={this.state.generaltoggle?"nav-link navbar-brand list-name active":"nav-link list-name"} name="General-tab"   role="tab" aria-controls="General"aria-selected={this.state.generaltoggle?true:false} >Géneralités</Link> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="left-section">
            <div
              className="nav flex-column nav-pills collapse navbar-collapse"
              id="navbarNavAltMarkup"
              name="tab"
              role="tablist"
              aria-orientation="vertical"
              onClick={(e) => this.toggle(e)}
            >
              <Link
                to="/general-tab"
                className={
                  this.state.generaltoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="General-tab"
                role="tab"
                aria-controls="General"
                aria-selected={this.state.generaltoggle ? true : false}
              >
                <i className="fas fa-globe fa-lg mr-4"></i> Géneralités
              </Link>
              <Link
                to="/instant-tab"
                className={
                  this.state.instanttoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name "
                }
                name="Instant-tab"
                role="tab"
                aria-controls="Instant"
                aria-selected={this.state.instanttoggle ? true : false}
              >
                <i className="fas fa-bolt fa-lg mr-4"></i> Réservation instantanée
              </Link>
              <Link
                to="/mon-bolide-tab"
                className={
                  this.state.monbolidetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name "
                }
                name="monbolide-tab"
                role="tab"
                aria-controls="Description"
                aria-selected={this.state.monbolidetoggle ? true : false}
              >
                <i className="fas fa-plus-circle fa-lg mr-4"></i>Monbolide Plus
              </Link>
              <Link
                to="/description-tab"
                className={
                  this.state.descriptiontoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="description-tab"
                role="tab"
                aria-controls="Description"
                aria-selected={this.state.descriptiontoggle ? true : false}
              >
                <i className="fa fa-solid fa-tag fa-lg mr-4"></i>  Description
              </Link>
              <Link
                to="/address-tab"
                className={
                  this.state.addresstoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Address-tab"
                role="tab"
                aria-controls="Address"
                aria-selected={this.state.addresstoggle ? true : false}
              >
                <i className="fa fa-address-card fa-lg mr-4"></i> Adresse
              </Link>
              <Link
                to="/photos-tab"
                className={
                  this.state.photostoggle
                    ? "nav-link list-name nav-item active"
                    : "nav-link nav-item list-name"
                }
                name="Photos-tab"
                role="tab"
                aria-controls="Photos"
                aria-selected={this.state.photostoggle ? true : false}
              >
                <i className="fas fa-images mr-4 fa-lg"></i> Photos
              </Link>
              <Link
                to="/calendar-tab"
                className={
                  this.state.calendartoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Calendar-tab"
                role="tab"
                aria-controls="Calendar"
                aria-selected={this.state.calendartoggle ? true : false}
              >
                <i className="far fa-calendar fa-lg mr-4"></i> Calendrier
              </Link>
              <Link
                to="/price-tab"
                className={
                  this.state.pricetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Price-tab"
                role="tab"
                aria-controls="Price"
                aria-selected={this.state.pricetoggle ? true : false}
              >
                <i className="fas fa-dollar-sign fa-lg mr-4"></i> Prix
              </Link>
              <Link
                to="/conditions-tab"
                className={
                  this.state.conditionstoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="conditions-tab"
                role="tab"
                aria-controls="conditions"
                aria-selected={this.state.conditionstoggle ? true : false}
              >
                <i className="fa fa-tasks fa-lg mr-4"></i> Vos conditions
                <Badge
                  count={this.count() >= 0 ? 1 : 0}
                  title={`Vous avez ${this.count()} champ${this.count() > 1 ? "s" : ""
                    }  vide${this.count() > 1 ? "s" : ""}`}
                  offset={[5, 10]}
                  dot={true}
                ></Badge>
              </Link>
              <Link
                to="/insurance-tab"
                className={
                  this.state.insurancetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="quality-tab"
                role="tab"
                aria-controls="quality"
                aria-selected={this.state.insurancetoggle ? true : false}
              >
                <i className="fas fa-hand-holding-medical fa-lg mr-4"></i> Assurance et qualité
              </Link>
              <Link
                to="/deactivate-tab"
                className={
                  this.state.deactivatetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Deactivate-tab"
                role="tab"
                aria-controls="Deactivate"
                aria-selected={this.state.deactivatetoggle ? true : false}
              >
                <i className="fas fa-trash-alt fa-lg mr-4"></i> Désactiver/supprimer
              </Link>
            </div>
          </div>


        </nav>

        <nav className="navbar hide" id="mobile-nav">
          
          <div className="left-section">
            <div
              className="nav show"
              id="navbarNavAltMarkup2"
              name="tab"
              role="tablist"
              aria-orientation="vertical"
              onClick={(e) => this.toggle(e)}
            >
              <Link
                to="/general-tab"
                className={
                  this.state.generaltoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="General-tab"
                role="tab"
                aria-controls="General"
                aria-selected={this.state.generaltoggle ? true : false}
              >
                <i className="fas fa-globe fa-md mr-4"></i> Géneralités
              </Link>
              <Link
                to="/instant-tab"
                className={
                  this.state.instanttoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name "
                }
                name="Instant-tab"
                role="tab"
                aria-controls="Instant"
                aria-selected={this.state.instanttoggle ? true : false}
              >
                <i className="fas fa-bolt fa-md mr-4"></i> Réservation instantanée
              </Link>
              <Link
                to="/mon-bolide-tab"
                className={
                  this.state.monbolidetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name "
                }
                name="monbolide-tab"
                role="tab"
                aria-controls="Description"
                aria-selected={this.state.monbolidetoggle ? true : false}
              >
                <i className="fas fa-plus-circle fa-md mr-4"></i>Monbolide Plus
              </Link>
              <Link
                to="/description-tab"
                className={
                  this.state.descriptiontoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="description-tab"
                role="tab"
                aria-controls="Description"
                aria-selected={this.state.descriptiontoggle ? true : false}
              >
                <i className="fa fa-solid fa-tag fa-md mr-4"></i>  Description
              </Link>
              <Link
                to="/address-tab"
                className={
                  this.state.addresstoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Address-tab"
                role="tab"
                aria-controls="Address"
                aria-selected={this.state.addresstoggle ? true : false}
              >
                <i className="fa fa-address-card fa-md mr-4"></i> Adresse
              </Link> 
              <Link
                to="/photos-tab"
                className={
                  this.state.photostoggle
                    ? "nav-link list-name nav-item active"
                    : "nav-link nav-item list-name"
                }
                name="Photos-tab"
                role="tab"
                aria-controls="Photos"
                aria-selected={this.state.photostoggle ? true : false}
              >
                <i className="fas fa-images mr-4 fa-md"></i> Photos
              </Link>
              <Link
                to="/calendar-tab"
                className={
                  this.state.calendartoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Calendar-tab"
                role="tab"
                aria-controls="Calendar"
                aria-selected={this.state.calendartoggle ? true : false}
              >
                <i className="far fa-calendar fa-md mr-4"></i> Calendrier
              </Link>
              <Link
                to="/price-tab"
                className={
                  this.state.pricetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Price-tab"
                role="tab"
                aria-controls="Price"
                aria-selected={this.state.pricetoggle ? true : false}
              >
                <i className="fas fa-dollar-sign fa-md mr-4"></i> Prix
              </Link>
              <Link
                to="/conditions-tab"
                className={
                  this.state.conditionstoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="conditions-tab"
                role="tab"
                aria-controls="conditions"
                aria-selected={this.state.conditionstoggle ? true : false}
              >
                <i className="fa fa-tasks fa-md mr-4"></i> Vos conditions
                <Badge
                  count={this.count() >= 0 ? 1 : 0}
                  title={`Vous avez ${this.count()} champ${this.count() > 1 ? "s" : ""
                    }  vide${this.count() > 1 ? "s" : ""}`}
                  offset={[5, 10]}
                  dot={true}
                ></Badge>
              </Link>
              <Link
                to="/insurance-tab"
                className={
                  this.state.insurancetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="quality-tab"
                role="tab"
                aria-controls="quality"
                aria-selected={this.state.insurancetoggle ? true : false}
              >
                <i className="fas fa-hand-holding-medical fa-md mr-4"></i> Assurance et qualité
              </Link>
              <Link
                to="/deactivate-tab"
                className={
                  this.state.deactivatetoggle
                    ? "nav-link nav-item list-name active"
                    : "nav-link nav-item list-name"
                }
                name="Deactivate-tab"
                role="tab"
                aria-controls="Deactivate"
                aria-selected={this.state.deactivatetoggle ? true : false}
              >
                <i className="fas fa-trash-alt fa-md mr-4"></i> Désactiver/supprimer
              </Link>
            </div>
          </div>


        </nav>
        
      </>


      // <nav className="navbar navbar-expand-lg navbar-light bg-light col-lg-3">
      //   <Link to="/general-tab" className={this.state.generaltoggle?"nav-link list-name active":"nav-link list-name"} name="General-tab"   role="tab" aria-controls="General"aria-selected={this.state.generaltoggle?true:false} >Géneralités</Link>
      //   <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      //     <span className="navbar-toggler-icon"></span>
      //   </button>
      //   <div className="collapse navbar-collapse  left-section" id="navbarNavAltMarkup">
      //     <div className="navbar-nav nav flex-column nav-pills" name="tab" role="tablist" aria-orientation="vertical" onClick={(e)=>this.toggle(e)}>
      //       {/* <a className="nav-item nav-link active" href="#">Home <span className="sr-only">(current)</span></a>
      //       <a className="nav-item nav-link" href="#">Features</a>
      //       <a className="nav-item nav-link" href="#">Pricing</a>
      //       <a className="nav-item nav-link disabled" href="#">Disabled</a> */}

      //       <Link to="/instant-tab"  className={this.state.instanttoggle?"nav-link list-name active":"nav-link list-name "} name="Instant-tab"  role="tab" aria-controls="Instant"aria-selected={this.state.instanttoggle?true:false} >Réservation instantanée</Link>
      //       <Link to="/mon-bolide-tab" className={this.state.monbolidetoggle?"nav-link list-name active":"nav-link list-name "} name="monbolide-tab"   role="tab" aria-controls="Description" aria-selected={this.state.monbolidetoggle?true:false}>Monbolide Plus</Link>
      //       <Link to="/description-tab"  className={this.state.descriptiontoggle?"nav-link list-name active":"nav-link list-name" }name="description-tab"   role="tab" aria-controls="Description" aria-selected={this.state.descriptiontoggle?true:false}>Description</Link>
      //       <Link to="/address-tab"  className={this.state.addresstoggle?"nav-link list-name active":"nav-link list-name" }name="Address-tab"  role="tab" aria-controls="Address" aria-selected={this.state.addresstoggle?true:false}>Adresse</Link>
      //       <Link to="/photos-tab"  className={this.state.photostoggle?"nav-link list-name active":"nav-link list-name" }name="Photos-tab"  role="tab" aria-controls="Photos"aria-selected={this.state.photostoggle?true:false} >Photos</Link>
      //       <Link to="/calendar-tab"  className={this.state.calendartoggle?"nav-link list-name active":"nav-link list-name" }name="Calendar-tab"  role="tab" aria-controls="Calendar" aria-selected={this.state.calendartoggle?true:false}>Calendrier</Link>
      //       <Link to="/price-tab"  className={this.state.pricetoggle?"nav-link list-name active":"nav-link list-name" }name="Price-tab" role="tab" aria-controls="Price" aria-selected={this.state.pricetoggle?true:false}>Prix</Link>
      //       <Link to="/conditions-tab"  className={this.state.conditionstoggle?"nav-link list-name active":"nav-link list-name" }name="conditions-tab" role="tab" aria-controls="conditions" aria-selected={this.state.conditionstoggle?true:false}>Vos conditions</Link>
      //       <Link to="/insurance-tab"  className={this.state.insurancetoggle?"nav-link list-name active":"nav-link list-name" }name="quality-tab"   role="tab" aria-controls="quality"aria-selected={this.state.insurancetoggle?true:false} >Assurance et qualité</Link>
      //       <Link to="/deactivate-tab"  className={this.state.deactivatetoggle?"nav-link list-name active":"nav-link list-name" }name="Deactivate-tab"  role="tab" aria-controls="Deactivate" aria-selected={this.state.deactivatetoggle?true:false}>Désactiver/supprimer</Link>
      //     </div>
      //   </div>
      // </nav>
    );
  }
}
function mapStateToProps(state) {
  return {
    CarsReducers: state.CarsReducers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewCar: AC_YOUR_CAR }, dispatch);
}

const CarsDashboardNavigationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarsDashboardNavigation);
export default CarsDashboardNavigationComponent;
