const initialstate = {
  FaqInfo  : []
}

// Pages  Reducer
function FaqReducer (state = initialstate, action) {
  switch (action.type) {
    case 'VIEW_FAQS':
    return Object.assign({}, state, {
      FaqInfo  : action.payload.data,
    });
    default:
      return state
  }
}

export default FaqReducer;
