const initialState = {
  modelList: [],
  carModelInfo: {}, // make, model, type
  parkingInfo: {},
  mileageInfo: {},
  numberInfo: {},
  fuelInfo: {},
  billaddressInfo: {},
  customercareInfo: {},
  dobInfo: {},
  featureInfo: {},
  priceInfo: {},
  locationInfo: {},
  checkinaddressInfo: {},
  contactNumberInfo: {},
  facilitiesInfo: {},
  carImageInfo: {}
}
function HostCarReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_CAR_MODEL":
      return Object.assign({}, state, {
        modelList: action.payload
      })
    case "VIEW_HOST_DETAILS":
      return Object.assign({}, state, {
        carModelInfo: action.payload.carmodel,
        parkingInfo: action.payload.parking,
        mileageInfo: action.payload.mileage,
        numberInfo: action.payload.number,
        fuelInfo: action.payload.fuel,
        formId: action.payload._id,
        billaddressInfo: action.payload.billaddress,
        dobInfo: action.payload.dob,
        customercareInfo: action.payload.customercare,
        locationInfo: action.payload.locationspace,
        checkinaddressInfo: action.payload.checkinaddress,
        contactNumberInfo: action.payload.contactnumber,
        featureInfo: action.payload.feature,
        priceInfo: action.payload.price,
        facilitiesInfo: action.payload.facilities,
        carImageInfo: action.payload.carimage,
        modelList: action.model,
      })
    case "HANDLE_INPUT_CHANGE":
      if (action.module === "carmodel") {
        if (action.name === "make") {
          return {
            ...state,
            carModelInfo: {
              ...state.carModelInfo,
              [action.name]: action.value,
              model: ""
            }
          }
        } else if (action.name === "model") {
          return {
            ...state,
            carModelInfo: {
              ...state.carModelInfo,
              [action.name]: action.value
            }
          }
        } else {
          return {
            ...state,
            carModelInfo: {
              ...state.carModelInfo,
              [action.name]: action.value
            }
          }
        }
      }
      if (action.module === "parking") {
        return Object.assign({}, state, {
          parkingInfo: action.value
        })
      }
      if (action.module === "mileage") {
        return {
          ...state,
          mileageInfo: {
            ...state.mileageInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "number") {
        return {
          ...state,
          numberInfo: {
            ...state.numberInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "fuel") {
        return {
          ...state,
          fuelInfo: {
            ...state.fuelInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "location") {
        return {
          ...state,
          locationInfo: {
            ...state.locationInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "features") {
        return {
          ...state,
          featureInfo: {
            ...state.featureInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "carprice") {        
        return {
          ...state,
          priceInfo: {
            ...state.priceInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "contactnumber") {
        return {
          ...state,
          contactNumberInfo: {
            ...state.contactNumberInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "customercare") {
        return {
          ...state,
          customercareInfo: {
            ...state.customercareInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "dob") {
        return {
          ...state,
          dobInfo: {
            ...state.dobInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "billaddress") {
        return {
          ...state,
          billaddressInfo: {
            ...state.billaddressInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "facilities") {
        return {
          ...state,
          facilitiesInfo: {
            ...state.facilitiesInfo,
            [action.name]: action.value
          }
        }
      }
      if (action.module === "carImage") {
        return {
          ...state,
          carImageInfo: {
            ...state.carImageInfo,
            [action.name]: action.value
          }
        }
      }
      break
    default:
      return state
  }
}

export default HostCarReducer;
