import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';

class DateofBirth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
      doberror: ""
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.props.handleInputChange(name, value, 'dob');
  }
  validateForm() {
    const data = this.props.HostCarReducer.dobInfo;
    const id = localStorage.getItem('formId');
    let d = new Date();
    if (data) {
      if (data.month && data.year && data.date && data.month !== "Mois" && data.date !== "Jour" && data.year !== "Année") {
        if (d.getFullYear() - data.year > 17) {
          this.props.updateHostDetails(data, id, 'Dob');
          this.setState({ doberror: "", next: true })
        } else {
          this.setState({ doberror: "L'âge doit être supérieur à 18 ans" })
        }
      } else {
        this.setState({ doberror: "Entrez une date de naissance valide" })
      }
    } else {
      this.setState({ doberror: "Entrez votre date de naissance" })
    }
  }

  previous() {
    this.setState({ previous: true })
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect to="/add-car-image" />
    }
    if (previous) {
      return <Redirect to="/customer-care" />
    }
    let month = this.state.month;
    let datedisplay = this.state.date;
    let yeardisplay = this.state.year;
    const dobInfo = this.props.HostCarReducer.dobInfo;
    if (dobInfo) {
      month = dobInfo.month;
      yeardisplay = dobInfo.year;
      datedisplay = dobInfo.date;
    }
    const months = [
      "Janvier",
      "février",
      "Mars",
      "Avril",
      "mai",
      "juin",
      "juillet",
      "Août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];
    let mon = [];
    for (let i = 0; i <= 11; i++) {
      mon.push(
        <option key={months[i]} value={months[i]} selected={month === months[i]}>
          {months[i]}
        </option>
      );
    }

    let date = [];
    for (let i = 1; i <= 31; i++) {
      date.push(
        <option key={i} value={i} selected={datedisplay === i}>{i}</option>
      )
    }
    let year = [];
    for (let i = 1950; i <= 2021; i++) {
      year.push(
        <option key={i} value={i} selected={yeardisplay === i}>{i}</option>
      )
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "60%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <div className="container">
                      <h5>Ajouter mon véhicule</h5>
                      <div className="title-card">Quelle est votre date de naissance ?</div>
                      <label htmlFor="" className="acc-label">Date de naissance</label>
                      <div className="row">
                        <div className="col">
                          <select className="form-control" id="sel1" name="date" onChange={(e) => this.onChangeValue(e)}>
                            <option>Jour</option>
                            {date}
                          </select>
                        </div>
                        <div className="col">
                          <select className="form-control" id="sel1" name="month" onChange={(e) => this.onChangeValue(e)}>
                            <option>Mois</option>
                            {mon}
                          </select>
                        </div>
                        <div className="col">
                          <select className="form-control" id="sel1" name="year" onChange={(e) => this.onChangeValue(e)}>
                            <option>Année</option>
                            {year}
                          </select>
                        </div>
                      </div>
                      {this.state.doberror !== "" ? <label className='text-danger'>{this.state.doberror}</label> : ""}
                      <div className="list-info mt-4 mb-2">Ces informations sont nécessaires pour des raisons juridiques.</div>
                    </div>

                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button className="prev-button mr-2" onClick={this.previous}>
                        <span className="cobalt-Icon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>Précédent
                      </button>
                      <button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}
const DateofBirthComponent = connect(mapStateToProps, mapDispatchToProps)(DateofBirth);

export default DateofBirthComponent;
