import React from "react";
import 'antd/dist/reset.css';
import PropTypes from "prop-types";
import { Modal,Button} from 'antd';


export default class ThoughtModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    return (
      <>
        <Modal 
        title="Avis" visible={this.props.showThought}
        centered={true}
        width={1000}
        footer={null}
        onCancel={this.onClose} >
         <div class="modalText m-4 p-4">
			...
		 </div>
        </Modal>

      </>
    
    )
  }
}

ThoughtModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showThought: PropTypes.bool.isRequired
};