import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';

class CarFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    }
  }
  onChangeValue(e) {
    const val = e.target.id;
    const featuresList = this.props.DashboardReducer.featuresList;
    var value = true;
    const featureInfo = this.props.HostCarReducer.featureInfo;
    if (featureInfo) {
      if (featureInfo[val]) {
        value = !featureInfo[val];
      }
    }
    if(val === "Full Option"){      
      for(let i = 0; i < featuresList.length; i++) {
        this.props.handleInputChange(featuresList[i].name, value, 'features')    
      }
    }
    else {
      this.props.handleInputChange(val, value, 'features')
    } 
    
    // this.props.handleInputChange(val, value, 'features')
    this.setState({ feature: val })
  }
  validateForm() {
    const id = localStorage.getItem('formId');
    const featureInfo = this.props.HostCarReducer.featureInfo;
    if (featureInfo) {
      if (Object.keys(featureInfo).length === 0 && featureInfo.constructor === Object) {
        this.setState({ next: true })
      }
      else {
        this.props.updateHostDetails(featureInfo, id, 'Feature');
        this.setState({ next: true })
      }
    } else {
      this.setState({ next: true })
    }
  }

  previous() {
    this.setState({ previous: true })
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect push={true} to="/car-price" />
    }
    if (previous) {
      return <Redirect to="/car-facilities"/>
    }
    var featureInfo = {};
    if (this.props.HostCarReducer.featureInfo) {
      featureInfo = this.props.HostCarReducer.featureInfo;
    }
    const featuresList = this.props.DashboardReducer.featuresList;
    var featuresListArray = []
    if (featuresList.length) {
      for (var i = 0; i < featuresList.length; i++) {
        featuresListArray.push(
          <div className="group grp-W" key={featuresList[i].name} >
            <span onClick={(e) => this.onChangeValue(e)} id={featuresList[i].name} className="badg" style={{ backgroundColor: featureInfo[featuresList[i].name] ? "#004AAD" : "white", color: featureInfo[featuresList[i].name] ? "white" : "black" }}>{featuresList[i].name}</span>
          </div>
        )
      }
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "70%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                  <h4 className='card-header text-white text-center py-4 mb-2' style={{background:"#004AAD", textTransform:'uppercase', fontFamily: 'poppins'}}>Ajouter mon véhicule</h4>
                    <div className="container">
                      <div className="title-card">D'autres caractéristiques ?</div>
                      <div className="">
                        {featuresListArray}
                      </div>
                      <div className="list-info mt-4 mb-2">Attirez les conducteurs vers votre listing et obtenez plus de réservations💪.</div>
                    </div>

                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button className="prev-button mr-2" onClick={this.previous}>
                        <span className="cobalt-Icon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>Précédent
                      </button>
                      <button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer,
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}

const CarFeaturesComponent = connect(mapStateToProps, mapDispatchToProps)(CarFeatures);

export default CarFeaturesComponent;
