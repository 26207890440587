import React from 'react';
import {Validpassword} from '../../common/validate';
import {AC_CHANGE_PASSWORD} from '../../actions/accountaction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import {Link,Redirect} from "react-router-dom";
import { Error  } from '../../common/swal';

class ChangePassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        password    		       : '',
        confirmpassword        : '',
        passwordError          : false,
        confirmpasswordError   : false,
        validPassword          : false,
        validConfirmpassword   : false,
      }
  }
  ValidateForm(){
  	const data		   		= this.state;
    const { token } = this.props.match.params;
  
    if(token){
      if(!data.password){
    		this.setState({passwordError:true});
    	}
      if(!data.confirmpassword){
    		this.setState({confirmpasswordError:true});
    	} else{
        if(data.confirmpassword !== data.password){
      		this.setState({validConfirmpassword:true});
      	}
      }
      if(data.password&&!data.passwordError&&data.password===data.confirmpassword){
        this.props.changePassword({token:token,password:data.password})
      }
    } else {
      Error("Something wrong please request to resend mail");
    }
  }
  onchangeValue(e){
    const name  				 = e.target.name;
    const value 				 = e.target.value;
    const validpasswordcheck   = Validpassword(value);
    if(name === "password"){
      if(validpasswordcheck){
        this.setState({password:value,validPassword:false,passwordError:false});
      } else {
        this.setState({password:value,validPassword:true,passwordError:false});
      }
    }
    if(name === "confirmpassword"){
      if(value === this.state.password){
        this.setState({confirmpassword:value,validConfirmpassword:false,confirmpasswordError:false});
      } else {
        this.setState({confirmpassword:value,validConfirmpassword:true,confirmpasswordError:false});
      }
    }
  }
  render() {
    return(
      <div className="main-div">
    		<div className="row">
    		  <div className="col-lg-6">
    			 <div className="main-layout">
    			  	<ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
    				</ul>
    				<div className="tab-content" id="pills-tabContent">
  				  	<div className="tab-header" style={{boxShadow:"none",paddingBottom: "30px"}}>
  				  		 Modifier mot de passe
  				  	</div>
    					<form action="#">
                  <label>Nouveau mot de passe.</label>
                  <input type="password" placeholder="Password" className="form-control form-pad" name="password"  onChange={(e)=>this.onchangeValue(e)}/>
                  {this.state.passwordError?<label style={{color:"red"}}>Le mot de passe est obligatoire</label>:""}
  								{this.state.validPassword?<label style={{color:"red"}}>Veuillez renseigner un mot de passe robuste</label>:""}
                  <br/>
                  <label>Confirmation mot de passe</label>
    							<input type="password" placeholder="Password" className="form-control form-pad" name="confirmpassword"  onChange={(e)=>this.onchangeValue(e)}/>
                  {this.state.confirmpasswordError?<label style={{color:"red"}}>Le mot de passe est obligatoire</label>:""}
  								{this.state.validConfirmpassword?<label style={{color:"red"}}>Mot de passe et mot de passe confirmation non identique</label>:""}
                  <input type="button" value="Change password" className="form-control sign-btn" onClick={()=>this.ValidateForm()}/>
    					</form>
    				</div>
    			</div>
    		  </div>
    		  <div className="col-lg-6">
    		  	<div className="sign-img">
    		  		{/* <img src="assets/images/sign-img.jpg" alt=""/> */}
    		  	</div>
    		  </div>
    		</div>
    	</div>
    )
  }
}

function mapStateToProps(state) {
	return {
		AccountReducer	: state.AccountReducer
	};
  }

function mapDispatchToProps(dispatch) {
   return bindActionCreators({changePassword : AC_CHANGE_PASSWORD},dispatch)
 }

const  ChangePasswordComponent = connect(mapStateToProps,mapDispatchToProps)(ChangePassword);

export default ChangePasswordComponent;
