// App Colors
const maincolor = '#004AAD';
const subcolor = '#ddf3ff';
const layColor = '#007BFF';
const success = "#04AA6D";
const statusbarcolor = '#a877d6';
const bgColor = '#f7fcff';
const whiteColor = '#FFFFFF';
const warningColor = '#F3B119';
const dangerColor = '#f44336';

// GradientColor
const gradientColor1 = '#b668fc';
const gradientColor2 = '#7f16e0';
const gradientColor3 = '#5702a3';

// Fonts
const bold = 'Ubuntu-Bold';
// const semiBold = 'UbuntuSemiBold';
const medium = 'Ubuntu-Medium';
const regular = 'Ubuntu-Regular';
const light = 'Ubuntu-Light';

const ThemeStyle = {
  mvatar: {
    marginRight: 5,
  },
  
  // App Colors
  maincolor,
  subcolor,
  layColor,
  success,
  statusbarcolor,
  bgColor,
  whiteColor,
  warningColor,
  
  // GradientColor
  dangerColor,
  gradientColor1,
  gradientColor2,
  gradientColor3,
  
  // Fonts
  bold,
  // semiBold,
  medium,
  regular,
  light,
};

export default ThemeStyle;
