import React from 'react';
import BusinessSection from './businesssection';
import BookSection from './booksection';
import BookcardSection from './bookcardsection';
import QuestionSection from './questionsection';
import AboutSection from './aboutsection';
// import AppSection from './appsection';

class Business extends React.Component {
  render() {
    return(
        <div  className="main-body">
            <BusinessSection/>
            <BookSection/>
            <BookcardSection/>
            <QuestionSection/>
            <AboutSection/>
            {/* <AppSection/> */}
        </div>
    )
  }
}

export default Business