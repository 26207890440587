import moment from "moment";
// import { fr } from "date-fns/locale";
// registerLocale("fr", fr);
// setDefaultLocale("fr");

export function getFinalPrice(price, day) {
    let finalPrice = price
    if(day < 2) {
        finalPrice = price + price * 0.10
    }
    if (day > 2 && day <= 6) {
        // console.log('On applique une réduction de 10%')
        finalPrice = price - price * 0.05
    }
    if (day >=7  && day <= 13) {
        // console.log('On applique une réduction de 20%')
        finalPrice = price - price * .1
    }
    if (day >= 14) {
        // console.log('On applique une réduction de 30%')
        finalPrice = price - price * .15
    }
    return finalPrice;
}
export function getTravelDay(checkin, checkout) {    
    let startDate = moment(checkin, "DD-MM-YYYY HH:mm");
    let endDate = moment(checkout, "DD-MM-YYYY HH:mm");
    let days = endDate.diff(startDate, "hours");
    console.log("days ceil: "+ Math.ceil(days / 24) + ", days :"+ days / 24)
    return Math.ceil(days / 24)
}

export function getTravelDayFromCarDelails(checkin, checkout) {
    let startDate = moment(checkin).format('DD-MM-YYYY HH:mm');
    let endDate = moment(checkout).format('DD-MM-YYYY HH:mm');
    startDate = moment(startDate, "DD-MM-YYYY HH:mm");
    endDate = moment(endDate, "DD-MM-YYYY HH:mm");
    let days = endDate.diff(startDate, "hours");    
    return Math.ceil(days / 24)
}