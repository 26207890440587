export function Emailvalidate(email){
    const regemail=/^\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3})+$/;
    return regemail.test(email)
       
}

export function CodeValidate(code){
  const status = code.match(/\d/g) && code.length === 4  ? true : false;  
  return status
}

export function PhoneValidate(mobilenumber){
  const status = mobilenumber.match(/\d/g) && mobilenumber.length === 10  ? true : false;  
  return status
}


export function Validpassword(password){
  const pattern         = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})");
  const passwordvalid   = pattern.test(password)
  return passwordvalid
}

export function Twittervalidate(twitter_link)
{
    const twitter_pattern=/^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/;
    const isvalidTwitterlink=twitter_pattern.test(twitter_link);
    return isvalidTwitterlink;
}

export function Phonenumber(number){
    const phoneno = /^\d{10}$/;
    if(phoneno.test(number)){
        return 1;
    }else{
        return 0;
    }
  }
  
  export function Imagevalidation(logo){
    const fileInfo     = logo;
    const fileType     = fileInfo.type;
    const type         = fileType.split('/');
    if(type[1] ==='jpg' || type[1] ==='jpeg' || type[1] === 'png'){
      return 1;
    }else{
      return 0;
    }

  } 
  
  
  
