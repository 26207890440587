import axios from 'axios';
import URL from '../common/api';

const VIEW_PAGES        = 'VIEW_PAGES';

// Action types for Pages management
export function AC_VIEW_PAGES(slug) {
  var formData = {slug:slug}
 return function(dispatch) {
   return axios.post(URL.API.ViewPages,formData)
     .then(({ data }) => {
       dispatch({type: VIEW_PAGES, payload:data});
   });
 };
}
