import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';

class ParkingLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
      place: 'reserved space',
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.props.handleInputChange(name, value, 'location')
  }
  validateForm() {
    var place = this.state.place;
    const id = localStorage.getItem('formId');
    const locationInfo = this.props.HostCarReducer.locationInfo;
    if (locationInfo) {
      if (locationInfo.place) {
        place = locationInfo.place;
      }
    }
    const formData = { place: place }
    this.props.updateHostDetails(formData, id, 'Location');
    this.setState({ next: true })
  }

  previous() {
    this.setState({ previous: true })
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect to="/car-features" />
    }
    if (previous) {
      return <Redirect to="/car-facilities" />
    }
    var location = this.state.place;
    var locationInfo = this.props.HostCarReducer.locationInfo;
    if (locationInfo) {
      if (locationInfo.place) {
        location = locationInfo.place;
      }
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "35%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <div className="container">
                      <h5>Ajouter mon véhicule</h5>
                      <div className="title-card">Où les conducteurs trouveront-ils votre véhicule ?</div>
                      <div><input type="radio" id="other" name="place" value="reserved space" checked={location === 'reserved space'} onChange={(e) => this.onChangeValue(e)} /><span><label htmlFor="other" className="pl-2">In a reserved space</label></span>
                      </div>
                      <div className="alert alert-info" role="alert">
                      Votre véhicule est toujours garé au même endroit, sur une place réservée ou dans un parking privé accessible.
                      </div>                      
                      <div><input type="radio" id="other" name="place" value="public parking" checked={location === 'public parking'} onChange={(e) => this.onChangeValue(e)} /><span><label htmlFor="other" className="pl-2">In public parking</label></span>
                      </div>
                      <div className="alert alert-primary" role="alert">
                        Votre véhicule est garé sur une place disponible sur la route ou dans un parking en libre accès.
                      </div>                      
                      <div className="list-info mt-4 mb-2">Vous pouvez modifier ces informations ultérieurement</div>
                    </div>

                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button className="prev-button mr-2" onClick={this.previous}>
                        <span className="cobalt-Icon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>Précédent
                      </button>
                      <button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}

const ParkingLocationComponent = connect(mapStateToProps, mapDispatchToProps)(ParkingLocation);

export default ParkingLocationComponent;
