const initialstate = {
  pageInfo  : {
    description : ''
  }
}

// Pages  Reducer
function PagesReducer (state = initialstate, action) {
  switch (action.type) {
    case 'VIEW_PAGES':
    return Object.assign({}, state, {
      pageInfo  : action.payload.data,
    });
    default:
      return state
  }
}

export default PagesReducer;
