import React from "react";
// import {Redirect} from "react-router-dom"

class CoverSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      knowMore: false,
    };
  }

  knowMore() {
    this.setState({
      knowMore: true,
    });
  }

  render() {
    if (this.state.knowMore) {
      return window.location.replace("http://onibus.3dev.ci/#/site/home");
    }
    return (
      <section className="cover-section">
        <div className="container">
          <div className="cover-text">
            <h2 style={{ color: "#fff" }}>
              Tous les voyages peuvent être traqués par notre partenaire ONI
            </h2>
          </div>

          <div className="row" style={{justifyContent: 'center'}}>
            <div className="col-lg-3">
              <p className="big-text">Géolocalisation de votré véhicule</p>
              <span className="small-text">
                Ne cherchez plus votre véhicule est ici! Grâce à notre carte
                interactive, vous avez la possibilité de suivre en temps réel le
                bus.
              </span>
            </div>
            <div className="col-lg-3">
              <p className="big-text">
                Heure exacte de dépot de votre véhicule
              </p>
              <span className="small-text">
                Vous saurez exactement à quelle heure le conducteur va arriver
                au lieu de dépot du véhicule
              </span>
            </div>
            <div className="col-lg-3">
              <p className="big-text">Alerte de zone</p>
              <span className="small-text">
                Définissez votre périmètre d'alerte de zone et recevez des sms
                lorsque votre véhicule sors de la zone
              </span>
            </div>
          </div>
          <div className="learn-button">
            <button
              type="button"
              className="learn-btn"
              onClick={(e) => this.knowMore()}
            >
              En savoir plus
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default CoverSection;
