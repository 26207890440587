import React from "react";
import 'antd/dist/reset.css';
import PropTypes from "prop-types";
import { Modal,Button} from 'antd';


export default class PaymentModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    return (
      <>
        <Modal 
        title="Payements" visible={this.props.showPayment}
        centered={true}
        width={1000}
        footer={null}
        onCancel={this.onClose} >
          <div class="modalText m-4 p-4">
            <h3>
                Guide moyen de paiement
            </h3>
            <p>
                Il existe trois moyens de paiement chez Monbolide :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Par Mtn Money 
                    </p>
                </li>
                <li>
                    <p align="left">
                        Par Orange Money
                    </p>
                </li>
                <li>
                    <p align="left">
                        Par Moov Money
                    </p>
                </li>
            </ul>
            <p>
                Pour facilité les transactions pour la réservation ou la location vous
                pourrez payer directement grâce aux différents moyens de payement.
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Par CB
                    </p>
                </li>
            </ul>
            <p>
                Vous pourrez également régler vos locations grâce au paiement par carte
                bancaire, les paiements par compte bancaire seront sécuriser par 3Dsecure.
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Paiement direct a l’agence
                    </p>
                </li>
            </ul>
            <p>
                Pour les personnes ne disposant pas de carte bancaire ou ne désirant pas
                payer électroniquement pourrons réserver et louer directement à l’agence.
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Interdire
                    </p>
                </li>
            </ul>
            <p>
                Il est strictement déconseillé de faire une réservation hors de la
                plateforme MONBOLIDE ou de donner l’argent directement au propriétaire.
                Nous ne serons pas responsables de cette location et nous ne pourrons rien
                faire pour un potentiel remboursement.
            </p>
            <h3>
                Problèmes de paiement
            </h3>
            <p>
                il y a un problème au niveau du paiement vérifié bien certains éléments
                comme :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Vérifiez que votre nom est identique avec le nom qui est associer
                        au moyens de paiement
                    </p>
                </li>
                <li>
                    <p align="left">
                        Vérifiez que votre moyen de paiement fonctionne bien
                    </p>
                </li>
                <li>
                    <p align="left">
                        Vérifiez vos plafonds pour les cartes bancaires
                    </p>
                </li>
                <li>
                    <p align="left">
                        Assurer vous que vous avez le bon numéro pour valider votre
                        3Dsecure.
                    </p>
                </li>
            </ul>
            
            <h3>
                Comprendre le prix d’une location
            </h3>
            <p>
                Le prix de la location pourra se faire en heure en jours et en mois. Le
                prix de la location inclut :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Le prix que le propriétaire a choisi pour sa voiture par zone
                    </p>
                </li>
                <li>
                    <p align="left">
                        Les frais de MONBOLIDE : ces frais serviront au bon fonctionnement
                        de votre plateforme et aidera à améliorer votre parcours clients
                    </p>
                </li>
            </ul>
            <p>
                Le prix total de la location dépendra de plusieurs facteurs tels que la
                durée de votre location et les options que vous aurez choisi.
            </p>
            <p>
                Le prix de votre location ne prendra pas en compte :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Le carburant : si vous rendez la voiture avec moins de carburant
                        pour pourrez être pénaliser par le propriétaire. Vous serez donc
                        dans l’obligation de de lui rembourser la somme correspondante aux
                        carburants manquant
                    </p>
                </li>
                <li>
                    <p align="left">
                        La zone définie : si vous ne respectez pas défini par le
                        propriétaire, il sera en droit de vous demandez une contre parie et
                        vous aurez l’obligation de vous y soumettre
                    </p>
                </li>
                <li>
                    <p align="left">
                        L’assurance et l’assistance : cette couverture est optionnelle et
                        donc vous aurez le choix de vous faire couvrir par notre assurance
                        et de bénéficier de notre assistance totale pendant votre trajet.
                    </p>
                </li>
            </ul>
            <p>
                Par contre sachez que toute inscriptions et toutes les autres options
                proposées sont gratuite
            </p>
            <h3>
                Trouver mon justificatif de paiement :
            </h3>
           
            <p>
                Vous recevrez par email tout les détails de la location avec une facture
                détaillée en fonction de votre moyen de paiements
            </p>
           
            <h3>
                Guide : la caution
            </h3>
            <p>
                Pour une location sur MONBOLIDE, une caution peut vous être demandé selon
                le type de véhicule que vous louez. Celui ci peut être réduit si vous
                souscrivez à l’option assurance et assistance MONBOLIDE. Elle sera payable
                en espèce ou directement sur le site MONBOLIDE. Cette caution peut être
                utilisée en cas de sinistre sur le véhicule pendant votre location.
            </p>
            <p>
                La caution ne doit jamais être remise au propriétaire directement.
            </p>
            
            <h3>
                Guide : les contraventions
            </h3>
            <p>
                Attention, ne pas respecter le code de la route est dangereux et coûte
                cher. Vous êtes responsable de toutes les contraventions émises pendant
                votre location : vous êtes responsable de payer le montant des
                contraventions et, le cas échéant.
            </p>
            <p>
                Selon le type de contravention, le propriétaire devra soit :
            </p>
            
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        La contester : vous recevrez alors la contravention à votre adresse
                        et vous devrez la régler directement.
                    </p>
                </li>
                <li>
                    <p align="left">
                        La payer et nous en informer : nous vous débiterons du montant de
                        la contravention.
                    </p>
                </li>
            </ul>
            <p>
                Dans les deux cas, vous êtes redevable de 10000FCFA supplémentaires de
                frais de gestion pour chaque contravention reçue.
            </p>
          </div>
        </Modal>

      </>
    
    )
  }
}

PaymentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showPayment: PropTypes.bool.isRequired
};