import axios from 'axios';
import URL from '../common/api';

const GET_CONFIGURATION      = 'GET_CONFIGURATION';

export function AC_GET_CONFIGURATION(formdata) {
  return function(dispatch) {
    return axios.post(URL.API.GetConfigurationValue,formdata)
      .then(({ data }) => {
        dispatch({type: GET_CONFIGURATION , payload:data});
    });
  };
}
