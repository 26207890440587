import React, { useState, useRef } from "react"
import { b64toFile } from 'b64-to-file'
import { useHistory, useParams } from "react-router-dom";
// import Autocomplete from 'react-google-autocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_ACCOUNT, AC_EDIT_ACCOUNT, AC_HANDLE_INPUT_CHANGE, AC_SEND_CONTRACT } from '../../actions/accountaction';
// import { Imagevalidation } from '../../common/validate.js';
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";
// import React, {useEffect, useState} from 'react';
import SignaturePad from 'react-signature-canvas'
import './sigCanvas.css'
import { useDropzone } from 'react-dropzone';
// import { format } from "date-fns";
// import { Radio } from 'antd';
import './Rental.css'
// import { useRef } from "preact/hooks";

const RentalAgreement = ({ ViewAccount, AccountReducer, DashboardReducer, SendContract }) => {
  const [step, setStep] = useState(JSON.parse(localStorage.getItem("step")));
  const [imageSignOnwerURL, setImageSignOwnerURL] = useState(''); // create a state that will contain our image url
  const [imageSignCustomerURL, setImageSignCustomerURL] = useState(''); // create a state that will contain our image url
  var history = useHistory()
  const [stateCarError, setStateCarError] = useState({
    exterior: false,
    interior: false,
    carImage: false,
    mileage: false,
    fuel: false
  })

  const [contractIsGood, setcontractIsGood] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [isActiveSignCustomer, setIsActiveSignCustomer] = useState(true)
  const [damaged, setDamaged] = useState(false)
  const [loading, setLoading] = useState(false)

  const sigCanvasOwner = useRef({})
  const sigCanvasCustomer = useRef({})
  const clear = () => {
    sigCanvasOwner.current.clear()
    setIsActive(true)
    setImageSignOwnerURL('')
    setOwnerSignFile(null)
  }
  
  const [customerSignFile, setCustomerSignFile] = useState(null)
  const [ownerSignFile, setOwnerSignFile] = useState(null)


  const clearSignCustomer = () => {
    sigCanvasCustomer.current.clear()
    setIsActiveSignCustomer(true)
    setImageSignCustomerURL('')
    setCustomerSignFile(null)
  }
  const saveSignOwner = async () => {
    setImageSignOwnerURL(sigCanvasOwner.current.getTrimmedCanvas().toDataURL("image/png"));
    setIsActive(false)
  }

  const saveSignCustomer = () => {
    setImageSignCustomerURL(sigCanvasCustomer.current.getTrimmedCanvas().toDataURL("image/png"));
    setIsActiveSignCustomer(false)

  }

  // const save = () =>
  // setImageURL(sigCanvasCustomer.current.getTrimmedCanvas().toDataURL("image/png"));

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 4,
  });
  const acceptedFileItems = acceptedFiles.map(file => (
    <li style={{ fontSize: '9px' }} key={file.path}>
      {file.path} - {file.size / 1000}Ko
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li style={{ fontSize: '9px' }} key={file.path}>
        {file.path} - {file.size / 1000} Ko
        <ul style={{ listStyleType: 'none' }}>
          {errors.map(e => <li style={{ fontSize: '9px' }} key={e.code}>{e.message}</li>)}
        </ul>

      </li>
    )
  });
  // const [contratInfo,setContratInfo] = useState({});
  // const userId = localStorage.getItem('siteUser')
  const param = useParams()
  if (localStorage.getItem("step")) {
    let step = JSON.parse(localStorage.getItem("step"));
    localStorage.setItem("step", step)
  } else {
    localStorage.setItem("step", 1);
  }


  const countryList = DashboardReducer.countryList;
  let tripsList = AccountReducer.tripsList;
  const currentTrip = tripsList.find(trip => trip._id === param.id);
 
  const [permisInfo, setPermisInfo] = useState({
    dateOptention: '',
    permisNumber: '',
    birthDay: '',
  })

  const [paymentMethod, setPaymentMethod] = useState(null);

  const [etatCar, setEtatCar] = useState({
    etatExterieur: '',
    etatInterieur: '',
  })

  const [mileageInfo, setMileageInfo] = useState({
    mileage: ''
  })
  const [carburantInfo, setCarburantInfo] = useState({
    carburant: ''
  })



  var countryArray = [];
  for (var i = 0; i < countryList.length; i++) {
    countryArray.push(
      <option key={i} value={countryList[i].countryname}>{countryList[i].countryname}</option>
    )
  }


  const selectPayment = (event, method) => {
    setPaymentMethod(method);
  }

  const setDamagedCar = (event, value) => {
    setDamaged(value)
  }
  
  //PERIMIS INFO
  const onPermisInfoChange = async (event) => {
    if (event.target.name === "permisNumber") {
      setPermisInfo({
        ...permisInfo,
        permisNumber: event.target.checked
      })
    }

    if (event.target.name === "birthDay") {
      setPermisInfo({
        ...permisInfo,
        birthDay: event.target.checked
      })
    }

    if (event.target.name === "dateOptention") {
      setPermisInfo({
        ...permisInfo,
        dateOptention: event.target.checked
      })
    }
  }

  const isDisabled = (value) => {
    if (value === '') {
      return true
    } else {
      return false
    }
  }
  // Etat car Info
  const etatOnchange = async (event) => {
    if (event.target.name === "etatExterieur") {
      if (event.target.value === '') {
        setStateCarError({ ...stateCarError, exterior: true })

      } else {
        setStateCarError({ ...stateCarError, exterior: false })
      }
      setEtatCar({
        ...etatCar,
        etatExterieur: event.target.value
      })
    }

    if (event.target.name === "etatInterieur") {
      if (event.target.value === '') {
        setStateCarError({ ...stateCarError, interior: true })
      }
      else {
        setStateCarError({ ...stateCarError, interior: false })
      }
      setEtatCar({
        ...etatCar,
        etatInterieur: event.target.value
      })
    }

    // if (event.target.name === "etatCarImage") {
    //   const etatCarImageFile = event.target.files[0];
    //   if (Imagevalidation(etatCarImageFile)) {
    //     setEtatCar({
    //       ...etatCar,
    //       etatCarImage: etatCarImageFile
    //     })

    //     let reader = new FileReader();
    //     var url = reader.readAsDataURL(etatCarImageFile);

    //     reader.onloadend = () => {
    //       setPermisInfo({
    //         ...etatCar,
    //         carImagePreview: [reader.result]
    //       })
    //     }
    //   }

    // }
  }

  // Mileage info

  const onMileageChange = async (event) => {
    if (event.target.name === "mileage") {
      if (event.target.value === "") {
        setStateCarError({ ...stateCarError, mileage: true })
      } else {
        setStateCarError({ ...stateCarError, mileage: false })
      }
      setMileageInfo({
        ...mileageInfo,
        mileage: event.target.value
      })
    }
  }

  // carburant
  const onCarburantChange = async (event) => {
    if (event.target.name === "carburant") {
      if (event.target.value === "") {
        setStateCarError({ ...stateCarError, fuel: true })
      } else {
        setStateCarError({ ...stateCarError, fuel: false })
      }
      setCarburantInfo({
        ...carburantInfo,
        carburant: event.target.value
      })
    }
  }
  const sendContract = async () => {
    setLoading(true)
    var formData = new FormData();
    // Information sur le véhicule
    formData.append('numberCar', currentTrip?.carId.number?.number)
    formData.append('carName', currentTrip?.carId?.carmodel?.make?.brandname + ' ' + currentTrip?.carId?.carmodel?.model?.name)

    // Information sur la location
    formData.append('tripID', currentTrip?._id)
    formData.append('price', currentTrip?.price)
    formData.append('checkin', currentTrip?.checkin)
    formData.append('checkout', currentTrip?.checkout)
    //Informations client (OK)
    formData.append('customerName', currentTrip?.customerId?.firstname + " " + currentTrip?.customerId?.lastname)
    formData.append('dateofbirth', currentTrip?.customerId?.dateofbirth)
    formData.append('customerEmail', currentTrip?.customerId?.email)
    formData.append('addressline1', currentTrip?.customerId?.addresline1)
    formData.append('permisNumber', currentTrip?.customerId?.validatedate)

    // Information Propriétaire (Ok)
    formData.append('ownerName', currentTrip?.ownerId?.firstname + " " + currentTrip?.ownerId?.lastname)
    formData.append('ownerEmail', currentTrip?.ownerId?.email)


    if (currentTrip?.tripStatus === 2) {

      if (paymentMethod &&
        permisInfo.birthDay === true &&
        permisInfo.dateOptention === true &&
        permisInfo.permisNumber === true &&
        acceptedFiles.length > 0 &&
        etatCar.etatExterieur !== '' &&
        etatCar.etatInterieur !== '' &&
        mileageInfo.mileage &&
        carburantInfo.carburant &&
        imageSignCustomerURL !== '' &&
        imageSignOnwerURL !== ''
      ) {
        const signOwner = await b64toFile(imageSignOnwerURL, 'signature_proprietaire.png')
        const signCustomer = await b64toFile(imageSignCustomerURL, 'signature_locataire.png')
        // Information sur le véhicule
        formData.append('action', 0)
        // formData.append('numberCar', currentTrip?.carId.number?.number)
        formData.append('fuelLevel', carburantInfo?.carburant)
        formData.append('mileage', mileageInfo?.mileage)
        acceptedFiles.map((file, index) => {
          formData.append('carImages', file);
        });
        // formData.append('carImages', acceptedFiles)

        // Information sur la location
        formData.append('paymentMethod', paymentMethod)
        // formData.append('permisNumber', permisInfo.permisNumber)
        formData.append('externalState', etatCar.etatExterieur)
        formData.append('internalState', etatCar.etatInterieur)

        // Information sur contrat
        formData.append('signOwner', signOwner)
        formData.append('signCustomer', signCustomer)
        
        SendContract(formData).then(() => {
          history.push('/booking-request')
          localStorage.setItem('step', 1)
          setLoading(false)
        })
      } 
      else {
        setcontractIsGood(true)
        setLoading(false)
      }

    }
    else if (currentTrip?.tripStatus === 22) {
      if (
        imageSignCustomerURL !== '' &&
        imageSignOnwerURL !== ''
      ) {
        const signOwner = await b64toFile(imageSignOnwerURL, 'signature_proprietaire.png')
        const signCustomer = await b64toFile(imageSignCustomerURL, 'signature_locataire.png')
        formData.append('action', 1) // le serveur se informé 
        formData.append('fuelLevel', carburantInfo?.carburant)
        formData.append('mileage', mileageInfo?.mileage)
        formData.append('damaged', damaged)
        // Information sur contrat
        formData.append('signOwner', signOwner)
        formData.append('signCustomer', signCustomer)

        // Mettre fin au contrat de location
        // Le véhicule est il endomagé
        SendContract(formData).then(() => {
          history.push('/booking-request')
          localStorage.setItem('step', 1)
          setLoading(false)
        })
      } else {
        setcontractIsGood(true)
        setLoading(false)
      }

    }
    // tripID			: currentTrip._id,

    // paymentMethod	: req.body.paymentMethod,
    // permisNumber	: req.body.permisNumber,
    // birthDay		: req.body.birthDay,
    // carImage		: req.body.carImage,
    // etatExterieur	: req.body.etatExterieur,
    // etatInterieur	: req.body.etatInterieur,
    // mileage			: req.body.mileage,
    // carburant		: req.body.carburant,

    // else {
    //   setcontractIsGood(true)
    // }

    // })
  }
  const override = css`
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
  const nextStep = async (step) => {
    if (step === 3) {
      if (etatCar.etatExterieur === '' || etatCar.etatExterieur === null) {
        setStateCarError({ ...stateCarError, exterior: true })
      } else {
        setStateCarError({ ...stateCarError, exterior: false })
      }
      // else
      if (etatCar.etatInterieur === '') {
        setStateCarError({ ...stateCarError, interior: true })
      } else {
        setStateCarError({ ...stateCarError, interior: false })
      }

    }
    setStep(step + 1)
    localStorage.setItem("step", step + 1)
  }

  const prevStep = async (step) => {
    setStep(step - 1)
    localStorage.setItem("step", step - 1)
  }

  // if(loading){
  //   return (
  //     <div className="loading"><BounceLoader color="#5C95F5" loading={loading} css={override} size={50} /></div>
  //   )
  // }


  // BILLING FRONT
  if(currentTrip?.tripStatus === 2) {
    if (JSON.parse(localStorage.getItem("step")) === 1) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>        
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "20%" }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card px-0">
  
                      <h5 className="card-header text-center" style={{ borderBottom: 'none' }}><i className="fas fa-money-bill"></i> Vérification paiement</h5>
                      {/* 
                      <div className="alert alert-secondary">
                        <ul>
                          <li>Merci de lancer le numéro du client sa présence pour vérifier la conformité de</li>
                          <li></li>
                        </ul>
                        Par quel moyen de paiement avez-vous vérifiez le paiement de location de véhu.
                        </div> 
                        */}
  
                      <div className="card--container" style={{ borderTop: "none" }}>
                        <div className={`card--method ${paymentMethod === 'card' ? 'selected pulse-button' : ""}`} onClick={(e) => selectPayment(e, "card")}>
                          <div>
                            <img className="card--method-img" src="https://i.imgur.com/V71FtoY.png" title="source: imgur.com" alt="Contrat" />
                          </div>
                          <p className="card--method-name text-center">
                            Je confirme la carte de crédit
                          </p>
                        </div>
                        <div className={`card--method ${paymentMethod === 'mobile' ? 'selected pulse-button' : ""}`} onClick={(e) => selectPayment(e, "mobile")}>
  
                          <div>
                            <img className="card--method-img" src="https://i.imgur.com/UP0ifOT.png" title="source: imgur.com" alt="contrat2" />
                          </div>
                          <p className="card--method-name text-center">
                            Je confirme le numéro mobile
                          </p>
                        </div>
  
                      </div>
  
                      {/* <div className="list-info mt-4 mb-2">
                          Pour que vous puissiez recevoir des paiements, nous utilisons votre adresse de facturation, votre numéro de téléphone et votre adresse électronique, que vous pouvez modifier dans les détails de votre compte.</div> */}
                      <div className="list-info mt-4 mb-2">
                        <div>Cliquer sur le bouton:</div>
                        <ul>
                          <li>Pour la vérification de paiement le client doit présenter sa facture. Le propriétaire quand à lui lance le numéro du client pour vérification</li>
                          <li> <code>Je confirme la carte de crédit</code> si vous confirmez la vérification du numéro de la carte bancaire. </li>
                          <li><code>Je confirme le numéro mobile</code> si vous confirmez la vérification du numéro mobile.</li>
                        </ul>
                      </div>
  
                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        <button className="Woohoo-btn" disabled={paymentMethod === null ? true : false} onClick={() => nextStep(step)}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
  
    // PERMIS FRONT
    if (JSON.parse(localStorage.getItem("step")) === 2) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "40%" }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card">
  
                      <h5 className="card-header text-center"><i className="fas fa-user-shield"></i> Permis de conduire original</h5>
                      {/* <div className="title-card">Verifier le permis de conduire original</div> */}
                      <div className="imgBtnContainers">
                        {/* { (!permisInfo.rectoPreview) ? <label className="upladBtn">
                            <input type="file" hidden  name="rectoImage" onChange={(e)=>onPermisInfoChange(e)}/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"  className="bi bi-camera-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                            </svg>
                            <span  className="mt-2">Recto</span>
                        </label> :null}
                        {(!permisInfo.versoPreview) ? <label className="upladBtn">
                            <input type="file" hidden name="versoImage" onChange={(e)=>onPermisInfoChange(e)}/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"  className="bi bi-camera-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                            </svg>
                            <span  className="mt-2" >Verso</span>
                        </label> : null } */}
  
                      </div>
  
  
                      {/* <div className="list-info mt-4 mb-2" style={{ marginBottom: "20px" }}>
                        Verifier le permis de conduire original du locataire (pas de photocopie ou de déclaration de perte)
                      </div> */}
  
                      <div className="flex-container">
                        <label htmlFor="permisNumber" className="field--density">N° Permis <br />{currentTrip?.customerId?.licensenumber ? <span>{currentTrip?.customerId?.licensenumber}</span> : <span className="text-danger">Non renseigné</span>} </label>
  
                        <input type="checkbox" checked={permisInfo.permisNumber} value={permisInfo.permisNumber} className="" id="permisNumber" name="permisNumber" onChange={(e) => onPermisInfoChange(e)} />
                      </div>
                      {/* <label>Numero du permis</label> */}
                      {/* <input type="checkbox" value={permisInfo.permisNumber} className="form-control mb-3" placeholder="Entrer le numero de permis" name="permisNumber"  onChange={(e)=>onPermisInfoChange(e)}/> */}
                      <div className="flex-container" style={{ backgroundColor: 'white' }}>
                        <label htmlFor="dateOptention" className="field--density">Date d'obtention <br /> {currentTrip?.customerId?.validatedate ? <span>{currentTrip?.customerId?.validatedate}</span> : <span className="text-danger">Non renseignée</span>}</label  >
                        <input type="checkbox" checked={permisInfo.dateOptention} value={permisInfo.dateOptention} className="" name="dateOptention" id="dateOptention" onChange={(e) => onPermisInfoChange(e)} />
                      </div>
                      {/* <label>Date D'optention</label  >
                      <input type="date" value={permisInfo.dateOptention} className="form-control mb-3" name="dateOptention" onChange={(e) => onPermisInfoChange(e)} /> */}
                      <div className="flex-container" >
                        <label htmlFor="birthDay" className="field--density">Date de naissance <br /> {currentTrip?.customerId?.dateofbirth ? <span>{currentTrip?.customerId?.dateofbirth}</span> : <span className="text-danger">Non renseignée</span>}</label>
                        <input type="checkbox" checked={permisInfo.birthDay} value={permisInfo.birthDay} className="" id="birthDay" name="birthDay" onChange={(e) => onPermisInfoChange(e)} />
                      </div>
                      {/* <label>Date de naissance</label  >
                      <input type="date" value={permisInfo.birthDay} className="form-control mb-3" name="birthDay" onChange={(e) => onPermisInfoChange(e)} /> */}
  
                      <div className="list-info mt-4 mb-2">
                        <ul>
                          <li>Vous devez vérifier le permis de conduire du locataire et confirmer si les informations sont correctes</li>
                          <li>Si l'une des informations est incorrecte, vous pouvez demander au locataire de mettre à jour son profil et recommencez le contrat depuis le début</li>
                        </ul>
                        
                      </div>
                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={() => prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        <button className="Woohoo-btn" onClick={() => nextStep(step)}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>)
    }
  
    // ETAT FRONT
    if (JSON.parse(localStorage.getItem("step")) === 3) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card px-2">
  
                      <h5 className="card-header text-center">Etats des lieux du véhicule</h5>
                      {/* <div className="title-card"></div> */}
                      {/* <div className="list-info mt-4 mb-2" style={{ marginBottom: "20px" }}>
                        Photos de la voiture
                      </div> */}
                      {/* <div className="alert alert-info">Merci de renseigner toutes les informations sur l'état du véhicule concerné</div> */}
                      {currentTrip?.tripStatus === 2 &&
                        <div className="imgBtnContainers mt-3">
                          <section className="container p-0">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <span className='icon-size'><i className="fas fa-camera-retro fa-lg"></i></span>
                              <p>Glisser ou cliquer dans ce champs pour charger une image</p>
                              <em>(Vous êtes autorisé à sélectionner 4 images au maximum)</em>
                            </div>
                            <aside className="py-3" style={{ display: 'flex', justifyContent: 'space-around' }}>
                              {acceptedFiles.length > 0 && <div className="card">
                                <h6 className="card-header bg-success text-white">Images acceptées</h6>
                                {acceptedFiles.length > 0 ? <ul style={{ listStyleType: 'none' }}>{acceptedFileItems}</ul> : <div>Aucun fichier accepté</div>}
                                {/* {!acceptedFiles && <div>Aucun fichier accepté</div>} */}
                              </div>}
                              {fileRejections.length > 0 &&
                                <div className="card">
                                  <h6 className="card-header bg-danger text-white">Images refusées</h6>
                                  <ul style={{ listStyleType: 'none' }}>{fileRejectionItems}</ul>
                                </div>}
  
                            </aside>
                          </section>
                        </div>
                      }
  
  
  
                      { currentTrip?.tripStatus === 2 && 
                      <>
                      <label htmlFor="etatExterieur">Etat extérieur </label>
                      <textarea className="form-control mb-1" value={etatCar.etatExterieur} name="etatExterieur" id="etatExterieur" placeholder="Laisser ici un commentaire sur l'extérieur de la voiture" onChange={(e) => etatOnchange(e)}>  </textarea>
                      {stateCarError?.exterior && <div className="alert alert-danger">Vos impressions sur l'état extérieur du véhicule</div>}
  
                      <label htmlFor="etatInterieur">Etat intérieur </label>
                      <textarea className="form-control mb-1" id="etatInterieur" value={etatCar.etatInterieur} name="etatInterieur" placeholder="Laisser ici un commentaire sur l'intérieur de la voiture" onChange={(e) => etatOnchange(e)}>  </textarea>
                      {stateCarError?.interior && <div className="alert alert-danger">Vos impressions sur l'état intérieur du véhicule</div>}
                      </>
                      }
                      {currentTrip?.tripStatus === 22 &&
                      <>
                      <label htmlFor="">Ma voiture est endomagée</label>
  
                      </>
                      }
  
                    </div>
    
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={() => prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        <button className="Woohoo-btn" onClick={() => nextStep(step)}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
  
    // KILOMETRAGE FRONT
    if (JSON.parse(localStorage.getItem("step")) === 4) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card px-2">
  
                      <h5 className="card-header text-center"><i className="fas fa-balance-scale"></i> Kilometrage au compteur</h5>
                      <div className="title-card"></div>
  
  
                      <div className="list-info mt-4 mb-2" style={{ marginBottom: "20px" }}>
                        Rélevez le kilométrage affiché au compteur de la voiture
                      </div>
  
  
                      <label htmlFor="mileage">Kilométrage</label  >
                      <div style={{ display: 'flex' }}>
                        <input type="text" value={`${mileageInfo?.mileage}`} id="mileage" className="form-control mb-3" placeholder="Kilométrage constaté ? Ex: 10394" name="mileage" onChange={(e) => onMileageChange(e)} />
                        <input type="text" defaultValue="Km" className="form-control mb-3" style={{ width: "80px", textAlign: "center", MozUserModify: "read-only", WebkitUserModify: "read-only" }} />
                      </div>
                      {stateCarError.mileage && <div className="alert alert-danger">Veuillez renseigner le Kilometrage au compteur de la voiture</div>}
  
  
  
                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={() => prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        <button className="Woohoo-btn" disabled={isDisabled(mileageInfo.mileage)} onClick={() => nextStep(step)}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
  
    if (JSON.parse(localStorage.getItem("step")) === 5) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>
          {loading && <div className="loading"><BounceLoader color="#5C95F5" loading={loading} css={override} size={50} /></div>}
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card px-2">
  
                      <h5 className="card-header text-center"><i className="fas fa-ruler-vertical"></i> Niveau de carburant et signature</h5>
                      {/* <div className="title-card"></div> */}
                      <div className="list-info mt-4 mb-2" style={{ marginBottom: "20px" }}>
                        Rélevez le <strong>niveau de carburant</strong> affiché le tableau de bord de votre voiture
                      </div>
                      <label><strong>Carburant</strong></label>
                      <div style={{ display: 'flex' }}>
                        <input type="text" value={`${carburantInfo?.carburant}`} className="form-control mb-3" placeholder="Entrer le niveau de carburant" name="carburant" onChange={(e) => onCarburantChange(e)} />
                        <input type="text" defaultValue="%" className="form-control mb-3" style={{ width: "80px", textAlign: "center", MozUserModify: "read-only", WebkitUserModify: "read-only" }} />
                      </div>
                      {stateCarError.fuel && <div className="alert alert-danger">Veuillez renseigner le niveau de carburant</div>}
  
                      <label><strong>Signature du propriétaire</strong></label>
                      <div >
                        <SignaturePad onEnd={saveSignOwner} ref={sigCanvasOwner} canvasProps={{ className: 'signatureCanvas' }} />
                        <div style={{ display: 'flex', justifyContent: 'right' }} className="mb-3">
                          <button onClick={clear} className="btn btn-danger mr-2"><i className="fas fa-times-circle"></i> Effacer</button>
                          {/* <button className="btn btn-success" disabled={isActive} onClick={saveSignOwner}><i className="fas fa-check-circle"></i> Valider</button> */}
                        </div>
                      </div>
  
  
                      <label><strong>Signature du client</strong></label>
                      <div className="mb-3">
                        <SignaturePad ref={sigCanvasCustomer} onEnd={saveSignCustomer} canvasProps={{ className: 'signatureCanvas' }} />
                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                          <button onClick={clearSignCustomer} className="btn btn-danger mr-2"><i className="fas fa-times-circle"></i> Effacer</button>
                          {/* <button className="btn btn-success" disabled={isActiveSignCustomer} onClick={saveSignCustomer}><i className="fas fa-check-circle"></i> Valider</button> */}
                        </div>
                      </div>
                      {contractIsGood && <div className="alert alert-danger">Veuillez renseigner tous les champs avant la soumission de ce contrat</div>}
  
                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={() => prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        {(JSON.parse(localStorage.getItem("step")) < 5) ? <button className="Woohoo-btn" onClick={() => nextStep(step)}>Suivant</button> : <button className="Woohoo-btn" onClick={() => sendContract()}>Valider</button>}
                      </div>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
  
  }
  else if(currentTrip?.tripStatus === 22){
        // ETAT FRONT
    if (JSON.parse(localStorage.getItem("step")) === 1) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "33.33%" }} aria-valuenow="33.33" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card px-0">
  
                      <h5 className="card-header text-center" style={{ borderBottom: 'none' }}> Etat du véhicule</h5>
                      <div className="lead p-3 text-center">Votre véhicule est-il endomagé ?</div>
                      {/* 
                      <div className="alert alert-secondary">
                        <ul>
                          <li>Merci de lancer le numéro du client sa présence pour vérifier la conformité de</li>
                          <li></li>
                        </ul>
                        Par quel moyen de paiement avez-vous vérifiez le paiement de location de véhu.
                        </div> 
                        */}
  
                      <div className="card--container" style={{ borderTop: "none" }}>
                        <div className={`card--method ${damaged === true ? 'selected pulse-button' : ""}`} onClick={(e) => setDamagedCar(e, true)}>
                          <div>
                          <i className={`fas fa-exclamation-triangle fa-lg ${damaged ? "text-white" : ""}`}></i>
                          </div>
                          <p className="card--method-name text-center">
                            Oui
                          </p>
                        </div>
                        <div className={`card--method ${damaged === false ? 'selected pulse-button' : ""}`} onClick={(e) => setDamagedCar(e, false)}>
  
                          <div>
                            <i className={`fas fa-hand-sparkles fa-lg ${!damaged ? "text-white" : ""}`}></i>
                          </div>
                          <p className="card--method-name text-center">
                            Non
                          </p>
                        </div>
  
                      </div>
  
                      
                      {/* <div className="list-info mt-4 mb-2">
                        <div>Cliquer sur le bouton:</div>
                        <ul>
                          <li> Cliquer sur <code>Oui</code>si votre voiture est votre véhicule est emdomagé ou cliquer sur <code>Non</code> s'il ne l'est pas.</li>
                         
                        </ul>
                      </div> */}
  
                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        <button className="Woohoo-btn" onClick={() => nextStep(step)}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }

    if (JSON.parse(localStorage.getItem("step")) === 2) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "66.66%" }} aria-valuenow="66.66" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card px-2">
  
                      <h5 className="card-header text-center"><i className="fas fa-balance-scale"></i> Kilometrage au compteur</h5>
                      <div className="title-card"></div>
  
  
                      <div className="list-info mt-4 mb-2" style={{ marginBottom: "20px" }}>
                        Rélevez le kilométrage affiché au compteur de la voiture
                      </div>
  
  
                      <label htmlFor="mileage">Kilométrage</label  >
                      <div style={{ display: 'flex' }}>
                        <input type="text" value={`${mileageInfo?.mileage}`} id="mileage" className="form-control mb-3" placeholder="Kilométrage constaté ? Ex: 10394" name="mileage" onChange={(e) => onMileageChange(e)} />
                        <input type="text" defaultValue="Km" className="form-control mb-3" style={{ width: "80px", textAlign: "center", MozUserModify: "read-only", WebkitUserModify: "read-only" }} />
                      </div>
                      {stateCarError.mileage && <div className="alert alert-danger">Veuillez renseigner le Kilometrage au compteur de la voiture</div>}
  
  
  
                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={() => prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        <button className="Woohoo-btn" disabled={isDisabled(mileageInfo.mileage)} onClick={() => nextStep(step)}>Suivant</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
    if (JSON.parse(localStorage.getItem("step")) === 3) {
      return (
        <section style={{ position: "relative", bottom: "100px" }}>
          {loading && <div className="loading"><BounceLoader color="#5C95F5" loading={loading} css={override} size={50} /></div>}
          <div className="form-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="list-my-car">
                    <div className="list-top">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="list-car-card px-2">
  
                      <h5 className="card-header text-center"><i className="fas fa-ruler-vertical"></i> Niveau de carburant et signature</h5>
                      {/* <div className="title-card"></div> */}
                      <div className="list-info mt-4 mb-2" style={{ marginBottom: "20px" }}>
                        Rélevez le <strong>niveau de carburant</strong> affiché le tableau de bord de votre voiture
                      </div>
                      <label><strong>Carburant</strong></label>
                      <div style={{ display: 'flex' }}>
                        <input type="text" value={`${carburantInfo?.carburant}`} className="form-control mb-3" placeholder="Entrer le niveau de carburant" name="carburant" onChange={(e) => onCarburantChange(e)} />
                        <input type="text" defaultValue="%" className="form-control mb-3" style={{ width: "80px", textAlign: "center", MozUserModify: "read-only", WebkitUserModify: "read-only" }} />
                      </div>
                      {stateCarError.fuel && <div className="alert alert-danger">Veuillez renseigner le niveau de carburant</div>}
  
                      <label><strong>Signature du propriétaire</strong></label>
                      <div >
                        <SignaturePad onEnd={saveSignOwner} ref={sigCanvasOwner} canvasProps={{ className: 'signatureCanvas' }} />
                        <div style={{ display: 'flex', justifyContent: 'right' }} className="mb-3">
                          <button onClick={clear} className="btn btn-danger mr-2"><i className="fas fa-times-circle"></i> Effacer</button>
                          {/* <button className="btn btn-success" disabled={isActive} onClick={saveSignOwner}><i className="fas fa-check-circle"></i> Valider</button> */}
                        </div>
                      </div>
  
  
                      <label><strong>Signature du client</strong></label>
                      <div className="mb-3">
                        <SignaturePad ref={sigCanvasCustomer} onEnd={saveSignCustomer} canvasProps={{ className: 'signatureCanvas' }} />
                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                          <button onClick={clearSignCustomer} className="btn btn-danger mr-2"><i className="fas fa-times-circle"></i> Effacer</button>
                          {/* <button className="btn btn-success" disabled={isActiveSignCustomer} onClick={saveSignCustomer}><i className="fas fa-check-circle"></i> Valider</button> */}
                        </div>
                      </div>
                      {contractIsGood && <div className="alert alert-danger">Veuillez renseigner tous les champs avant la soumission de ce contrat</div>}
  
                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        {JSON.parse(localStorage.getItem("step")) > 1 ? (
                          <button className="prev-button mr-2" onClick={() => prevStep(step)}>
                            <span className="cobalt-Icon">
                              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                              </svg>
                            </span>Précédent
  
                          </button>
                        ) : null}
  
                        {(JSON.parse(localStorage.getItem("step")) < 3) ? <button className="Woohoo-btn" onClick={() => nextStep(step)}>Suivant</button> : <button className="Woohoo-btn" onClick={() => sendContract()}>Valider</button>}
                      </div>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }
  
}




function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
    DashboardReducer: state.DashboardReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ SendContract: AC_SEND_CONTRACT, ViewAccount: AC_VIEW_ACCOUNT, EditAccount: AC_EDIT_ACCOUNT, HandleInputChange: AC_HANDLE_INPUT_CHANGE }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalAgreement);
