import React from 'react';
// import {Link } from "react-router-dom";
import Carlist from './carlist';

class Searchcar extends React.Component {

  constructor(props){
    super(props)
  }


  render() {

    return(
      <div className="address-full">
        <div className="main-body">
          <Carlist history={this.props.history}/>
        </div>
      </div>
    )
  }
}

export default Searchcar;
