import axios from 'axios';
import URL from "../common/api";

const DASHBOARD = 'DASHBOARD' ;

// export function AC_DASHBOARD(formdata) {
//   return function(dispatch) {
//     return axios.post(URL.API.DashboardList,formdata)
//       .then(({ data }) => {
//         dispatch({type: DASHBOARD, payload:data.data});
//     });
//   };
// }

export function AC_DASHBOARD(formdata){
  try {

    const dashboard = axios.post(URL.API.DashboardList,formdata);
    const lastestCars = axios.post(URL.API.LastAddCars);


    return function(dispatch){
        axios.all([dashboard,lastestCars]).then(axios.spread((...response)=>{
            const {data:dash} = response[0];
            const {data:last} = response[1];
            dash.data.results.push(last.data)            
            dispatch({type: DASHBOARD, payload:dash.data});
        }))
    }
    
  } catch (error) {    
  }
  
}