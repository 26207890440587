import React from "react";
// import Autocomplete from 'react-google-autocomplete';
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AC_GET_CONFIGURATION } from "../../actions/configurationaction";
import { AC_GET_MODEL, AC_HOST_CAR } from "../../actions/hostcaraction";
import { Route , withRouter} from 'react-router-dom';
class BannerSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelpage: false,
      make: "",
      model: "",
      year: "2021",
      mileage: "",
      parking: "",
      price: "1.000.000",
    };
    this.validateForm = this.validateForm.bind(this);
  }
  componentDidMount() {    
    this.props.ConfigList({ slug: "mileage" });
    this.props.getModel({ id: "" });
  }
  
  onChangeValue(event) {
    const name = event.target.name;
    var value = event.target.value;
    if (name === "make") {
      this.props.getModel({ id: value });
      this.setState({ model: "" });
    }
    this.setState({
      [name]: value,
      price: Math.floor(Math.random() * 200 + 200),
    });
  }
  selectPlace(event) {
    const value = {
      formatted_address: event.formatted_address,
      location: event.geometry.location,
    };
    this.setState({
      parking: value,
      price: Math.floor(Math.random() * 200 + 200),
    });
  }

  validateForm() {
    const modelList = this.props.HostCarReducer.modelList;
    var brandList = this.props.DashboardReducer.brandList;
    var options = this.props.ConfigurationReducer.ConfigValue;
    var make = this.state.make;
    var model = this.state.model;
    var year = this.state.year;
    var mileage = this.state.mileage;
    var parking = this.state.parking;
    const userId = localStorage.getItem("siteUser");
    if (make === "" && brandList[0]) {
      make = brandList[0]?._id;
    }
    if (model === "" && modelList[0]) {
      model = modelList[0]?._id;
    }
    if (mileage === "" && options && options[0]) {
      mileage = options[0]?.value;
    }
    const data = {
      carmodel: {
        make: make,
        model: model,
      },
      mileage: {
        mileage: mileage,
      },
      number: {
        year: year,
      },
      userId: userId,
      status: 3,
    };
    if (parking !== "") {
      data.parking = parking;
    }
    if (userId) {
      localStorage.removeItem("formId", "");
      this.props.history.push('/car-model');
      // window.location.href = window.location.origin + "/car-model";
    } else {
      window.location.href = window.location.origin + "/sign-in";
    }
  }

  render() {
    const brandList = this.props.DashboardReducer.brandList;
    var brandOptions = [];
    if (brandList) {
      for (let i = 0; i < brandList.length; i++) {
        brandOptions.push(
          <option key={i} value={brandList[i]._id}>
            {brandList[i].brandname}
          </option>
        );
      }
    }
    const modelList = this.props.HostCarReducer.modelList;
    var modelArray = [];
    if (modelList) {
      for (let j = 0; j < modelList.length; j++) {
        modelArray.push(
          <option key={j} value={modelList[j]._id}>
            {modelList[j].name}
          </option>
        );
      }
    }
    var year = [];
    for (let i = 2021; i > 2010; i--) {
      year.push(
        <option key={i} value={i} selected={i}>
          {i}
        </option>
      );
    }
    const options = this.props.ConfigurationReducer.ConfigValue;
    var mileageOptions = [];
    if (options) {
      for (let i = 0; i < options.length; i++) {
        mileageOptions.push(
          <option key={i} value={options[i].value} selected={options[i].value}>
            {options[i].value}
          </option>
        );
      }
    }
    return (
      <section className="banner-section">
        <div className="container-fluid" style={{backgroundColor: "#2A2932"}}>
          <div className="row">
            <div className="col-lg-6">
              <div className="left-margin">
                <div className="banner-text">
                  <h2 className="banner-h2 "> <span className="tracking-in-expand" style={{color: "inherit"}}>Laissez</span>  votre voiture <br /> travailler pour vous  </h2>
                </div>
                <div className="button">
                  <p className="upto">Gagnez jusqu'à</p>
                  <p className="price">{this.state.price} FCFA</p>
                  <p className="estinated"> par mois</p>
                  <button type="text" className="get-btn blink-1" onClick={this.validateForm}>Commencer</button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 no-padding">
              <div className="banner-img">
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigurationReducer: state.ConfigurationReducer,
    DashboardReducer: state.DashboardReducer,
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ConfigList: AC_GET_CONFIGURATION,
      getModel: AC_GET_MODEL,
      hostaCar: AC_HOST_CAR,
    },
    dispatch
  );
}
const BannerSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerSection);

export default withRouter(BannerSectionComponent);
