import React from "react";
import { Redirect } from "react-router-dom";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import moment from "moment";
import swal from 'sweetalert';
import API from "../../common/api";

import {
  AC_YOUR_CAR,
  AC_ADD_UNAVAILIBILITY,
  AC_DELETE_UNAVAILIBILITY,
} from "../../actions/carsaction";
import { AC_VIEW_TRIPS, AC_VIEW_CAR_TRIPS } from "../../actions/accountaction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Calendarsection.css";
import { NestCamWiredStandTwoTone } from "@mui/icons-material";
// import SaveEventDialog from '../dashboard/SaveEventDialog';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   Button,
//   DialogActions,
// } from "@mui/material";

class CalendarSection extends React.Component {
  constructor(props) {
    super(props);
    currentDay: moment().format("Y-0M-D");
  }

  state = {
    openSave: false,
    title: "",
    message: "",
    eventToPost: {
      checkin: "",
      checkout: "",
    },
    statusAction: -1,
    inDate: false,
  };

  componentDidMount() {
    if(localStorage.getItem("carId")) {      
      this.props.ViewCar({ id: localStorage.getItem("carId") });
      this.props.ViewCarTrips({ carId: localStorage.getItem("carId") });
    }            
  }

  /* // Formattage de l'heure de la minutre de l'évènement
  getHourAndMinuteDate = (partialDate) => {
    let strPlited = partialDate.split(":");
    let hour = strPlited[0];
    let minute = strPlited[1];

    return hour + ":" + minute;
  }; */

  // Formattage de date YYYY-MM-JJ -hh:mm
  formatEventDate = (initialDate) => {
    let arrayDate = initialDate.split(" ");
    let render = arrayDate[0].split("-");
    return render[2] + "-" + render[1] + "-" + render[0];
  };

  // Afficher le modal
  setOpenSave = () => {
    this.setState({ openSave: true });
  };

  handleEventClick = (infos) => {
    const start = infos.event.startStr;
    const end = infos.event.endStr;
    this.setState({
      eventToPost: {
        id: localStorage.getItem("carId"),
        checkin: start,
        checkout: end,
      },
      openSave: true,
      title: "Annuler une indisponibilité",
      message:
        "Aimeriez-vous rendre disponible ce véhicule à cette à cette période ?",
      statusAction: 0,
    });
  };

  handleDateSelect = (e) => {
    // const today = moment().format("Y-0M-D");
    const myToday = new Date();
    let start = e.startStr;
    const end = e.endStr;
    // if(myToday > start) {
    //   console.log("Possible d'ajouter une dateelse {")
    // }
    // console.log(start + " - " + end)

    
    
    // console.log("Un intervalle de date selectionné...")
    // console.log(today, typeof(start) );

    // console.log("Moment: " + moment(today).isSame(start))
    // const startDateConverted = moment(start)
    // startDateConverted.isSameOrAfter(today, 'day')
    // console.log(myToday <= (new Date(start)))
    if (myToday.getTime() <= (new Date(start).getTime())) {
      // console.log("Un intervalle de date selectionné... dans la condition")
      this.setState({
        eventToPost: {
          id: localStorage.getItem("carId"),
          checkin: start,
          checkout: end,
        },
        openSave: true,
        title: "Ajouter une indisponibilité",
        message:
          "Acceptez-vous de rendre indisponible ce véhicule à cette période ?",
        statusAction: 1,
      });
    } else {
      this.setState({
        openCancel: true,
        statusAction: 3,
        openSave: false,
      });
    }

    // console.log(this.state.openSave)
  };

  /* // L'utilisateur survol un évenement
  handleEventMouse = (info) => {
    
  }; */

  /*  // L'évenement est un drag and drop
  handleEventDrop = (infos) => {
    this.setState({
      openSave: true,
      title: "Déplacer une indisponibilité",
      message: "Aimeriez-vous déplacer cet évèmement à cet période?",
    });
  }; */

  // Confirmation de l'action
  actionOnUnavailabilities = () => {
    // Ajouter un indisponibilité
    if (this.state.statusAction === 0) {
      // Suppression
      this.props.DeleteUnavailibility(this.state.eventToPost);
    } else if (this.state.statusAction === 1) {
      // Ajouter
      this.props.AddUnavailibility(this.state.eventToPost);
    }
    this.setState({ openSave: false });
  };

  render() {

    if(this.state.openSave === true){
      swal({
        title: `${this.state.title}`,
        text: `${this.state.message}`,
        icon: "warning",
        buttons: ["Non", "Oui"],
      })
      .then((willDelete) => {
        if (willDelete) {
          this.actionOnUnavailabilities()
        }
      });
    }

    if(!localStorage.getItem("carId")) {
      return <Redirect push={true} to="/hired-cars" />
    }

    // let carUnavailabilities = this.props.CarsReducers.carList.unavailabilities.map((car)=>({...car,title:'Non Disponible', start:car.checkin,end:car.checkout, color: '#e6eaee', textColor: '#1E1E1E', textAlign: 'center'}))
    let carUnavailabilities =
      this.props?.CarsReducers?.carList?.unavailabilities?.map((car) => ({
        title: "Non Disponible",
        start: car.checkin,
        end: car.checkout,
        color: "rgb(181 166 166 / 0.88)",
        textColor: "#000",
        textAlign: "center",
      }));

    let tripsUnavailabilities = [];
    let unavailabilities = [];
    //const color = ['green', 'blue', 'lemon', 'yellow'];
    if (this.props.AccountReducer.tripsList) {
      //let trips = this.props.AccountReducer.tripsList;
      const id = localStorage.getItem("carId");
      tripsUnavailabilities = this.props.AccountReducer.tripsList.map((trip) =>
        trip.tripStatus === 2 && trip.carId._id === id
          ? {
              title:
                "Reservé par " +
                trip.ownerId.lastname +
                " " +
                trip.ownerId.firstname,
              start: this.formatEventDate(trip.checkin),
              end: moment(this.formatEventDate(trip.checkout))
                .add(1, "d")
                .format("YYYY-MM-DD"),
              color: "#008000ab",
              textAlign: "center",
              url: API.IMAGEURL + trip.ownerId.image,
              display: "background",
            }
          : {}
      );
      unavailabilities = [...carUnavailabilities, ...tripsUnavailabilities];
    } else {
      unavailabilities = [...carUnavailabilities];
    }

    // console.log(this.props.AccountReducer.tripsList);

    /* // let tripsList = this.props.TripsReducers.
    let events = [
      {
        title: "Réservation",
        start: "2022-04-20",
        end: "2022-04-24",
        allDay: true,
        color: "green",
      },
      {
        title: "Réservation",
        start: "2022-04-15",
        end: "2022-04-19",
        allDay: true,
        color: "red",
        display: "background",
      },
    ];
 */
    return (
      <div className="calendar list-car-card ">
        <div className="mt-4"></div>
        <div className="fullContainer">
          <h5>Calendrier de disponibilité</h5>
          <div className="customCalendar">
            <FullCalendar
              locale={frLocale}
              droppable={true}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "dayGridMonth",
              }}
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              selectLongPressDelay={1000}
              dayMaxEvents={true}
              allDaySlot={false}
              nowIndicator={true}
              weekends={this.props.weekendsVisible}
              navLinks={false}
              select={this.handleDateSelect}
              eventContent={renderEventContent}
              events={unavailabilities}
              eventClick={this.handleEventClick}
              /* eventChange={this.handleEventChange} */ // called for drag-n-drop/resize
              eventRemove={this.handleEventRemove}
              /* eventDrop={this.handleEventDrop} */
            />
          </div>
        </div>
        {/* <Dialog
          open={this.state.openSave}
          onClose={() => this.setState({ openSave: false })}
        >
          <DialogTitle>{this.state.title} </DialogTitle>
          <DialogConten t>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.message}
            </DialogContentText>
          </DialogConten>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => this.actionOnUnavailabilities()}
            >
              Oui
            </Button>

            <Button onClick={() => this.setState({ openSave: false })}>
              Non
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

function renderEventContent(eventInfo) {
  return (
    <>
      <div>
        <span className="fcEventTitle text-white ">
          {eventInfo.event.title}
        </span>
        <img className="rounded-circle" src={eventInfo.event.url} width="50" />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    CarsReducers: state.CarsReducers,
    AccountReducer: state.AccountReducer,
    weekendsVisible: state.weekendsVisible,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewCar: AC_YOUR_CAR,
      ViewTrips: AC_VIEW_TRIPS,
      ViewCarTrips: AC_VIEW_CAR_TRIPS,
      AddUnavailibility: AC_ADD_UNAVAILIBILITY,
      DeleteUnavailibility: AC_DELETE_UNAVAILIBILITY,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSection);
