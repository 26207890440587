import React from 'react';

class SearchSection extends React.Component {
  render() {
    return(
      <section className="search-section">
        <div className="container">
          <form>
            <a href='#'><h1 className="search-h1">Comment pouvons-nous vous aider ?</h1></a>
            <div >
              
            </div>
          </form>
        </div>
      </section>
    )
  }
}

export default SearchSection
