import React, { useState } from "react";
// import { , , CardElement, CardCvcElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import CardSection from "./CardSection";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Row from "./prebuilt/Row";
// import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";
import styled from "@emotion/styled";

import { AC_ADD_TRIP, AC_STRIPE_PAY } from "../../actions/searchcarsaction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Error } from "../../common/swal";
// import {Redirect} from "react-router-dom"
const CheckoutForm = (props) => {
  const history = useHistory();

  // const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [isCompletedCardNumber, setIsCompletedCardNumber] = useState(false);
  const [isCompletedCardExpiry, setIsCompletedCardExpiry] = useState(false);
  const [isCompletedCardCvcElement, setIsCompletedCardCvcElement] =
    useState(false);

  const { amount, accountInfo, tripsID } = props;
  const stripe = useStripe();
  const elements = useElements();

  const {
    stripePayLoading,
    stripePayData,
    // modelcarList: carData,
  } = props.SearchCarsReducer;

  const CardElementContainer = styled.div`
    display: flex;
    align-items: center;
    & .StripeElement {
      width: 100%;
      padding: 15px;
    }
  `;

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        iconColor: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        width: "100%",
        margin: "0 auto",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
  };

  const handleCardDetailChange = async (ev) => {
    if (ev.elementType === "cardNumber" && ev.complete) {
      setIsCompletedCardNumber(true);
    } else if (ev.elementType === "cardNumber" && !ev.complete) {
      setIsCompletedCardNumber(false);
    }
    if (ev.elementType === "cardExpiry" && ev.complete) {
      setIsCompletedCardExpiry(true);
    } else if (ev.elementType === "cardExpiry" && !ev.complete) {
      setIsCompletedCardExpiry(false);
    }

    if (ev.elementType === "cardCvc" && ev.complete) {
      setIsCompletedCardCvcElement(true);
    } else if (ev.elementType === "cardCvc" && !ev.complete) {
      setIsCompletedCardCvcElement(false);
    }
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // const [isProcessing,
    // which would refresh the page.

    event.preventDefault();
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      //create payment method (Ok) à transmettre au serveur
      const paymentMethodReq = await stripe.createPaymentMethod({        
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: accountInfo.firstname + " " + accountInfo.lastname,
          email: accountInfo.email,
          phone: accountInfo.phone,          
        },
      });

      if (paymentMethodReq.error) {
        toast.error(paymentMethodReq.error, { position: "top-center" });
      } else {       
        props
          .stripePay({
            payment_method_id: paymentMethodReq.paymentMethod.id,
            payment_intent_id: null,
            amount: amount,
            email: accountInfo.email,
            tripsID,
          })
          .then( async (data) => {
            if(data.requires_action) {
              const { paymentIntent, error } = await stripe.confirmCardPayment(
                data.payment_intent_client_secret
              );
              if (error) return Error("Une erreur s'est produite! Merci de ressayer plutard.");
              if (paymentIntent.status === "succeeded") {
                // Le paiement est réussi
                history.push({
                  pathname: "/payment-success",
                });
              }                              
            }
            else {
              // Le paiement est réussi
              history.push({
                pathname: "/payment-success",
              });
            }
            
          })
      }
      //id success send data to express api
    } catch (e) {
       Error("Tentative de paiement échouée!");
      console.log({ e });
      // Display error on client
      //return response.send({ error: e.message });
    }
  };

  // if (stripePayData.hasOwnProperty("payment_intent_client_secret")) {
  //   stripe
  //     .handleCardAction(stripePayData.payment_intent_client_secret)
  //     .then((data) => {
  //       props.addTrip(formData).then((res) => {
  //         if (res.data) {
  //           props.stripePay({
  //             payment_method_id: null,
  //             payment_intent_id: data.paymentIntent.id,
  //             amount: amount,
  //             email: accountInfo.email,
  //             tripsID:res.data._id
  //           }).then(data=>{
  //             if(data.success){
  //               history.push('/payment-success')
  //             }
  //           });

  //         }
  //       });
  //     });
  // }

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <Row>
        <CardNumberElement
          options={cardElementOptions}
          onChange={handleCardDetailChange}
        />
      </Row>
      <Row>
        <CardElementContainer>
          <CardExpiryElement /> <CardCvcElement />
        </CardElementContainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      {/* <Row>
          <SubmitButton disabled={isProcessing || checkoutError || !(isCompletedCardNumber && isCompletedCardCvcElement && isCompletedCardExpiry)}>
          {isProcessing ? "Payement en cours..." : "Payer"}
        </SubmitButton>  
      </Row> */}

      {!stripePayLoading && stripePayData.hasOwnProperty("error") && (
        <p className="text text-danger">{stripePayData.error}</p>
      )}

      <div className="d-flex justify-content-around">
        <button
          type="submit"
          disabled={stripePayLoading}
          className="form-control btn btn-primary text-white"
          style={{ width: "100%", fontFamily: "Poppins" }}
        >
          {stripePayLoading ? "Veuillez patienter ..." : `Payer ${amount} FCFA`}
        </button>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    SearchCarsReducer: state.SearchCarsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stripePay: AC_STRIPE_PAY,
      addTrip: AC_ADD_TRIP,
    },
    dispatch
  );
}

const CheckoutFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutForm);

export default CheckoutFormComponent;
