import React from "react";
import { Carousel } from 'antd';
// import "antd/dist/antd.css";
import UserComment from "./userComment";
const contentStyle = {
  height: '360px',
  color: '#fff',
  // lineHeight: '160px',
  textAlign: 'center',
  // background: '#364d79',
};

const comments = [
  {
    username: "Camara",
    description: "La plateforme Monbolide a changé ma vie. Depuis que je loue ma voiture sur ce site, je gagne plus de 200.000FCFA par mois. Je vous le recommande vivement",
    rating: 5,
    profil: "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/camara_mib51r.png",
    alt: "camara_mib51r"
  },
  {
    username: "Kouakou",
    description: "Avec Monbolide je n'ai plus besoin de chercher des clients. Je reçois mes demandes directement sur la plateforme surtout avec le mode reservation instantané qui me permet de doubler mon chiffre d'affaire. J'ai gagné plus de 250.000FCFA ce mois",
    rating: 5,
    profil: "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/kouakou_hhhiuf.png",
    alt: "kouakou_hhhiuf"
  },
  {
    username: "François",
    description: "Je suis sous Monbolide depuis 2 mois maintenant et mes locations Monbolide permettent de financer mon contrat de location de voiture et mon parking, tout en laissant un bénéfice d'environ 250 000 FCFA",
    rating: 5,
    profil: "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/francois_zyzmij.png",
    alt: "francois_zyzmij"
  }
  
]


class GetAroundSection extends React.Component {

  render() {
    return (
      <section className="monbolide-section">
        <div className="container">
          <div className="monbolide-text text-center">
            <h2 className="text-white">Ils partagent leur voiture sur Monbolide.</h2>
          </div>
          <Carousel autoplay={true} autoplaySpeed={7000} >
            {comments.map((comment) => <UserComment key={comment.username} profil={comment.profil} alt={comment.alt} username={comment.username} description={comment.description} rating={comment.rating}/>)}
          
          </Carousel>
        </div>


        {/* <div className="container">
          <div className="monbolide-text text-center">
            <h2 >Ils partagent leur voiture sur Monbolide.</h2>
          </div>
          <div className="flex-container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="flex-box">
                  <div className="card-img">
                    <img
                      src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/camara_mib51r.png"
                      alt="camara_mib51r"
                    />
                  </div>
                  <div className="card-location">
                    <i className="fas fa-map-marker-alt map-icon"></i>

                    <span className="place">Abidjan</span>
                  </div>
                  <div className="card-body">
                    <h6 className="card-title review-name">Camara</h6>
                    <span className="color">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </span>
                    <div className="card-text"><cite>La plateforme Monbolide a changé ma vie. Depuis que je loue ma voiture sur ce site, je gagne plus de 200.000XOF par mois. Je vous le recommande vivement</cite><span id="dots">...</span><span id="more"></span> <br /> <span id="link"><i> En savoir plus</i></span></div>

                    <div className="card-text-2">
                      <span className="card-icon">
                        <i className="fa fa-car card-icon-size"></i>
                      </span>
                      <span className="card-text-color"> 250 000 XOF</span>
                      <p className="text-center">Le mois dernier via l'assistance complète Monbolide</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-12">
                <div className="flex-box">
                  <div className="card-img">
                    <img
                      src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/kouakou_hhhiuf.png"
                      alt="kouakou_hhhiuf"
                    />
                  </div>
                  <div className="card-location">
                    <i className="fas fa-map-marker-alt map-icon"></i>
                    <span className="place">Abidjan</span>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title review-name">Kouakou</h5>
                    <span className="color">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </span>
                    <div className="card-text"><cite>Avec Monbolide je n'est plus besoin de chercher des clients. Je reçois mes demandes directement sur la plateforme surtout avec le mode reservation instantané qui me permet de doubler mon chiffre d'affaire. J'ai gagné plus de 250.000XOF ce mois.</cite><span id="dots">...</span><span id="more"></span> <br /> <span id="link">En savoir plus</span></div>

                    <div className="card-text-2">
                      <span className="card-icon">
                        <i className="fa fa-car card-icon-size"></i>
                      </span>
                      <span className="card-text-color"> 250 000 FCFA</span>
                      <p className="text-center">Le mois dernier via l'assistance complète Monbolide</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="flex-box">
                  <div className="card-img">
                    <img
                      src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/francois_zyzmij.png"
                      alt="francois_zyzmij"
                    />
                  </div>
                  <div className="card-location">
                    <i className="fas fa-map-marker-alt map-icon"></i>

                    <span className="place">Abidjan</span>
                  </div>
                  <div className="card-body">
                    <h6 className="card-title review-name">François</h6>
                    <span className="color">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </span>
                    <div className="card-text"><cite>Je suis sous Monbolide depuis 2 mois maintenant et mes locations Monbolide permettent de financer mon contrat de location de voiture et mon parking, tout en laissant un bénéfice d'environ 250 000 XOF</cite><br /> <span id="dots">...</span><span id="more"></span><span id="link">En savoir plus</span></div>

                    <div className="card-text-2">
                      <span className="card-icon">
                        <i className="fa fa-car card-icon-size"></i>
                      </span>
                      <span className="card-text-color"> 100 000 XOF</span>
                      <p className="text-center">La semaine dernière</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="card-carousel">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-flex">
                      <div className="card-img">
                        <img
                          src="assets/images/user/user-1.png"
                          alt="user-1.png"
                        />
                      </div>
                      <div className="card-location">
                        <i className="fas fa-map-marker-alt map-icon"></i>

                        <span className="place">Abidjan</span>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title review-name">Mickael</h5>
                      <span className="color">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </span>
                      <div className="card-text">Je suis sous Monbolide depuis 2 mois maintenant et mes locations Monbolide permettent de financer mon contrat de location de voiture et mon parking, tout en laissant un bénéfice d'environ 150 000 XOF<span id="dots">...</span><span id="more"></span><span id="link">En savoir plus</span></div>
                      <hr className=""></hr>
                      <div className="card-text-2">
                        <span className="card-icon">
                          <i className="fa fa-car card-icon-size"></i>
                        </span>
                        <span className="card-text-color">150 000 XOF</span>
                        <p className="text-center">Il y'a 2 jours</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-flex">
                      <div className="card-img">
                        <img
                          src="assets/images/user/user-1.png"
                          alt="user-1.png"
                        />
                      </div>
                      <div className="card-location">
                        <i className="fas fa-map-marker-alt map-icon"></i>

                        <span className="place">London</span>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title review-name">
                        Simon{" "}
                        <span className="badge badge-secondary pro">Pro</span>
                      </h5>
                      <span className="color">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </span>
                      <div className="card-text">I've been on Monbolide for 6 months now and my Monbolide Connect rentals help finance my car leasing contract and my parking, while still leaving a profit of about £250. I only use my Mini occasionally and use a scooter the rest of the time.</div>
                      <hr className=""></hr>
                      <div className="card-text-2">
                        <span className="card-icon">
                          <i className="fa fa-car card-icon-size"></i>
                        </span>
                        <span className="card-text-color"> £650</span>
                        <p className="text-center">last month with Monbolide Connect</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-flex">
                      <div className="card-img">
                        <img
                          src="assets/images/user/user-.png"
                          alt="user-.png"
                        />
                      </div>
                      <div className="card-location">
                        <i className="fas fa-map-marker-alt map-icon"></i>

                        <span className="place">London</span>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title review-name">Neil</h5>
                      <span className="color">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </span>
                      <div className="card-text">I've been on Monbolide for 6 months now and my Monbolide Connect rentals help finance my car leasing contract and my parking, while still leaving a profit of about £250.</div>
                      <hr className="line-3"></hr>
                      <div className="card-text-2">
                        <span className="card-icon">
                          <i className="fa fa-car card-icon-size"></i>
                          <span className="card-text-color"> £650</span>
                        </span>
                        <p className="text-center">last month with Monbolide Connect</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div> */}
      </section >
    );
  }
}

export default GetAroundSection;
