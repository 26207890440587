import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AboutComponent from "../dashboard/about";
class Footer extends React.Component {
	constructor(props) {
		super(props);
		// this.state = {
		// 	language: '',
		// }
	}
	// componentDidMount() {
	// 	if (localStorage.getItem("lang")) {
	// 		this.setState({ language: localStorage.getItem("lang").toUpperCase() })
	// 	}
	// }
	// onChangeValue(event) {
	// 	localStorage.setItem("lang", event.target.value.toLowerCase());
	// 	this.setState({ language: event.target.value })
	// 	window.location = '/';
	// }
	render() {
		// const languageListArray = [];
		// if (this.props.DashboardReducer && this.props.DashboardReducer !== undefined) {
		// 	const languageList = this.props.DashboardReducer.languageList;
		// 	if (languageList !== undefined && languageList.length > 0) {
		// 		for (let i = 0; i < languageList.length; i++) {
		// 			languageListArray.push(
		// 				<option key={i} value={this.state.language === languageList[i].languagecode} value={languageList[i].languagecode}>{languageList[i].languagecode}</option>
		// 			)
		// 		}
		// 	}
		// }
		return (
			<footer className='main-footer' style={{ background: "rgb(2 74 125)", position: "absolute", width:"100%", bottom: 0 }}>
				<div className="container">
					{/* <AboutComponent /> */}
					<div className="bottom">
						{/* <div className="mr-3">
							<div className="form">
						 	<select className="form-control" data-show-content="true" id="exampleFormControlSelect1" onChange ={(e) => this.onChangeValue(e)}>
						 	     {languageListArray}
						 	 </select>
						</div> 
						</div> */}
            <div className="flx-view social-ord ml-3">
              <a href="https://instagram.com/monbolide.civ?utm_medium=copy_link">
                <i className="fa fa-instagram footer-social-icon"></i>
              </a>
              <a href="https://www.facebook.com/monbolideci/">
                <i className="fa fa-facebook-square footer-social-icon"></i>
              </a>
              <a href="https://mobile.twitter.com/MONBOLIDE_">
                <i className="fa fa-twitter footer-social-icon"></i>
              </a>
              
            </div>
            <div className="flx-view rights-flex">
              <div className="rights">
                © Monbolide 2022 - Tous droits réservés
              </div>
              <ul className="terms">
                 <li>
                  <a href="assets/security/cgu.pdf" className="text-white" download>
                    CGU
                  </a>{" "}
                </li> 
                {/* <li>Cookies</li> */}
                {/* <li>Charte de transparence</li> */}
                {/* <li>
                  <a href="#" className="text-white" >Offres d'emploi</a>{" "}
                </li> */}
                <li>
                  <a href="/policy" className="text-white">Politique de confidentialité</a>{" "}
                </li> 
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const FooterComponent = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default FooterComponent;
