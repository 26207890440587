import React, { createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import ReactStars from "react-rating-stars-component";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import swal from "sweetalert";
import PaymentMethod from "./paymentMethod";
import { io } from "socket.io-client";
import { IoIosChatbubbles } from "react-icons/io";
import {
  AC_VIEW_TRIPS,
  AC_ADD_REVIEW,
  AC_UPDATE_CAR_STATUS,
} from "../../actions/accountaction";
import API from "../../common/api";
import "./Rental.css";
import moment from "moment";
import ChatBoxContainer from "../ChatBox/ChatBoxContainer";
// import api from "../../common/api";


const override = css`
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class Rental extends React.Component {
  constructor(props) {
    super(props);
    this.socket = createRef()
    this.state = {
      review: "",
      rating: "",
      loading: true,
      currentTrip: null,
      closed: false,
    };
    // const socket = useRef();
  }
  componentDidMount() {
    this.props.ViewTrips({
      request: 0,
      id: localStorage.getItem("siteUser"),
      status: 5,
    }).then(() => {
      this.setState({
        loading: false,
      });
      const tripID = this.getTripFromQuery();
      var tripsList = this.props.AccountReducer.tripsList;
    if(tripID && tripsList) {
      this.getTripByUrl(tripsList, tripID);
    } 

    });

    
    this.socket.current = io(API.LOCAL_WEBSOCKET_URL)
    // this.socket.current = io(API.LOCAL_WEBSOCKET_URL)
    this.socket.current.emit("add-user", localStorage.getItem("siteUser"));
  }

  getCurrentTrip = (trip) => {    
    this.setState({ currentTrip: trip, closed:false });

  };

  getTripFromQuery = () => {
    const params = new URLSearchParams(this.props.location.search);
    const tripId = params.get('tripId');
    // console.log(tripId)
    return tripId;
  };
  getNbHourBeforeTrips = (checkin) => {
    // Recupération de la date de début
    let currentDate = new Date();
    currentDate = moment(currentDate, "DD-MM-YYYY HH:mm");
    let checkInFormatted = moment(checkin, "DD-MM-YYYY HH:mm");
    // endDate.diff(startDate, 'day')
    let hours = checkInFormatted.diff(currentDate, "hours");
    // if  <= 0 hours <= 2 afficher le contrat
    // if hours >= 2 contrat de location masqué
    // if hours <0 date de location du véhicule passé
    //  console.log('différence heure: ' + hours)
    return hours;
  };

  goToVerification = () => {
    this.props.history.push({
      pathname: "/verify-profile",
      // state: { id: e.target.id }
    })
  }
  statusChange(e) {
    this.setState({
      loading: true
    });
    this.props.ViewTrips({
      id: localStorage.getItem("siteUser"),
      request: 0,
      status: e.target.value,
    }).then(() => {
      this.setState({
        loading: false
      });
    });
  }
  review(e) {
    this.setState({ rating: e });
  }
  onChangeValue(e) {
    this.setState({ review: e.target.value });
  }
  setCarStatus(e) {
    // var name = e.target.name;
    var id = e.target.value;
    var userId = localStorage.getItem("siteUser");
    swal({
      title: "Vous êtes sûr ?",
      text: "Une fois annulé, vous ne pourrez plus l'ouvrir !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          loading: true
        });
        this.props.UpdateCarStatus({
          id: id,
          tripStatus: 4,
          type: "rental",
          userId: userId,
        }).then(() => {
          this.props.ViewTrips({
            request: 0,
            id: localStorage.getItem("siteUser"),
            status: 5,
          }).then(() => {
            this.setState({
              loading: false
            });
          });
        });
      }
    });
  }

  writeReview(e) {
    var rating = this.state.rating;
    var review = this.state.review;
    const id = e.target.name;
    var customerId = localStorage.getItem("siteUser");
    if (rating !== "" || review !== "") {
      const formData = {
        id: id,
        customerId: customerId,
      };
      if (review !== "") {
        formData.review = review;
      }
      if (rating !== "") {
        formData.rating = rating;
      }
      this.props.AddReview(formData);
      this.setState({ review: "", rating: "" });
    }
  }
  closeChat = (closed) => {
    this.setState({ closed: closed });
  };


  getTripByUrl = (trips, tripId) => {
    for(let i = 0; i < trips.length; i++) {
      if(trips[i]._id === tripId) {
        this.getCurrentTrip(trips[i]);    
        break;
      }
    }
    
  }

  render() {
    var tripsList = this.props.AccountReducer.tripsList;
    // this.getCurrentTrip(tripsList[i]); 
    
    let tripsArray = [];
    if (tripsList !== undefined) {
      for (let i = 0; i < tripsList.length; i++) {
        // let tripsId = tripsList[i]._id
        let image =
          "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/car/user-profile_heulnq.png";

        // if(tripsList[i]?.carId?.carimage){
        //   image = API.IMAGEURL+tripsList[i]?.carId?.carimage?.mainimage;
        // }

        if (tripsList[i]?.carId) {
          if (tripsList[i]?.carId?.carimage) {
            image = API.IMAGEURL + tripsList[i]?.carId?.carimage?.mainimage;
          }
        }

        if (
          tripsList[i]?.carId &&
          tripsList[i]?.checkin &&
          tripsList[i]?.checkout
        ) {
          tripsArray.push(
            <div className="main-sec mb-5" key={i} >
              <div
                className="chatButton"
                onClick={() => this.getCurrentTrip(tripsList[i])}
              >
                <IoIosChatbubbles size={25} color={"#fff"} />
              </div>
              {tripsList[i].tripStatus === 0 ? <div className="alert alert-success flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-info-circle mr-2"></i> Le propriétaire de véhicule a décliné l'offre: {tripsList[i].patternRefuse && <div><q>{tripsList[i].patternRefuse}</q></div>} </div> : ""}
              {tripsList[i].tripStatus === 2 ? <div className="alert alert-success flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-info-circle mr-2"></i> Félicitation ! Vous pouvez maintenant contacter le propriétaire pour remise du véhicule</div> : ""}
              {tripsList[i].tripStatus === 4 ? <div className="alert alert-info flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-ban mr-3"></i> Vous avez annulé votre demande</div> : ""}
              {tripsList[i].tripStatus === 1 && this.getNbHourBeforeTrips(tripsList[i].checkin) >= 0 && <div className="alert alert-info flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-spinner fa-spin mr-2"></i> Requête en attente d'approbation par le propriétaire</div>}
              {tripsList[i].tripStatus === 1 && this.getNbHourBeforeTrips(tripsList[i].checkin) < 0 && <div className="alert alert-info flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-hourglass-end mr-2"></i> Délai d'attente de location expiré. Vous pouvez effectuer une nouvelle demande</div>}
              {tripsList[i].tripStatus === 3 && this.getNbHourBeforeTrips(tripsList[i].checkin) >= 0 ? <div className="alert alert-info flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-hourglass-end mr-2"></i> Le propriétaire du véhicule a accepté de faire affaire avec vous ! Merci de procéder au paiement.</div> : ""}
              {tripsList[i].tripStatus === 3 && this.getNbHourBeforeTrips(tripsList[i].checkin) < 0 ? <div className="alert alert-warning flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-ban mr-2"></i> Délai d'attente de paiement expiré. Nous ne pouvons plus donner suite à cette demande</div> : ""}
              {tripsList[i].tripStatus === 7 ? <div className="alert alert-success flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-check-double fa-bounce mr-2" style={{ "--fa-bounce-start-scale-x": 1, "--fa-bounce-start-scale-y": 1, "--fa-bounce-jump-scale-x": 1, "--fa-bounce-jump-scale-y": 1, "--fa-bounce-land-scale-x": 1, "--fa-bounce-land-scale-y": 1 }}></i> Location terminée avec succès</div> : ""}

              {/* {tripsList[i].tripStatus === 3 ? <div className="alert alert-info flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-hourglass-end mr-3"></i> &nbsp;&nbsp; Le propriétaire du véhicule a accepté de faire affaire avec vous ! Merci de procéder au paiement.</div> : ""} */}

              <div className="main-profile-card">
                <div className="profile-card">
                  <div className="profile-card-img">
                    <a href={image}>
                      <img src={image} alt="Profile" />
                    </a>
                  </div>
                  <div className="text-btn">
                    <p className="fullName" style={{textTransform: 'uppercase'}}>{tripsList[i]?.ownerId?.firstname + " " + tripsList[i]?.ownerId?.lastname}</p>
                    <p className="fullName" style={{textTransform: 'uppercase'}}>{tripsList[i].price} CFA</p>
                    <div><span className="brandName">{tripsList[i]?.carId?.carmodel?.make?.brandname}</span></div>
                    <div><span className="brandName">{tripsList[i]?.destination}</span></div>
                    {tripsList[i]?.ownerId?.email && (tripsList[i]?.tripStatus === 2 || tripsList[i]?.tripStatus === 7 || tripsList[i]?.tripStatus === 22) ? <div className="email">{tripsList[i]?.ownerId?.email}</div> : ""}
                    {/* {tripsList[i]?.ownerId?.mobilenumber ? <div className="email">{tripsList[i]?.ownerId?.mobilenumber}</div> : ""} */}
                    {tripsList[i].ownerId?.mobilenumber && tripsList[i]?.customerId?.verification === 1 && (tripsList[i]?.tripStatus === 2 || tripsList[i]?.tripStatus === 7 || tripsList[i]?.tripStatus === 22) ? <div className="email">{tripsList[i]?.ownerId?.mobilenumber}</div> : ""}
                    {tripsList[i]?.ownerId?.addressline1 ? <div className="email">{tripsList[i]?.ownerId?.addressline1}</div> : ""}
                    <div className="email"> {tripsList[i]?.withDriver ? "Avec chauffeur" : "Sans chauffeur"}</div>
                    {tripsList[i]?.checkin ? <div className="email"> {tripsList[i]?.checkin} <i className="fas separatorIconDate fa-arrow-right fa-xs"></i>{tripsList[i]?.checkout}</div> : ""}

                    {/* {(tripsList[i]?.carId?.contactnumber)? <p>Contact: {tripsList[i]?.carId?.contactnumber?.mobilenumber}</p>:""} */}
                  </div>
                </div>
                {/* {tripsList[i].tripStatus === 2 && tripsList[i]._id ? <div className="text-btn" id="tripStatus" value={tripsList[i]._id}>
                  <p>Notation</p><ReactStars
                    count={5} name={tripsList[i]._id}
                    onChange={(e) => this.review(e, tripsId)}
                    size={24}
                    value={tripsList[i].rating}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700" />
                  {tripsList[i].review ? "" : <><label for=""  className="acc-label mt-4">Ajouter un avis</label>
                    <textarea  className="form-control" onChange={(e) => this.onChangeValue(e)} /></>}
                  <button type="button" style={{ padding: "3px 5px" }} name={tripsList[i]._id} onClick={(e) => this.writeReview(e)}  className="account-btn">Terminer</button>
                </div> : ""} */}
                {/* <div className="buttons">
                  {tripsList[i].tripStatus === 1 ? (
                    <>
                      <button
                        className="decline-btn"
                        value={tripsList[i]._id}
                        onClick={(e) => this.setCarStatus(e)}
                      >
                        <i
                          className="fa fa-times-circle-o close-icon-size-decline"
                          value={tripsList[i]._id}
                        ></i>{" "}
                        Annuler
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div> */}
              </div>

              {tripsList[i].tripStatus === 3 &&
                tripsList[i]._id &&
                this.getNbHourBeforeTrips(tripsList[i]?.checkin) >= 0 ? (
                <PaymentMethod trips={tripsList[i]} />
              ) : null}
            </div>
          );
        }
      }
    }
    return (
      <div className="container" style={{ marginTop: "-70px", overflow: 'hidden' }}>
        {/* <ChatBoxContainer /> */}
        
        {this.state.loading && (
          <div className="loading">
            <BounceLoader
              color="#004aad"
              loading={this.state.loading}
              css={override}
              size={50}
            />
          </div>
        )}
        <div className="col-lg-8" style={{ overflow: 'hidden'}}>
          <div className="your-listing-header">
            <div className="right-side">
              <form className="dropdown-status">
                <button
                  type="button"
                  className="status-btn dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Statut
                </button>
                <div className="dropdown-menu">
                  <div className="signle-aligner">
                    <input
                      type="radio"
                      id="all"
                      className="list-name"
                      name="options"
                      value="5"
                      onChange={(e) => this.statusChange(e)}
                    />
                    <label className="list-label" htmlFor="all">
                      Tous
                    </label>
                  </div>
                  <div className="signle-aligner">
                    <input
                      type="radio"
                      id="waiting"
                      className="list-name"
                      name="options"
                      value="1"
                      onChange={(e) => this.statusChange(e)}
                    />
                    <label className="list-label" htmlFor="waiting">
                      En attente
                    </label>
                  </div>
                  <div className="signle-aligner">
                    <input
                      type="radio"
                      id="completed"
                      className="list-name"
                      name="options"
                      value="2"
                      onChange={(e) => this.statusChange(e)}
                    />
                    <label className="list-label" htmlFor="completed">
                      Complété{" "}
                    </label>
                  </div>
                  <div className="signle-aligner">
                    <input
                      type="radio"
                      id="accepted"
                      className="list-name"
                      name="options"
                      value="3"
                      onChange={(e) => this.statusChange(e)}
                    />
                    <label className="list-label" htmlFor="accepted">
                      Accepté
                    </label>
                  </div>
                  <div className="signle-aligner">
                    <input
                      type="radio"
                      id="refused"
                      className="list-name"
                      name="options"
                      value="0"
                      onChange={(e) => this.statusChange(e)}
                    />
                    <label className="list-label" htmlFor="refused">
                      Refusé
                    </label>
                  </div>
                  <div className="signle-aligner">
                    <input
                      type="radio"
                      id="canceled"
                      className="list-name"
                      name="options"
                      value="4"
                      onChange={(e) => this.statusChange(e)}
                    />
                    <label className="list-label" htmlFor="canceled">
                      Annulé
                    </label>
                  </div>
                </div>
              </form>
            </div>          

          {(tripsList[0]?.customerId?.verification !== 1 && tripsList[0]?.tripStatus === 2) && <div className="container"><div className="alert alert-light text-center text-danger mb-2" style={{ fontSize: "12px" }}><i className="fas fa-info-circle fa-spin"></i> Merci de faire vérifier ce compte pour avoir accès au contact des propriétaires de véhicule <button className="btn btn-outline-danger btn-sm" onClick={() => this.goToVerification()}>D'accord</button></div></div>}
          {tripsArray.length === 0 ? 
          <div className="container">
            <div className="col-lg-12">
              <div className="main-sec">
                <div className="car-sec">
                  <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/driver_ssjflq.png" style={{ width: "60%" }} className="car-img" alt="" />
                  <h2>Aucune location à venir</h2>
                  <p>	Vous n'avez pas prévu de location.</p>
                  {/*<a href="#" className="rent-btn">Find a Car</a>*/}
                </div>
              </div>
            </div>
          </div> : ""}
          <div className="container">
            {tripsArray}
          </div>
        </div>
          
        
        </div>
        {(this.state.currentTrip && this.state.closed === false) && <ChatBoxContainer currentChat={this.state.currentTrip} socket={this.socket} root={"rental"} closeChat={this.closeChat} />}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewTrips: AC_VIEW_TRIPS,
      UpdateCarStatus: AC_UPDATE_CAR_STATUS,
      AddReview: AC_ADD_REVIEW,
    },
    dispatch
  );
}

const RentalComponent = connect(mapStateToProps, mapDispatchToProps)(Rental);

export default RentalComponent;
