import React from 'react';


class AboutSection extends React.Component {
  render() {
    return(
        <section className="about-section">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 offset-sm-0">
						<div className="resize-container">
							<h2>Monbolide, Késaco ?</h2>
							<p className="center">Monbolide est la première plateforme complète de location de véhicules en Côte d'Ivoire. 
							L'application Monbolide permet aux utilisateurs de trouver une voiture à côté de chez eux, de l'ouvrir avec leur smartphone et de partir. 
							Les véhicules proviennent de propriétaires privés et de gestionnaires de parcs automobiles professionnels. 
							Monbolide apporte de l'air frais aux citadins, en encourageant la réduction du nombre de véhicules sur la route et une plus grande liberté de mouvement.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
  }
}

export default AboutSection