import React from 'react';

class MillionSection extends React.Component {
  render() {
    return(
      <section className="million-section">
    		<div className="container">
    			<div className="row">
    				<div className="col-lg-3 col-md-3 col-12">
    					<div className="million-text">
    						<p className="big-text">Présent dans</p>
    						<p className="small-text">Le Grand Abidjan</p>
    					</div>
    				</div>
    				<div className="col-lg-3 col-md-3 col-12">
    					<div className="million-text">
    						<p className="big-text">Plus de 150 </p>
    						<p className="small-text">Entreprises de locations partenaires</p>
    					</div>
    				</div>
    				<div className="col-lg-3 col-md-3 col-12">
    					<div className="million-text">
    						<p className="big-text">Ouvert 7j/7</p>
    						<p className="small-text">Service client</p>
    					</div>
    				</div>
    				<div className="col-lg-3 col-md-3 col-12">
    					<div className="million-text">
    						<p className="big-text">Plus de 5</p>
    						<p className="small-text">Locations/jour</p>
    					</div>
    				</div>
    			</div>
    		</div>
    	</section>
    )
  }
}

export default MillionSection
