import React from "react";

class ContentSection extends React.Component {
  render() {
    return (
      <section className="connect-section">
        <div className="container-fluid">
          <nav style={{ maxWidth: "700px", margin: "30px auto" }}>
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active assis-div"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Avec l'assistance complète
              </a>
              <a
                className="nav-item nav-link assis-div"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Sans l'assistance complète
              </a>
            </div>
          </nav>
          <div className="layer">
          <div className="container bg-white">
            <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="row mx-0">
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-car tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Enregistrez votre véhicule
                        </div>
                        <p className="connect-homepage-text">
                          Remplissez les informations essentielles sur votre
                          voiture, puis si vous choisissez l'option Tracking nous vous appellerons pour planifier
                          l'installation de notre dispositif.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-bolt tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Recevoir des réservations
                        </div>
                        <p className="connect-homepage-text">
                          Après un contrôle d'identité, les conducteurs peuvent
                          réserver votre voiture lorsque vous la rendez
                          disponible.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-car tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Louer sans effort
                        </div>
                        <p className="connect-homepage-text">
                          {/* Les locataires peuvent se deplacer jusqu'à votre véhicule, et vous n'avez plus qu'à leur remettre les clés après l'état des lieux du véhicule. */}
                          Notre équipe se chargera de remettre les clés aux locataires et effectuera l'état des lieux du véhicule à votre place. 

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container-none">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-car tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Recevoir son paiement
                        </div>
                        <p className="connect-homepage-text">
                          Vous recevez un virement bancaire ou un paiement
                          Mobile Money entre 1 et 5 jours selon le mode de réception ouvrables après chaque location -
                          y compris une compensation pour le carburant ou le
                          kilométrage supplémentaires.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="connect-homepage-learn">
                  <a href="https://monbolide.com/car-model" className="connect-homepage-learn-btn">
                    En savoir plus
                  </a>
                </div>

                <div className="container">
                  <hr className="connect-homepage-learn-hr"></hr>
                  <div className="row mx-0" >
        
                    <div className="col-lg-6 col-md-6 col-12 px-1 py-5">
                      <div className="connect-homepage-learn-work-content py-5 px-2">
                        <p className="connect-homepage-learn-work-content-title">
                          Votre voiture est toujours sécurisée
                        </p>
                        <p className="connect-homepage-learn-work-content-detail">
                          Nos agents effectuent une visite approfondie avec
                          8 photos à l'arrivée et au départ. Le système
                          d'immobilisation Monbolide le protège de toute autre
                          personne.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 px-1">
                      <div className="connect-homepage-learn-work-content py-5 px-2">
                        <p className="connect-homepage-learn-work-content-title">
                          Pas de rencontre ? Pas de problèmes
                        </p>
                        <p className="connect-homepage-learn-work-content-detail">
                          Nous vérifions les documents officiels de chaque
                          conducteur et utilisons un système de reconnaissance
                          faciale 100 % sécurisé pour garantir la fiabilité des
                          conducteurs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="row mx-0" >
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-car tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Enregistrez votre véhicule
                        </div>
                        <p className="connect-homepage-text">
                          Remplissez les informations essentielles sur votre
                          voiture pour pouvoir commencer à louer.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-bolt tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Recevoir des réservations
                        </div>
                        <p className="connect-homepage-text">
                          Activez la réservation instantanée ou acceptez les
                          demandes. Confirmez l'heure et le lieu d'arrivée avec
                          le chauffeur.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-car tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Louez votre véhicule
                        </div>
                        <p className="connect-homepage-text">
                          Effectuez l'inspection et signez le contrat de
                          location mobile avec le conducteur.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <div className="connect-homepage-item">
                      <div className="connect-homepage-icon-container-none">
                        <div className="connect-homepage-icon">
                          <span className="cobalt-Icon">
                            <i className="fa fa-car tab-icon-size"></i>
                          </span>
                        </div>
                      </div>
                      <div className="connect-homepage-item-content">
                        <div className="connect-homepage-item-title">
                          Recevoir son paiement
                        </div>
                        <p className="connect-homepage-text">
                          Vous recevez un virement bancaire ou un paiement
                          mobile money entre 1 et 5 jours selon le mode de réception ouvrables après chaque location -
                          y compris une compensation pour le carburant ou le
                          kilométrage supplémentaires. Deux façons de partager
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <hr className="connect-homepage-learn-hr"></hr>
                  <div className="row mx-0" style={{paddingTop: "70px", paddingBottom: "70px"}}>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="images-height">
                        <img
                          src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/ph-1_vss3c0.png"
                          alt="ph-1_vss3c0"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="connect-homepage-learn-work-content">
                        <p className="connect-homepage-learn-work-content-title">
                          Rencontrez chaque conducteur
                        </p>
                        <p className="connect-homepage-learn-work-content-detail">
                          Vous vous mettez d'accord sur le lieu et rencontrez
                          les conducteurs à chaque arrivée et départ.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="connect-homepage-learn-work-content">
                        <p className="connect-homepage-learn-work-content-title">
                          Un contrat signé pour chaque location Deux façons de
                          partager
                        </p>
                        <p className="connect-homepage-learn-work-content-detail">
                          Vous effectuez la visite de la voiture avec le
                          conducteur et signez le contrat de location
                          directement dans l'application Monbolide.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="images-height">
                        <img
                          src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/ph-2_syssal.png"
                          alt="ph-2_syssal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        </div>

        
      </section>
    );
  }
}

export default ContentSection;
