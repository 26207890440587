import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';

class BillingAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
      addressline1error: false,
      addressline2error: false,
      postcodeerror: false,
      townerror: false,
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    const error = name + "error";
    this.setState({ [error]: false })
    this.props.handleInputChange(name, value, 'billaddress')
  }

  validateForm() {
    const countryList = this.props.DashboardReducer.countryList;
    const billaddress = this.props.HostCarReducer.billaddressInfo;
    const id = localStorage.getItem('formId');
    var addressline1 = "";
    var addressline2 = '';
    var postcode = '';
    var town = '';
    if (billaddress) {
      addressline1 = billaddress.addressline1;
      addressline2 = billaddress.addressline2;
      postcode = billaddress.postcode;
      town = billaddress.town;
      if (!billaddress.country && countryList[0]) {
        billaddress.country = countryList[0].countryname;
      }
    }
    if (addressline1) {
      this.setState({ addressline1error: false })
    } else {
      this.setState({ addressline1error: true })
    }
    if (addressline2) {
      this.setState({ addressline2error: false })
    } else {
      this.setState({ addressline2error: true })
    }
    if (postcode) {
      this.setState({ postcodeerror: false })
    } else {
      this.setState({ postcodeerror: true })
    }
    if (town) {
      this.setState({ townerror: false })
    } else {
      this.setState({ townerror: true })
    }
    if (addressline1 && addressline2 && postcode && town) {
      const data = {
        data: billaddress,
        status: 2
      }
      this.props.updateHostDetails(data, id, 'BillAddress');
      this.setState({ next: true })
    }
  }

  previous() {
    this.setState({ previous: true })
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect to="/ready-to-rent" />
    }
    if (previous) {
      return <Redirect to="/add-car-image" />
    }
    var addressline1 = '';
    var addressline2 = '';
    var postcode = '';
    var town = '';
    var country = '';
    const billaddressInfo = this.props.HostCarReducer.billaddressInfo;
    if (billaddressInfo) {
      addressline1 = billaddressInfo.addressline1;
      addressline2 = billaddressInfo.addressline2;
      postcode = billaddressInfo.postcode;
      town = billaddressInfo.town;
      country = billaddressInfo.country;
    }
    const countryList = this.props.DashboardReducer.countryList;
    var countryArray = [];
    for (var i = 0; i < countryList.length; i++) {
      countryArray.push(
        <option key={i} value={countryList[i].countryname} selected={country === countryList[i].countryname}>{countryList[i].countryname}</option>
      )
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "65%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <div className="container">
                      <h5>Ajouter mon véhicule</h5>
                      <div className="title-card">Quelle est votre adresse de facturation ?</div>
                      <label htmlFor="" className="acc-label">Adresse</label>
                      <input type="text" className="form-control mb-3" placeholder="Entrer l'adresse" name="addressline1" value={addressline1} onChange={(e) => this.onChangeValue(e)} />
                      {this.state.addressline1error ? <label className='text-danger'>L'adresse est requise</label> : ""}
                      {this.state.addressline1error ? <br /> : ""}
                      <label htmlFor="" className="acc-label">Complément d'adresse</label>
                      <input type="text" className="form-control mb-3" placeholder="Entrer un complément d'adresse" name="addressline2" value={addressline2} onChange={(e) => this.onChangeValue(e)} />
                      {this.state.addressline2error ? <label className='text-danger'>Le complément d'adresse est requis</label> : ""}
                      {this.state.addressline2error ? <br /> : ""}
                      <label htmlFor="" className="acc-label">Code postal</label>
                      <input type="text" className="form-control mb-3" placeholder="Entrer le code postal" name="postcode" value={postcode} onChange={(e) => this.onChangeValue(e)} />
                      {this.state.postcodeerror ? <label className='text-danger'>Le code postal est requis</label> : ""}
                      {this.state.postcodeerror ? <br /> : ""}
                      <label htmlFor="" className="acc-label">Ville</label>
                      <input type="text" className="form-control mb-3" placeholder="Entrer une ville" name="town" value={town} onChange={(e) => this.onChangeValue(e)} />
                      {this.state.townerror ? <label style={{ color: 'red' }}>La ville est requise</label> : ""}
                      {this.state.townerror ? <br /> : ""}
                      <label htmlFor="" className="acc-label mt-4">Pays</label>
                      <select className="form-control" id="sel1" name="country" onChange={(e) => this.onChangeValue(e)} >
                        {countryArray}
                      </select>
                      <div className="list-info mt-4 mb-2">Pour que vous puissiez recevoir des paiements, nous utilisons votre adresse de facturation, votre numéro de téléphone et votre adresse électronique, que vous pouvez modifier dans les détails de votre compte.</div>
                    </div>

                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button className="prev-button mr-2" onClick={this.previous}>
                        <span className="cobalt-Icon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>Précédent
                      </button>
                      <button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}
const BillingAddressComponent = connect(mapStateToProps, mapDispatchToProps)(BillingAddress);

export default BillingAddressComponent;
