const initialState = {
  carList: [],
  modelcarList: [],
  reviewList: [],
  rating: [],
  pricerange: {},
  isLoading: true,
  stripePayData: {},
  stripePayLoading: false,
  paydunyaToken: "",
};
function SearchCarsReducer(state = initialState, action) {
  switch (action.type) {
    case "PAYDUNYA_PAYMENT_TOKEN":
      return Object.assign({}, state, {
        paydunyaToken: action.payload,
      });

    case "PAYDUNYA_PAYMENT_TOKEN_RESET":
      return Object.assign({}, state, {
        isLoading: false,
        paydunyaToken: "",
      });
    case "START_LOADING":
      return Object.assign({}, state, {
        isLoading: true,
      });
    case "END_LOADING":
      return Object.assign({}, state, {
        isLoading: false,
      });
      case "STRIPE_PAY_DATA_RESET":
      return Object.assign({}, state, {
        stripePayData:{},
        stripePayLoading: false,
      });
    case "STRIPE_PAY_LOADING":
      return Object.assign({}, state, {
        stripePayLoading: true,
      });
    case "STRIPE_PAY":
      return Object.assign({}, state, {
        stripePayData: action.payload,
        stripePayLoading: false,
      });
    case "SEARCHCARLIST":
      return Object.assign({}, state, {
        carList: action.payload,
        pricerange: action.pricerange,
      });
    case "PRICERANGELIST":
      return Object.assign({}, state, {
        carList: action.payload,
      });
    case "MODELCARLIST":
      return Object.assign({}, state, {
        modelcarList: action.payload.results[0].data,
        reviewList: action.payload.results[1].reviewList,
        rating: action.payload.results[1].rating,
        isLoading: false,
      });
    default:
      return state;
  }
}

export default SearchCarsReducer;
