import React from "react";
import 'antd/dist/reset.css';
import PropTypes from "prop-types";
import { Modal,Button} from 'antd';


export default class RentalDetailsModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    return (
      <>
        <Modal 
        title="Détails de la location" visible={this.props.showRental}
        centered={true}
        width={1000}
        footer={null}
        onCancel={this.onClose} >
          <div class="modalText m-4 p-4">
            <h3>
                Condition d’annulation et remboursement
            </h3>
            <br/>
            <h5>
                Politique d’annulation :
            </h5>
            <p>
                Vous pouvez annuler votre location sans frais si l’annulation se fait dans
                la même journée. Au delà, des frais seront appliqués :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Les frais de service ne sont pas remboursables
                    </p>
                </li>
                <li>
                    <p align="left">
                        Si vous annuler plus de 2 jours avant la location vous serez
                        remboursez à 90% ; les autres 10% reviendrons au propriétaire pour
                        le gène occasionné
                    </p>
                </li>
                <li>
                    <p align="left">
                        Si vous annulez moins de 2 jours avant la location vous serez
                        rembourser seulement de la moitié du montant de la location :
                        l’autre moitié reviendra au propriétaire
                    </p>
                </li>
            </ul>
            <p>
                Si l’annulation est faite par la propriétaire ou si vous annulez à cause de
                ce dernier vous serez intégralement remboursé.
            </p>
            <p>
                Si vous souhaiter contester des frais d’annulation il faudra le faire dans
                le jour qui suit l’annulation sinon le paiement sera déjà envoyé aux
                propriétaires et là, il ne pourra pas y avoir de retour
            </p>
            <h5>
                Délai de remboursement :
            </h5>
            <p>
                Vous êtes remboursez dans les deux semaines à venir par le moyen de
                paiement que vous avez utilisé pour régler votre location
            </p>
            <h5>
                Conditions de remboursement :
            </h5>
            <p>
                Vous êtes remboursé intégralement dans les cas suivants :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Le propriétaire n’était plus en mesure d’assurer la location
                        (indisponible, souhaite annuler).
                    </p>
                </li>
                <li>
                    <p align="left">
                        Le véhicule n’était pas conforme à l’annonce ou présentait un
                        défaut de sécurité majeur.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Vous avez rencontré un cas de force majeure vous empêchant
                        d’effectuer la location.
                    </p>
                </li>
            </ul>
            <h5>
                Force majeur :
            </h5>
            
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Décès soudain ou maladie grave d’un locataire ou d’un membre de sa
                        famille proche.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Blessure grave qui restreint directement la capacité d’un locataire
                        à effectuer sa location.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Catastrophe naturelle d’ampleur ou incidents météorologiques graves
                        dans le lieu de destination ou de départ.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Restrictions urgentes de voyage ou alertes de sécurité émises après
                        la réservation par une autorité nationale ou internationale
                        compétente (par exemple, par un ministère ou une administration
                        publique).
                    </p>
                </li>
                <li>
                    <p align="left">
                        Obligations d’origine gouvernementale imposées par les autorités
                        après la réservation (ex : fonction de juré).
                    </p>
                </li>
            </ul>
            <h3>
                <p>Gérer le début et la fin d’une location</p>
            </h3>
            <h5>
                Début de la location
            </h5>
            <p>
                Sur une location avec contact du propriétaire, vous n’aurez aucune action
                spécifique à faire a part :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Vous rendre sur les lieux du rendez vous
                    </p>
                </li>
                <li>
                    <p align="left">
                        Faire l’état des lieux avec le propriétaire
                    </p>
                </li>
                <li>
                    <p align="left">
                        Complétez et signez le contrat que le propriétaire vous indiquera
                    </p>
                </li>
                <li>
                    <p align="left">
                        Récupérez les clés
                    </p>
                </li>
            </ul>
            <p>
                Ce sera la même démarche pour une location avec un agent MONBOLIDE.
            </p>
            <h5>
                Fin de la location
            </h5>
            <p>
                Une fois votre location terminée vous aurez juste à la ramener à l’endroit
                ou vous l’avez prise et signez le contrat avec le propriétaire en fin de
                location.
            </p>
            <h3>
                <p>Prolonger ou modifier</p>
            </h3>
            <p align="justify">
                Toute prolongation doit être faite sur la plate forme et validé par le
                propriétaire avant la fin de la location. Dans ce cas si le propriétaire
                n’accepte pas la prolongation vous devez suivre les termes du contrat
                initialement prévu.
            </p>
            <p>
                Si vous avez fait une location sans contact du propriétaire la modification
                sera automatiquement accepter. Mais sachez qu’une location ne doit pas
                dépasser un mois. Pour une prolongation un nouveau contrat doit être édité
                et signé
            </p>
            <h3>
                <p>Faire le plein</p>
            </h3>
            
            <p>
                Le coût du carburant n'est pas inclus dans le prix de la location. En
            principe, vous devriez rendre le véhicule avec    <strong>le même niveau de carburant </strong>qu'au début de la location.
            </p>
            <p>
                S'il y a une différence de niveau de carburant entre le début et la fin de
                la location, le propriétaire pourra procéder à un ajustement.
            </p>
            <p>
                Toutefois, vous pouvez rendre le véhicule sans faire le plein. Dans ce cas,
                vous paierez un service de remise à niveau du réservoir de 300francs par
                litre manquant en plus du prix du litre pour vos locations sur MONBOLIDE
                bénéficiant des ajustements automatiques de carburant.
            </p>
            <p>
                Vous pouvez également passez un accord a l’amiable avec le propriétaire si
                vous souhaiter payer direct le manque au propriétaire
            </p>
            <h3>
                <p>Restituer la voiture</p>
            </h3>
            <p>
                À la fin de votre location vous devez restituer la voiture aux propriétaire
                ( 500Metre maximum aux alentours de l’endroit ou vous avez récupérer le
                véhicule) ou à une agence MONBOLIDE.
            </p>
          </div>
        </Modal>

      </>
    
    )
  }
}

RentalDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showRental: PropTypes.bool.isRequired
};