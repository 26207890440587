import React from 'react';

class PlayStore extends React.Component{
    render(){
        return(
          <section className="playstore">	
            <div className="container">	
                <div className="row">	
                    <div className="col-lg-7 img-ord">	
                        <div className="mob-img">
                            <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/get-img/mob_setszy-_1_xrusjh.png" alt=""/>	
                        </div>
                    </div>
                    <div className="col-lg-5 pad">
                        <h2 className="heading text-left" style={{marginTop:"30px"}}> Démarrez en quelques clics avec l'application</h2>
                        <a href="https://apps.apple.com/fr/app/monbolide/id1625891237" className="app-btn">
                            <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/business/app-store_uswyto.png" className='mr-1' alt="AppStore"  style={{ width: "30px", height: "30px", borderRadius: "5px" }} /> {" Apple Store"}
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.monbolide"  className="app-btn" style={{background: "#000", color: "white"}}>
                            <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/business/playstore_dgxhb1.png" className='mr-1' alt="PlayStore"  style={{ width: "30px", height: "30px", borderRadius: "5px" }}  />
                              {" Play Store"}
                        </a>
                    </div>
                  </div>
                </div>
          </section>
        )
    }
}

export default PlayStore;


    