import React from 'react';

class ShareSection extends React.Component {
  render() {
    return(
      <section className="share-section bg-white">
    		<div className="container">
    			<div className="share-text">
    				<h2 className="share-h2">Deux façons de partager</h2>
    				<p className="share-p">Louez quand vous voulez, au prix que vous voulez</p>
    			</div>

    			<div className="row">
    				<div className="main-hare-section">
    					<div className="col-lg-10 mx-auto">
    						<div className="row">
    							<div className="col-lg-6 col-md-6 col-12">
    								<div className="share-section-box">
    									<div className="box-h6 mt-2 card-header" style={{background: "#004aad"}}>
    										<h6 style={{textTransform: 'uppercase'}}>PREMIUM</h6>
    									</div>
    									<h5 className="box-h5">Sans échange de clés</h5>
    									<p className="box-p">Louez souvent et gagnez plus</p>
    									<div className="main-box">
    										<div className="small-box">
    											<div className="car-icon" style={{flex:2}}>
    												<i className="fa fa-car icon-size"></i>
    											</div>
    											<div className="text" style={{flex:9}}>
    												<p className="up">Monbolide Assistance</p>
    												<p className="down">Monbolide se charge de remettre la clé pour vous</p>
    											</div>
    											{/* <div className="circle-icon">
    												<a href="" className="circle"><i className="fa fa-exclamation-circle circle-size"></i></a>
    											</div> */}
    										</div>
    										<div className="share-section-box-text px-3 pb-5">
    											<p className='text-left'>Pas besoin de rencontrer les conducteurs<span className="bold"> Vous deposez votre véhicule, Monbolide se charge de tout.</span></p>
    											<p className="text-left"><span className="bold">Nous vérifions l'identité de chaque conducteur</span> pour que votre voiture reste toujours entre de bonnes mains.</p>
    										</div>
    									</div>
    								</div>
    							</div>
    							<div className="col-lg-6 col-md-6 col-12">
    								<div className="share-section-box">
									<div className="box-h6 mt-2 card-header" style={{background:"#212529"}}>
    										<h6 style={{textTransform: 'uppercase', }}>STANDARD</h6>
    									</div>
    									<h5 className="box-h5">Échange de clés</h5>
    									<p className="box-p">Location occasionnelle</p>
    									<div className="main-box">
    										<div className="small-box">
    											<div className="car-icon">
    												<i className="fa fa-users icon-size"></i>
    											</div>
    											<div className="text">
    												<p className="up">Rencontrer les conducteurs</p>
    												<p className="down">Donnez les clés à chaque location</p>
    											</div>
    										</div>
    										<div className="share-section-box-text px-3 pb-5">
    											<p>Rencontrez les conducteurs à chaque arrivée et départ pour signer le contrat de location.</p>
    											<p>Adapté aux locations occasionnelles en dehors des <a href="">zones que couvrent Monbolide</a> .</p>
    										</div>
    									</div>
    								</div>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</section>
    )
  }
}

export default ShareSection
