import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { AC_VIEW_CARS,AC_DELETE_CARS } from '../../actions/accountaction';

class DeactivateSection extends React.Component
{
  deleteCar(event){
    const id = localStorage.getItem("carId");
    swal({
         title: "Etes-vous sûr ?",
         text: "Votre véhicule sera supprimée de façon permanente !",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.deleteCars({id:id,userId:localStorage.getItem('siteUser')});
         }
   });
  }
    render()
    {
        return(
            <div className="deactivate list-car-card px-3 py-3">
              <p className="deactivate-text">
              Si vous désactivez votre véhicule, il n'apparaîtra plus en ligne. Si vous décidez de supprimer votre annonce, veuillez noter que cette opération ne peut être annulée.
              </p>
              <div className="button">
                <a href className="delete-btn" onClick={(e)=>this.deleteCar(e)}>Supprimer ce véhicule</a>
              </div>
            </div>
      

        );
    }
}
function mapStateToProps(state) {
  return {
        AccountReducer : state.AccountReducer
  };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ViewCars: AC_VIEW_CARS,deleteCars:AC_DELETE_CARS },dispatch)
}

const DeactivateSectionComponent = connect(mapStateToProps,mapDispatchToProps)(DeactivateSection);

export default DeactivateSectionComponent;