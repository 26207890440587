import React from "react";
// import { BrowserRouter as Router, Switch, Route,Link} from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AC_VIEW_PAGES } from "../../actions/pagesaction";

class Pages extends React.Component {
  componentDidMount() {
    const slug = this.props.match.params.slug;
    this.props.ViewPages(slug);
  }
  render() {
    const pageInfo = this.props.PagesReducer.pageInfo;
    if (pageInfo !== undefined) {
      window.$(".dialogMessageArea").summernote("code", pageInfo.description);
      window.$(".dialogMessageArea").summernote("destroy");
    }
    return (
      <section className="layer-section">
        <div className="container dialogMessageArea"></div>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    PagesReducer: state.PagesReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewPages: AC_VIEW_PAGES }, dispatch);
}

const PagesComponent = connect(mapStateToProps, mapDispatchToProps)(Pages);

export default PagesComponent;
