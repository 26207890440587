import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';

class RegistrationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
      year: this.props.HostCarReducer?.numberInfo?.year || new Date().getFullYear(),
      country: this.props.HostCarReducer?.numberInfo?.country || "Côte d'Ivoire",
      numbererror: false,
      nowYear: new Date().getFullYear()
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "number") {
      this.setState({ [name + "error"]: false })
    }
    if (name === "year") {
      this.setState({ year: value })
    }

    this.props.handleInputChange(name, value, 'number')
  }
  validateForm() {
    const numberInfo = this.props.HostCarReducer.numberInfo;
    const id = localStorage.getItem('formId');
    const countryList = this.props.DashboardReducer.countryList;
    const year = this.state.nowYear;
    if (numberInfo) {
      if (!numberInfo.country && countryList[0]) {
        numberInfo.country = countryList[0].countryname
      }
      if (!numberInfo.year) {
        numberInfo.year = year
      }
      if (numberInfo.number) {
        this.props.updateHostDetails(numberInfo, id, 'Number');
        this.setState({ next: true })
      } else {
        this.setState({ next: false, numbererror: true })
      }
    } else {
      this.setState({ numbererror: true })
    }
  }

  previous() {
    this.setState({ previous: true })
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect push={true} to="/car-fuel-detail" />
    }
    if (previous) {
      return <Redirect to="/mileage" />
    }
    let number = '';
    // let country = '';    
    const numberInfo = this.props.HostCarReducer.numberInfo;
    if (numberInfo) {
      number = numberInfo.number;
      // country = numberInfo.country;        
    }
    const countryList = this.props.DashboardReducer.countryList;
    var countryArray = [];
    for (let i = 0; i < countryList.length; i++) {
      countryArray.push(
        <option key={i} value={countryList[i].countryname} >{countryList[i].countryname}</option>
      )
    }

    let years = []
    for (let i = this.state.nowYear; i > 2010; i--) {
      years.push(i)     
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "40%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                  <h4 className='card-header text-white text-center py-4 mb-2' style={{background:"#004AAD", textTransform:'uppercase', fontFamily: 'poppins'}}>Ajouter mon véhicule</h4>
                    <div className="container">

                      <div className="title-card">Quel est le numéro d'immatriculation du véhicule ?</div>
                      <label htmlFor="" className="acc-label">Numéro de plaque</label>
                      <input type="text" className="form-control" name="number" value={number} onChange={(e) => this.onChangeValue(e)} placeholder="Ex: 1234-AB-12" />
                      <div>
                        {this.state.numbererror ? <label className='text-danger'>Un numéro de plaque est requis</label> : ""}
                      </div>
                      <label htmlFor="" className="acc-label mt-4">Pays d'immatriculation</label>
                      <select className="form-control" id="sel1" name="country" value={this.state.country} onChange={(e) => this.onChangeValue(e)}>
                        {countryArray}
                      </select>
                      <label htmlFor="" className="acc-label mt-4">Année d'immatriculation</label>
                      <select className="form-control" id="sel1" name="year" value={parseInt(this.state.year)} onChange={(e) => this.onChangeValue(e)}>
                        {years.map((y) => <option value={y} key={y}  >{y}</option>)}

                      </select>
                      <small className="pb-3 list-info mt-4 mb-2 d-block">Vous trouverez ces informations sur le certificat d'immatriculation du véhicule.</small>
                    </div>

                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button className="prev-button mr-2" onClick={this.previous}>
                        <span className="cobalt-Icon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>Précédent
                      </button>
                      <button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}
const RegistrationDetailComponent = connect(mapStateToProps, mapDispatchToProps)(RegistrationDetail);

export default RegistrationDetailComponent;
