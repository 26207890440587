import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';
import './CustomerCare.css'
class CustomerCare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
      vehiclemaintainace: false,
      registeredowner: false,
      termsandcondition: false,
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.checked;
    this.setState({ [name]: value })
    this.props.handleInputChange(name, value, 'customercare')
  }

  validateForm() {
    const id = localStorage.getItem('formId');
    const customercareInfo = this.props.HostCarReducer.customercareInfo;
    var vehiclemaintainace = this.state.vehiclemaintainace;
    var registeredowner = this.state.registeredowner;
    var termsandcondition = this.state.termsandcondition;
    if (customercareInfo) {
      if (customercareInfo.vehiclemaintainace) {
        vehiclemaintainace = customercareInfo.vehiclemaintainace;
      }
      if (customercareInfo.registeredowner) {
        registeredowner = customercareInfo.registeredowner;
      }
      if (customercareInfo.termsandcondition) {
        termsandcondition = customercareInfo.termsandcondition;
      }
    }
    const customerCare = {
      vehiclemaintainace: vehiclemaintainace,
      registeredowner: registeredowner,
      termsandcondition: termsandcondition,
    }
    this.props.updateHostDetails(customerCare, id, 'CustomerCare');
    this.setState({ next: true });
  }

  previous() {
    this.setState({ previous: true })
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (next) {
      return <Redirect push={true} to="/add-car-image" />
    }
    if (previous) {
      return <Redirect to="/car-price" />
    }
    const customercareInfo = this.props.HostCarReducer?.customercareInfo;
    let vehiclemaintainace = false
    let registeredowner = false
    let termsandcondition = false
    if (customercareInfo) {
      vehiclemaintainace = customercareInfo.vehiclemaintainace;
      registeredowner = customercareInfo.registeredowner;
      termsandcondition = customercareInfo.termsandcondition;
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                  <h4 className='card-header text-white text-center py-4 mb-2' style={{background:"#004AAD", textTransform:'uppercase', fontFamily: 'poppins'}}>Ajouter mon véhicule</h4>
                    <div className="container">

                      <div className="title-card">Prenez soin de vos locataires</div>
                      <div className="form9-img d-flex mb-4">
                        <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/form-img10_l2ofwh.png" alt="" />
                        <p className="m-0 lead">En louant votre véhicule sur Monbolide, vous vous engagez à offrir une expérience sûre et agréable à vos locataires.</p>
                      </div>

                      <div className="list-car-card ml-3">
                        <div className="flex-div toggle-flex">
                          <div className="left-div p-0">
                            <p className="m-0 font-size-text" >Le véhicule est bien entretenu et son contrôle technique est à jour.</p>
                          </div>
                          <div className="right basis text-right" >
                            <label className="switch">
                              <input type="checkbox" name="vehiclemaintainace" checked={vehiclemaintainace} onChange={(e) => this.onChangeValue(e)} />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="list-car-card  ml-3">
                        <div className="flex-div toggle-flex">
                          <div className="left-div p-0">
                            <p className="m-0 font-size-text">Je suis le propriétaire enregistré du véhicule sur la carte grise</p>
                          </div>
                          <div className="right basis text-right">
                            <label className="switch">
                              <input type="checkbox" name="registeredowner" checked={registeredowner} onChange={(e) => this.onChangeValue(e)} />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="list-car-card ml-3">
                        <div className="flex-div toggle-flex">
                          <div className="left-div p-0">
                            <p className="m-0 font-size-text">J'accepte les <a target="_blank" rel="noopener" href="/terms#tos_title"><i>conditions d'utilisations de Monbolide</i>

                            </a></p>
                          </div>
                          <div className="right basis text-right">
                            <label className="switch">
                              <input type="checkbox" name="termsandcondition" checked={termsandcondition} onChange={(e) => this.onChangeValue(e)} />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>



                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button className="prev-button mr-2" onClick={this.previous}>
                        <span className="cobalt-Icon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>Précédent
                      </button>
                      <button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}
const CustomerCareComponent = connect(mapStateToProps, mapDispatchToProps)(CustomerCare);

export default CustomerCareComponent;
