import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from "react-router-dom"
import swal from "@sweetalert/with-react";

// import  API  from '../../common/api';



const LastestFeeds = (props) => {

  const [lastestCars, setLastestCars] = useState([])
  const [redirectLink, setRedirectLink] = useState('')
  const [isOk, setIsOk] = useState(false);
  
  useEffect(() => {
    setLastestCars(props?.DashboardReducer?.lastCars);
  }, [props?.DashboardReducer?.lastCars])

  const checkDate = () => {
    const checkIn = localStorage.getItem('checkIn');
    const checkOut = localStorage.getItem('checkOut');

    if(checkIn == null || checkOut == null) {
      swal({
        title: "Informations",
        text: "Veuillez renseigner la période de location (Date de debut et date de fin)",
        buttons: {          
          confirm: {
            visible: true,
            text: "Ok"
          }
        },
      })
    }
  }
  const viewCar = (car) => {
    const checkIn = localStorage.getItem('checkIn');
    const checkOut = localStorage.getItem('checkOut');

    if(checkIn == null || checkOut == null) {
      swal({
        title: "Informations",
        text: "Veuillez renseigner la période de location",
        buttons: {          
          confirm: {
            visible: true,
            text: "Ok"
          }
        },
      })
    }
    else {
      localStorage.setItem("modelId", car._id);
      const links = `/search-car/${car._id}`
      setRedirectLink(links);
    }

    

  }

  if (redirectLink) {
    return <Redirect push={true} to={redirectLink} />
  }
  return (
    <section className="latest-feeds">
      <div className="container"  >
        <h2 className="heading">Les derniers véhicules ajoutés</h2>
        <div className="row">

          {lastestCars?.map((rentList, index) => (
            <div className="col-md-4 mb-2" key={index} style={{ cursor: "pointer" }} 
            onClick={(e) => viewCar(rentList)}
            >
              <div className="rented-car">
                <div className="car-img" style={{ width: "100%" }} id={rentList._id} >
                  <img src={URL.IMAGEURL + rentList.carimage?.mainimage} className="w-100" style={{ objectFit: 'cover', height: "350px" }} alt="" id={rentList._id} />
                </div>
                <div className="car-body" id={rentList._id}>
                  <div className="car-content">
                    <div className="price-brand">
                      <ul className="car-desc">
                        <li>{rentList.carmodel?.make?.brandname}</li>
                        <li className="year">{rentList.number?.year}</li>
                      </ul>
                      <div className="car-price">{`${parseInt(rentList?.price?.price).toLocaleString()} FCFA`}	<span>/jour</span>
                    </div>
                     
                  </div>
                    
                    <div className="car-titl">{rentList?.mileage?.mileage}</div>

                    {/* <div className="rating" id={rentList._id}>
                       {ratingArray} 
                      <span className="review-count">{rentList?.rating}</span>
                    </div> */}
                  </div>
                 
                </div>
              </div>
            </div>))
          }

        </div>
      </div>
    </section>
  )
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}

const LatestFeedsComponent = connect(mapStateToProps, mapDispatchToProps)(LastestFeeds);

export default LatestFeedsComponent;
