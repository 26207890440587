import React, {useState} from 'react';

const DownloadApp = (props) => {    
    const [visible, setVisible] = useState(true);
    return (
        <div id="downloadButton" className='downloadButtonStyle' style={{display: props.isMobile && visible ? 'flex': 'none'}}>
            <span className='closeDownloadButton' onClick={() => setVisible(false)}>x</span>
            <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/images/APP_ICON_BLUE-07_vngu5v.png"  alt="monbolide" width={50} height={50} />
            <span className="downloadButtonHead" id="downloadButtonHead">{props.text}</span>
            <a href={props.url} className="px-2 py-1" style={{textDecoration: "none", backgroundColor: "#004aad", color: 'white', borderRadius: "20px" }}>Ouvrir </a>
        </div>
    );
}

export default DownloadApp;
