import React from "react";
import "./PhotoSection.css";
import { Imagevalidation } from "../../common/validate.js";
import { AC_ADD_CARIMAGE } from "../../actions/carsaction";
import API from "../../common/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import 'antd/dist/reset.css';
import {
  AC_YOUR_CAR,  
} from "../../actions/carsaction";
import { Image } from 'antd';
class PhotoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "/assets/images/photograph.png",
      imagecheck: false,
      imagevalue: false,
      imgSrc: "/assets/images/photograph.png",
      imagedata: {},
    };
  }

  componentDidMount() {
    this.props.ViewCar({ id: localStorage.getItem("carId") });
  }

  handleChange = (e) => { };
  saveImage = () => {
    this.setState({ imgSrc: "", imagevalue: false });
    let formData = new FormData();
    if (this.state.imagedata) {
      formData.append("otherimage", this.state.imagedata);
      formData.append("id", localStorage.getItem("carId"));
    }

    this.props.addImage(formData).then(()=>{
      this.props.ViewCar({ id: localStorage.getItem("carId") });
    });
  };
  showPreview = (event) => {
    var value = event.target.value;

    this.setState({ imagedata: event.target.files[0] });
    var file = event.target.files[0];
    // console.log(file)
    if (file) {
      const imgvalidate = Imagevalidation(file);
      if (imgvalidate) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          this.setState({
            imgSrc: reader.result,
            imagevalue: true,
            imagecheck: false,
          });
        }.bind(this);
      } else {
        this.setState({ image: value, imagecheck: true });
        this.setState({ imgSrc: "" });
      }
    }
    value = event.target.files[0];
  };
  render() {
    var car = this.props.CarsReducers;
    console.log(car)
    
    return (
      <div className="photo list-car-card px-3 py-3">
        <h2 className="title mb-16">Les photos du véhicule</h2>
        <p className="mb-24">
          Nous n'affichons que les voitures avec photos. Vous pouvez{" "}
          <b>commencer par un</b> et en ajouter d'autres après la mise en ligne
          de votre véhicule
        </p>
        <h3 className="sub-title">Nos conseils</h3>
        <div className="row">
          <div className="col-lg-6 flex_row_center">
            <span className="tick-icon">
              <i className="fa fa-check-circle tick-icon-size" />
            </span>
            <p className="mb-0">Utilisez le format paysage</p>
          </div>
          <div className="col-lg-6 flex_row_center">
            <span className="tick-icon">
              <i className="fa fa-check-circle tick-icon-size" />
            </span>
            <p className="mb-0">Suivez nos directives en matière d'angle</p>
          </div>
          <div className="col-lg-6 flex_row_center">
            <span className="tick-icon">
              <i className="fa fa-check-circle tick-icon-size" />
            </span>
            <p className="mb-0">
              Veillez à ce que l'arrière-plan soit clair et neutre
            </p>
          </div>
          <div className="col-lg-6 flex_row_center">
            <span className="tick-icon">
              <i className="fa fa-check-circle tick-icon-size" />
            </span>
            <p className="mb-0">
              Utiliser uniquement la lumière naturelle du jour
            </p>
          </div>
        </div>
        {/* <hr className="divider" /> */}
        <div style={{marginBottom: "30px"}}>
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            {car.carList?.carimage?.backimage && <Image width={200} src={API.IMAGEURL+ car.carList?.carimage?.backimage} />}
            
            {car.carList?.carimage?.mainimage && <Image
              width={200}
              src={API.IMAGEURL+ car.carList?.carimage?.mainimage}
            />}
            
            {car.carList?.carimage?.sideimage && <Image
              width={200}
              src={API.IMAGEURL+ car.carList?.carimage?.sideimage}
            />}
            {car.carList?.carimage?.interiorimage && <Image
              width={200}
              src={API.IMAGEURL+ car.carList?.carimage?.interiorimage}
            />}
            {car.carList?.carimage?.extraimage && <Image
              width={200}
              src={API.IMAGEURL+ car.carList?.carimage?.extraimage}
            />}
          </Image.PreviewGroup>
        </div>
        <div className="main_photo_section">
          <div className="main-mb-24">
            <div className="row">
              <div className="col-lg-6">
                <div className="center">
                  <div className="form-input">
                    <div className="preview">
                      {/* <img id="file-ip-1-preview" src={this.state.imgSrc} /> */}
                      {this.state.imagevalue && (
                        <img
                          id="file-ip-1-preview"
                          className={`${this.state.imagevalue
                              ? "showImagePreview"
                              : "hideImagePreview"
                            }`}
                          src={this.state.imgSrc}
                          alt=""
                        />
                      )}
                    </div>
                    <label htmlFor="file-ip-1">
                      <i className="fas fa-upload"></i>{" "}
                      {!this.state.imagevalue
                        ? "Ajouter une Image"
                        : "Changer d'image"}
                    </label>
                    <input
                      type="file"
                      id="file-ip-1"
                      accept="image/*"
                      onChange={(e) => this.showPreview(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="car-container">
              <div className="car-text">
                <p>
                  <b>Photos supplémentaires</b> pour mettre en évidence les
                  spécificités (siège bébé, GPS...).
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-divided">
          <div className="align_right">
            <div className="save-button-item">
              <button
                className="save-button"
                type="submit"
                onClick={this.saveImage}
              >
                <span className="save-button-Icon">
                  <span className="save-Icon" />
                </span>
                <span className="text-white">Enregistrer</span>
              </button>
            </div>
          </div>
        </div>

        
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    CarListReducer: state.DashboardReducer,
    CarsReducers: state.CarsReducers,    
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addImage: AC_ADD_CARIMAGE,
      ViewCar: AC_YOUR_CAR,
    },
    dispatch
  );
}
const PhotoSectionCompenent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoSection);

export default PhotoSectionCompenent;
