const initialState = {
  accountId: "",
  customerAccount: {},
  accountList: [],
  tripsList: [],
  tripsListRequest: [],
  tripsListRental: [],
  countRequest: 0,
  countRental: 0,
  carsList: [],
  tripsCarList: [],
  accountCount: 0,
  notificationList: [],
  notificationCount: 0,
  appNotifier: {},
  emailNotifier: {},
  smsNotifier: {},
  notificationInfo: {},
  accountInfo: {
    firstname: "",
    lastname: "",
    placeofbirth: "",
    licensenumber: "",
    issuingcountry: "",
    contactemail: "",
    addressline1: "",
    addressline2: "",
    postcode: "",
    town: "",
    code: "",
    email: "",
    addressline3: "",
    aboutme: "",
    linkedin: "",
    twitter: "",
    mobilenumber: "",
    validatedate: "",
    dateofbirth: "",
    image: "",
    iban: "",
    ownercard: "",
    mobileMoney: "",
    ownerInfosCar: [],
  },
};

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case "VIEW_ACCOUNT":
      if (action.payload.data.notifier) {
        return Object.assign({}, state, {
          accountInfo: action.payload.data,
          emailNotifier: action.payload.data.notifier.emailNotifier,
          appNotifier: action.payload.data.notifier.appNotifier,
          smsNotifier: action.payload.data.notifier.smsNotifier,
        });
      } else {
        return Object.assign({}, state, {
          accountInfo: action.payload.data,
        });
      }
    case "VIEW_CUSTOMER":
      return Object.assign({}, state, {
        customerAccount: action.payload.data,
      });

    case "VIEW_OWNER_INFO":
      return Object.assign({}, state, {
        ownerInfosCar: action.payload.data,
      });
    case "EDIT_ACCOUNT":
      return Object.assign({}, state, {
        accountInfo: action.payload.data,
      });
    case "ACCOUNT_CREATION":
      return Object.assign({}, state, {
        accountInfo: action.payload.data,
        accountId: action.payload.data._id,
      });
    case "NOTIFICATION_SETTINGS":
      return Object.assign({}, state, {
        notificationList: action.payload.data,
        notificationCount: action.payload.count,
      });
    case "VIEW_TRIPS":
      return Object.assign({}, state, {
        tripsList: action.payload.data,
      });
      case "COUNT_TRIPS":
      return Object.assign({}, state, {
        countRental: action.payload.data.countRental,
        countRequest: action.payload.data.countRequest,
      });
      case "VIEW_TRIPS_REQUEST":
      return Object.assign({}, state, {
        tripsListRequest: action.payload.data,
      });
    case "VIEW_CARS_TRIPS":
      return Object.assign({}, state, {
        tripsCarList: action.payload.data,
      });
    case "ADD_REVIEW":
      return Object.assign({}, state, {
        tripsList: action.payload.data,
      });
    case "SET_CAR_STATUS":
      return Object.assign({}, state, {
        tripsList: action.payload,
      });
    case "VIEW_CARS":
      return Object.assign({}, state, {
        carsList: action.payload.data,
      });
    case "EDIT_NOTIFICATION":
      return Object.assign({}, state, {
        emailNotifier: action.payload.data.emailNotifier,
        appNotifier: action.payload.data.appNotifier,
        smsNotifier: action.payload.data.smsNotifier,
      });
    case "UPDATE_ACCOUNT_DATA":
      return editAccountInfo(action, state);

    case "UPDATE_NOTIFICATION_DATA":
      if (action.name === "appnotifier") {
        return {
          ...state,
          appNotifier: {
            ...state.appNotifier,
            [action.id]: action.value,
          },
        };
      }
      if (action.name === "smsnotifier") {
        return {
          ...state,
          smsNotifier: {
            ...state.smsNotifier,
            [action.id]: action.value,
          },
        };
      }
      if (action.name === "emailnotifier") {
        return {
          ...state,
          emailNotifier: {
            ...state.emailNotifier,
            [action.id]: action.value,
          },
        };
      }
      break;
    default:
      return state;
  }
}

// functions to update edit accountinfo data
function editAccountInfo(action, state) {
  if (action.name === "firstname") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        firstname: action.value,
      },
    });
  }
  if (action.name === "lastname") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        lastname: action.value,
      },
    });
  }

  if (action.name === "contactemail") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        contactemail: action.value,
      },
    });
  }

  if (action.name === "placeofbirth") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        placeofbirth: action.value,
      },
    });
  }
  if (action.name === "code") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        code: action.value,
      },
    });
  }
  if (action.name === "validatedate") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        validatedate: action.value,
      },
    });
  }
  if (action.name === "licensenumber") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        licensenumber: action.value,
      },
    });
  }
  if (action.name === "issuingcountry") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        issuingcountry: action.value,
      },
    });
  }
  if (action.name === "addressline1") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        addressline1: action.value,
      },
    });
  }
  if (action.name === "addressline2") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        addressline2: action.value,
      },
    });
  }
  if (action.name === "postcode") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        postcode: action.value,
      },
    });
  }
  if (action.name === "town") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        town: action.value,
      },
    });
  }
  if (action.name === "addressline3") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        addressline3: action.value,
      },
    });
  }
  if (action.name === "aboutme") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        aboutme: action.value,
      },
    });
  }
  if (action.name === "mobilenumber") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        mobilenumber: action.value,
      },
    });
  }
  if (action.name === "dateofbirth") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        dateofbirth: action.value,
      },
    });
  }
  if (action.name === "linkedin") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        linkedin: action.value,
      },
    });
  }
  if (action.name === "twitter") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        twitter: action.value,
      },
    });
  }
  if (action.name === "image") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        image: action.value,
      },
    });
  }

  if (action.name === "iban") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        iban: action.value,
      },
    });
  }

  if (action.name === "ownercard") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        ownercard: action.value,
      },
    });
  }

  if (action.name === "mobilemoney") {
    return Object.assign({}, state, {
      accountInfo: {
        ...state.accountInfo,
        mobilemoney: action.value,
      },
    });
  }

  return 1;
}
export default AccountReducer;
