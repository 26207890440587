import React from "react";
import 'antd/dist/reset.css';
import PropTypes from "prop-types";
import { Modal,Button} from 'antd';


export default class QuestionModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    return (
      <>
        <Modal 
        title="Question" visible={this.props.showQuestion}
        centered={true}
        width={1000}
        footer={null}
        onCancel={this.onClose} >
         <div class="modalText m-4 p-4">
          ...
        </div>
        </Modal>

      </>
    
    )
  }
}

QuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showQuestion: PropTypes.bool.isRequired
};