import React from "react";
class GetCredit extends React.Component {
  render() {
    return (
      <div className="main-acc">
        <div className="refer-tab">
          <h2 className="refer mb-4">
            Parrainez un ami et gagnez 15 000 XOF de crédit
          </h2>
          <span className="tick-icon">
            <i className="fa fa-check-circle tick-icon-size" />
            Votre ami reçoit <strong>15 000 XOF</strong> pour leur premier
            voyage Monbolide
          </span>
          <br />
          <span className="tick-icon">
            <i className="fa fa-check-circle tick-icon-size" />
            Votre ami reçoit <strong>15 000 XOF</strong> pour leur premier
            voyage Monbolide
          </span>
          <br />
          <p className="mb-4 r-clr">
            Vous pouvez gagner des crédits en parrainant jusqu'à 5 amis par
            mois.
          </p>
          <div>
            <span className="rent-btn mb-4">
              <i className="fa fa-gift gift-icon-size" />
              <a href>Inviter des amis</a>
            </span>
          </div>
          {/* <a href="#" className="r-more">En savoir plus sur le fonctionnement des crédits de parrainage</a> */}
          <p className="r-more">
            En savoir plus sur le fonctionnement des crédits de parrainage
          </p>
        </div>
      </div>
    );
  }
}
export default GetCredit;
