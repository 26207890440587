import axios from 'axios';
import URL from '../common/api';

const VIEW_FAQS        = 'VIEW_FAQS';

export function AC_VIEW_FAQ_DESCRIPTION(data) {
 return function(dispatch) {
   return axios.post(URL.API.ViewFaq,data)
     .then(({ data }) => {
       dispatch({type: VIEW_FAQS, payload:data});
   });
 };
}
