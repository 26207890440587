import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
// import {Redirect } from "react-router-dom";
// import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
// import Icon from '@mui/material/Icon';
import {
  AC_VIEW_CARS,
  AC_DELETE_CARS,
  AC_TOGGLE_ACTIVE_CAR,
} from "../../actions/accountaction";
import { AC_HANDLE_INSTANT_LOCATION } from "../../actions/carsaction";
// import GeneralSection from "../carsdashboard/generalsection"
import API from "../../common/api";
import "./Cars.css";

class Cars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      goToCarModel: false,
      carId: ''
    };
  }

  componentDidMount() {
    this.props.ViewCars({ id: localStorage.getItem("siteUser"), status: 4 });
  }

  onChangeValue(e) {
    this.props.ViewCars({
      id: localStorage.getItem("siteUser"),
      status: e.target.value,
    });
  }

  navigateToAgreement(event) {
    // event.preventDefault()

    this.props.history.push({
      pathname: `/rental-agreement/${event.target.id}`,
      state: { id: event.target.id },
    });
  }
  // deleteCar(event) {
  //   const id = event.target.value;
  //   swal({
  //     title: "Etes-vous sûr ?",
  //     text: "Ce véhicule sera définitivement supprimé !",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   })
  //     .then((willDelete) => {
  //       if (willDelete) {
  //         this.props.deleteCars({ id: id, userId: localStorage.getItem('siteUser') });
  //       }
  //     });
  // }

  deleteCar(event) {
    const id = event.target.id;
    const message = "Etes-vous sûr de vouloir supprimer cette annonce ?";
    if (message) {
      swal({
        title: "SUPPRIMER UNE ANNONCE",
        text: message,
        icon: "warning",
        buttons: ["Non, Merci", "Oui, merci"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.props.deleteCars({
            id: id,
            userId: localStorage.getItem("siteUser"),
          });
        }
      });
    }
  }
  toggleActiveCar(event) {
    // event.stopPropagation()
    const id = event.target.id;
    let message = "";
    if (event.target.value === "true" || event.target.name === "true") {
      message =
        "Ce véhicule sera mis en pause ! Et plus personne ne verra cette annonce";
    } else if (event.target.value === "false" || event.target.name === "true") {
      message = "Vos clients pourrons désormais voir cette annonce";
    }

    if (message) {
      swal({
        title: "Etes-vous sûr ?",
        text: message,
        icon: "warning",
        buttons: ["Non, Merci", "Oui, merci"],
        // dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.props.ToggleActiveCar({
            id: id,
            userId: localStorage.getItem("siteUser"),
          });
        }
      });
    }
  }

  setIdGeneralTab(e) {
    const carId = e.target.id;    
    e.stopPropagation();
    localStorage.setItem("carId", carId);
    this.props.history.push({
      pathname: `/general-tab`,
    });
  }
  setIdCarModel = (e) => {
    e.stopPropagation();
    localStorage.setItem("formId", e.target.id);
    window.location.href = window.location.origin + "/car-model";

    // this.props.history.push({
    //   pathname: "/car-model"
    // })
  };
  render() {
    let carsList = this.props.AccountReducer.carsList;
    // console.log("nombre de véhicule - cars.js:", this.props.AccountReducer.carsList)

    let carsArray = [];
    if (carsList) {
      for (var i = 0; i < carsList.length; i++) {
        var image =
          "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/car/d-car_sc0hgy.jpg";
        if (carsList[i].carimage) {
          image = API.IMAGEURL + carsList[i].carimage.mainimage;
        }

        // const carId = carsList[i]._id
        carsArray.push(
          <div
            className="main-sec col-lg-6 mb-4"
            key={carsList[i]._id}
            data-aos="zoom-in-left"
          >
            <div className="car-listing-card">
              <div className="profile-card">
                <div className="car-listing-img">
                  <img src={image} alt="Profil véhicule" />
                </div>
                <div className="title-car px-2">
                  <span className="car-listing-title">
                    {carsList[i]?.carmodel?.make?.brandname}
                  </span>
                </div>
              </div>
              <div className="listing-button">
                {(carsList[i].status === 2 || carsList[i].status === 1) && (
                  <button
                    name="view"
                    onClick={(e) => {
                      this.setIdGeneralTab(e)
                      // this.setState({carId: e.target.id})
                    }}
                    id={carsList[i]._id}
                    className="complete-btn mr-2 px-3"
                    style={{ borderWidth: "0px" }}
                  >                    
                    Réglages
                  </button>
                )}

                {carsList[i].status === 3 && (
                  <div className="listing-button">
                    <button
                      type="button"
                      id={carsList[i]._id}
                      onClick={(e) => this.deleteCar(e)}
                      className="account-remove-btn ml-1"
                    >
                      Supprimer
                    </button>

                    <button
                      to="/car-model"
                      onClick={(e) => this.setIdCarModel(e)}
                      name="complete"
                      id={carsList[i]._id}
                      className="ml-1 account-btn complet-btn text-white"
                    >
                      Compléter
                    </button>
                  </div>
                )}
                {/* <a  className="complete-btn mr-2" value={carsList[i]._id} onClick={(e) => this.navigateToAgreement(e)} ><i className="fas fa-file-contract fa-lg"></i></a>  */}
                {/* {carsList[i].status !== 3 &&
                  <button className="complete-btn px-3 mr-2" style={{ borderWidth: "0px" }}  id={carsList[i]._id} onClick={(e) => this.navigateToAgreement(e)}><i className="fas fa-file-contract fa-lg" id={carsList[i]._id} onClick={(e) => this.navigateToAgreement(e)}></i> Contrat</button>                  
                  } */}
                {carsList[i].status !== 3 && (
                  <button
                    type="button"
                    className={`px-3 mr-2 ${!carsList[i].active ? 'active-btn' : 'delete-btn'}`}
                    style={{ borderWidth: "0px" }}
                    value={carsList[i].active}
                    id={carsList[i]._id}                    
                    onClick={(e) => this.toggleActiveCar(e)}
                    title={
                      carsList[i].active
                        ? "Désactiver l'annonce"
                        : "Activer l'annonce"
                    }
                  >
                    {!carsList[i].active ? "Activer" : "Désactiver" }
                  </button>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              {carsList[i].status === 2 ? (
                <div className="car-listing-info">
                  <div className="car-listing-info-title">Liste en attente</div>
                  <p className="text">
                    Notre équipe de sécurité doit approuver votre voiture.
                  </p>
                </div>
              ) : (
                ""
              )}
              {carsList[i].status === 3 ? (
                <div className="car-listing-info">
                  <div className="car-listing-info-title">Liste incomplète</div>
                  <p className="text">
                    Complétez les détails de base de la liste.
                  </p>
                </div>
              ) : (
                ""
              )}
              {carsList[i].status === 0 ? (
                <div className="car-listing-info">
                  <div className="car-listing-info-title">
                    Désactivation de la liste
                  </div>
                  <p className="text">
                    Notre équipe de sécurité a rendu votre voiture inactive.
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }
    }
    return (
      <div className="container" style={{ marginTop: "-70px" }}>
        <div className="col-lg-12">
          <div className="your-listing-header">
            <div className="right-side">
              <form className="dropdown-status">
                <button
                  type="button"
                  className="status-btn dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Statut
                </button>
                <div className="dropdown-menu">
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value="4"
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Tous les véhicules</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value="1"
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Actif</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value="2"
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">En attente d'approbation</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value="3"
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Incomplet</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value="0"
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Inactif</label>
                  <br />
                </div>
              </form>
            </div>
          </div>
          {carsArray.length === 0 ? (
            <div className="container">
              <div className="col-lg-12">
                <div className="main-sec">
                  <div className="car-sec">
                    <img
                      src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/cars_ljk47u.png"
                      className="car-img"
                      style={{ width: "50%" }}
                      alt="Alternative"
                    />
                    <h2>Aucun véhicule n'est actuellement répertoriée.</h2>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
          {carsArray}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewCars: AC_VIEW_CARS,
      deleteCars: AC_DELETE_CARS,
      ToggleActiveCar: AC_TOGGLE_ACTIVE_CAR,
      switchIntantLocation: AC_HANDLE_INSTANT_LOCATION,
    },
    dispatch
  );
}

const CarsComponent = connect(mapStateToProps, mapDispatchToProps)(Cars);

export default CarsComponent;
