import React from 'react';
import SearchSection from './searchsection';
import TabSection from './tabsection';

class Question extends React.Component {
  render() {
    return(
    <div className="main-body">
      <SearchSection/>
      <TabSection/>
    </div>
    )
  }
}

export default Question
