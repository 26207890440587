import React from 'react';
import { Redirect } from "react-router-dom";
import Autocomplete from 'react-google-autocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_HOST_DETAILS, AC_HANDLE_INPUT_CHANGE, AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';
import { getFormattedAdress } from '../../utils/geoLocation';

class ParkingAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      nextpage: false,
      parking: this.props.HostCarReducer?.parkingInfo?.formatted_address || "",
      parkingerror: false,
    }
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('formId')) {
      this.props.viewDetails(localStorage.getItem('formId'));
    }
    // console.log(this.state.parking)
  }
  setValue(e) {
    this.setState({ parking: e })
  }

  setPlace = (place) => {
    const name = "location";
    let parking = {
      formatted_address: null,
      location: {
        lat: null,
        lon: null
      }
    };
    const addressName = getFormattedAdress(place)
    this.setState({ parking: addressName })
    parking.formatted_address = place.name
    parking.location.lat = place.geometry.location.lat()
    parking.location.lon = place.geometry.location.lng()
    this.props.handleInputChange(name, parking, 'parking')
  }


  onChangeValue(event) {
    if (event) {
      this.setState({ parking: event.target.value })
    } else {
      this.setState({ parking: event.target.value })
    }
  }
  // this.props.handleInputChange(name, value, 'parking')  
  validateForm() {
    const parking = this.props.HostCarReducer?.parkingInfo;
    const id = localStorage.getItem('formId');
    if (parking) {
      if (parking.location) {
        // console.log("Location: " + parking.location.lon)
        this.props.updateHostDetails(parking, id, 'Parking');
        this.setState({ nextpage: true, parkingerror: false })
      } else {
        this.setState({ parkingerror: true })
      }
    } else {
      this.setState({ parkingerror: true })
    }
  }

  previous() {
    this.setState({ previous: true })
  }
  render() {
    const next = this.state.nextpage;
    const previous = this.state.previous;
    if (next) {
      return <Redirect push={true} to="/mileage" />
    }
    if (previous) {
      return <Redirect to="/car-model" />
    }
    var parking = '';
    const parkingInfo = this.props.HostCarReducer.parkingInfo;
    if (parkingInfo) {
      parking = parkingInfo.formatted_address;
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "20%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <h4 className='card-header text-white text-center py-4 mb-2' style={{ background: "#004AAD", textTransform: 'uppercase', fontFamily: 'poppins' }}>Ajouter mon véhicule</h4>
                    <div className="container">
                      <div className="title-card">Où garez-vous habituellement votre véhicule ?</div>
                      <label htmlFor="" className="acc-label">Adresse de stationnement</label>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1"><i className="fas fa-parking"></i></span>
                        </div>
                        <Autocomplete className="form-control"
                          fields={["name", "formatted_address", "geometry", "type", "address_components"]}
                          onChange={(e) => this.onChangeValue(e)}
                          onPlaceSelected={(place) => {
                            this.setPlace(place);
                            // console.log(place)
                          }}
                          types={['geocode', 'establishment']}
                          componentRestrictions={{ country: "civ" }}
                          value={this.state.parking}


                        />
                      </div>
                      {this.state.parkingerror ? <label className="text-danger">Veuillez renseigner une adresse de stationnement valide</label> : ""}

                      <div className="list-info mb-5">Assurez-vous que les loueurs pourront trouver votre voiture dans un rayon de 400 m de ce point.</div>
                      <p className="list-para m-0">Vous pouvez modifier ces informations ultérieurement</p>
                    </div>
                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button className="prev-button mr-2" onClick={this.previous}>
                        <span className="cobalt-Icon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>Precédent
                      </button>
                      <button className="Woohoo-btn" onClick={this.validateForm}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ viewDetails: AC_VIEW_HOST_DETAILS, handleInputChange: AC_HANDLE_INPUT_CHANGE, updateHostDetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}
const ParkingAddressComponent = connect(mapStateToProps, mapDispatchToProps)(ParkingAddress);

export default ParkingAddressComponent;
