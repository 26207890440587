import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AC_NOTIFICATION_SETTINGS,
  AC_VIEW_ACCOUNT,
  AC_HANDLE_NOTIFICATION_CHANGE,
  AC_EDIT_NOTIFICATION,
} from "../../actions/accountaction";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailnotifier: false,
      smsnotifier: false,
      appnotifier: false,
      smsnotifierError: false,
    };

    this.UpdateForm = this.UpdateForm.bind(this);
  }

  componentDidMount() {
    const id = localStorage.getItem("siteUser");
    this.props.ViewAccount(id);
    this.props.NotificationSettings();
  }
  UpdateForm() {
    const id = this.props.AccountReducer.accountInfo._id;
    const emailNotifier = this.props.AccountReducer.emailNotifier;
    const appNotifier = this.props.AccountReducer.appNotifier;
    const smsNotifier = this.props.AccountReducer.smsNotifier;
    const notifier = {
      emailNotifier: emailNotifier,
      appNotifier: appNotifier,
      smsNotifier: smsNotifier,
    };
    this.props.EditNotification(notifier, id);
  }

  onchangeValue(event) {
    const id = event.target.id;
    const name = event.target.name;
    const value = event.target.checked;

    this.props.UpdateNotification(name, value, id);
  }
  render() {
    const accountInfo = this.props.AccountReducer.accountInfo;
    const contactemail = accountInfo.email;
    const notificationList = this.props.AccountReducer.notificationList;
    const notificationCount = this.props.AccountReducer.notificationCount;
    var notificationlistArray = [];
    var emailNotifierView = {};
    var appNotifierView = {};
    var smsNotifierView = {};
    if (this.props.AccountReducer.emailNotifier) {
      emailNotifierView = this.props.AccountReducer.emailNotifier;
    }
    if (this.props.AccountReducer.appNotifier) {
      appNotifierView = this.props.AccountReducer.appNotifier;
    }
    if (this.props.AccountReducer.smsNotifier) {
      smsNotifierView = this.props.AccountReducer.smsNotifier;
    }
    for (var i = 0; i < notificationCount; i++) {
      const emailnotifier =
        this.props.AccountReducer.notificationList[i].emailnotifier;
      const smsnotifier =
        this.props.AccountReducer.notificationList[i].smsnotifier;
      const appnotifier =
        this.props.AccountReducer.notificationList[i].appnotifier;
      notificationlistArray.push(
        <div className="flex-div" key={i}>
          <div className="left-div">
            <strong className="text-body">{notificationList[i].name}</strong>
            <p className="cobalt-text_style_body_subdued">
              {notificationList[i].description}
            </p>
          </div>
          <div className="child">
            <input
              type="checkbox"
              name="emailnotifier"
              id={notificationList[i]._id}
              className=""
              checked={
                emailNotifierView[notificationList[i]._id] && emailnotifier
              }
              disabled={emailnotifier === false}
              onChange={(e) => this.onchangeValue(e)}
            />{" "}
          </div>
          <div className="child">
            {" "}
            <input
              type="checkbox"
              name="smsnotifier"
              id={notificationList[i]._id}
              className=""
              checked={smsNotifierView[notificationList[i]._id] && smsnotifier}
              disabled={smsnotifier === false}
              onChange={(e) => this.onchangeValue(e)}
            />
          </div>
          <div className="child">
            <input
              type="checkbox"
              name="appnotifier"
              id={notificationList[i]._id}
              className=""
              checked={appNotifierView[notificationList[i]._id] && appnotifier}
              disabled={appnotifier === false}
              onChange={(e) => this.onchangeValue(e)}
            />{" "}
          </div>
        </div>
      );
    }
    return (
      <div className="main-acc py-5 px-3" data-aos="zoom-in">
        <div className="flex-div">
          <div className="left-div">
            <fieldset className="form-field">
              <legend className="form-head">
                <i className="fas fa-shopping-cart"></i> En tant que locataire de
                véhicules
              </legend>
            </fieldset>
          </div>
          <div className="child">EMAIL</div>
          <div className="child">SMS</div>
          <div className="child">APP</div>
        </div>
        {notificationlistArray}

        <fieldset className="form-field mt-5">
          <legend className="form-head">
            <i className="fas fa-mail-bulk"></i> Newsletter
          </legend>
        </fieldset>
        <div className="flex-div">
          <div className="left-div">
            <p className="cobalt-text-body">
              <strong>Les courriels seront envoyés à cette adresse :</strong>{" "}
              {contactemail}
            </p>
          </div>
          <div className="right">
            <p>
              <a
                className="cobalt-Button cobalt-Button--ghost cobalt-Button--fullWidth"
                href="#"
              >
                Éditer
              </a>
            </p>
          </div>
        </div>
        <div className="flex-div">
          <div className="left-div">
            <strong className="text-body">Newsletter Monbolide</strong>
            <p className="cobalt-text_style_body_subdued">
              Restez informé des nouvelles et des offres commerciales de
              Monbolide chaque mois.
            </p>
          </div>
          <div className="right">
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="flex-div">
          <div className="left-div">
            <strong className="text-body">Newsletter Monbolide </strong>
            <p className="cobalt-text_style_body_subdued">
              Restez informé des nouvelles et des offres commerciales de
              Monbolide chaque mois.
            </p>
          </div>
          <div className="right">
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <fieldset className="form-field mt-5">
          <legend className="form-head">
            <i className="fas fa-sms"></i> Notifications mobiles
          </legend>
        </fieldset>
        <div className="group-div mb-5">
          <label htmlFor="smsNotifier" className="labl-sent">
            Les notifications par SMS seront envoyées à :
          </label>
          <div className="row">
            <div className="col-lg-6">
              <input
                type="tel"
                id="smsNotifier"
                placeholder="Ex: 0707070707"
                className="form-control"
              />
              {/* <span className='text-danger' style={{ fontSize: '14px' }}>10 chiffres requis !</span> */}
            </div>
          </div>
        </div>
        <div className="push">Paramètres des notifications push</div>
        <p>
          Pour recevoir des notifications push en cas de demande ou de
          réservation, téléchargez <a href="# ">l'application Monbolide</a>.
          <br />
          Pour activer ou désactiver ces notifications, rendez-vous dans la
          section "paramètres" de votre appareil mobile.
        </p>
        <fieldset className="form-field mt-5">
          <button
            type="button"
            name="commit"
            className="account-btn text-white"
            onClick={this.UpdateForm}
          >
            Mettre à jour
          </button>
        </fieldset>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewAccount: AC_VIEW_ACCOUNT,
      NotificationSettings: AC_NOTIFICATION_SETTINGS,
      UpdateNotification: AC_HANDLE_NOTIFICATION_CHANGE,
      EditNotification: AC_EDIT_NOTIFICATION,
    },
    dispatch
  );
}
const NotificationsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);

export default NotificationsComponent;
