import React from 'react';
import {Emailvalidate} from '../../common/validate';
import {AC_FORGOT_PASSWORD} from '../../actions/accountaction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Link} from "react-router-dom";

class ForgotPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        email       		       : '',
        emailError             : false,
        validemailError        : false,
      }
  }
  ValidateForm(){
  	const data		   		= this.state;
  	if(!data.email){
  		this.setState({emailError:true});
  	}
    if(data.email && !data.validemailError){
      this.props.forgotpassword({email:data.email.toLowerCase(), host: window.location.host})
    }
  }
  onchangeValue(e){
    // const name  				 = e.target.name;
    const value 				 = e.target.value;
    const validemailcheck 	 = Emailvalidate(value);
    this.setState({email:value,emailError:false});
    if(!validemailcheck){
      this.setState({validemailError:true});
    } else {
      this.setState({validemailError:false});
    }
  }
  render() {
    return(
      <div className="main-div">
    		<div className="row">
    		  <div className="col-lg-6">
    			 <div className="main-layout">
    			  	<ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
    				</ul>
    				<div className="tab-content" id="pills-tabContent">
  				  	<div className="tab-header" style={{boxShadow:"none",paddingBottom: "30px"}}>
              Mot de passe oublié
  				  	</div>
              <label>Saisissez votre adresse électronique ci-dessous pour recevoir les instructions de réinitialisation de votre mot de passe.</label>
    					<form action="#">
    							<input type="text" placeholder="Email" className="form-control form-pad" name="email"  onChange={(e)=>this.onchangeValue(e)}/>
                  {this.state.emailError?<label style={{color:"red"}}>Le champs email est requis.</label>:""}
  								{this.state.validemailError?<label style={{color:"red"}}>L'adresse email est invalide</label>:""}
    							<input type="button" value="Envoyer" className="form-control sign-btn" onClick={()=>this.ValidateForm()}/>
    							<div className="text-center">
                    Connectez-vous à votre compte ?
                    <Link to="/sign-in" name="signup" > Se connecter</Link>
                </div>
    					</form>
    				</div>
    			</div>
    		  </div>
    		  <div className="col-lg-6">
    		  	<div className="sign-img">
    		  		{/* <img src="assets/images/sign-img.jpg" alt=""/> */}
    		  	</div>
    		  </div>
    		</div>
    	</div>
    )
  }
}

function mapStateToProps(state) {
	return {
		AccountReducer	: state.AccountReducer
	};
  }

function mapDispatchToProps(dispatch) {
   return bindActionCreators({forgotpassword : AC_FORGOT_PASSWORD},dispatch)
 }

const ForgotPasswordComponent = connect(mapStateToProps,mapDispatchToProps)(ForgotPassword);

export default ForgotPasswordComponent;
