import React from "react";
import 'antd/dist/reset.css';
import PropTypes from "prop-types";
import { Modal,Button} from 'antd';


export default class OtherQuestionModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    return (
      <>
        <Modal 
        title="Autre question" visible={this.props.showOtherQuestion}
        centered={true}
        width={1000}
        footer={null}
        onCancel={this.onClose} >
          <div class="modalText m-4 p-4">
            <h5 align="left">
            <strong>Contacter le ou la propriétaire</strong>
            </h5>
            <p>
                Vous pourrez rentrer en contact avec le propriétaire dès que celui aura
                accepter votre location.
            </p>
            <p>
                Avant cela vous pourrez lui laisser un petit message sur la messagerie
                MONBOLIDE mais il est STRICTEMENT INTERDIT D’ECHANGER VOTRE NUMERO ET VOTRE
                ADRESSE AVANT QUE
            </p>
            <p>
                LA RESERVATION SOIT EFFECTIVE pour des raisons de sécurité.
            </p>
            <p>
                Après que la location soit effective vous pourrez rentrer en contact avec
                le propriétaire sur la messagerie MONBOLIDE ou avec directement via sur son
                numéro de téléphone.
            </p>
            <p>
                Nous vous conseillons de contacter le propriétaire avant l’heure définir
                pour la location pour plus de renseignement sur les détails que vous
                jugerez nécessaire pour une bonne location.
            </p>
            <p>
                Si le propriétaire est injoignable après avoir réserver une voiture sur
                MONBOLIDE
            </p>
            <p>
                Si votre location est confirmée et que le propriétaire ne répond pas à vos
                messages : <strong>soyez patient</strong>. Les propriétaires n'ont pas
                toujours le temps de répondre immédiatement ou de vérifier leur boite mail
                de manière régulière.
            </p>
            <p>
                Si vous avez tenté de le contacter depuis la messagerie MONBOLIDE, nous
                vous conseillons également de l'appeler et de lui laisser un message afin
                qu'il puisse vous recontacter au plus vite.
            </p>
          </div>
        </Modal>

      </>
    
    )
  }
}

OtherQuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showOtherQuestion: PropTypes.bool.isRequired
};