import React from "react";
import BannerSearch from "./bannersearch";
// import NewName from './newname';
import RentCar from "./rentcar";
import LatestFeeds from "./latestfeeds";
import ListCar from "./listcar";
import PlayStore from "./playstore";
import Mobility from "./mobility";
// import MediaLayer from './medialayer';
import About from "./about";

class Dashboard extends React.Component{
    render(){
        return(
            <div className="main-body oveflowX">
                <BannerSearch/>
                {/* <NewName/> */}
                <RentCar/>
                <LatestFeeds history={this.props.history}/>
                <ListCar/>
                <PlayStore/>
                <Mobility/>
                {/* <MediaLayer/> */}
                <About/>
            </div>
        )
    }
}

export default Dashboard;
