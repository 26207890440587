import { combineReducers } from 'redux';
import ConfigurationReducer from './reducers/configurationreducer';
import DashboardReducer from './reducers/dashboardreducer';
import HostCarReducer from './reducers/hostcarreducer';
import CarsReducers from './reducers/carsreducer';
import AccountReducer from './reducers/accountreducer';
import PagesReducer from  './reducers/pagesreducer';
import SearchCarsReducer from './reducers/searchcarsreducer';
import FaqReducer from  './reducers/faqreducer';

export default combineReducers({
  DashboardReducer,
  ConfigurationReducer,
  HostCarReducer,
  AccountReducer,
  CarsReducers,
  PagesReducer,
  SearchCarsReducer,
  FaqReducer
})
