import React from "react";
import 'antd/dist/reset.css';
import PropTypes from "prop-types";
import { Modal,Button} from 'antd';


export default class InsuranceModal extends React.Component {

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    return (
      <>
        <Modal 
        title="Assurance et assistance" visible={this.props.showInsurance}
        centered={true}
        width={1000}
        footer={null}
        onCancel={this.onClose} >
          <div class="modalText">
            <h3>
                Panne et assistance routière
            </h3>
            <p>
                Tout les véhicules présents sur notre plateforme sont assurés. En cas de
                panne avec un véhicule vous devez d’abord joindre l’assistance de la
                plateforme :
            </p>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Dans le cas ou c’est une location avec option assurance et
                        assistance des deux parts, nous prenons en charge le dépannage sur
                        place ou le remorquage du véhicule vers le garage le plus proche,
                        le retour à domicile ou la poursuite du voyage.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Dans le cas ou c’est une avec l’option location assurance et
                        assistance de votre part, nous prenons en charge la poursuite de
                        voyage grâce à un autre véhicule de remplacement ou votre retour a
                        domicile.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Dans le cas ou c’est une location sans option assurance et
                        assistance de la part du propriétaire et de votre part, c’est
                        l’assurance du propriétaire qui devra s’occuper du véhicule
                    </p>
                </li>
                <li>
                    <p align="left">
                        Il est possible aussi de suivre une autre démarche d’un commun
                        accord avec le propriétaire.
                    </p>
                </li>
            </ul>
            <h3>
                Démarche à suivre en cas d’accident
            </h3>
            <h5>
                Si l’accident implique une autre personne (un tiers identifié) :
            </h5>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Vous devez remplir un constat amiable, qui déterminera la
                        responsabilité de chaque conducteur dans l’occident ou attendre
                        l’arrivé des autorités pour faire le constat.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Dans la rubrique assurée : indiquer le nom du propriétaire
                    </p>
                </li>
                <li>
                    <p align="left">
                        Pour le conducteur, indiqué votre nom et prénom
                    </p>
                </li>
                <li>
                    <p align="left">
                        Remplissez les autres cases et indiquez les circonstances avec le
                        tiers impliqué
                    </p>
                </li>
                <li>
                    <p align="left">
                        Prenez les photos des dommages sur votre véhicule ainsi que l’autre
                        véhicule impliqué
                    </p>
                </li>
                <li>
                    <p align="left">
                        Enfin si le véhicule n’est plus en état de fonctionner contactez
                        l’assistance MONBOLIDE
                    </p>
                </li>
            </ul>
            <h5>
                Si l’accident n’implique pas une autre personne et que vous êtes le seul
                auteur des dégâts :
            </h5>
            <ul class="m-4 p-4">
                <li>
                    <p align="left">
                        Prenez des photos des dommages.
                    </p>
                </li>
                <li>
                    <p align="left">
                        Appelez le propriétaire pour l’informer des dommages
                    </p>
                </li>
                <li>
                    <p align="left">
                        Vous compléter le constat MONBOLIDE partie dommages en fin de
                        location
                    </p>
                </li>
                <li>
                    <p align="left">
                        Si la voiture n’est plus en état de rouler contacter l’assistance
                        MONBOLIDE (voir page assistance)
                    </p>
                </li>
            </ul>
            <p>
                <b class="text-info">
                    Dans tous les cas, le propriétaire procédera ensuite à une déclaration de
                    sinistre en ligne sur le site de MONBOLIDE. Nous reviendrons vers vous pour
                    que vous nous fournissiez une déclaration circonstanciée de l’accident si
                    besoin. Si vous n’êtes pas responsable de l’accident, vous n’aurez rien à
                    payer. Si vous êtes responsable de l'accident ou que le tiers responsable
                    n'est pas identifié, votre franchise entrera en jeu, et des frais de
                    gestion pourront être appliqués.
                </b>
            </p>
            <p align="left">
                <u><strong>Démarche à suivre en cas de vol</strong></u>
            </p>
            <p>
                Si vous vous faites voler le véhicule au cours de votre location, vous
                devez prévenir immédiatement le propriétaire et le service client
                MONBOLIDE. Une plainte sera déposée auprès de la police et votre
                rapatriement sera assuré par l’assistance.
            </p>
            <h3>
                Véhicule de remplacement
            </h3>
            <p>
                Si vous avez souscrit à l’option assurance et assistance MONBOLIDE, vous
                pourrez bénéficier d'un véhicule de remplacement en cas de panne ou
                accident survenu pendant la location, sous réserve de disponibilité d'un
                véhicule au moment de l'incident, de l’âge du conducteur, de la détention
                du permis de conduire et d’une carte bancaire pour la caution du véhicule
                de remplacement.
            </p>
          </div>
        </Modal>

      </>
    
    )
  }
}

InsuranceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showInsurance: PropTypes.bool.isRequired
};