import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ChatInput from "./ChatInput";

export default function ChatContainer() {
    const [messages, setMessages] = useState([
        {
            fromSelf: true,
            message: "Hello world",
            time: Date.now(),
        },
        {
            fromSelf: false,
            message: "How are you",
            time: Date.now(),
        }
    ]);
    const scrollRef = useRef(); // gestion du scroll


    const handleSendMsg = async (msg) => {
        // récupérer les données de l'utilisateur

        // envoyer le message de d'utilisateur au destinataire par socket
        // socket.current.emit("send-msg", {
        //     to: currentChat._id, // L'envoyeur
        //     from: data._id, //Le receveur
        //     msg, // Le message
        //   });

        //  Ajouter un message par la methode post 
        console.log(msg)
    }
    // db.carlists.updateOne({"parking.location.lon": null}, {$set: {"parking.formatted_address": "Cocody, Abidjan", "parking.location.lat": 5.365935001667557, "parking.location.lon": -3.9550803064452316}})

    return (
        <div>
            <Container>
                <div className="chat-header">
                    <div className="user-details">
                        <div className="avatar">

                        </div>
                        <div className="username">
                            <h3>Brice Gnanago</h3>
                        </div>
                    </div>
                </div>
                <div className="chat-messages">
                    {messages.map((message) => {
                        return (
                            <div ref={scrollRef} key={uuidv4()}>
                                <div
                                    className={`message ${message.fromSelf ? "sended" : "recieved"
                                        }`}
                                >
                                    <div className="content">
                                        <p>{message.message}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <ChatInput handleSendMsg={handleSendMsg} />
            </Container>
        </div>
    );
}


const Container = styled.div`
            display: grid;
            height: 50%;
            width: 300px;
            position: fixed;
            bottom: 100px;
            right: 10px;
            /* grid-template-rows: 10% 80% 10%; */
            background-color: #fff;
            gap: 0.1rem;
            overflow: hidden;
            @media screen and (min-width: 720px) and (max-width: 1080px) {
                grid - template - rows: 15% 70% 15%;
            }

            /* Entête du chat */
            .chat-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 2rem;
                background-color: #d1d1d1;
                .user-details {
                    display: flex;
                    align-items: center;
                    gap: .6rem;
                    .avatar {
                        img {
                            height: 2rem;
                        }
                    }
                    .username {
                        h3 {
                            color: white;
                        }
                    }
                }
            }
            /*  */
            .chat-messages {
                padding: 1rem 2rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    &-thumb {
                        background-color: #ffffff39;
                        width: 0.1rem;
                        border-radius: 1rem;
                    }
                }
                .message {
                    display: flex;
                    align-items: center;
                    .content {
                        max-width: 100px;
                        overflow-wrap: break-word;
                        padding: .7rem;
                        font-size: .85rem;
                        border-radius: 1rem;
                        color: #d1d1d1;
                        @media screen and (min-width: 720px) and (max-width: 1080px) {
                            max-width: 70%;
                        }
                    }
                }
                .sended {
                    justify-content: flex-end;
                    .content {
                        background-color: #4f04ff21;
                    }
                }
                .recieved {
                    justify-content: flex-start;
                    .content {
                        background-color: #9900ff20;
                    }
                }
            }`;