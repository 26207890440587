import React from 'react';
import {Link,Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_GET_CONFIGURATION } from '../../actions/configurationaction';
import { AC_VIEW_HOST_DETAILS,AC_HANDLE_INPUT_CHANGE,AC_UPDATE_HOST_DETAILS } from '../../actions/hostcaraction';

class Mileage extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     previous     : false,
     next         : false,
   }
   this.validateForm = this.validateForm.bind(this);
   this.previous     = this.previous.bind(this);
 }
 componentDidMount() {
     this.props.ConfigList({slug:'mileage'});
     if(localStorage.getItem('formId')){
       this.props.viewDetails(localStorage.getItem('formId'));
     }
 }
 onChangeValue(event) {
   const name  = event.target.name;
   const value = event.target.value;
   this.props.handleInputChange(name,value,'mileage')
 }
 validateForm() {
   const id          = localStorage.getItem('formId');
   var mileageInfo = this.props.HostCarReducer?.mileageInfo;
   const options = this.props.ConfigurationReducer?.ConfigValue;
   if(mileageInfo==undefined && options[0]?.value){
     mileageInfo = {mileage:options[0]?.value};
   }
   this.props.updateHostDetails(mileageInfo,id,'Mileage');
   this.setState({next : true})
 }

  previous() {
    this.setState({ previous: true });
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if(next) {
      return <Redirect to="/car-registration-detail" />
    }
    if (previous) {
      return <Redirect to="/parking-address" />;
    }
    var mileage = "";
    const mileageInfo = this.props.HostCarReducer?.mileageInfo;
    const options = this.props.ConfigurationReducer?.ConfigValue;
    if(mileageInfo) {
      mileage = mileageInfo?.mileage;
      
    }else{
      mileage = options[0]?.value
    }
    
    var mileageOptions = [];
    if (options) {
      for (var i = 0; i < options.length; i++) {
        mileageOptions.push(
          <option key={i} value={options[i].value}>{options[i]?.value}</option>
        )
      }
    }
    return (
      <section>
    		<div className="form-layout">
    			<div className="container">
    				<div className="row">
    					<div className="col-md-6 offset-md-3">
    						<div className="list-my-car">
    							<div className="list-top">
    								<div className="progress" style={{height: "8px"}}>
      									<div className="progress-bar" role="progressbar" style={{width: "15%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    								</div>
    							</div>
    							<div className="list-car-card">
                  <h4 className='card-header text-white text-center py-4 mb-2' style={{background:"#004AAD", textTransform:'uppercase', fontFamily: 'poppins'}}>Ajouter mon véhicule</h4>
    								<div className="container">
                      
                      <div className="title-card">Le kilométrage est-il exact ?</div>
                      <label htmlFor="" className="acc-label">kilométrage</label>
                      <select className="form-control" id="sel1" name="mileage" value={mileage} onChange ={(e) => this.onChangeValue(e)}>
                          {mileageOptions}
                      </select>
                      <div className="list-info mt-4 mb-2">
                        Ces informations nous permettent de garantir la qualité
                        et la sécurité de notre flotte.
                      </div>
                    </div>
                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button
                        className="prev-button mr-2"
                        onClick={this.previous}
                      >
                        <span className="cobalt-Icon">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>
                        Precédent
                      </button>
                      <button
                        className="Woohoo-btn"
                        onClick={this.validateForm}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigurationReducer: state.ConfigurationReducer,
    HostCarReducer: state.HostCarReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ConfigList: AC_GET_CONFIGURATION,
      viewDetails: AC_VIEW_HOST_DETAILS,
      handleInputChange: AC_HANDLE_INPUT_CHANGE,
      updateHostDetails: AC_UPDATE_HOST_DETAILS,
    },
    dispatch
  );
}
const MileageComponent = connect(mapStateToProps, mapDispatchToProps)(Mileage);

export default MileageComponent;
