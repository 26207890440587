import { useState } from "react";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";


// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Loading() {

  let [color] = useState("blue");

  return (
    <div className="sweet-loading">
      <MoonLoader color={color}  css={override} size={50} />
    </div>
  );
}

export default Loading;