import React, { createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import BounceLoader from "react-spinners/BounceLoader";
import { IoIosChatbubbles } from "react-icons/io";
import { css } from "@emotion/react";
import { io } from "socket.io-client";
import API from "../../common/api";
import {
  AC_VIEW_TRIPS,
  AC_UPDATE_CAR_STATUS,
  AC_VIEW_TRIPS_REQUEST,
} from "../../actions/accountaction";
import "./Request.css";
import moment from "moment";
// import ChatBox from "../../pages/ChatBox";
// import ChatContainer from "../Chats/ChatContainer";
import ChatBoxContainer from "../ChatBox/ChatBoxContainer";
import { isDateExpired } from "../../utils/functions";


const override = css`
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.socket = createRef()
    this.state = {
      countMessage: 0,
      loading: true,
      currentTrip: null,
      closed:false,
    };
  }

  componentDidMount() {
    this.props.ViewTrips({
      request: 1,
      id: localStorage.getItem("siteUser"),
      status: 5,
    }).then(()=>{
      this.setState({
        loading: false,
      });
    });
    const tripID = this.getTripFromQuery();
      var tripsList = this.props.AccountReducer.tripsListRequest;
    if(tripID && tripsList) {
      this.getTripByUrl(tripsList, tripID);
    } 
    this.socket.current = io(API.LOCAL_WEBSOCKET_URL)
    this.socket.current.emit("add-user", localStorage.getItem("siteUser"));
  }

  closeChat = (closed) => {
    this.setState({ closed: closed });
  };
  
  getCurrentTripChat = (trip) => {    
    this.setState({ currentTrip: trip, closed:false });
  };

  getTripFromQuery = () => {
    const params = new URLSearchParams(this.props.location.search);
    const tripId = params.get('tripId');
    // console.log(tripId)
    return tripId;
  };

  onChangeValue(e) {
    this.setState({
      loading: true,
    });
    this.props.ViewTrips({
      id: localStorage.getItem("siteUser"),
      request: 1,
      status: e.target.value,
    }).then(()=>{
      this.setState({
        loading: false,
      });
    });
  }

  formatDate(date) {
    let dt = date.substring(0, 10).split("-").reverse().join("/");
    let hr = date.split(" ")[1];

    return hr ? dt + " " + hr : dt;
  }

  // Fonction que retourne la bonne clé de l'image
  imageKey = (data, waste) => data.replace(waste, "");

  getNbHourBeforeTrips = (checkin) => {
    // Recupération de la date de début
    let currentDate = new Date();
    currentDate = moment(currentDate, "DD-MM-YYYY HH:mm");
    let checkInFormatted = moment(checkin, "DD-MM-YYYY HH:mm");
    // endDate.diff(startDate, 'day')
    let hours = checkInFormatted.diff(currentDate, "hours");
    // if  <= 0 hours <= 2 afficher le contrat
    // if hours >= 2 contrat de location masqué
    // if hours <0 date de location du véhicule passé
    return hours;
  };


  navigateToAgreement = (event) => {
    // event.preventDefault()

    this.props.history.push({
      pathname: `/rental-agreement/${event.target.id}`,
      state: { id: event.target.id },
    });
  };

  getCurrentTrip = (tripId) => {    
    for(let i = 0; i < this.props?.AccountReducer?.tripsListRequest.length; i++){
      if(tripId === this.props.AccountReducer.tripsListRequest[i]._id){        
        return this.props.AccountReducer.tripsListRequest[i];
      }

    }
  }

  getTripByUrl = (trips, tripId) => {
    for(let i = 0; i < trips.length; i++) {
      if(trips[i]._id === tripId) {
        this.getCurrentTrip(trips[i]);    
        break;
      }
    }
    
  }

  setCarStatus(e) {
    
    var name = e.target.name;
    var id = e.target.id;
    // const currentTrip = this.getCurrentTrip(id)
    var userId = localStorage.getItem("siteUser");
    // console.log('############CustomerId###############')
    //         console.log(currentTrip)
    if (name === "accept") {
      swal({
        title: "Etes-vous sûr ?",
        text: "Une fois accepté, vous ne pourrez pas la refuser !",
        buttons: true,
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          this.props.UpdateCarStatus({
            id: id,
            tripStatus: 3,
            type: "request",
            userId: userId,
          }).then(()=> {
            // console.log('############CustomerId###############')
            // console.log(currentTrip.customerId)
            this.setState({
              loading: true,
            });
            this.props.ViewTrips({
              request: 1,
              id: localStorage.getItem("siteUser"),
              status: 5,
            }).then(() => {
              this.setState({
                loading: false,
              });
              swal({
                text: "Merci de patienter ! Vous serez contacté dans les prochaines minutes",
                icon: "success",
              });
            })
          });
        }
      });
    }
    if (name === "decline") {
      swal("Merci de renseigner le motif du motif.", {
        content: "input",
        buttons: ["Fermer", "Envoyer!"],
        
                
      })
        .then((value) => {
          if (value) {
            if (value.length > 3) {
              this.setState({
                loading: true,
              });
              this.props.UpdateCarStatus({
                id: id,
                tripStatus: 0,
                patternRefuse: value,
                type: "request",
                userId: userId,
              }).then(()=> {   
                this.setState({
                  loading: false,
                });             
                swal({
                  text: "Merci ! Votre client sera informé de votre décision",
                  icon: "success",
                });
                this.props.ViewTrips({
                  request: 1,
                  id: localStorage.getItem("siteUser"),
                  status: 5,
                })

              }).catch(e => {
                this.setState({
                  loading: false,
                });
                swal({
                  text: "Une erreur s'est produite",
                  icon: "error",
                });
              });                            
            }
            else {
              swal(`Merci de faire une phrase cohérence`, {
                button: "D'accord",
                dangerMode: true
              });
            }
          }else {
            swal(`Merci de faire une phrase cohérence`, {
              button: "D'accord",
              dangerMode: true
            });
          }
        });
    }
    //   swal({
    //     title: "Etes-vous sûr ?",
    //     text: "Une fois refusé, vous ne pourrez pas l'accepter !",
    //     icon: "warning",
    //     buttons: true,
    //     dangerMode: true,
    //   }).then((willDelete) => {
    //     if (willDelete) {
    //       this.props.UpdateCarStatus({
    //         id: id,
    //         tripStatus: 0,
    //         type: "request",
    //         userId: userId,
    //       });
    //     }
    //   });
    // }


    if (name === "completed") {
      this.props.history.push({
        pathname: `/rental-agreement/${e.target.id}`,
        state: { id: e.target.id },
      });
    }
  }
  render() {
    let tripsList = this.props.AccountReducer.tripsListRequest;

    let tripsArray = [];
    let count = 0;
    if (tripsList) {
      for (let i = 0; i < tripsList.length; i++) {
        var image =
          "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/car/user-profile_heulnq.png";

        if (tripsList[i]?.customerId) {
          if (tripsList[i]?.customerId?.image) {
            image =
              API.IMAGEURL +
              this.imageKey.bind(this)(
                tripsList[i]?.customerId?.image,
                "uploads/"
              );
          }
          if (tripsList[i]?.tripStatus === 1) {
            count++;
          }
        }

        if (tripsList[i]?.customerId && !isDateExpired(tripsList[i].checkin)) {
          tripsArray.push(
            <div className="main-sec mb-5" key={i}>
              <div
                className="chatButton"                
                onClick={() => this.getCurrentTripChat(tripsList[i])}
              >
                <IoIosChatbubbles size={25} color={"#fff"}/>
              </div>
              
              {tripsList[i].tripStatus === 0 ? (
                <div>
                  <div className="alert alert-warning alert-fontsize text-center">
                    <i className="fa fa-times-circle-o close-icon-size-decline" />
                    Vous avez refusé cette demande
                  </div>
                </div>
              ) : (
                ""
              )}
              {tripsList[i].tripStatus === 2 ? (
                <div>
                  {/* <button className="accept-btn"><i className="fas fa-clipboard-check tick-icon-size-accept"></i>Terminée</button> */}
                  {this.getNbHourBeforeTrips(tripsList[i].checkin) >= 5 && (
                    <div className="alert my-alert alert-fontsize text-center">
                      Votre contrat sera disponible 5h avant le début de la
                      location pour signature
                    </div>
                  )}
                </div>) : ("")}

              {!isDateExpired(tripsList[i].checkin) && tripsList[i].tripStatus === 1 && <div><div className="alert alert-info alert-fontsize text-center"><i className="fas fa-circle-notch fa-spin mr-2"></i> Nouvelle demande en cours... Merci de l'accepter ou de la refuser selon votre convenance</div></div>}
              {tripsList[i].tripStatus === 3 && !isDateExpired(tripsList[i]?.checkin) && <div><div className="alert alert-info alert-fontsize text-center"><i className="fas fa-circle-notch fa-spin mr-2"></i> Attente de paiement du locataire ! Merci de patienter SVP</div></div>}
              {isDateExpired(tripsList[i].checkin) && tripsList[i].tripStatus !== 4 && tripsList[i].tripStatus !== 7 && tripsList[i].tripStatus !== 0 && <div><div className="alert alert-warning alert-fontsize text-center"><i className="fas fa-hourglass-end mr-2"></i> Demande Expirée ! Impossible de continuer le processus de cette demande</div></div>}
              {tripsList[i].tripStatus === 4 ? <div>
                <div className="alert my-alert alert-fontsize text-center"><i className="fa fa-times-circle-o close-icon-size-decline" />Le client a annulé sa demande</div>
              </div> : ""}
              {tripsList[i].tripStatus === 7 ? <div className="alert alert-success flex-alert" style={{ fontSize: '12px', fontFamily: 'Roboto' }}><i className="fas fa-check-double mr-2"></i> Location terminée avec succès</div> : ""}
              <div className="main-profile-card">
                <div className="profile-card">
                  <div className="profile-card-img">
                    <a href={image}>
                      <img src={image} alt="Profile" />
                    </a>
                  </div>
                  <div className="text-btn">
                    <p
                      className="fullName"
                      style={{ textTransform: "uppercase" }}
                    >
                      {tripsList[i]?.customerId?.firstname
                        ? tripsList[i]?.customerId?.firstname
                        :  " " + tripsList[i]?.customerId?.lastname
                          ? tripsList[i]?.customerId?.lastname
                          : ""}
                    </p>
                    <p
                      className="fullName"
                      style={{ textTransform: "uppercase" }}
                    >
                      {tripsList[i].price} CFA
                    </p>
                      {/* {tripsList[i]?.customerId?.email?<p>Email: {tripsList[i]?.customerId?.email}</p>:""} */}
                      {/* {tripsList[i]?.customerId?.addressline1?<p>Adresse: {tripsList[i]?.customerId?.addressline1}</p>:""} */}
                      {/* {tripsList[i]?.customerId?.mobilenumber? <p>Adresse: {tripsList[i]?.customerId?.mobilenumber}</p>:""} */}
                    <div>
                      <span className="brandName">
                        {tripsList[i]?.carId?.carmodel?.make?.brandname}
                      </span>
                    </div>
                    {tripsList[i]?.checkin ? (
                      <span className="checkIn">
                        {tripsList[i]?.checkin}
                      </span>
                    ) : (
                      ""
                    )}{" "}
                    <i className="fas separatorIconDate fa-arrow-right fa-xs"></i>
                    {tripsList[i]?.checkout ? (
                      <span className="checkOut">
                        {" "}
                        {tripsList[i]?.checkout}
                      </span>
                    ) : (
                      ""
                    )}
                    
                    {tripsList[i]?.destination && <div>
                      <span className="brandName">
                        Location hors de la ville de stationnement
                      </span>
                    </div>}
                    <div>
                      <span className="number">
                        {tripsList[i]?.carId?.number?.number}
                      </span>
                    </div>
                  </div>
                </div>
                {tripsList[i].tripStatus === 1 &&
                  !isDateExpired(tripsList[i]?.checkin) ? (
                  <div className="buttons">
                    <button
                      className="accept-btn"
                      name="accept"
                      id={tripsList[i]?._id}
                      value={tripsList[i]?._id}
                      onClick={(e) => this.setCarStatus(e)}
                    >
                      <i
                        onClick={(e) => this.setCarStatus(e)}
                        id={tripsList[i]?._id}
                        className="fa fa-check-circle tick-icon-size-accept"
                      />
                      Accepter
                    </button>
                    <button
                      className="decline-btn"
                      name="decline"
                      id={tripsList[i]?._id}
                      value={tripsList[i]?._id}
                      onClick={(e) => this.setCarStatus(e)}
                    >
                      <i
                        onClick={(e) => this.setCarStatus(e)}
                        id={tripsList[i]?._id}
                        className="fa fa-times-circle-o close-icon-size-decline"
                      />
                      Refuser
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {/* {tripsList[i].tripStatus === 3 && this.getNbHourBeforeTrips(tripsList[i]?.checkin) >= 0 ? <div className="buttons">
                  <button className="accept-btn"><i className="fa fa-check-circle tick-icon-size-accept" />En attente de paiement</button>
                </div> : ""} */}
                {tripsList[i].tripStatus === 22 ? (
                  <div className="buttons">
                    <button
                      className="accept-btn"
                      name="completed"
                      value={tripsList[i]?._id}
                      id={tripsList[i]?._id}
                      onClick={(e) => this.setCarStatus(e)}
                    >
                      <span style={{ padding: "0px 10px", color: "orange" }}>
                        <i className="fas fa-thumbs-up"></i>
                      </span>{" "}
                      Terminer
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {tripsList[i].tripStatus === 2 ? (
                  <div className="buttons">
                    {/* <button className="accept-btn"><i className="fas fa-clipboard-check tick-icon-size-accept"></i>Terminée</button> */}
                    {!isDateExpired(tripsList[i]?.checkin)
                        && (
                        <button
                          className="complete-btn px-2 py-2 mr-2"
                          style={{ borderWidth: "0px", borderRadius: "5px" }}
                          id={tripsList[i]?._id}
                          onClick={(e) => this.navigateToAgreement(e)}
                        >
                          <i
                            className="fas fa-file-contract fa-md"
                            id={tripsList[i]._id}
                            onClick={(e) => this.navigateToAgreement(e)}
                          ></i>{" "}
                          Contrat
                        </button>
                      )}
                    {isDateExpired(tripsList[i]?.checkin) && (
                      <button
                        className="complete-btn px-2 py-2 mr-2"
                        disabled
                        style={{ borderWidth: "0px", borderRadius: "5px" }}
                        id={tripsList[i]?._id}
                        onClick={(e) => this.navigateToAgreement(e)}
                      >
                        <i
                          className="fas fa-file-contract fa-md"
                          id={tripsList[i]._id}
                          onClick={(e) => this.navigateToAgreement(e)}
                        ></i>{" "}
                        Contrat
                      </button>
                    )}
                    {/* {this.getNbHourBeforeTrips(tripsList[i].checkin) >= 5 && <div className="alert alert-secondary alert-fontsize">Contrat disponible 5h avant le début de la location</div>} */}
                    {/* {this.getNbHourBeforeTrips(tripsList[i].checkin) < 0 && <div className="alert alert-secondary alert-fontsize">Demande expirée</div>} */}
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="container">
                  
                
                </div> */}
              </div>
            </div>
          );
        }
      }
    }
    return (
      <div className="container" style={{ marginTop: "-70px" }}>
        {(this.state.currentTrip && this.state.closed === false) && <ChatBoxContainer currentChat={this.state.currentTrip} socket={this.socket} closeChat={this.closeChat} root={"request"} />}
        {this.state.loading && (
          <div className="loading">
            <BounceLoader
              color="#004aad"
              loading={this.state.loading}
              css={override}
              size={50}
            />
          </div>
        )}
        <div className="col-lg-8">
          <div className="your-listing-header">
            <div className="right-side">
              <form className="dropdown-status">
                <button
                  type="button"
                  className="status-btn dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Statut
                </button>
                <div className="dropdown-menu">
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value={5}
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Toutes les demandes</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value={1}
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">En attente d'approbation</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value={2}
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Compléte </label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value={3}
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Acceptée</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value={0}
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Refusée</label>
                  <br />
                  <input
                    type="radio"
                    className="list-name"
                    name="options"
                    value={4}
                    onChange={(e) => this.onChangeValue(e)}
                  />
                  <label className="list-label">Annulée</label>
                  <br />
                </div>
              </form>
            </div>
            {/* {count > 1 && (
              <div
                className="nb-message-read py-3 px-2"
                style={{ fontSize: ".8rem" }}
              >
                {" "}
                {count} Demandes en cours...
              </div>
            )} */}
            {/* {count === 1 && (
              <div
                className="nb-message-read py-3 px-2"
                style={{ fontSize: ".8rem" }}
              >
                {" "}
                {count} Demande en cours...
              </div>
            )} */}

            {/* {
              count === 0 && (
                <div
                  className="nb-message-read py-3 px-2"
                  style={{ fontSize: ".8rem" }}
                >
                  {" "}
                  Aucune Demande en cours...
                </div>
              )
            } */}
          </div>
          <br />
          {tripsArray.length === 0 ? (
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="container">
                  <div className="col-lg-12">
                    <div className="main-sec">
                      <div className="car-sec">
                        <img
                          src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/car-img_vocfo7.png"
                          style={{ width: "60%" }}
                          className="car-img"
                          alt=""
                        />
                        <h2>Aucune demande de réservation</h2>
                        <p>
                          {" "}
                          Vous n'avez pas de demandes pour le moment. Pourquoi
                          ne pas améliorer votre liste ?
                        </p>
                        {/*<a href="#" className="rent-btn">Gérer ses véhicules</a>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {tripsArray}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ViewTrips: AC_VIEW_TRIPS_REQUEST, UpdateCarStatus: AC_UPDATE_CAR_STATUS },
    dispatch
  );
}

const RequestComponent = connect(mapStateToProps, mapDispatchToProps)(Request);

export default RequestComponent;
