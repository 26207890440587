import axios from "axios";
import URL from "../common/api";
import { Success, Error } from "../common/swal";
const LIST_CAR_MODEL = "LIST_CAR_MODEL";
// const VIEW_HOST_DETAILS   = 'VIEW_HOST_DETAILS' ;

const VIEW_YOUR_CAR = "VIEW_YOUR_CAR";
const EDIT_CARDETAILS = "EDIT_CARDETAILS";
const ADD_CARIMAGE = "ADD_CARIMAGE";
const ADD_UNAVAILIBILITY = "ADD_UNAVAILIBILITY";
const DELETE_UNAVAILIBILITY = "DELETE_UNAVAILIBILITY";

const UPDATE_CAR_DETAILS = "UPDATE_CAR_DETAILS";

const INSTANT_LOCATION = "INSTANT_LOCATION";

const EDIT_REQUIRE_CAR = "EDIT_REQUIRE_CAR";
const CAR_REQUIRE = "CAR_REQUIRE";

export function AC_DELETE_UNAVAILIBILITY(formData) {
  return function (dispatch) {
    return axios
      .post(URL.API.DeleteUnavailibility, formData)
      .then(({ data }) => {
        if (data.status) {
          Success(data.message);
          dispatch({ type: DELETE_UNAVAILIBILITY, payload: data });
        } else {
          Error(data.message);
        }
      });
  };
}

export function AC_ADD_UNAVAILIBILITY(formData) {
  return function (dispatch) {
    return axios.post(URL.API.AddUnavailibility, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        dispatch({ type: ADD_UNAVAILIBILITY, payload: data });
      } else {
        Error(data.message);
      }
    });
  };
}

export function AC_EDIT_CAR_REQUIREMENT(formData, id) {
  return function (dispatch) {
    return axios
      .post(URL.API.EditRequireCar, formData, {
        headers: { authorization: id },
      })
      .then(({ data }) => {
        if (data.status) {
          Success(data.message);
          dispatch({
            type: EDIT_REQUIRE_CAR,
            payload: data.data,
            model: data.model,
          });
        } else {
          Error(data.message);
        }
      });
  };
}

export function AC_HANDLE_CAR_REQUIRE(name, value) {
  return function (dispatch) {
    dispatch({ type: CAR_REQUIRE, name: name, value: value });
  };
}

export function AC_YOUR_CAR(formData) {
  return function (dispatch) {
    return axios.post(URL.API.ViewYourCar, formData).then(({ data }) => {
      dispatch({
        type: VIEW_YOUR_CAR,
        payload: data.data,
        model: data.model,
        reviews: data.reviews,
      });
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name, value, module, data) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_CAR_DETAILS,
      name: name,
      value: value,
      module: module,
      data: data,
    });
  };
}

/*START ACTION FOR ENABLE OR DISABLE INSTANT LOCATION  */

export function AC_HANDLE_INSTANT_LOCATION(formData, id) {
  return function (dispatch) {
    return axios
      .post(
        URL.API.EditCardetails,
        { ...formData },
        { headers: { authorization: id } }
      )
      .then(({ data }) => {
        dispatch({ type: INSTANT_LOCATION, payload: data.data });
      });
  };
}

/*END ACTION FOR ENABLE OR DISABLE INSTANT LOCATION  */

export function AC_EDIT_CARDETAILS(formData, id) {
  return function (dispatch) {
    return axios
      .post(URL.API.EditCardetails, formData, {
        headers: { authorization: id },
      })
      .then(({ data }) => {
        if (data.status) {
          Success(data.message);
          dispatch({
            type: EDIT_CARDETAILS,
            payload: data.data,
            model: data.model,
          });
        } else {
          Error(data.message);
        }
      });
  };
}

export function AC_ADD_CARIMAGE(formData) {
  return function (dispatch) {
    return axios.post(URL.API.AddCarImage, formData).then(({ data }) => {
      if (data.status) {
        Success(data.message);
        dispatch({ type: ADD_CARIMAGE, payload: data });
      } else {
        Error(data.message);
      }
    });
  };
}

export function AC_GET_MODEL(formData) {
  return function (dispatch) {
    return axios.post(URL.API.getCarModel, formData).then(({ data }) => {
      dispatch({ type: LIST_CAR_MODEL, payload: data.data });
    });
  };
}
